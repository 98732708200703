import React, { useState } from "react";
import withError from "./hocs/withError";
import RCSlider from "rc-slider";
import DOMPurify from "dompurify";

import "rc-slider/assets/index.css";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";

const Slider = ({ question, value, changeValue, isReadOnly }) => {
  let [internalValue, setInternalValue] = useState(value);

  const onChange = (value) => {
    if (isReadOnly) return;

    if (value < 0) {
      setInternalValue(0);
      changeValue(0);
    } else if (value > 100) {
      setInternalValue(100);
      changeValue(100);
    } else {
      setInternalValue(value);
      changeValue(value);
    }
  };

  let accessibilityHint = question.label;
  if (typeof question?.config?.accessibilityHint === "string") {
    accessibilityHint = question.config.accessibilityHint;
  }

  return (
    <div data-question-answer-value={internalValue}>
      <div style={styles.viewOuter}>
        <div style={styles.viewInner1}>
          {question.config.verticalLabels ? (
            <div />
          ) : (
            <div
              style={styles.text}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.config.label1),
              }}
            />
          )}
          <div style={styles.viewDisplayContainer}>
            <div
              style={styles.textDisplay}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.config.label2),
              }}
            />
            <div style={styles.viewValueDisplay}>
              <h3 style={styles.textValueDisplay}>
                {internalValue === undefined ? "?" : value}
              </h3>
            </div>
          </div>
          {question.config.verticalLabels ? (
            <div />
          ) : (
            <div
              style={styles.text}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.config.label3),
              }}
            />
          )}
        </div>
        <div style={styles.viewInner1}>
          {question.config.verticalLabels ? (
            <div
              style={{ ...styles.text, alignSelf: "flex-start" }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.config.label1),
              }}
            />
          ) : (
            <div />
          )}
          <div style={styles.viewInner2} accessibilityHint={accessibilityHint}>
            <NumberMarks />
            <Track />
            <RCSlider
              vertical
              onChange={onChange}
              min={0}
              max={100}
              step={question.config?.step}
              defaultValue={internalValue}
              styles={{
                handle: {
                  backgroundColor: "#fff",
                  border: "2px solid #f8991d",
                  outline: "2px solid #f8991d",
                  opacity: 1

                },
                track: { backgroundColor: "#f8991d" },
                rail: { backgroundColor: "#444" }
              }}
              style={{
                backgroundColor: null,
                height: "500px",
                marginLeft: "13px",
              }}
              disabled={isReadOnly}
            />
          </div>
          {question.config.verticalLabels ? (
            <div
              style={{ ...styles.text, alignSelf: "flex-start" }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.config.label3),
              }}
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

const Track = (props) => {
  const array = Array(101).fill(true);
  const ticks = array.map((val, index) => {
    if (index % 10 === 0) {
      return <div style={trackStyles.viewTick100} pointerEvents="none" />;
    }
    return <div style={trackStyles.viewTick50} pointerEvents="none" />;
  });

  return (
    <div style={trackStyles.viewOuter} pointerEvents="none">
      <div style={trackStyles.viewInner} pointerEvents="none">
        {ticks}
      </div>
    </div>
  );
};

const NumberMarks = () => {
  const numbers = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const numberMarks = numbers.map((val) => (
    <div style={numberMarkStyles.text}>{val}</div>
  ));
  return (
    <div style={numberMarkStyles.view} pointerEvents="none">
      {numberMarks}
    </div>
  );
};

const numberMarkStyles = {
  view: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column-reverse",
    alignItems: "flex-end",
    height: 520,
    paddingRight: 20,
  },
  text: {
    textAlign: "left",
    fontFamily: "Poppins-Bold",
  },
};

const trackStyles = {
  viewTick100: {
    height: "2px",
    backgroundColor: "#444",
    width: "40px",
  },
  viewTick50: {
    height: "2px",
    backgroundColor: "#444",
    width: "20px",
  },
  viewOuter: {
    height: "500px",
    width: "0px",
    overflow: "visible",
  },
  viewInner: {
    display: "flex",
    flexDirection: "column",
    width: "40px",
    position: "relative",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

const styles = {
  viewOuter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
  },
  viewInner1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "space-between",
    paddingTop: "16px",
    paddingBottom: "16px",
    flex: 1,
  },
  viewValueDisplay: {
    display: "flex",
    borderRadius: "100px",
    height: "80px",
    width: "80px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F8991D",
  },
  viewDisplayContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textValueDisplay: {
    fontFamily: "Poppins-Bold",
    color: "#FFF",
  },
  textDisplay: {
    textAlign: "center",
    fontFamily: "Poppins-Bold",
    paddingBottom: "16px",
    color: "#444",
    maxWidth: "200px",
  },
  text: {
    textAlign: "center",
    alignSelf: "flex-end",
    width: "160px",
    fontFamily: "Poppins-Regular",
  },
  viewInner2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "16px",
  },
};

export default withContainer(withLabel(withError(Slider)));
