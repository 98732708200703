import React, {useContext, useEffect, useMemo, useState, useCallback} from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import {Button, Icon, Message, Placeholder, Segment} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ConfigContext from "../../context/ConfigContext";
import GroupPermission from "../../GroupPermission";
import SubjectDataTableWorkflowTaskDisplay from "./SubjectDataTableWorkflowTaskDisplay";
import {getUiTabFromConfig} from "../../services/helpers/moduleHelper";

const SubjectDataWorkflowTables = ({t, subjectId, questionnaireDefinitions, questionnaireType, definitionCode, history, match, componentStillLoading}) => {
  const config = useContext(ConfigContext);
  const moduleCode = match?.params?.moduleCode;
  const uiTabConfig = getUiTabFromConfig(moduleCode);

  const definition = useMemo(()=>{
    return questionnaireDefinitions.find(
        (d) => d.code === definitionCode
    );
  }, [questionnaireDefinitions, definitionCode]);

  // handle loading permissions and config
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [permissions, setPermissions] = useState(null);
  const getPermissions = useCallback(async () => {
    const permissions = await SubjectService.getSubjectPermission(subjectId);
    setPermissions(permissions);
  }, [subjectId]);

  useEffect(()=>{
    if(hasLoaded) return;
    try {
      getPermissions()
    } catch (error) {
      console.error(error);
      setHasError(true);
    }
  }, [hasLoaded, getPermissions]);

  // get questionnaires for each workflow step
  const [workflowQuestionnaires, setWorkflowQuestionnaires] = useState([])
  const getQuestionnaires = useCallback(async () => {
    const requestParams = [subjectId, definitionCode];
    const questionnaireWorkflow = definition.questionnaireWorkflowDefinition;
    const tasks = questionnaireWorkflow.questionnaireWorkflowTasks;

    const taskQuestionnairesArray = await Promise.all(tasks.map(t=>SubjectService.getSubjectQuestionnairesByCodeAndWorkflowTask(...requestParams, t.code)))
    setWorkflowQuestionnaires(taskQuestionnairesArray
        .map((tQA, i)=> {return {task: tasks[i], response: tQA}})
        .filter((tQ, i) => !(tasks[i].type === "START" && tQ.response.count === 0))
    );
  }, [subjectId, definitionCode, definition.questionnaireWorkflowDefinition])

  useEffect(()=>{
    try{
      getQuestionnaires();
    } catch (error) {
      console.error(error);
      setHasError(true);
    }
  },[hasLoaded, getQuestionnaires])

  // hasLoaded check and set
  useEffect(()=>{
    const hasResponse = workflowQuestionnaires.length !== 0;
    const areAllLoaded = [
      ...workflowQuestionnaires,
      config,
      permissions
    ].every(v=> v!== null)

    setHasLoaded(hasResponse && areAllLoaded)
    if(componentStillLoading) componentStillLoading(false)
  },[componentStillLoading, config, permissions, workflowQuestionnaires]);

  // handle route change
  const resetState = useCallback(() => {
    setHasLoaded(false);
    setHasError(false);
    setPermissions(null);
  }, []);
  useEffect(()=>{
    resetState();
    if(componentStillLoading) componentStillLoading(true)
  }, [subjectId, questionnaireType, definitionCode, resetState, componentStillLoading]);


  // questionnaire creation
  const hasManualQuestionnaireTriggerPermission =
      permissions?.includes(
          GroupPermission.MANUAL_QUESTIONNAIRE_DELIVERY
      );
  const hasCreateSubjectQuestionnairesPermission =
      permissions?.includes(
          GroupPermission.CREATE_SUBJECT_QUESTIONNAIRES
      );

  let defaultAddNew = "SUBJECT_TAB_ADD_NEW_PRO";
  let labels = questionnaireDefinitions.filter(
      (q) => q.code === definitionCode
  );
  let buttonLabel = "";
  //if we found a translated label the use that else determine default
  if (labels.length > 0 && labels[0] !== undefined) {
    buttonLabel = t("GLOBAL_BUTTON_ADD_NEW") + " " + labels[0].label;
  } else {
    if (match.params.moduleCode !== undefined) {
      defaultAddNew = "SUBJECT_TAB_ADD_NEW_MODULE";
    } else if (
        match.params.questionnaireType &&
        match.params.questionnaireType.toUpperCase() === "DATA"
    ) {
      defaultAddNew = "SUBJECT_TAB_ADD_NEW_DATA";
    } else if (
        match.params.questionnaireType &&
        match.params.questionnaireType.toUpperCase() === "EVENT"
    ) {
      defaultAddNew = "SUBJECT_TAB_ADD_NEW_EVENT";
    }

    buttonLabel = t([defaultAddNew, "GLOBAL_BUTTON_ADD_NEW"]);
  }

  if (hasError) return <Message>{t("GLOBAL_GENERIC_ERROR_MESSAGE")}</Message>

  if(!hasLoaded) return (
      <Segment>
        <Placeholder fluid>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
  )

  if(!definition) return  <div>
    {t("DEFINITION_NOT_FOUND", "Question definition not found")}
  </div>



  return <>
    {workflowQuestionnaires.map((wQ) => <Segment style={{overflowX: "auto", overflowY: "hidden"}}>
      <SubjectDataTableWorkflowTaskDisplay
          t={t}
          definition={definition}
          workflow={definition.questionnaireWorkflowDefinition}
          task={wQ?.task}
          questionnaireType={questionnaireType}
          definitionCode={definitionCode}
          questionnaires={wQ?.response?.result}
          permissions={permissions}
          subjectId={subjectId}
          config={config}
          history={history}
          uiTabConfig={uiTabConfig}
      />
    </Segment>)}
    {questionnaireType === "pro" &&
        questionnaireDefinitions.length > 0 &&
        hasManualQuestionnaireTriggerPermission && (
            <>
              <Button
                  primary
                  icon
                  as={Link}
                  labelPosition="left"
                  to={
                      "/app/subject/" +
                      subjectId +
                      "/questionnaire-type/" +
                      questionnaireType +
                      "/" +
                      definitionCode +
                      "/create"
                  }
              >
                {buttonLabel} <Icon name="plus" />
              </Button>
            </>
        )}
    {questionnaireType === "data" &&
        questionnaireDefinitions.length > 0 &&
        hasCreateSubjectQuestionnairesPermission && (
            <Button
                primary
                icon
                as={Link}
                labelPosition="left"
                to={
                    "/app/subject/" +
                    subjectId +
                    "/data/" +
                    definitionCode
                }
            >
              {buttonLabel} <Icon name="plus" />
            </Button>
        )}
  </>


};

export default withTranslation()(SubjectDataWorkflowTables);