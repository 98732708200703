import React from "react";
import {withTranslation} from "react-i18next";
import {Message, Popup} from "semantic-ui-react";

const NullAnswerMedia = ({t}) => {

    return (
        <Popup
            trigger={
                <Message info>
                    <p>{t("MEDIA_CONTENT_NULLANSWER", "No Content Provided")}</p>
                </Message>
            }
        >
            <Popup.Content>{t("MEDIA_CONTENT_NULLANSWER_DETAIL", "Media content was not provided for this question.")}</Popup.Content>
        </Popup>
    );

}

export default withTranslation()(NullAnswerMedia);
