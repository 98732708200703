import SubjectService from "../../../SubjectService";


/**
 * Returns a new array filtered based on multi-questionnaire completion consideration. Please note that the submission
 * of the questionnaire cannot be awaited as it is handled in the backend queue, and so we cannot check for completion nor
 * answers submitted with the questionnaire.
 *
 * @param {Array} followingQuestionnaires - The array of following questionnaires base64 encoded.
 * @param {Object} definition - The questionnaire definition.
 * @param {Object} questionnaire - The questionnaire.
 * @returns {Promise<Array>} - A Promise that resolves to the filtered array.
 */
const getEncodedListWithMultiQuestionnairePresenceConsidered = async (followingQuestionnaires, definition,questionnaire) => {
  let arrayToReturn = [...followingQuestionnaires]
  let indexesToRemove = [];

  if(!definition?.config?.multiQuestionnaire) return arrayToReturn

  const followingQuestionnairesDecoded = followingQuestionnaires.map(encoded => atob(encoded).split(","))
  for(const [i,followingQuestionnaire] of followingQuestionnairesDecoded.entries()){
    const isSameSubject = parseInt(followingQuestionnaire[0]) === questionnaire.subjectId;
    if(!isSameSubject) continue;

    const isOfCorrectDefinition = definition?.config?.multiQuestionnaire.definitions.includes(followingQuestionnaire[2]);
    if(!isOfCorrectDefinition) continue;

    const hasOnQuestionMatchConfiguration = typeof definition?.config?.multiQuestionnaire?.onQuestionMatch === 'string';
    if(!hasOnQuestionMatchConfiguration) {
      // if we dont need to consider question match we can remove
      indexesToRemove.push(i)
      continue;
    }

    const questionMatch = definition?.config?.multiQuestionnaire?.onQuestionMatch;
    const upcomingQuestionnaire = await SubjectService.getSubjectQuestionnaireByCodeAndId(
        questionnaire.subjectId,
        followingQuestionnaire[2],
        followingQuestionnaire[3]
    );

    const doesQuestionAnswerMatch = questionnaire[`${definition.code}_${questionMatch}`] === upcomingQuestionnaire[`${followingQuestionnaire[2]}_${questionMatch}`];
    if(!doesQuestionAnswerMatch) continue;
    // Unable to check backend as it uses a queue for submission
    // if(!upcomingQuestionnaire.complete) continue;

    indexesToRemove.push(i)
  }
  // Will be naturally ordered front to back, reverse to back to front so
  // sequential removal does not affect index of later items
  indexesToRemove.reverse()
  for (let indexToRemove of indexesToRemove){
    arrayToReturn.splice(indexToRemove, 1);
  }

  return arrayToReturn
}

export default {getEncodedListWithMultiQuestionnairePresenceConsidered}
