import React, {useState} from 'react';
import {Button, Card, Form, Grid, Message, MessageHeader} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import AccordianReveal from "../../generic/AccordianReveal";
import EntryDisplay from "./EntryDisplay";


const RegionSelectorControls = ({t, selectedRows, setSelectedRows, entries, fps, setFPS, boxSelectActive, handleToggleBoxSelect, boxToolActive, handleToggleBoxTool, boxId, setBoxId, handleFilteredToggle, filtered, handleDownload, handleNextFrame, handleGoToFrame, handleLogSingle, handleLogTrack, handleLogInit, handleLogEnd, handleInterpolateFrames, handlePrevFrame, handleRemove, handleUpdateBoxId, calculatedFPSData, currentFrame, error}) => {

  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const [goToFrame, setGoToFrame] = useState("");
  const handleRowClick = (entry, index, event) => {
    if (event.shiftKey && lastSelectedIndex !== null) {
      const start = Math.min(lastSelectedIndex, index);
      const end = Math.max(lastSelectedIndex, index);
      const newSelectedRows = entries.slice(start, end + 1).map(e => e.id);
      setSelectedRows(newSelectedRows);
    } else if(event.ctrlKey || event.metaKey){
      setSelectedRows(prevSelectedRows => {
        if (prevSelectedRows.includes(entry.id)) {
          // If the entry is already selected, remove it from the selection
          return prevSelectedRows.filter(id => id !== entry.id);
        } else {
          // Otherwise, add it to the selection
          return [...prevSelectedRows, entry.id];
        }
      })
    } else
    {
      setSelectedRows([entry.id]);
      setLastSelectedIndex(index);
    }
  };

  const handleDeleteSelected = () => {
    selectedRows.forEach(id => handleRemove(id));
    setSelectedRows([]);
  };

  const handleSetBoxIdSelected = () => {
    selectedRows.forEach(id => handleUpdateBoxId(id, boxId,entries.find(entry => entry.id === id)));
  };

  const handleInterpolateSelection = () => {
    if(selectedRows.length === 2){
      const initEntry = entries.find(entry => entry.id === selectedRows[0]);
      const endEntry = entries.find(entry => entry.id === selectedRows[1]);
      handleInterpolateFrames(initEntry,endEntry);
    }
    else if(selectedRows.length >2){
      const filteredEntities = entries.filter(entity =>
          selectedRows.includes(entity.id) && entity.type === "keyframe"
      );
      if(filteredEntities.length > 2){
        for(let i=0;i<filteredEntities.length - 1;i++){
          const initEntry = filteredEntities[i]
          const endEntry = filteredEntities[i+1]
          handleInterpolateFrames(initEntry,endEntry);
        }
      }

    }
  }

  const scrollableGridStyle = {
    maxHeight: '600px',
    overflowY: 'auto',
  };


  return <div>
    {/*The default video controls are moved down, so spacer is needed*/}
    <div className={"spacer"} style={{height: '75px'}} />
    <Card fluid>
      <Card.Content>
        <Form>
          {error != null && <Message negative>
            <MessageHeader>{t("GLOBAL_ERROR_TITLE","Error!")}</MessageHeader>
            <p>{error.message}</p>
          </Message>}
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Input
                  disabled
                  style={{opacity: 0.6}}
                  size={"small"}
                  label={t("REGION_SELECTOR_CALCULATED_FPS", "Calculated FPS")}
                  placeholder={t("REGION_SELECTOR_CALCULATED_FPD_PLACEHOLDER", "Play video to calculate")}
                  value={calculatedFPSData.fps}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                  disabled
                  style={{opacity: 0.6}}
                  size={"small"}
                  label={t("REGION_SELECTOR_CURRENT_TIME", "Current time")}
                  placeholder={t("REGION_SELECTOR_CURRENT_TIME_PLACEHOLDER", "Play video to find")}
                  value={calculatedFPSData.currentTime}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            <Grid.Column>
              <Input
                  primary
                  size={"small"}
                  label={t("REGION_SELECTOR_FPS","FPS")}
                  type={'numeric'}
                  placeholder={t("REGION_SELECTOR_FPS_PLACEHOLDER", "Select FPS")}
                  onChange={e=> {
                    setFPS(parseInt(e.target.value))
                  }}
                  value={fps}

              />
            </Grid.Column>
            <Grid.Column>
              <Input
                  disabled
                  style={{opacity: 0.6}}
                  size={"small"}
                  label={t("REGION_SELECTOR_CURRENT_FRAME","Current Frame")}
                  placeholder={t("REGION_SELECTOR_CURRENT_FRAME_PLACEHOLDER", "Set FPS to goto")}
                  value={currentFrame}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                  style={{opacity: 0.6}}
                  size={"small"}
                  label={t("REGION_SELECTOR_CURRENT_GO_TO_FRAME","Go To Frame")}
                  placeholder={t("REGION_SELECTOR_CURRENT_FRAME_PLACEHOLDER", "Set FPS to enable")}
                  value={goToFrame}
                  onChange={e=> {
                    setGoToFrame(e.target.value)
                  }}

                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (goToFrame.length === 0) return;
                      try {
                        const gotoFrame = parseInt(goToFrame);
                        handleGoToFrame(gotoFrame);
                      } catch (error) {
                        console.log("Cannot parse " + goToFrame);
                      }
                    }
                  }}
              />
            </Grid.Column>

          </Grid.Row>

          <Grid.Row columns={4}>
            <Grid.Column width={1}>
              <Button fluid onClick={handleToggleBoxTool} primary = {boxToolActive} disabled={!fps}>
                <p>{t("REGION_SELECTOR_BOX_TOOL", "Box Tool (B)")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column width={1}>
              <Button fluid onClick={handleToggleBoxSelect} primary={boxSelectActive} disabled={!fps}>
                <p>{t("REGION_SELECTOR_BOX_SELECT_TOOL", "Box Select (S)")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column >

              <Input
                  style={{opacity: 0.6}}
                  size={"small"}
                  label={t("REGION_SELECTOR_BOX_ID", "Box Id")}
                  placeholder={t("REGION_SELECTOR_BOX_ID_PLACEHOLDER", "Assigned box id")}
                  value={boxId}
                  onChange={e=> {
                    setBoxId(parseInt(e.target.value))
                  }}
              />
            </Grid.Column>
          <Grid.Column>
              <Button fluid onClick={handleFilteredToggle}  primary={fps} disabled={!fps}>
                <p>{filtered?t("REGION_SELECTOR_UNFILTER","Unfilter"):t("REGION_SELECTOR_FILTER","Filter")}</p>
              </Button>

            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={10}>
            <Grid.Column>
              <Button fluid onClick={handlePrevFrame} primary={fps} disabled={!fps}>
                <p>{t("REGION_SELECTOR_PREV", "Previous (Q)")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid onClick={handleNextFrame} primary={fps} disabled={!fps}>
                <p>{t("REGION_SELECTOR_NEXT", "Next (W)")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid onClick={handleLogSingle}  primary={fps} disabled={!fps && !boxToolActive}>
                <p>{t("REGION_SELECTOR_LOG", "Log (E)")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid onClick={handleLogInit}  primary={fps} disabled={!fps}>
                <p>{t("REGION_SELECTOR_LOG_INIT", "Log Init (I)")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column>
            <Button fluid onClick={handleLogEnd}  primary={fps} disabled={!fps}>
              <p>{t("REGION_SELECTOR_LOG_END", "Log End (O)")}</p>
            </Button>
          </Grid.Column>
            <Grid.Column>
              <Button fluid onClick={handleLogTrack}  primary={fps} disabled={!fps}>
                <p>{t("REGION_SELECTOR_TRACK", "Track (T)")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid onClick={handleDownload}  primary={fps} disabled={!fps}>
                <p>{t("REGION_SELECTOR_DOWNLOAD", "Download")}</p>
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={10}>
            <Grid.Column>
              <Button fluid onClick={handleDeleteSelected}  primary={selectedRows.length > 1 } disabled={selectedRows.length <= 1}>
                <p>{t("REGION_SELECTOR_DELETE_SELECTED", "Delete Selected")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid onClick={handleInterpolateSelection}  primary={selectedRows.length > 1} disabled={selectedRows.length < 2}>
                <p>{t("REGION_SELECTOR_INTERPOLATE_SELECTED", "Interpolate Selected")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid onClick={handleSetBoxIdSelected}  primary={fps} disabled={selectedRows.length === 0}>
                <p>{t("REGION_SELECTOR_SET_BOXID_SELECTED", "Set BoxId to Selection")}</p>
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <AccordianReveal displayText={"More"}>
              <div style={scrollableGridStyle}>
              <Grid padded >
                <Grid.Row><p>{t("REGION_SELECTOR_INSTRUCTIONS", "Box Region can be moved with I, J, K, and L, and resized with U and O.")}</p></Grid.Row>
                <Grid.Row columns={18}>
                  <Grid.Column width={2}>
                    <p><strong>{t("REGION_SELECTOR_ENTRY_FRAME", "Frame")}</strong></p>
                  </Grid.Column>
                  <Grid.Column  width={2} >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_BOXID", "boxId")}</strong></p>
                  </Grid.Column>
                  <Grid.Column  width={2} >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_TYPE", "type")}</strong></p>
                  </Grid.Column>
                  <Grid.Column >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_X", "x")}</strong></p>
                  </Grid.Column>
                  <Grid.Column width={2} >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_Y", "y")}</strong></p>
                  </Grid.Column>
                  <Grid.Column >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_X'", "x'")}</strong></p>
                  </Grid.Column>
                  <Grid.Column  width={2} >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_Y'", "y'")}</strong></p>
                  </Grid.Column>
                </Grid.Row>
                {entries.map((e,index) => (
                      <EntryDisplay
                          key={'entry-display-' + e.id}
                          entry={e} handleRemove={handleRemove}
                                    isSelected={selectedRows.includes(e.id)}
                                    onClick={(event) => handleRowClick(e, index, event)}
                                  handleGoToFrame={handleGoToFrame}

                      />
                ))}
              </Grid>
              </div>
            </AccordianReveal>
          </Grid.Row>
        </Grid>
        </Form>
      </Card.Content>
    </Card>
  </div>
}

export default withTranslation()(RegionSelectorControls);
