import RequestHelper from "../RequestHelper";
import {serverAddress} from "./config/EnvConfig";

const RemoteJobQueueService = {

  getJobQueues: async (pageNo, pageSize) => {
    const queryParams = {
      pageNo:pageNo,
      pageSize:pageSize
    }
    const url = `${serverAddress}/remotejobqueues`;
    const response = await RequestHelper.send(
      url,
      {},
      "GET",
      queryParams
    );
    return response;
  },
  getJobQueueCount: async () => {
    const queryParams = {}
    const url = `${serverAddress}/remotejobqueues/count`;
    const response = await RequestHelper.send(
        url,
        {},
        "GET",
        queryParams
    );
    return response;
  },

  reprocessJob: async (jobId) => {
    const queryParams = {}
    const url = `${serverAddress}/remotejobqueues/reprocess/` + jobId;
    const response = await RequestHelper.send(
        url,
        {},
        "GET",
        queryParams
    );
    return response;
  },

  manuallyTriggerJob: async (jobId) => {
    const queryParams = {}
    const url = `${serverAddress}/remotejobqueues/mantrigger/` + jobId;
    const response = await RequestHelper.send(
        url,
        {},
        "GET",
        queryParams
    );
    return response;
  },
};

export default RemoteJobQueueService;
