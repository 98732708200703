import { Duration } from "luxon";
import GeneralHelpers from "../../../../helpers/GeneralHelpers";

export const DISPLAY_TIME_FORMAT = {
  MILLISECONDS: {
    value: 'MILLISECONDS',
    labelTranslationKey: 'ENHANCED_VIDEO_PLAYER_DISPLAY_TIME_FORMAT_MILLISECONDS',
    labelDefault: 'Milliseconds',
    toString: (ms) => ms? (ms.toFixed(0) + 'ms') : '',
  },
  SECONDS: {
    value: 'SECONDS',
    labelTranslationKey: 'ENHANCED_VIDEO_PLAYER_DISPLAY_TIME_FORMAT_SECONDS',
    labelDefault: 'Seconds',
    toString: (ms) => ms? (GeneralHelpers.round(ms / 1000, 3).toString() + 's') : '',
  },
  MINUTES_SECONDS: {
    value: 'MINUTES_SECONDS',
    labelTranslationKey: 'ENHANCED_VIDEO_PLAYER_DISPLAY_TIME_FORMAT_MINUTES_SECONDS',
    labelDefault: 'mm:ss',
    toString: (ms) => ms? (Duration.fromMillis(ms).toFormat('mm:ss')): '',
  },
  HOURS_MINUTES: {
    value: 'HOURS_MINUTES',
    labelTranslationKey: 'ENHANCED_VIDEO_PLAYER_DISPLAY_TIME_FORMAT_HOURS_MINUTES',
    labelDefault: 'hh:mm',
    toString: (ms) => ms? (Duration.fromMillis(ms).toFormat('hh:mm')): '',
  },
  HOURS_MINUTES_SECONDS: {
    value: 'HOURS_MINUTES_SECONDS',
    labelTranslationKey: 'ENHANCED_VIDEO_PLAYER_DISPLAY_TIME_FORMAT_HOURS_MINUTES_SECONDS',
    labelDefault: 'hh:mm:ss',
    toString: (ms) => ms? (Duration.fromMillis(ms).toFormat('hh:mm:ss')): '',
  },
  HOURS_MINUTES_SECONDS_MILLISECONDS: {
    value: 'HOURS_MINUTES_SECONDS_MILLISECONDS',
    labelTranslationKey: 'ENHANCED_VIDEO_PLAYER_DISPLAY_TIME_FORMAT_HOURS_MINUTES_SECONDS_MILLISECONDS',
    labelDefault: 'hh:mm:ss.SSS',
    toString: (ms) => ms? (Duration.fromMillis(ms).toFormat('hh:mm:ss.SSS')): '',
  }
};
