import React, {useEffect, useState, useContext} from "react";
import {withTranslation} from "react-i18next";
import {Button, Dropdown, Grid, Loader, Form, Table} from "semantic-ui-react";
import Page from "../../components/page/Page";
import {useHistory, useParams} from "react-router-dom";
import DashboardReportService from "../../services/DashboardReportService";
import {DateTime} from "luxon";
import DateRangeControls from "../../components/DateRangeControls";
import moment from "moment";
import FileSaver from 'file-saver';
import AparitoSwitch from "../../components/questionnaire/AparitoSwitch";
import TrialService from "../../TrialService";
import SubjectCodeLookup from "../../components/subjects/SubjectCodeLookup";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import AuthService from "../../services/AuthService";
import {debounce} from 'lodash';
import DownloadModal from "../export/DownloadModal";
import ConfigContext from "../../context/ConfigContext";


const DashboardReportPage = ({t}) => {
    const config = useContext(ConfigContext);

    const params = useParams();
    const query = new URLSearchParams(window.location.search);

    const defaultReportsStartDateConfig = config?.ui?.dashboard?.reports?.defaultRangeStartDateMinusDays || 14;
    const defaultReportStartDate = DateTime.now()
        .minus( {days: defaultReportsStartDateConfig} )
        .setLocale("en");
    const [dateFilter, setDateFilter] = useState(
        {
            startDate: defaultReportStartDate.toISO(),
            endDate: DateTime.now().toISODate(),
        });
    const [siteFilterOptions, setSiteFilterOptions] = useState();
    const [siteIds, setSiteIds] = useState([]);
    const [freeTextSearch, setFreeTextSearch] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const [dashboardReportTitle, setDashboardReportTitle] = useState("");
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(null);
    const [filterExcelExport, setFilterExcelExport] = useState(false);
    const [loading, setLoading] = useState(true);
    const allSitesLabel = t("ALL_SITES_LABEL", "All Sites");
    const [subjectCodeFilter, setSubjectCodeFilter] = useState({ subjects: [] });
    const [childrenReports, setChildrenReports] = useState([]);
    const [selectedReportCode, setSelectedReportCode] = useState();
    const [page, setPage] = useState(query.has('page') ? query.get('page') : 0);

    const [download, setDownload] = useState({
        inProgress: false,
        error: undefined,
        completed: false
    })

    const SEARCH_CRITERIA_STATE = {
        'INITIAL': 'INITIAL',
        'MODIFIED': 'MODIFIED'
    }

    const handleDateChange = (startDate, endDate) => {
        if(startDate !== dateFilter.startDate || endDate !== dateFilter.endDate){
            console.log('setting date filter', startDate, endDate)
            setDateFilter({startDate, endDate});
        }

    };

    const updateSubjectCodeFilter = (criteria) => {
        criteria.state = SEARCH_CRITERIA_STATE.MODIFIED;
        setSubjectCodeFilter(criteria);
    };

    useEffect(() => {
        const init = async () => {
            try {
                const profile = await AuthService.getMyProfile();
                const userGroupCodes = profile.groupMappings.map(group => group.group.code);
                const allGroups = await TrialService.getGroups();
                let filteredSites = allGroups
                    .filter(group => userGroupCodes.includes(group.code))
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((group, index) => ({
                        key: index,
                        text: group.label,
                        value: group.id,
                    }));

                setSiteFilterOptions(filteredSites);
            } finally {
                setIsLoading(false);
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const getChildrenForReport = async () => {
            setLoading(true)
            try {
                const allReports = await DashboardReportService.getDashboardReportList();
                const byParentCode = allReports.reports.filter(d => d.parent && d.parent !== null).filter(d => d.parent.code === params.code)
                const byCode =  allReports.reports.filter(d => d.code === params.code)
                setChildrenReports(byParentCode)

                if (byParentCode && byParentCode.length > 0) {//multiple reports
                    setSelectedReportCode(byParentCode[0].code)
                    setDashboardReportTitle(t(byParentCode[0].parent.label))
                } else if(byCode && byCode.length>0){//multiple reports
                    setSelectedReportCode(byCode[0].code)
                    setDashboardReportTitle(t(byCode[0].label))
                }else{
                    throw new Error("invalid report:"+params.code)
                }
            } catch (e) {
                console.error('Error getting children reports', e)
            } finally {
                setLoading(false)
            }
        }

        getChildrenForReport()
    }, [params, t]);

    useEffect(() => {
        const getTabsForReport = async () => {
            if (!selectedReportCode) {
                console.log('No Selected Report Code', selectedReportCode)
                return;
            }
            const tabs = await DashboardReportService.getDashboardReportTabList(selectedReportCode);
            setTabs(tabs);
            if (tabs.length > 0) {
                setSelectedTab(tabs[0].code)
            }
        }
        getTabsForReport()
    }, [selectedReportCode]);

    const [dashboardReport, setDashboardReport] = useState();
    useEffect(() => {
        const getDashboardReport = async () => {
            try {
                setLoading(true)
                if (!selectedReportCode) {
                    return;
                }
                const subjectIds = subjectCodeFilter.subjects.map(subject => subject.subjectId);

                const reportInformation = await Promise.all([
                    selectedTab != null ?
                        DashboardReportService.getDashboardReportOfTab(
                            selectedReportCode,
                            dateFilter.startDate,
                            dateFilter.endDate,
                            siteIds,
                            subjectIds,
                            freeTextSearch,
                            page,
                            100,
                            selectedTab) :
                        [] // todo no data until tab selected - refactor once All option is made configurable
                ]);

                if (!reportInformation || reportInformation.length === 0) {
                    console.error("Report not found", selectedReportCode)
                }
                const report = reportInformation ? reportInformation[0] : undefined
                setDashboardReport(report);

            } catch (e) {
                console.log('Error loading dashboard reports', e)
            } finally {
                setLoading(false)
            }
        }

        getDashboardReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReportCode, dateFilter, selectedTab, page, subjectCodeFilter, siteIds, freeTextSearch]);

    const handleFilterExcelExport = () => {
        setFilterExcelExport(!filterExcelExport);
    }
    const handleExport = async () => {
        try {
            setDownload({...download, inProgress: true, completed: false, error:undefined})
            const subjectIds = subjectCodeFilter.subjects.map(subject => subject.subjectId);

            let exportContent;
            if (filterExcelExport) {
                exportContent = await DashboardReportService.getDashboardReportExportByFilters(
                    selectedReportCode,
                    dateFilter.startDate,
                    dateFilter.endDate,
                    siteIds,
                    subjectIds,
                    freeTextSearch);
            } else {
                exportContent = await DashboardReportService.getDashboardReportExport(selectedReportCode)
            }
            let blob = new Blob([exportContent], {
                type: "application/octet-stream"
            });
            let jsDate = new Date();
            //jsDate.setTime( jsDate.getTime() + jsDate.getTimezoneOffset() * 60 * 1000 );
            FileSaver.saveAs(blob, "dashboard-report-export-" + jsDate.toISOString().slice(0, 19) + ".zip");
            setDownload({...download, inProgress:true, completed: true, error: undefined})
        } catch (response) {
            setDownload({...download, error: 'Error downloading report:' + response, completed: true, inProgress: true})
            console.log("Error Downloading Excel Dashboard report:" + response);
        }
    }

    const createTable = (report) => {
        if (!report || !report.headers || !report.rows || report.rows.length === 0) {
            return;
        }

        const headerStyle = {textOverflow: "ellipses", whiteSpace: "nowrap", width: '100px'};
        const cellStyle = {textOverflow: "ellipses", whiteSpace: "nowrap", color: 'black', cursor: "pointer"};
        const headerRow = (
            <Table.Header>
                <Table.Row>
                {report.headers.map((header, index) => (
                    <Table.HeaderCell style={headerStyle} key={'header-'+index}>
                        {t(header, header)}
                    </Table.HeaderCell>
                ))}
                </Table.Row>
            </Table.Header>
        );

        const reportRows = report.rows
            .map((row, index) => {
                const link = `/app/subject/${row.subjectId ? row.subjectId : 'unknown'}/questionnaire-type/${row.questionnaireType}/${row.questionnaireDefinitionCode}/${row.isComplete ? "view" : "submit"}/${row.questionnaireId}`;
                return (
                    <Table.Row
                        key={'row'+index}
                        style={cellStyle}
                        onClick={() => history.push(link)}
                    >
                        {row.values.map((cell, index) => <Table.Cell key={'cell-'+index}
                            style={{...cellStyle, backgroundColor: cell.color}}
                        >{cell.value}</Table.Cell>)}
                    </Table.Row>
                )
            });
        return (
            <Table>
                {headerRow}
                <Table.Body>
                    {reportRows}
                </Table.Body>
            </Table>
        );
    };

    return (
        <Page
            name=""
            header={t(dashboardReportTitle)}
        >
            {isLoading && <Loader active={isLoading}/>}
            {!isLoading &&
                <>
                    <Grid style={{width: '100%'}}>
                        <Grid.Row>
                            {childrenReports?.length> 0 && <Grid.Column width={4}>
                                <label>{t('DASHBOARD_REPORT_LABEL', 'Report Selection')}</label>
                                <Dropdown
                                    className="ui fluid input"
                                    fluid
                                    selection
                                    options={childrenReports.map((r, index)=>{
                                        return {
                                            key: index,
                                            text: t(r.label),
                                            value: r.code,
                                        }
                                    })}
                                    value={selectedReportCode}
                                    onChange={(_e, data) => {
                                        setSelectedReportCode(data.value);
                                    }}
                                />
                            </Grid.Column>}
                            <Grid.Column width={12}>
                                <p style={{
                                    width: '100%',
                                    textAlign: 'center'
                                }}>{t("DASHBOARD_REPORT_COMPLETION_DATE_RANGE", "Date Range")}</p>
                                <DateRangeControls
                                    onChange={handleDateChange}
                                    start={moment(dateFilter.startDate)}
                                    end={moment(dateFilter.endDate)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid style={{width: '100%'}}>
                        <Grid.Column width={4}>
                            <label>{t('DASHBOARD_SITE_LABEL', 'Site')}</label>
                            <Dropdown
                                className="ui fluid input"
                                fluid
                                multiple
                                selection
                                placeholder={allSitesLabel}
                                options={siteFilterOptions}
                                onChange={(_e, data) => {
                                    setSiteIds(data.value);
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <label>{t('DASHBOARD_SUBJECT_CODE_LABEL', 'Subject Code')}</label>
                            <Form>
                                <SubjectCodeLookup
                                    multiple
                                    onChange={(subjects) => {
                                        const newSearchCriteria = {...subjectCodeFilter};
                                        newSearchCriteria.subjects = subjects;
                                        updateSubjectCodeFilter(newSearchCriteria);
                                    }}
                                    placeholder={t(['ATTACHMENTS_SEARCH_INPUT_SUBJECT_CODE_PLACEHOLDER', 'ATTACHMENTS_SEARCH_INPUT_SUBJECT_CODE'],
                                        'Subject codes (minimum 5 characters)')}
                                    value={subjectCodeFilter?.subjects || []}
                                />
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <label>{t('DASHBOARD_FREE_TEXT_LABEL', 'Free text search')}</label>
                            <Input
                                style={{display: "flex"}}
                                onChange={debounce((_e, data) => {
                                    setFreeTextSearch(data.value);
                                }, 1000)}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <div style={{
                                border: '1px solid',
                                margin: 5,
                                padding: 3,
                                borderRadius: '5px',
                                borderColor: 'darkgray'
                            }}>
                                <div style={{display: 'flex', justifyContent: 'center', marginBottom: 5}}>
                                    <AparitoSwitch id="aparitoSwitch"
                                                   height={15}
                                                   handleDiameter={17}
                                                   checked={filterExcelExport}
                                                   onChange={handleFilterExcelExport}
                                                   label={t("DASHBOARD_REPORT_FILTER_EXCEL_EXPORT", "Limit to filtered results")}/>
                                </div>
                                <Button primary fluid key={'excel-export-button'}
                                        onClick={handleExport}>{t("DASHBOARD_REPORT_EXPORT", "Export in Excel")}</Button>
                                <DownloadModal t={t}
                                               title={'Download Dashboard Report: '+selectedReportCode}
                                               visible={download.inProgress}
                                               onClose={() => setDownload({...download, inProgress: false, completed: false, error: undefined})}
                                               completed={download.completed}
                                               error={download.error}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Row>
                            <Grid.Column width={12}>
                                {/*todo removed for recursion as its a first customer for this feature*/}
                                {/*todo once needed to a different study, make configurable if All option is available*/}
                                {/*<Button*/}
                                {/*    primary*/}
                                {/*    className={"no-outline"}*/}
                                {/*    basic={selectedTab !== null}*/}
                                {/*    onClick={()=>{setSelectedTab(null)}}*/}
                                {/*>*/}
                                {/*    {t('DASHBOARD_REPORT_TAB_ALL','All')}*/}
                                {/*</Button>*/}
                                {tabs.length>1 && tabs.map((tab, i) =>
                                    <Button
                                        key={'tab-button-'+i}
                                        primary
                                        className={"no-outline"}
                                        basic={tab.code !== selectedTab}
                                        disabled={tab.code === selectedTab}
                                        onClick={() => {
                                            setSelectedTab(tab.code)
                                        }}
                                    >
                                        {t(tab.label)}
                                    </Button>)}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <span
                                    onClick={() => {
                                            if(dashboardReport?.hasPrev){
                                                setPage(page-1)
                                            }
                                        }
                                    }
                                    style={dashboardReport?.hasPrev ? {color:'black', cursor:'pointer'} : {color:'lightgray'}}> &#171; Prev Page</span>
                                <span style={{color:'black'}}> | {page + 1} | </span>
                                <span
                                    onClick={() => {
                                        if(dashboardReport?.hasNext){
                                            setPage(page+1)
                                        }
                                    }
                                    }
                                    style={dashboardReport?.hasNext ? {color:'black', cursor:'pointer'} : {color:'lightgray'}}> Next Page &#187;</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            {loading && (<Loader active={loading}/>)}
                            {!loading && <Grid.Column width={16}>
                                <div style={{display: 'flex', flexShrink: 1, overflowX: 'scroll', maxWidth: '100%'}}>
                                    {createTable(dashboardReport)}
                                </div>
                            </Grid.Column>
                            }

                        </Grid.Row>
                    </Grid>
                </>
            }
        </Page>
    );
};

export default withTranslation()(DashboardReportPage);
