/* eslint no-unused-vars: 0 */

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  Input,
  Label,
  Table,
  Checkbox,
  Button,
  Form,
  Dropdown,
} from "semantic-ui-react";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import CreateSubjectButton from "./CreateSubjectButton";
import TrialService from "../../TrialService";
import RSVP from "rsvp";
import ScoringService from "../../services/ScoringService";
import DisplayQuestion from "../../questionnaires/display/DisplayQuestion";
import AuthService from "../../services/AuthService";
import _, { uniq } from "lodash";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import { CONTENT_TYPE } from "../../RequestHelper";
import ExportModal from "./ExportModal";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";
import exportConstants from "../../constants/EXPORT_MODAL";
import { typeHelper } from "atom5-branching-questionnaire";
import { serverAddress } from "../../services/config/EnvConfig";
import consoleSanitisation from "../../utility/consoleSanitisation";

class SubjectListPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      subjects: [],
      recordDefinitions: [],
      loading: true,
      groupLabel: "",
      shouldHideIfStopped: true,
      searchTerm: '',
      pageNum: 0,
      totalSubjects: 0,
      selectedSubjects: [],
      shouldLabelsRepeat: false,
      allSelected: false,
      sortBy: null,
      sortDirection: null,
      enabledFilters: [],
      showExportModal: false,
      hasManageSiteExport: false,
      sortByOptions: [],
      groups: []
    };

    this.searchInputRef = React.createRef();
    this.checkForPermission();
  }

  componentDidMount() {
    TrialService.getConfiguration()
      .then((config) => {
        this.setState({ config: config });
      })
      .then(this.buildSortOptions)
      .then(() => {
        SubjectService.getSubjectRecordQuestionnaireDefinitions().then(
          (recordDefinitions) => {
            this.setState({ recordDefinitions });
          }
        )
      })
      .then(() => {
        TrialService.getGroups().then((groups) => {
          this.setState({ groups: groups });
          this.setSelectedGroup(groups);
        })
      })
      .then(() => this.reload(false));
  }

  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.match.params.groupCode !== this.props.match.params.groupCode
    ) {
      await this.setState({
        searchTerm: "",
        pageNum: 0,
        selectedSubjects: [],
        allSelected: false,
      });
      this.reload();
    }
  };

  reload = (pageInitialised = true) => {


    if (pageInitialised) {
      this.setState({ loading: true });
    }

    RSVP.Promise.all([
      SubjectService.listSubjects(
        this.props.match.params.groupCode,
        this.state.pageNum,
        this.state.searchTerm,
        this.state.sortBy,
        this.state.sortDirection,
        null,
        this.state.enabledFilters.join(","),
        this.state.shouldHideIfStopped === true ? "ACTIVE,AWAITING_ACTIVATION" : "ACTIVE,AWAITING_ACTIVATION,SUSPENDED",
        !this.state.shouldHideIfStopped

      ).then((subjectsData) => {
        const { pageSubjects: subjects } = subjectsData;
        this.setState({ subjects });
      }),
      SubjectService.getSubjectsCount(
        this.props.match.params.groupCode,
        this.state.pageNum,
        this.state.searchTerm,
        this.state.enabledFilters.join(","),
        this.state.shouldHideIfStopped === true ? "ACTIVE,AWAITING_ACTIVATION" : "ACTIVE,AWAITING_ACTIVATION,SUSPENDED",
        !this.state.shouldHideIfStopped
      ).then((count) => {
        this.setState({ totalSubjects: count });
      }),
      this.setSelectedGroup(this.state.groups)
    ]).finally(() => {
      this.setState({ loading: false });
    });
  };

  setSelectedGroup = async (groups) => {
    groups.forEach((group) => {
      if (group.code === this.props.match.params.groupCode) {
        this.setState({ groupLabel: group.label });
      }
    })
  }

  checkForPermission = async () => {
    const hasManageSiteExport = await PermissionsService.hasPermissionInGroup(this.props.match.params.groupCode, GroupPermission.MANAGE_SITE_EXPORTS);
    this.setState({ hasManageSiteExport });
  };

  handleExportModalClose = () => {
    this.setState({ showExportModal: false })
  };

  openExportModal = () => {
    this.setState({ showExportModal: true })
  }

  handleShouldLabelsRepeat = (e, v) => {
    this.setState({ shouldLabelsRepeat: v.checked });
  };

  downloadFile = (blob, fileName) => {
    const link = document.createElement("a");
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };

  createLabels = (e) => {
    const subjectString = this.state.selectedSubjects.toString();

    fetch(
      serverAddress +
      "/templates/generate-labels?subjectIds=" +
      subjectString +
      "&singleLabel=" +
      !this.state.shouldLabelsRepeat,
      {
        method: "GET",
        headers: {
          "Content-Type": CONTENT_TYPE.APPLICATION_OCTETSTREAM,
          Authorization: "Bearer " + AuthService.getAuthToken(),
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error in response");
        }
        return response.blob();
      })
      .then((blob) => {
        this.downloadFile(blob, "labels.docx");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  debouncedReload = _.debounce((value) => {
    this.reload(value);
  }, 500);

  onChangeSearch = (e) => {
    this.setState(() => {
      return { searchTerm: e.target.value }
    }, () => {
      //this.reload(false);
      this.debouncedReload(false);
    });
  };

  onChangeSort = (_e, { value: val }) => {
    const [sortBy, sortDirection] = val.split(',');
    this.setState({ sortBy, sortDirection }, this.reload);
  };

  onChangeFilter = ({ value: enabledFilters }, isSingle) => {
    if (isSingle) {
      enabledFilters = [enabledFilters];
    }
    this.setState({ enabledFilters }, this.reload);
  };

  submitSearch = () => {
    if (this.searchInputRef.current.value === "") {
      this.setState({ searchTerm: null });
    }
    this.reload();
  };

  nextPage = async () => {
    await this.setState((prevState) => {
      return { pageNum: prevState.pageNum + 1 };
    });
    this.reload();
  };

  prevPage = async () => {
    if (this.state.pageNum !== 0) {
      await this.setState((prevState) => {
        return { pageNum: prevState.pageNum - 1 };
      });
      this.reload();
    }
  };

  captureEnterKey = (e) => {
    if (e.key === "Enter") {
      this.submitSearch();
    }
  };

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
  escapeRegExp = function (string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
  };

  handleHide = () => {
    this.setState((prevState) => {
      return { shouldHideIfStopped: !prevState.shouldHideIfStopped };
    }, () => {
      this.reload(false);
    });
  };

  clickCheckbox = (event, target, subjectId) => {
    event.stopPropagation();
    let subjectIndex = this.state.selectedSubjects.findIndex((s) => {
      return s === subjectId;
    });
    if (target.checked) {
      if (subjectIndex === -1) {
        this.setState((oldState) => {
          const selectedSubjects = [...oldState.selectedSubjects];
          selectedSubjects.push(subjectId);
          return { selectedSubjects };
        }, this.checkAreAllSelected);
      }
    } else {
      if (subjectIndex !== -1) {
        this.setState((oldState) => {
          const selectedSubjects = [...oldState.selectedSubjects];
          selectedSubjects.splice(subjectIndex, 1);
          return { selectedSubjects };
        }, this.checkAreAllSelected);
      }
    }
  };

  checkAreAllSelected = () => {
    if (this.state.subjects.length === 0) {
      this.setState({ allSelected: false });
      return;
    }

    const areAllSelected = this.state.subjects.every((s) => {
      return this.state.selectedSubjects.some((id) => s.id === id);
    });

    if (areAllSelected !== this.state.allSelected) {
      this.setState({ allSelected: areAllSelected });
    }
  };

  toggleAllSelected = (_e, checkbox) => {
    if (checkbox.checked) {
      this.setState({
        selectedSubjects: uniq([
          ...this.state.selectedSubjects,
          ...this.state.subjects.map((s) => s.id),
        ]),
        allSelected: true,
      });
    } else {
      this.setState({ selectedSubjects: [], allSelected: false });
    }
  };

  buildSortOptions = async () => {
    const { t } = this.props;

    let options = [];

    const pushOption = (type, direction) => {
      options.push({
        key: type + "_" + direction,
        text: t("SUBJECT_LIST_SORT_BY_" + type + "_" + direction),
        value: `${type},${direction}`
      });
    };

    const sortTypes = this.state.config?.ui?.sortOptions || [
      "SUBJECT_CODE",
      "DATE_CONSENTED",
      "PRO_LAST_COMPLETED",
    ];
    sortTypes.forEach((type) => {
      if (type.endsWith("_ASC")) {
        pushOption(type.substring(0, type.length - 4), "ASC");
        return;
      }
      if (type.endsWith("_DESC")) {
        pushOption(type.substring(0, type.length - 5), "DESC");
        return;
      }
      ["ASC", "DESC"].forEach((direction) => {
        pushOption(type, direction);
      });
    });

    if (this.state.sortBy == null || this.state.sortDirection == null) {
      const [type, direction] = options[0].value.split(',');
      this.setState({ sortBy: type, sortDirection: direction });
    }

    this.setState({ sortByOptions: options });
  };

  render() {
    if (!Array.isArray(this.state.sortByOptions) || this.state.sortByOptions.length === 0) {
      return null;
    }

    const { t, history } = this.props;
    const groupCode = this.props.match.params.groupCode;

    const subjectListFilterParams = {
      groupCode: this.props.match.params.groupCode,
      pageNum: this.state.pageNum,
      searchTerm: this.state.searchTerm,
      sortBy: this.state.sortBy,
      sortDirection: this.state.sortDirection,
      pageLimit: null,
      enabledFilters: this.state.enabledFilters.join(","),
      totalSubjectCount: this.state.totalSubjects,
      subjectStates: this.state.shouldHideIfStopped === true ? "ACTIVE,AWAITING_ACTIVATION" : "ACTIVE,AWAITING_ACTIVATION,SUSPENDED",
      includeStopped: !this.state.shouldHideIfStopped
    }
    LocalStorageHelper.setJson("subjectListFilterParams", subjectListFilterParams);

    const paginationLimit = this.state?.config?.ui?.subjectPaginationLimit
      ? this.state?.config?.ui?.subjectPaginationLimit
      : 25;

    const subjectTablePageSearch = this.state?.config?.ui?.subjectTablePageSearch != null
      ? typeHelper.parseBool(this.state?.config?.ui?.subjectTablePageSearch)
      : true;

    const tableColumnsConfig = Window.configuration.ui.subjectTableColumns || [
      "subjectCode",
      "groups",
    ];
    const tableRowColoring = Window.configuration.ui.subjectTableColoring || [];

    const subjectFilters =
      Window.configuration.ui?.subjectFilter?.filters || [];
    const subjectFilterSingle =
      Window.configuration.ui?.subjectFilter?.single === "true";
    const subjectFilterOptions = subjectFilters.map((filter) => {
      return {
        key: filter.code,
        text: t("SUBJECT_LIST_FILTER_LABEL_" + filter.code.toUpperCase()),
        value: filter.code,
      };
    });

    const keyDisplay = tableRowColoring.map((colorObj, i) => {
      return (
        <div style={{ display: "flex", alignItems: "center", padding: "8px" }} key={`keyItem${i}`}>
          <div
            style={{
              height: "20px",
              width: "20px",
              backgroundColor: colorObj.color,
              borderRadius: "4px",
            }}
          />
          <div style={{ padding: "0 8px" }}>{t("COLOURING_KEY_DESC_" + i)}</div>
        </div>
      );
    });

    let headerCells = tableColumnsConfig.map((columnConfig) => {
      const prefix = columnConfig.split("_")[0];
      const suffix = columnConfig.split("_")[1];

      let headerCellContent = "";
      if (prefix === "subjectCode") {
        headerCellContent = t("SUBJECT_LIST_TABLE_HEADER_SUBJECT_CODE");
      } else if (prefix === "compliance") {
        headerCellContent = t("COHORT_COMPLIANCE_" + suffix.toUpperCase());
      } else if (prefix === "groups") {
        headerCellContent = t("SUBJECT_LIST_TABLE_HEADER_GROUPS");
      } else if (prefix === "checkbox") {
        headerCellContent = (
          <Checkbox
            onClick={(e, v) => {
              this.toggleAllSelected(e, v);
            }}
            checked={this.state.subjects.every((s) => {
              return this.state.selectedSubjects.some((id) => s.id === id);
            })}
          />
        );
      } else {
        const subjectRecordDefinition = this.state.recordDefinitions.find(
          (recordDef) => recordDef.code === prefix
        );
        if (subjectRecordDefinition) {
          const questionDefiniton = subjectRecordDefinition.questions.find(
            (question) => question.code === suffix
          );
          if (questionDefiniton) {
            headerCellContent = questionDefiniton.label;
          } else {
            console.error(
              `Could not find question definition with code ${consoleSanitisation.sanitizeString(suffix)} in `,
              subjectRecordDefinition
            );
          }
        }
      }

      return (
        <Table.HeaderCell key={columnConfig}>
          {headerCellContent}
        </Table.HeaderCell>
      );
    });

    const subjectRows = this.state.subjects.map((subject) => {
      let shouldShow = true;
      let subjectRecordsMap = {};
      subject.subjectRecords.forEach((subjectRecord) => {
        for (const [key, value] of Object.entries(subjectRecord.answers)) {
          subjectRecordsMap[subjectRecord.definition.code + "_" + key] = value;
        }
      });

      const cells = tableColumnsConfig.map((columnConfig) => {
        let content = "";

        const prefix = columnConfig.split("_")[0];
        const suffix = columnConfig.split("_")[1];

        if (prefix === "subjectCode") {
          content = subject.subjectCode;
        } else if (prefix === "checkbox") {
          content = (
            <Checkbox
              onClick={(e, v) => {
                this.clickCheckbox(e, v, subject.id);
              }}
              checked={this.state.selectedSubjects.some(
                (id) => id === subject.id
              )}
            />
          );
        } else if (prefix === "compliance") {
          content = subject.compliance
            ? Math.round(subject.compliance[suffix + "Compliance"]) + "%"
            : "";
        } else if (prefix === "groups") {
          content = subject.groups.map((group) => (
            <Label key={group.id}>{group.label}</Label>
          ));
        } else {
          // Look up value from subject records
          const record = subject.subjectRecords.find(
            (record) => record.definition.code === prefix
          );
          if (record) {
            const questionDefinition = record.definition.questions.find(
              (qDef) => qDef.code === suffix
            );
            content = (
              <DisplayQuestion
                question={questionDefinition}
                answer={record.answers[suffix]}
                subjectId={subject.id}
                showLabel={false}
              />
            );
          }
        }

        // Currently, endDate will never be in the future.
        if (this.state.shouldHideIfStopped && subject.endDate) {
          shouldShow = false;
        }

        return (
          <Table.Cell key={subject.id + "-" + columnConfig}>
            {content}
          </Table.Cell>
        );
      });

      if (shouldShow) {
        let backgroundColor = null;
        const scoringInformation = ScoringService.getScoringInformation(
          null,
          tableRowColoring,
          subjectRecordsMap
        );
        if (scoringInformation) {
          backgroundColor = scoringInformation.color;
        }
        return (
          <Table.Row
            style={{ cursor: "pointer", backgroundColor }}
            onClick={() => {
              history.push(
                "/app/subject/" + subject.id + "/tabs/subject-record/details"
              );
            }}
            key={subject.id}
          >
            {cells}
          </Table.Row>
        );
      }
      return null;
    });

    const hasResults = subjectRows.some((row) => row != null);

    return (
      <Page
        name="SUBJECT_LIST"
        header={t("SUBJECT_LIST_HEADER")}
        subheader={t("SUBJECT_LIST_SUBHEADER", {
          groupLabel: this.state.groupLabel,
        })}
        loading={this.state.loading}
      >
        <Grid key='controls1'>
          <Grid.Column floated="left" width={4} key='createSubject'>
            <CreateSubjectButton groupCode={groupCode} />
          </Grid.Column>
          <Grid.Column
            key='subjectExport'
            floated="right"
            width={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {this.state.hasManageSiteExport && (<Button
              color={
                this.state.selectedSubjects.length === 0 ? null : this.state.selectedSubjects.length <= exportConstants.MAX_SUBJECT_SELECTION_COUNT ? "orange" : null
              }
              onClick={this.openExportModal}
            >
              {t("SUBJECT_LIST_EXPORT_BUTTON", "Export Data")}
            </Button>)}
            {this.state.config?.ui?.showLabelDownload && (
              <div style={{ display: "flex" }} key='labelctls'>
                <div style={{ display: "flex", padding: "0 20px" }}>
                  <Checkbox
                    label={t("REPEAT_LABELS")}
                    checked={this.state.shouldLabelsRepeat}
                    onChange={this.handleShouldLabelsRepeat}
                    style={{ flex: 1, alignSelf: "center" }}
                  />
                </div>
                <Button
                  disabled={this.state.selectedSubjects.length === 0}
                  color={
                    this.state.selectedSubjects.length === 0 ? null : "orange"
                  }
                  onClick={this.createLabels}
                >
                  {t("CREATE_LABELS")}
                </Button>
              </div>
            )}
            <Form onSubmit={this.submitSearch}>
              <Input
                disabled={!subjectTablePageSearch}
                ref={this.searchInputRef}
                onChange={this.onChangeSearch}
                style={{ paddingRight: "120px" }}
                value={this.state.searchTerm}
                placeholder={t("GENERIC_TERM_SEARCH")}
                action={t("GENERIC_TERM_SEARCH")}
              />
            </Form>

            <div style={{ display: "flex", padding: "0 20px" }} key='chkstopped'>
              <Checkbox
                id="hide-stopped"
                label={t("HIDE_STOPPED")}
                checked={this.state.shouldHideIfStopped}
                onChange={this.handleHide}
              />
            </div>
          </Grid.Column>
        </Grid>

        <div
          key='keyDisplay'
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            padding: "8px",
            flexWrap: "wrap",
          }}
        >
          {keyDisplay}
        </div>

        <Grid key='controls2'>
          <Grid.Column floated="left" width={4} textAlign={"left"} key='sortBy'>
            <label>{t("SUBJECT_LIST_SORT_BY_LABEL")}</label>
            <Dropdown
              placeholder={t("SUBJECT_LIST_SORT_BY_PLACEHOLDER")}
              onChange={this.onChangeSort}
              fluid
              selection
              options={this.state.sortByOptions}
              defaultValue={`${this.state.sortBy},${this.state.sortDirection}`}
            />
          </Grid.Column>

          <Grid.Column floated="left" width={4} textAlign={"left"} key='filter'>
            {subjectFilterOptions.length > 0 && (
              <>
                <label>{t("SUBJECT_LIST_FILTER_LABEL")}</label>
                <div style={{ textAlign: "center" }} key='subjectfilterlist'>
                  {subjectFilterSingle ? (
                    <Dropdown
                      placeholder={t("SUBJECT_LIST_FILTER_PLACEHOLDER")}
                      onChange={(_e, data) => this.onChangeFilter(data, true)}
                      fluid
                      multiple={false}
                      selection
                      clearable={true}
                      options={subjectFilterOptions}
                      defaultValue={this.state.enabledFilters[0]}
                    />
                  ) : (
                    <Dropdown
                      placeholder={t("SUBJECT_LIST_FILTER_PLACEHOLDER")}
                      onChange={(_e, data) => this.onChangeFilter(data, false)}
                      fluid
                      multiple={true}
                      selection
                      options={subjectFilterOptions}
                      defaultValue={this.state.enabledFilters}
                    />
                  )}
                </div>
              </>
            )}
          </Grid.Column>

          <Grid.Column floated="right" width={4} textAlign={"right"} key='nextPrev'>
            <Button onClick={this.prevPage} disabled={this.state.pageNum === 0}>
              {t("GLOBAL_BUTTON_PREVIOUS")}
            </Button>
            <Button
              onClick={this.nextPage}
              disabled={
                Math.floor(this.state.totalSubjects / paginationLimit) <=
                this.state.pageNum
              }
            >
              {t("GLOBAL_BUTTON_NEXT")}
            </Button>
            Total: {this.state.totalSubjects}
          </Grid.Column>
        </Grid>

        <Table selectable columns={5} key='results'>
          <Table.Header>
            <Table.Row key='headerRow'>{headerCells}</Table.Row>
          </Table.Header>
          <Table.Body>{subjectRows}</Table.Body>
        </Table>

        {!hasResults && (
          <div style={{ padding: "14px", textAlign: "center" }} key='noresults'>
            <p style={{ fontSize: "20px", letterSpacing: "1px" }}>
              {t(
                'NO_RESULTS_SUBJECT',
                'No results found'
              )}
            </p>
          </div>
        )}

        <CreateSubjectButton groupCode={groupCode} />
        {this.state.showExportModal && this.state.hasManageSiteExport && (
          <ExportModal
            show={this.state.showExportModal}
            onClose={this.handleExportModalClose}
            selectedSubjects={this.state.selectedSubjects}
            groupCode={groupCode}
            modalTitle={t('SUBJECT_LIST_EXPORT_DATA_GROUP_MODAL_TITLE', "Exporting data from Site: ") + this.state.groupLabel}
            t={t}
            closeIcon
          />
        )}
      </Page>
    );
  }
}

export default withTranslation()(SubjectListPage);
