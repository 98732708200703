import React from 'react'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import ObjectGroup from './simpleValueEditors/ObjectGroup'
import LanguageGroup from './simpleValueEditors/LanguageGroup'
import TextValueEditor from './simpleValueEditors/TextValueEditor'
import SelectValueEditor from './simpleValueEditors/SelectValueEditor'
import BooleanValueEditor from './simpleValueEditors/BooleanValueEditor'
import ArrayGroup from './simpleValueEditors/ArrayGroup'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function AuthoriseConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <SelectValueEditor pathKey={"type"} label={"Type"} arrayOfValues={["ModalAuthorise"]} />
      <ObjectGroup pathKey={"confirmPasswordHeader"} label={"Confirm Password Header"} >
        <LanguageGroup pathKey={"label"} label={"Label"} />
      </ObjectGroup>
      <ObjectGroup pathKey={"confirmPasswordHeader"} label={"Checkbox"} >
        <LanguageGroup pathKey={"label"} label={"Label"} />
      </ObjectGroup>
      <ObjectGroup pathKey={"authorise"} label={"Authorise"}>
        <ObjectGroup pathKey={"ModalAuthorise"} label={"Modal Authorise"} >
          <BooleanValueEditor pathKey={"isVisible"} label={"is Visible?"} />
          <ArrayGroup pathKey={"includeQuestionCodes"} label={"Include Question Codes"} >
            <TextValueEditor label={"questionCode"} />
          </ArrayGroup>
        </ObjectGroup>
      </ObjectGroup>
    </ConfigWrapper>
  )
}

export default AuthoriseConfigEditor