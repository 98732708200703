import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Table } from "semantic-ui-react";
import Page from "../../components/page/Page";
import StaffService from "../../StaffService";
import {getProDefinitions} from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import {compose} from "redux";
import {connect} from "react-redux";

function RolesListPage({ t, definitions }) {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pstaffRoles = StaffService.getRolesForSuperAdminStaff();
    pstaffRoles.then(staffRoles => {
      setRoles(
        staffRoles.map(role => {
          const questionnaireLabels = role.mandatoryTraining === null ? [] : getLabels(role.mandatoryTraining);
          return {
            key: role.id,
            label: role.label,
            code: role.code,
            override: role.translatedCreatableRolesOverride != null ? role.translatedCreatableRolesOverride.join(', ') : '',
            mandatoryTraining: questionnaireLabels
          }
        })
      );
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLabels = (questionnaireIds) => {
    return questionnaireIds.split(",").map(id => parseInt(id)).map(id => getQuestionnaireLabel(id)).join(", ");
  }

  const getQuestionnaireLabel = (qId) => {
    return definitions
        .filter(q => q.id === parseInt(qId))
        .map(q => {
          return q.label;
        });
  }

  return (<Page
    name="ROLES_LIST"
    header={t("ROLES_LIST_HEADER", "List Roles")}
    subheader={t("ROLES_LIST_SUBHEADER", "Roles Management")}
    loading={loading}
  >
    <Table columns={4}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {t("ROLES_LIST_ROLES_LABEL", "Label")}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("ROLES_LIST_ROLES_CODE", "Code")}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("ROLES_LIST_OVERRIDE", "Override Roles")}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("ROLES_MANDATORY_TRAINING", "Mandatory Training")}
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
      {roles.map(role => {
        return (<Table.Row key={role.key}>
          <Table.Cell>{role.label}</Table.Cell>
          <Table.Cell>{role.code}</Table.Cell>
          <Table.Cell>{role.override}</Table.Cell>
          <Table.Cell style={{ wordBreak: "break-word" }}>{role.mandatoryTraining}</Table.Cell>
          <Table.Cell>
            <Button as={Link} to={`/app/roles/edit/${role.key}`} primary>
              {t("ROLES_LIST_EDIT", "Edit")}
            </Button>
          </Table.Cell>
        </Table.Row>);
      })}
      </Table.Body>
    </Table>
    <Button as={Link} primary to={"/app/roles/new"}>
      {t("ROLES_LIST_NEW", "New")}
    </Button>
  </Page>
  );
}

const mapStateToProps = (state) => {
  return {
    definitions: [
      ...getProDefinitions(state),
    ]
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(RolesListPage);




