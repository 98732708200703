import React from 'react';
import {Button, Grid} from "semantic-ui-react";
import {withTranslation} from "react-i18next";


const EntryDisplay = ({t, entry, handleRemove, isSelected , onClick, handleGoToFrame }) => {

    const entryRowStyle = {
        cursor: 'pointer',
        backgroundColor: isSelected ? '#e0e0e0' : 'inherit', // Adjust this color as needed
    };


    return <Grid.Row columns={18}  style={entryRowStyle} onClick={onClick}>
        <Grid.Column width={2}>
          <p>{entry.frame}</p>
        </Grid.Column>
      <Grid.Column width={2} >
          <p>{entry.boxId}</p>
      </Grid.Column>
      <Grid.Column width={2} >
          <p>{entry.type}</p>
      </Grid.Column>
        <Grid.Column >
          <p>{entry.coords.x1}</p>
        </Grid.Column>
        <Grid.Column width={2} >
          <p>{entry.coords.y1}</p>
        </Grid.Column>
        <Grid.Column >
          <p>{entry.coords.x2}</p>
        </Grid.Column>
        <Grid.Column width={2} >
          <p>{entry.coords.y2}</p>
        </Grid.Column>

        <Grid.Column >
          <Button onClick={()=>handleRemove(entry.id)}>{t("REGION_SELECTOR_ENTRY_DELETE", "Delete")}</Button>
        </Grid.Column>
        <Grid.Column >
            <Button onClick={()=>handleGoToFrame(entry.frame)}>{t("REGION_SELECTOR_ENTRY_GO_TO_FRAME", "Set")}</Button>
        </Grid.Column>
      </Grid.Row>
}

export default withTranslation()(EntryDisplay);
