import React, { useMemo } from 'react'
import TextValueEditor from './simpleValueEditors/TextValueEditor';
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors';
import ArrayGroup from './simpleValueEditors/ArrayGroup';
import SelectValueEditor from './simpleValueEditors/SelectValueEditor';
import LinkToArrayValueEditor from './compoundValueEditors/LinkToArrayValueEditor';
import ConfigWrapper from './simpleValueEditors/ConfigWrapper';

function LinkConfigEditor({ config, onChange }) {
  const typeofTo = useMemo(() => typeof config.to, [config]);


  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      {typeofTo !== "object" && <TextValueEditor pathKey={'to'} label={"Link to"} />}
      {typeofTo !== "string" &&
        <ArrayGroup pathKey={'to'} label={"Link to as Array"}>
          <LinkToArrayValueEditor />
        </ArrayGroup>
      }
      <SelectValueEditor pathKey={'displayIfVisited'} label={"Display when Visited"} arrayOfValues={["DEFAULT_WITH_MESSAGE", "MESSAGE", "NONE"]} />
    </ConfigWrapper>
  )
}

export default LinkConfigEditor