import React from 'react'
import BooleanValueEditor from './simpleValueEditors/BooleanValueEditor'
import ArrayGroup from './simpleValueEditors/ArrayGroup'
import ObjectGroup from './simpleValueEditors/ObjectGroup'
import TextValueEditor from './simpleValueEditors/TextValueEditor'
import SelectValueEditor from './simpleValueEditors/SelectValueEditor'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function RenderVideoConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <SelectValueEditor pathKey={'renderAs'} label={"Render as"} arrayOfValues={["value"]} />
      <BooleanValueEditor pathKey={'showFrameByFrameControls'} label={"Show Frame by Frame Controls"} />
      <BooleanValueEditor pathKey={'shouldUseRegionSelector'} label={"Show Region Selector"} />
      <BooleanValueEditor pathKey={'useEnhancedPlayer'} label={"Use Enhanced Player"} />
      <ArrayGroup pathKey='regionSelectorAttachmentsConfig' label={"Region Selector Attachments Config"} >
        <ObjectGroup >
          <TextValueEditor label={"Attachment Type"} pathKey={"attachmentType"} />
          <TextValueEditor label={"Mime Type"} pathKey={"mimeType"} />
          <TextValueEditor label={"File Uploader"} pathKey={"fileUploader"} />
        </ObjectGroup>
      </ArrayGroup>
    </ConfigWrapper>
  )
}

export default RenderVideoConfigEditor