import React, { useContext, useEffect, useMemo, useState } from "react";
import UserContext from "../../context/UserContext";
import GroupPermission from "../../GroupPermission";
import SubjectService from "../../SubjectService";
import PermissionsService from "../../services/PermissionsService";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AttachmentService, { WORKFLOW_STATUS_NOT_SPECIFIED } from "../../services/AttachmentService";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import ConfigContext from "../../context/ConfigContext";

const AttachmentWorkflowStatusOverride = ({
  t,
  onChange,
  subjectId,
  attachmentId,
  workflowStatus,
  reference
}) => {
  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const { isLoggedIn, accountType } = user;

  const [hasPermission, setHasPermission] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedWorkflowStatus, setSelectedWorkflowStatus] = useState(workflowStatus);
  const hasStatusChanged = selectedWorkflowStatus !== workflowStatus;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const availableWorkflowStatuses = config?.workflows?.attachments?.statuses || {};

  useEffect(() => {
    populatePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const workflowStatusDropDownOptions = useMemo(() => {
    const options = Object.keys(availableWorkflowStatuses)
      .sort((a, b) => {
        const sequenceA = availableWorkflowStatuses[a].search?.sequence || 0;
        const sequenceB = availableWorkflowStatuses[b].search?.sequence || 0;
        return sequenceA < sequenceB ? -1 : 1;
      })
      .map(statusKey => {
        const text = AttachmentService.getWorkflowStatusText(t, statusKey);
        return {
          key: statusKey,
          text: text,
          value: statusKey !== WORKFLOW_STATUS_NOT_SPECIFIED ? statusKey : ''
        };
      });
    options.push({
      key: WORKFLOW_STATUS_NOT_SPECIFIED,
      text: AttachmentService.getWorkflowStatusText(t, WORKFLOW_STATUS_NOT_SPECIFIED),
      value: WORKFLOW_STATUS_NOT_SPECIFIED
    });
    return options;
  }, [t, availableWorkflowStatuses]);

  const populatePermissions = async () => {
    if (!isLoggedIn) {
      setHasPermission(false);
      return;
    }
    if (accountType !== 'staff') {
      setHasPermission(false);
      return;
    }

    const subjectData = await SubjectService.getSubjectData(subjectId);
    const hasPerm = await PermissionsService.hasPermissionForSubject(
      subjectData.groups,
      GroupPermission.MANUAL_OVERRIDE_ATTACHMENT_WORKFLOW_STATUS);
    setHasPermission(hasPerm);
  };

  if (!hasPermission) {
    return null;
  }

  const handleOpenEditModalClick = () => {
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setIsEditModalOpen(false);
  };

  const updateWorkflowStatus = async (subjectId, feedbackReason, attachmentId, newStatus) => {
    await AttachmentService.manualOverrideWorkflowStatus(
      subjectId,
      feedbackReason,
      attachmentId,
      newStatus
    );
  };

  const handleEditSaveClick = async (subjectId, feedbackReason) => {
    const newStatus = selectedWorkflowStatus === WORKFLOW_STATUS_NOT_SPECIFIED ? '' : selectedWorkflowStatus;
    await updateWorkflowStatus(subjectId, feedbackReason, attachmentId, newStatus);
    if (onChange != null) {
      onChange({ attachmentId, reference });
    }
    closeModal();
  };

  const handleEditCancelClick = () => {
    closeModal();
  };

  return (
    <div style={{ marginTop: 10 }}>
      <Modal
        size={'small'}
        open={isEditModalOpen}
        closeIcon={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          {t(
            'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_BUTTON',
            'Change Workflow Status'
          )}
        </Modal.Header>
        <Modal.Content>
          <Dropdown
            placeholder={t(
              'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_DROPDOWN_PLACEHOLDER',
              'Select status'
            )}
            selection
            options={workflowStatusDropDownOptions || []}
            onChange={(_e, data) => {
              setSelectedWorkflowStatus(data.value);
            }}
            value={selectedWorkflowStatus || WORKFLOW_STATUS_NOT_SPECIFIED}
          />
          <div style={{ marginTop: 20 }}>
            <ConfirmButtonWithFeedback
              disabled={!hasStatusChanged}
              buttonText={t(
                'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_BUTTON',
                'Change Workflow Status'
              )}
              headerText={t(
                [
                  'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_PROMPT_HEADER',
                  'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_PROMPT_TEXT'
                ],
                'Change Workflow Status'
              )}
              contentText={t(
                'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_PROMPT_TEXT',
                'Please confirm that you want to update these items'
              )}
              confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
              cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
              onConfirm={(feedbackReason) => handleEditSaveClick(subjectId, feedbackReason)}
            />

            <Button onClick={handleEditCancelClick}>
              {t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
            </Button>
          </div>
        </Modal.Content>
      </Modal>

      <Button size="small" onClick={handleOpenEditModalClick}>
        {t(["ATTACHMENTS_WORKFLOW_OVERRIDE_STATUS_BUTTON", "ATTACHMENTS_WORKFLOW_CHANGE_STATUS_BUTTON"], "Override Workflow Status")}
      </Button>
    </div >
  )
};

export default withTranslation()(AttachmentWorkflowStatusOverride);
