import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  TextArea,
  Message,
  Dropdown,
} from "semantic-ui-react";
import ConfirmButtonWithFeedback from "../dashboard/ConfirmButtonWithFeedback";
import { QUESTIONNAIRE_DEFINITION_RESTRICT_TO } from "../../constants/QUESTIONNAIRE_DEFINITION_RESTRICT_TO";

const TRANSLATION_MAP = {
  INCORRECT_FORMAT: "Incorrect Format",
  NOT_FOUND: "Questionnaire Definition not found",
  ID_MISMATCH:
    "The id passed in the url is not the same as the url within the trigger",
};

function AdminQuestionnaireDefinitionEditComponent({
  t,
  questionnaireDefinition,
  serverTranslations,
  isReadOnly,
  onChange,
  onSubmit,
  isSubmitting,
  validationErrors = [],
}) {
  const getQuestionnaireDefinitionChangeButton = (
    onComplete,
    buttonText,
    isSubmitting,
    isPositive = true
  ) => {
    return (
      <ConfirmButtonWithFeedback
        fluid
        buttonText={t(buttonText)}
        headerText={t(buttonText)}
        contentText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PROMPT",
          "Please give a reason why this is being changed and confirm."
        )}
        confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
        cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
        onConfirm={onComplete}
        placeholderText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT",
          "Reason"
        )}
        mandatoryValidationText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_VALIDATION_TEXT",
          "Please supply a reason for the change."
        )}
        color={!isPositive ? "negative" : "orange"}
        isSubmitting={isSubmitting}
      />
    );
  };

  const createValidationMessageDisplay = (key) => (validationError) => {
    if (validationError.key !== key) {
      return null;
    }

    return (
      <Message negative>
        {t(
          validationError.errorCode,
          TRANSLATION_MAP[validationError.errorCode]
        )}
      </Message>
    );
  };

  const getTranslation = (searchString) =>
    serverTranslations.find((sT) => sT.code.includes(searchString));

  const labelTranslation = useMemo(
    () => getTranslation(`questionnaire_${questionnaireDefinition.code}_label`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serverTranslations, questionnaireDefinition.code]
  );

  const configTranslation = useMemo(
    () =>
      getTranslation(`questionnaire_${questionnaireDefinition.code}_config`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serverTranslations, questionnaireDefinition.code]
  );

  const restrictToOptions = Object.entries(QUESTIONNAIRE_DEFINITION_RESTRICT_TO)
    .map(([_key, value]) => {
      return {
        key: value,
        text: value,
        value: value,
      };
    });

  return (
    <Form>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {validationErrors.map(
              createValidationMessageDisplay("questionnaireDefinition")
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <Form.Field>
              <label>{t("QUESTIONNAIRE_DEFINITION_CODE", "Code")}</label>
              <Input
                value={questionnaireDefinition.code}
                name={"code"}
                fluid
                transparent={true}
                disabled={true}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={6}>
            <Form.Field>
              <label>{t("QUESTIONNAIRE_DEFINITION_LABEL", "Label")}</label>
              <Input
                value={labelTranslation?.translation}
                name={"label"}
                fluid
                transparent={true}
                disabled={true}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Field>
              <label>{t("QUESTIONNAIRE_DEFINITION_TYPE", "Type")}</label>
              <Input
                value={questionnaireDefinition.type}
                name={"type"}
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={2}>
            <Form.Field>
              <label>
                {t("QUESTIONNAIRE_DEFINITION_SEQUENCE", "Sequence")}
              </label>
              <Input
                value={questionnaireDefinition.sequence}
                name={"sequence"}
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
              />
              {validationErrors.map(createValidationMessageDisplay("sequence"))}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            {!isReadOnly &&
              getQuestionnaireDefinitionChangeButton(
                onSubmit,
                t("ADMIN_TRIGGER_SAVE", "Save"),
                isSubmitting
              )}
            {isReadOnly && (
              <Button
                primary
                fluid
                size="tiny"
                as={Link}
                to={
                  "/app/utils/questionnaire-definitions/edit/" +
                  questionnaireDefinition.id
                }
              >
                {t("ADMIN_TRIGGER_EDIT", "Edit")}
              </Button>
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Field>
              <label>
                {t("QUESTIONNAIRE_DEFINITION_NONSCORING", "Non Scoring?")}
              </label>
              <Checkbox
                checked={questionnaireDefinition.nonScoring}
                name={"nonScoring"}
                fluid
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("nonScoring")
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Field>
              <label>
                {t(
                  "QUESTIONNAIRE_DEFINITION_SCORECALCULATION",
                  "Score Calculation"
                )}
              </label>
              <Input
                value={questionnaireDefinition.scoreCalculation}
                name={"scoreCalculation"}
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("scoreCalculation")
              )}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={4}>
            <Form.Field>
              <label>
                {t("ADMIN_QUESTION_RESTRICTTO", "Restrict to")}
              </label>
              <Dropdown
                fluid
                selection
                options={restrictToOptions}
                value={questionnaireDefinition.restrictTo}
                name={"restrictTo"}
                onChange={onChange}
                disabled={isReadOnly}
              />
              {validationErrors.map(
                createValidationMessageDisplay("restrictTo")
              )}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={12}>
            <Form.Field>
              <label>{t("QUESTIONNAIRE_DEFINITION_CONFIG", "Config")}</label>
              <TextArea
                style={{
                  border: 0,
                  padding: 0,
                  wordWrap: "word-break",
                  caretColor: "transparent",
                  backgroundColor: "transparent",
                }}
                value={configTranslation.translation}
                name={"config"}
                fluid
                transparent={true}
                disabled={false}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}

export default withTranslation()(AdminQuestionnaireDefinitionEditComponent);
