import React from 'react'
import TextValueEditor from '../simpleValueEditors/TextValueEditor';
import ObjectGroup from '../simpleValueEditors/ObjectGroup';
import BooleanValueEditor from '../simpleValueEditors/BooleanValueEditor';

function LinkToArrayValueEditor(props) {
  const rendered = []
  if (typeof props.value !== 'object') {
    rendered.push(<TextValueEditor {...props} label={"Link Item As String"} />)
  }
  if (typeof props.value !== 'string') {
    rendered.push(<ObjectGroup {...props} label={"Link Item as Object"} >
      <TextValueEditor label={"URI"} pathKey={'uri'} />
      <BooleanValueEditor label="Completed" pathKey={'completed'} />
    </ObjectGroup>)
  }
  return rendered;
}

export default LinkToArrayValueEditor