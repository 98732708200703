import React from 'react'
import { Button, Input } from 'semantic-ui-react'

function NumberValueEditor({ pathKey, label, value, onChange }) {
  const handleChange = (v) => {
    onChange && onChange(pathKey, v)
  }

  return (
    <div style={{ padding: '4px' }}>
      {value === undefined && <Button primary compact size='small' onClick={() => handleChange(0)} >{"Add " + label}</Button>}
      {value !== undefined && <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ paddingRight: "20px" }}><b>{label}</b></p>
        <Input type='number' style={{ display: 'flex', flexGrow: 1 }} fluid value={value} onChange={(v) => handleChange(v.target.value)} /><div style={{ paddingLeft: '20px' }}>
          <Button inverted color='red' compact onClick={() => { handleChange(undefined) }} >{"x"}</Button>
        </div></div>}
    </div>
  )
}

export default NumberValueEditor