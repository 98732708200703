import React from "react";
import {withTranslation} from "react-i18next";
import {Button, Icon, Popup} from "semantic-ui-react";

const UnauthorisedMedia = ({t}) => {

    return (
        <Popup
            trigger={
                <Button color="orange" icon>
                    <Icon name="lock" size="big"/>
                </Button>
            }
        >
            <Popup.Content>{t("MEDIA_CONTENT_UNAUTHORISED", "You do not have permission to view this media attachment.")}</Popup.Content>
        </Popup>
    );

}

export default withTranslation()(UnauthorisedMedia);
