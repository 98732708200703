import {withTranslation} from "react-i18next";
import React, { useEffect, useState} from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { getProDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import {compose} from "redux";
import {connect} from "react-redux";

const MandatoryTraining = ({ t, definitions, onChange, formRole }) => {

    const [questionnairesOptions, setQuestionnairesOptions] = useState([]);

    useEffect(() => {
        setQuestionnairesOptions(
            definitions
                .sort((a, b) => a.label.localeCompare(b.label))
                .map(q => ({ key: q.id, text: q.label, value: q.id }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
       <>
           <Form.Field>
               <label>{t("ROLE_MANDATORY_TRAINING", "Mandatory Training")}</label>
               <Dropdown
                   placeholder={t("ROLE_EDIT_MANDATORY_TRAINING_DROPDOWN_PLACEHOLDER", "Select Mandatory Training")}
                   fluid
                   multiple
                   selection
                   options={questionnairesOptions}
                   value={formRole.mandatoryTraining}
                   onChange={onChange}
               />
           </Form.Field>
       </>
    );
}


const mapStateToProps = (state) => {
    return {
        definitions: [
            ...getProDefinitions(state),
        ]
    };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(MandatoryTraining);
