import RequestHelper, { CONTENT_TYPE } from "../RequestHelper";
import {serverAddress} from "./config/EnvConfig";

export default class ExportService {
  static triggerFullExport() {
    return RequestHelper.send(
      serverAddress + "/export-v2/trigger/full",
      {},
      "POST"
    );
  }

  static triggerRestrictedExport(requestBody) {
    return RequestHelper.send(
        serverAddress + "/export-v2/trigger/restricted",
        {
          Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
        },
        "POST",
        null,
        requestBody
    );
  }

  static listExportTypes(){
    return RequestHelper.send(
        serverAddress + "/export-v2/trigger/listtypes",
        {},
        "GET"
    );
  }

  static listExports() {
    return RequestHelper.send(
      serverAddress + "/export-v2/trigger/",
      {},
      "GET"
    );
  }

  static exportStatus(exportType) {
    return RequestHelper.send(
        serverAddress + "/export-v2/trigger/status/"+exportType,
        {},
        "GET"
    );
  }

  static downloadExport(identifier) {
    return RequestHelper.send(
      serverAddress +
        "/export-v2/trigger/download/" +
        encodeURIComponent(identifier),
      {
        Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
      },
      "GET"
    );
  }
}
