import React, { Component, Fragment } from "react";
import { Segment } from "semantic-ui-react";
import SubjectActivityGraph from "./SubjectActivityGraph";
import SubjectSleepGraph from "./SubjectSleepGraph";
import SubjectGarminSleepGraph from "./SubjectGarminSleepGraph";
import SubjectSpo2Graph from "./SubjectSpo2Graph";
import SubjectBbiGraph from "./SubjectBbiGraph";
import SubjectHeartRateGraph from "./SubjectHeartRateGraph";
import ActivityDistanceTable from "./ActivityDistanceTable";
import YumenWearableGraphDay from "./external/YumenWearableGraphDay";
import SubjectRespirationDataGraph from "./SubjectRespirationDataGraph";
import SubjectStepsLoggedGraph from "./SubjectStepsLoggedGraph";

export default class SubjectGraph extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.initialise();
    this.mounted = true;
  }

  initialise = () => {
    return {
      error: false,
      definition: {},
      questionnaires: [],
    };
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ error: true });
  };

  componentDidUpdate = (prevProps) => {
    if (
        prevProps.graph !== this.props.graph ||
        prevProps.subjectId !== this.props.subjectId
    ) {
      this.setState(this.initialise());
    }
  };


  componentWillUnmount = () => {
    this.mounted = false;
  };

  render() {
    return (
        <Fragment>
          <Segment>
            {this.props.graph === "activityDistance" && (
                <ActivityDistanceTable subjectId={this.props.subjectId} />
            )}
            {this.props.graph === "yumenHub" && (
                <YumenWearableGraphDay limbType="HUB" subjectId={this.props.subjectId} showHubNotConnected={false}/>
            )}
            {this.props.graph === "yumenArm" && (
                <YumenWearableGraphDay limbType="ARM" subjectId={this.props.subjectId} showHubNotConnected={false}/>
            )}
            {this.props.graph === "yumenLeg" && (
                <YumenWearableGraphDay limbType="LEG" subjectId={this.props.subjectId} showHubNotConnected={false}/>
            )}
            {this.props.graph === "activity" && (
                <SubjectActivityGraph subjectId={this.props.subjectId} />
            )}
            {this.props.graph === "sleep" && (
                <SubjectSleepGraph subjectId={this.props.subjectId} />
            )}
            {this.props.graph === "garminSleep" && (
                <SubjectGarminSleepGraph subjectId={this.props.subjectId} />
            )}
            {this.props.graph === "spo2" && (
                <SubjectSpo2Graph subjectId={this.props.subjectId} />
            )}
            {this.props.graph === "respiration" && (
                <SubjectRespirationDataGraph subjectId={this.props.subjectId} />
            )}
            {this.props.graph === "heartrate" && (
                <SubjectHeartRateGraph subjectId={this.props.subjectId} />
            )}
            {this.props.graph === "bbi" && (
                <SubjectBbiGraph subjectId={this.props.subjectId} />
            )}
           {this.props.graph === "stepsLogged" && (
                <SubjectStepsLoggedGraph subjectId={this.props.subjectId} />
            )}
            {!["activityDistance", "activity", "garminSleep", "spo2", "heartrate", "bbi", "yumenHub", "yumenArm", "yumenLeg", "activityDistance", "stepsLogged", "respiration"].includes(this.props.graph) && (
                <div>Legacy graph, check the configuration</div>
            )}
          </Segment>
        </Fragment>
    );
  }
}
