import React, { useContext, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Form, Icon, Modal } from "semantic-ui-react";
import UserContext from "../../context/UserContext";
import ConfigContext from "../../context/ConfigContext";
import GroupPermission from "../../GroupPermission";
import { typeHelper } from "atom5-branching-questionnaire";
import SubjectService from "../../SubjectService";
import PermissionsService from "../../services/PermissionsService";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import VisitDateService from "../../services/VisitDateService";

const NONE_SELECTED = 'NONE_SELECTED';

const VisitDateDisplay = ({
  t,
  showLabel = false,
  showEditIcon = false,
  style,
  subjectData,
  subjectVisitDates,
  questionnaire
}) => {
  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const { isLoggedIn, accountType } = user;
  const [group, setGroup] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [hasEditPermission, setHasEditPermission] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [visit, setVisit] = useState(questionnaire?.visit);
  const [selectedVisitId, setSelectedVisitid] = useState(visit?.id);

  useEffect(() => {
    if (group != null) {
      return;
    }
    populateGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectData, subjectVisitDates, questionnaire]);

  useEffect(() => {
    if (group == null) {
      return;
    }
    determineVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  const populateGroup = async () => {
    setGroup(SubjectService.getSubjectGroup(subjectData?.groups));
  }

  const determineVisibility = async () => {
    const hasViewPerm = await PermissionsService.hasPermissionInGroup(
      group?.code,
      GroupPermission.VIEW_SUBJECT_VISITDATES);
    if (!hasViewPerm) {
      setIsVisible(false);
      return;
    }

    const isVisitDatesEnabled = typeHelper.parseBool(config?.visitDates?.[group?.code]?.isEnabled);
    if (!isVisitDatesEnabled) {
      setIsVisible(false);
      return;
    }

    if (!isLoggedIn) {
      setIsVisible(false);
      return;
    }
    if (accountType !== 'staff') {
      setIsVisible(false);
      return;
    }

    setIsVisible(true);

    const hasEditPerm = await PermissionsService.hasPermissionInGroup(
      group?.code,
      GroupPermission.MANAGE_SUBJECT_VISITDATES);
    setHasEditPermission(hasEditPerm);
  };

  const populateVisitListOptions = () => {
    const options = (subjectVisitDates || []).map(v => {
      const scheduledItem = v.scheduledItem;
      return {
        key: scheduledItem.id,
        text: scheduledItem.name,
        value: scheduledItem.id
      }
    });
    options.unshift(
      {
        key: NONE_SELECTED,
        text: t("GENERIC_TERM_NONE", "None"),
        value: NONE_SELECTED
      });
    return options;
  };

  const visitListOptions = useMemo(populateVisitListOptions, [subjectVisitDates, t]);

  if (!isVisible) {
    return null;
  }

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleFormSubmit = async (feedbackReason) => {
    const newVisitId = selectedVisitId === NONE_SELECTED ? null : selectedVisitId;

    try {
      VisitDateService.updateVisitDateForQuestionnaire(questionnaire.subjectId, questionnaire.id, feedbackReason, newVisitId);
      const updatedVisit = subjectVisitDates.find(v => v?.scheduledItem?.id === newVisitId);
      setVisit(updatedVisit?.scheduledItem);
    } catch (error) {
      console.error('[VisitDateDisplay][handleFormSubmit]', error)
    }

    closeEditModal();
  };

  const handleEditCancelClick = () => {
    closeEditModal();
  };

  const displayText = visit?.name || t("GENERIC_TERM_NONE", "None");

  return (
    <>
      <span style={style}>
        {showLabel && (
          <>
            {t("GENERIC_TERM_VISIT_DATE", "Visit date")}:{" "}
          </>
        )}
        {displayText}
        {showEditIcon && hasEditPermission && (
          <Icon
            style={{ marginLeft: 5 }}
            name='edit'
            onClick={handleEditClick}
            className='mouse-pointer'
            title={t('VISITDATEDISPLAY_EDIT_TITLE', 'Edit visit date')}
          />
        )}
      </span>

      <Modal
        open={isEditModalOpen}
        closeIcon={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          <h3>{t('VISITDATEDISPLAY_EDIT_TITLE', 'Edit visit date')}</h3>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field key='formField_visitDate'>
              <label>
                {t('VISITDATEDISPLAY_EDIT_VISIT', 'Visit')}
              </label>
              <Dropdown
                selection
                options={visitListOptions || []}
                onChange={(_e, data) => {
                  setSelectedVisitid(data?.value);
                }}
                value={selectedVisitId || NONE_SELECTED}
              />
            </Form.Field>
            <ConfirmButtonWithFeedback
              buttonText={t(
                'GLOBAL_BUTTON_SAVE',
                'Save'
              )}
              headerText={t('VISITDATEDISPLAY_EDIT_SAVE_TEXT', 'Enter a reason for this change')}
              confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
              cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
              onConfirm={(feedbackReason) => handleFormSubmit(feedbackReason)}
              mandatoryValidationText={t(
                "VISITDATEDISPLAY_EDIT_SAVE_REASON_VALIDATION_TEXT",
                "Please supply a reason for the change."
              )}
            />
            <Button
              onClick={handleEditCancelClick}
            >
              {t('GLOBAL_BUTTON_CLOSE', 'Close')}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  )
};

export default withTranslation()(VisitDateDisplay);
