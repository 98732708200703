import React from 'react'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import SelectValueEditor from './simpleValueEditors/SelectValueEditor'
import ObjectGroup from './simpleValueEditors/ObjectGroup';
import ArrayGroup from './simpleValueEditors/ArrayGroup';
import ConfigWrapper from './simpleValueEditors/ConfigWrapper';
import MinMaxConfigEditor from './compoundValueEditors/MinMaxConfigEditor';

function DateConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <SelectValueEditor pathKey="type" label={"Type"} arrayOfValues={['NOW']} />
      <ObjectGroup label={'Date Time Validation'} pathKey={'dateTimeValidation'} >
        <ArrayGroup pathKey='min' label={"Min"} >
          <MinMaxConfigEditor />
        </ArrayGroup>
        <ArrayGroup pathKey='max' label={"Max"} >
          <MinMaxConfigEditor />
        </ArrayGroup>
      </ObjectGroup>
    </ConfigWrapper>
  )
}

export default DateConfigEditor