import React, { useMemo, useState } from 'react'
import ConfigEditorSwitch from './ConfigEditorSwitch'
import { Button, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react'
import { ACTION_TYPES } from '../../../../hooks/useCompleteQuestionnaireDefinitionInformation'
import { set } from 'lodash'


function ConfigEditorModalInsert({ dispatch, questionnaireCode, questionCode, questionType, config, setIsConfigEditorOpen }) {
  const [allConfigChanges, setAllConfigChanges] = useState({});

  const createFilterOutRemovedChildren = (alteredPath, alteredValue) => ([key, value]) => {
    if (alteredValue !== undefined) return true;
    if (key === alteredPath) return true;
    if (key.startsWith(alteredPath)) return false;
    return true;
  }
  const handleChange = (path, value) => {
    setAllConfigChanges(aCC => {
      const newObject = Object.fromEntries(Object.entries(aCC).filter(createFilterOutRemovedChildren(path, value)));
      newObject[path] = value
      return newObject
    })
  }


  const configAsJson = useMemo(() => {
    const base = config.translation?.length > 0 ? JSON.parse(config.translation) : {};
    // The sort here is to guarentee that the base is added before the inner, e.g.
    // 1) "side", {}           => {side:{}}
    // 2) "side.chips", true => {side: {chips: true}}
    Object.keys(allConfigChanges).sort((a, b) => { return a.length - b.length }).forEach(key => {
      set(base, key, allConfigChanges[key]);
    })
    return base
  }, [allConfigChanges, config.translation]);


  const updateTranslation = () => {
    const newTranslationValues = Object.keys(allConfigChanges).sort((a, b) => { return a.length - b.length }).map(key => {
      return {
        path: key,
        value: allConfigChanges[key],
        questionCode
      }
    })

    dispatch({
      type: ACTION_TYPES.EDIT_CONFIG,
      payload: {
        questionCode,
        changes: newTranslationValues
      }
    })
  }

  return (
    <><ModalHeader>Edit Config</ModalHeader>
      <ModalContent scrolling>
        <ConfigEditorSwitch questionType={questionType} config={configAsJson} onChange={handleChange} />
      </ModalContent>
      <ModalActions>
        <Button color='black' onClick={() => setIsConfigEditorOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Update Translations"
          labelPosition='right'
          icon='checkmark'
          onClick={() => {
            updateTranslation()
            setIsConfigEditorOpen && setIsConfigEditorOpen(false)
          }}
          primary
        />
      </ModalActions></>
  )
}

export default ConfigEditorModalInsert;