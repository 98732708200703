import React, { useContext, useEffect, useState } from "react";
import {  Message, Progress } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AttachmentService, {
  MEDIA_STATE,
} from "../../services/AttachmentService";
import { QUESTION_TYPES } from "atom5-branching-questionnaire";
import AttachmentPreview from "../../components/attachments/AttachmentPreview";
import VideoPlayer from "../../components/media/VideoPlayer/VideoPlayer";
import AttachmentDownload from "./AttachmentDownload";
import ConfigContext from "../../context/ConfigContext";
import AttachmentWorkflowStatusOverride from "./AttachmentWorkflowStatusOverride";

const MEDIA_SIZE = {
  SMALL: {
    width: 500,
  },
  NORMAL: {
    width: 750,
  },
  LARGE: {
    width: 1000,
  },
};

const AttachmentInline = ({ question, reference, subjectId, t, printable, blurredOnly, hasAllowSubjectMediaAudioPermission }) => {
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaState, setMediaState] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [blobData, setBlobData] = useState(undefined);
  const config = useContext(ConfigContext);

  const loadContent = async () => {
    const attachmentResponse = await AttachmentService.getAttachment(
      subjectId,
      reference,
      blurredOnly
    );
    setAttachment(attachmentResponse);
    if (attachmentResponse && attachmentResponse.state) {
      setMediaState(AttachmentService.getAttachmentVariantStateMapping(attachmentResponse.type,attachmentResponse.category,attachmentResponse.state));
    }
  };

  useEffect(() => {
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if (
      mediaState !== MEDIA_STATE.COMPLETED &&
      mediaState !== MEDIA_STATE.LEGACY
    ) {
      return;
    }
    AttachmentService.getAttachmentInline(
      subjectId,
      reference,
      blurredOnly,
      (progress) => {
        setLoadingPercentage(progress.percentage);
      },
      (mediaDataUrl, blob) => {
        setMediaUrl(mediaDataUrl);
        setBlobData(blob);
        setIsLoaded(true);
      },
      (err) => {
        console.error('Error getAttachmentInline', err)
        setError(err);
        setIsLoaded(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState]);

  const onMediaLoadError = (err) => {
    console.error('onMediaLoadError', err)

    setError(err);
  };


  const getPlayer = () => {
    const videoPlayerConfig = getVideoPlayerConfig(config,question,mediaUrl,hasAllowSubjectMediaAudioPermission,onMediaLoadError);

    return <VideoPlayer {...videoPlayerConfig} />;
  }


  return (
    <>
      {!isLoaded &&
        !error &&
        (mediaState === MEDIA_STATE.COMPLETED ||
          mediaState === MEDIA_STATE.LEGACY) && (
          <Progress percent={loadingPercentage} indicating />
        )}
      {error &&
        (mediaState === MEDIA_STATE.COMPLETED ||
          mediaState === MEDIA_STATE.LEGACY) && (
          // Error
          <Message negative>{t("ERROR_LOADING_VIDEO")}</Message>
        )}
      {mediaState !== null &&
        mediaState !== MEDIA_STATE.COMPLETED &&
        mediaState !== MEDIA_STATE.LEGACY && (
          // Have state, Not Completed, Not Legacy - show info
          <>
            <Message
              icon={mediaState?.displayProperties?.icon}
              info={mediaState?.displayProperties?.isInfo}
              content={t(mediaState?.translationKey, mediaState?.fallbackText)}
              style={{ width: "600px" }}
            />
          </>
        )}
      {(mediaState === MEDIA_STATE.COMPLETED ||
        mediaState === MEDIA_STATE.LEGACY) && (
          // Completed or Legacy - Display It
          <>
            {(question.type === QUESTION_TYPES.IMAGE ||
              question.type === QUESTION_TYPES.SIGNATURE) && (
                <>
                  {isLoaded && !error && (
                    <>
                      <img
                        width={MEDIA_SIZE.SMALL.width}
                        src={mediaUrl}
                        alt={reference}
                        data-value={reference}
                      />
                    </>
                  )}
                </>
              )}
            {question.type === QUESTION_TYPES.VIDEO && (
              <>
                {isLoaded && getPlayer()}
              </>
            )}
            {question.type === QUESTION_TYPES.FILE && (
              <>
                {isLoaded && !error && (
                  <AttachmentPreview
                    question={question}
                    attachment={attachment}
                    url={mediaUrl}
                    blob={blobData}
                  />
                )}
              </>
            )}

            <AttachmentDownload
              subjectId={subjectId}
              reference={reference}
              blurredOnly={blurredOnly}
              mediaState={mediaState}
            />
            <AttachmentWorkflowStatusOverride
              subjectId={subjectId}
              attachmentId={attachment?.attachmentId} // NOTE: attachment is actually the attachmentVariant - needs changing properly, but this is a quick change to a live system right now.
              reference={reference}
              workflowStatus={attachment.workflowStatus}
            />
          </>
        )}
    </>
  );
};

export const getVideoPlayerConfig = (config,question, mediaUrl, hasAllowSubjectMediaAudioPermission, onMediaLoadError, extraAnnotationCategories, attachmentVariantRef  ) => {

  const videoPlayerGeneralConfig = config?.ui?.components?.videoPlayer;
  const videoPlayerQuestionConfig = question?.config?.videoPlayer;

  const videoPlayerConfig = { ...videoPlayerGeneralConfig, ...videoPlayerQuestionConfig};

  const frameSkipSizes = videoPlayerConfig?.skip?.availableStepSizes;
  const defaultSkipSize = Number(videoPlayerConfig?.skip?.defaultSkipSize ?? 1);


  const playbackRates = videoPlayerConfig?.playback?.availablePlaybackRates;
  const defaultPlaybackRate = Number(videoPlayerConfig?.playback?.defaultPlaybackRate ?? 1);
  const controlPanelLocked = videoPlayerConfig?.isControlPanelLockedOpen;

  let annotationCategories = videoPlayerQuestionConfig?.annotationCategories;
  if(annotationCategories !== undefined)
  {
    if(extraAnnotationCategories !== undefined){
      annotationCategories = [...annotationCategories,...extraAnnotationCategories]
    }
  }else {
    annotationCategories = extraAnnotationCategories;
  }

  const defaultTimeDisplayUnit =  videoPlayerConfig?.defaultTimeDisplayUnit

  const videoPlayerProps = {
    question,
    videoData: {
      url: mediaUrl,
      ...(typeof attachmentVariantRef !== 'undefined' && { attachmentVariantRef: attachmentVariantRef }),
    },
    options: {
      allowMuteChange: hasAllowSubjectMediaAudioPermission,
      ...(typeof controlPanelLocked !== 'undefined' && { isControlPanelLockedOpen: controlPanelLocked }),
      ...(typeof defaultTimeDisplayUnit !== 'undefined' && { defaultTimeDisplayUnit: defaultTimeDisplayUnit }),
    },
    timeSkipOptions: {
      ...(typeof frameSkipSizes !== 'undefined' && { sizes: frameSkipSizes }),
      ...(typeof defaultSkipSize !== 'undefined' && { defaultSkipSize: defaultSkipSize }),
    },
    playbackRateOptions: {
      ...(typeof playbackRates !== 'undefined' && { rates: playbackRates }),
      ...(typeof defaultPlaybackRate !== 'undefined' && { defaultPlaybackRate: defaultPlaybackRate }),
    },
    annotationOptions:{
      isEnabled: annotationCategories !== undefined,
      categories: annotationCategories
    },

    onError: onMediaLoadError
  };

  return videoPlayerProps;
}

export default withTranslation()(AttachmentInline);
