import React, { useState } from "react";
import { Button, Confirm, Form, Loader } from "semantic-ui-react";
import FeedbackForm from "./FeedbackForm";

const ConfirmButtonWithFeedback = (props) => {
  const {
    onConfirm,
    onCancel,
    buttonText,
    headerText,
    contentText,
    confirmButtonText,
    cancelButtonText,
    size,
    fluid,
    placeholderText,
    mandatoryValidationText,
    color,
    disabled,
    isSubmitting,
    propStyle,
    labelPosition,
    floated
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleConfirm = () => {
    //if mandatory validation text is supplied then check feedback is supplied
    //if no mandatory validation text is supplied then go ahead and submit the form
    if (
      mandatoryValidationText &&
      (!feedback || feedback.trim().length === 0)
    ) {
      setShowError(true);
    } else {
      setIsOpen(false);
      if (onConfirm) {
        onConfirm(feedback);
      }
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const addFeedback = (feedbackFromComponent) => {
    setFeedback(feedbackFromComponent);
  };

  const buttonSubmit = disabled || isSubmitting;

  const theButton = (
    <Button
      icon
      floated={floated}
      labelPosition={labelPosition}
      fluid={fluid}
      size={size}
      color={color ? color : "orange"}
      disabled={buttonSubmit}
      style={propStyle}
      onClick={() => setIsOpen(true)}
    >
      {buttonText}
      {props.children}
    </Button>
  );

  const style = showError
    ? { display: "flex", color: "red", margin: "0.5em", justifyContent: "center" }
    : { display: "none" };

  const contentTextWithFeedback = (
    <>
      <Form>
        <p style={{ marginLeft: "2em" }}>{contentText}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FeedbackForm
            placeholder={placeholderText}
            addFeedback={addFeedback}
          />
        </div>
        <br />
        <div style={style}>
          <p>{mandatoryValidationText}</p>
        </div>
        <br />
      </Form>
    </>
  );

  return (
    <>
      {!isSubmitting && theButton}
      {isSubmitting && <Loader active />}
      <Confirm
        open={isOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        header={headerText}
        content={contentTextWithFeedback}
        confirmButton={confirmButtonText}
        cancelButton={{ content: cancelButtonText, primary: true, basic: true }}
        size={size}
      />
    </>
  );
};

export default ConfirmButtonWithFeedback;
