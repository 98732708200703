import React, {Fragment} from "react";
import {withTranslation} from "react-i18next";
import {Button, Grid} from "semantic-ui-react";
import SensorDataService from "../../../../services/sensordata/SensorDataService";
import DateTimeService from "../../../../services/DateTimeService";
import { saveAs } from "file-saver";
import DateTimeField from "../../../../components/DateTimeField";
import moment from "moment";

const YumenPageHeader = ({handleFromDateChange, limbType, hubId, fromDate, showExportButton, t}) => {

  const exportFunction = async () => {

    if (hubId === null) {
      console.log('Hub Id not set, can not export');
      return;
    }

    let data = await SensorDataService.getYumenExportData(hubId, limbType, moment(fromDate).format("YYYY-MM-DD"));

    const csvBlobData = new Blob([data], {
      type: "text/csv;charset=utf-8;",
    });

    const theDate = DateTimeService.build.asDisplayDate(new Date());
    const filename = `Wearable-${limbType}-Data-${theDate}.csv`;
    saveAs(csvBlobData, filename);
  };

  return (
      <Fragment>
            <>
              {hubId && (<>
                <h2>{t("YUMEN_ARM_HEADER_CONNECTED", "Yumen Hub connected")} ({hubId})</h2>
                <Grid columns={'2'}>
                  <Grid.Row>
                    <Grid.Column>
                      <div style={{marginBottom: "20px"}}>
                        <DateTimeField
                            label={t("GRAPH_SELECT_DATE", "Data for day")}
                            onChange={d => handleFromDateChange(d)}
                            value={fromDate}/>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div style={{marginBottom: "20px"}}>
                  {showExportButton && (
                      <Button
                        primary
                        onClick={() => {
                          exportFunction();
                        }}>
                        {t("YUMEN_ARM_EXPORT_DATA", "Export selected dates data")}
                      </Button>)}
                </div>
              </>)}

              {!hubId && (<>
                <h2>{t("YUMEN_ARM_HEADER_NOT_CONNECTED", "No Yumen Hub connected")}</h2>
                <h3>{t("YUMEN_ARM_HEADER2_NOT_CONNECTED",
                    "Please define the Yumen Wearable Hub ID in the Subject Records section")}</h3>
              </>)}
            </>

      </Fragment>
  );
}

export default withTranslation()(YumenPageHeader);
