import moment from "moment";
import React from "react";
import DateTimeField from "../../components/DateTimeField";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";

const MonthYear = React.memo(({ value, changeValue, isReadOnly }) => (
      <DateTimeField
          value={moment(value)}
          onChange={(v) => changeValue(v.format("YYYY-MM"))}
          mode={"monthyear"}
          readOnly={isReadOnly}
      />
  ));

export default withContainer(withLabel(withError(MonthYear)));
