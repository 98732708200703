import React, { useState, useEffect } from "react";
import {Card, Loader, Dropdown, Grid, Button, Icon} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import StaffService from "../../StaffService";
import '../utils/AdminCardFade.css'
import ConfirmButton from "../../components/dashboard/ConfirmButton";
import AuthService from "../../services/AuthService";
import TrialService from "../../TrialService";

const StaffSubjectLinkCard = (props) => {
    const { t } = props;
    const staffMember = props.staffEntity;
    const disabled = props.disabled;
    const index = props.index;
    const [isLoading, setIsLoading] = useState(false);
    const [allSubjects, setAllSubjects] = useState([]);
    const [filteredSubjectsDropdown, setFilteredSubjectsDropdown] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [status, setStatus] = useState("");
    const [showCard, setShowCard] = useState(true);
    const [linkedSubjectId, setLinkedSubjectId] = useState(staffMember?.linkedSubjectId);
    const [groupLabels, setGroupLabels] = useState();
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const blink = props.blink;

    const handleUpdateButtonState = props.handleUpdateButtonState;
    const handleUnlinking = props.handleUnlinking;

    let alreadyLinkedIds = [];

    const loadAllSubjects = async (groupMappings) => {

        //remove already assigned subjects to avoid duplicate assigning
        let staffData = await StaffService.listStaff();
        let linkedIds = [];
        staffData.forEach(staff => {
            if (staff?.linkedSubjectId != null) {
                linkedIds.push(staff.linkedSubjectId);
            }
        });
        alreadyLinkedIds = linkedIds;

        const distinctSubjects = [];

        const groups = [];
        for (const group in groupMappings) {
            groups.push(groupMappings[group].group.code);
        }

        for (const group in groups) {
            let page = 0;
            let subjectsData;
            const subjects = [];
            do {
                subjectsData = await SubjectService.listSubjects(groups[group], page++, null, null, null, 50);
                for (const sub1 in subjectsData.pageSubjects) {
                    if (alreadyLinkedIds !== undefined && alreadyLinkedIds.includes(subjectsData.pageSubjects[sub1].id)) {
                        subjectsData.pageSubjects[sub1].linked = true;
                    }
                    subjects.push(subjectsData.pageSubjects[sub1]);
                }
            } while (subjectsData.pageSubjects.length > 0);

            subjects.forEach(sub => {
                const exists = distinctSubjects
                    .map(s => s.id === sub.id)
                    .some(i => i === true);

                if (!exists) {
                    distinctSubjects.push(sub);
                }
            });
        }
        setAllSubjects(distinctSubjects);
    };

    const populateLabels = () => {
        TrialService.getGroups().then((allGroups) => {
            const newGroupLabels = {};
            for (const group of allGroups) {
                newGroupLabels[group.code] = {
                    id: group.id,
                    label: group.label
                };
            }
            setGroupLabels(newGroupLabels);
        });
    };

    const init = async () => {
        setIsLoading(true);
        if (linkedSubjectId !== null) {
            setStatus("");
        }

        populateLabels();

        const profile = await AuthService.getMyProfile();
        setIsSuperAdmin(profile.superAdmin);
        await loadAllSubjects(profile.groupMappings);

        if (blink) {
            //blink the card to draw users attention to it
            const delay = 400;
            setTimeout(() => setShowCard(false), delay);
            setTimeout(() => setShowCard(true), delay * 2);
            setTimeout(() => setShowCard(false), delay * 3);
            setTimeout(() => setShowCard(true), delay * 4);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        init().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const findSubjectById = (id) => {
        const subject = allSubjects.filter(subject => subject.id === id);
        return subject.length > 0 ? subject[0].subjectCode : "";
    };

    const generateDropdownGroups = (groupData) => {
        const groupOptions = [];
        for (let k in groupData) {
            groupOptions.push({
                text: groupData[k].label,
                value: groupData[k].id,
                key: groupData[k].id
            });
        }
        return groupOptions.sort((a, b) => (a.text < b.text ? -1 : 1));
    };

    const generateDropdownUsers = (usersData) => {
        let usersOptions = [];
        for (let k in usersData) {
            usersOptions.push({
                text: usersData[k].subjectCode,
                value: usersData[k].id,
                key: usersData[k].id
            });
        }
        return usersOptions.sort((a, b) => (a.text < b.text ? -1 : 1));
    }

    const handleGroupChange = (selectedGroup) => {
        //lookup staff belonging to group
        const filteredList = allSubjects
            .filter(sub => {
                if (sub.linked) {
                    console.log("User already linked, not outputting this subject");
                }
                return sub.linked !== true
            })
            .filter(sub => {
                const uniqueIds = [];
                sub.groups.forEach(group => {
                    if (uniqueIds.indexOf(group.id) === -1) {
                        uniqueIds.push(group.id);
                    }
                });
                return uniqueIds.indexOf(selectedGroup.value) !== -1;
            });

        setSelectedUser({});

        //group change triggers a rebuild of the users dropdown data
        setFilteredSubjectsDropdown(generateDropdownUsers(filteredList));
    };

    const handleUserChange = (selectedDropdownUser) => {
        const subjectUser = allSubjects.filter(sub => sub.id === selectedDropdownUser.value);
        setSelectedUser({
            id:selectedDropdownUser.value,
            label:subjectUser[0].subjectCode,
        });
    };

    const linkStaffToSubject = async (staffId, subjectId) => {
        if (staffId === undefined || subjectId === undefined) {
            setStatus(t("STAFF_SUBJECT_LINK_ERROR_LINKING_STAFF_TO_SUBJECT", "There was a problem linking the staff member to the subject."));
        }
        try {
            const response = await StaffService.linkStaffToSubject(staffId, subjectId);
            if (response.status === 'success') {
                setStatus(t("STAFF_SUBJECT_LINK_SUCCESS", "Success!"));
            } else {
                setStatus(t("STAFF_SUBJECT_LINK_ERROR_LINKING_STAFF_TO_SUBJECT", "There was a problem linking the staff member to the subject."));
            }
            setLinkedSubjectId(subjectId);
            setSelectedUser({});
        } catch (e) {
            setStatus(JSON.stringify(e));
        }

        if (handleUpdateButtonState) {
            handleUpdateButtonState(index, "link")
        }
    };

    const unlinkStaffFromSubject = async (staffId) => {
        if (staffId === undefined) {
            setStatus(t("STAFF_SUBJECT_LINK_ERROR_LINKING_STAFF_TO_SUBJECT", "There was a problem linking the staff member to the subject."));
        }
        const response = await StaffService.unlinkStaffFromSubject(staffId);
        if (response.status === 'success') {
            setStatus(t("STAFF_SUBJECT_UNLINK_SUCCESS", "Subject has been unlinked from staff member!"));
        } else {
            setStatus(t("STAFF_SUBJECT_LINK_ERROR_LINKING_STAFF_TO_SUBJECT", "There was a problem unlinking the staff member from the subject."));
        }

        setLinkedSubjectId(null);

        if (handleUpdateButtonState) {
            handleUpdateButtonState(index, "unlink");
        }

        if (handleUnlinking) {
            handleUnlinking();
        }
    };

    return (
        <>
        <Loader inline={true} active={isLoading} />
        {!isLoading && (
            <div className={showCard ? 'card' : 'card hide'}>
                <Card style={{width:'100%'}}>
                    <Card.Content>
                        <Card.Meta style={{marginTop:'5px'}}>
                            {t("STAFF_SUBJECT_LINK_CARD_DESCRIPTION", "Staff to existing participant Link")}
                        </Card.Meta>
                        <Card.Description>
                            {!isSuperAdmin && (
                                <Grid>
                                    <Grid.Column width={16}>
                                        {t("STAFF_SUBJECT_LINK_NOT_SUPER_ADMIN", "You need to be logged in as Super Admin to Manually override the participant linking")}
                                    </Grid.Column>
                                </Grid>)
                            }
                            {status !== "" && (
                                <Grid>
                                    <Grid.Column width={16}>
                                        {status}
                                    </Grid.Column>
                                </Grid>)
                            }
                            {isSuperAdmin && linkedSubjectId === null && (
                                <Grid>
                                    <Grid.Column width={16}>
                                        <label>{t("STAFF_SuBJECT_LINK_MANUAL_OVERRIDE", "Manual override:")}</label>
                                        <br/><br/><label>{t("STAFF_SUBJECT_LINK_SELECT_SUBJECT", "Select the Subject to link to this staff member:")}</label>
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Dropdown
                                            placeholder={t("STAFF_SUBJECT_LINK_GROUP", "Filter by Group")}
                                            onChange={(e, data) => handleGroupChange(data)}
                                            options={generateDropdownGroups(groupLabels)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        {filteredSubjectsDropdown.length > 0 && (
                                            <>
                                                User: {" "}
                                                <Dropdown
                                                    scrolling={true}
                                                    options={filteredSubjectsDropdown}
                                                    onChange={(e, data) => handleUserChange(data)}
                                                />
                                            </>
                                        )}
                                        {filteredSubjectsDropdown.length === 0 && (
                                            <>
                                                {t("STAFF_SUBJECT_LINK_CARD_NO_SUBJECTS", "No subjects available for the selected group")}
                                            </>
                                        )}
                                    </Grid.Column>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            {Object.keys(selectedUser).length !== 0 && (
                                                <Button
                                                    primary
                                                    size={"tiny"}
                                                    labelPosition="left"
                                                    icon
                                                    onClick={(e)=> {
                                                        e.preventDefault();
                                                        linkStaffToSubject(staffMember.id, selectedUser.id);
                                                    }}>
                                                    {t("STAFF_SUBJECT_LINK_LINK_STAFF_MEMBER", "Link staff member")} <u>{staffMember.firstName} {staffMember.lastName}</u> {t("STAFF_SUBJECT_LINK_LINK_STAFF_MEMBER_WITH", "with")} {selectedUser.label}
                                                    <Icon name="linkify" />
                                                </Button>
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>)
                            }
                            {linkedSubjectId != null && (
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={12}>
                                            {t("STAFF_SUBJECT_LINK_LINKED_TO_SUBJECT", "This staff member is linked to participant")} {findSubjectById(linkedSubjectId)}
                                        </Grid.Column>
                                    </Grid.Row>
                                    {!disabled && (<Grid.Row>
                                        <Grid.Column width={12}>
                                            <ConfirmButton
                                                onConfirm={() => unlinkStaffFromSubject(staffMember.id)}
                                                buttonText={t("STAFF_SUBJECT_LINK_UNLINK_STAFF_MEMBER", "Unlink this participant from this staff member")}
                                                headerText={t("STAFF_SUBJECT_LINK_UNLINK_STAFF_MEMBER_HEADER", "Unlinking participant from staff member")}
                                                contentText={t("STAFF_SUBJECT_LINK_UNLINK_STAFF_MEMBER_SURE", "Please confirm the unlinking of the participant from the staff member")}
                                                confirmButtonText={t("STAFF_SUBJECT_LINK_UNLINK_STAFF_MEMBER_YES", "Yes unlink")}
                                                cancelButtonText={t("CANCEL", "Cancel")}
                                                preventDefault={true}>
                                            </ConfirmButton>
                                        </Grid.Column>
                                    </Grid.Row>)}
                                </Grid>)
                            }
                        </Card.Description>
                    </Card.Content>
                </Card>
            </div>
          )}
        </>
    );
}

export default withTranslation()(StaffSubjectLinkCard);
