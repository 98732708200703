import React, { useContext } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import BrandingService from "../../services/BrandingService";
import { compose } from "redux";
import ConfigContext from "../../context/ConfigContext";
import InternationalisationService from "../../InternationalisationService";

const MandatoryTrainingModalInsert = (props) => {
  const { t, outstandingMandatoryQuestionnaires, handleClose } = props;

  const config = useContext(ConfigContext);
  let brandImageUrl;
  if (config?.ui?.onboardingBrandImage) {
    brandImageUrl = BrandingService.getBrandImageForOnBoarding();
  }

  return (
      <>
        { outstandingMandatoryQuestionnaires.length > 0 && (<Form style={styles.container} >
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <Image
                    style={{ height: "25px" }}
                    src={
                      "/images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
                    }
                    alt={"Aparito Logo"}
                />
              </Grid.Column>
            </Grid.Row>
            {brandImageUrl && (
                <Grid.Row
                    centered
                    rows={3}
                    style={{
                      backgroundImage: `url(${brandImageUrl})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      height: "100px",
                    }}
                />
            )}
            <Grid.Row centered columns={3}>
              <Grid.Column
                  width={10}
                  textAlign={
                    InternationalisationService.isRTL() ? "right" : undefined
                  }
              >
                <Header as="h3" textAlign="center">
                  {t("MANDATORY_TRAINING_PLEASE_COMPLETE_TITLE", "Please complete your mandatory training:")}
                </Header>
                {
                    outstandingMandatoryQuestionnaires.map(q => {
                    return (
                        <div style={{padding:'10px'}}>
                          <li>{q.label}</li>
                        </div>);
                    })
                }
                <Button
                    style={{marginTop: '20px'}}
                    type="submit"
                    primary
                    fluid
                    size="large"
                    onClick={handleClose}
                >
                  {t("GO_TO_MANDATORY_TRAINING", "Click to close")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>)}
        </>
  );
};

const styles = {
  container: {
    minHeight: "420px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  accountTypeGroup: {
    display: "flex",
    justifyContent: "space-around",
    padding: "32px 0px",
  },
};

const enhance = compose(withTranslation());

export default enhance(MandatoryTrainingModalInsert);
