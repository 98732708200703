import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Icon, Modal } from 'semantic-ui-react';
import ConfirmButtonWithFeedback from '../../../components/dashboard/ConfirmButtonWithFeedback';
import ScheduledItemService, { SCHEDULED_ITEM_TYPE } from '../../../services/ScheduledItemService';
import PermissionsService from '../../../services/PermissionsService';
import GroupPermission from '../../../GroupPermission';

const SubjectVisitDateAttendance = (props) => {
  const { t, subjectId, subjectData, scheduledItem, onChange } = props;
  const scheduledItemService = new ScheduledItemService(SCHEDULED_ITEM_TYPE.VISITDATE);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendedState, setAttendedState] = useState(scheduledItem.attendedState);
  const [hasEditPermission, setHasEditPermission] = useState(false);

  const buildIconProps = () => {
    let iconName, iconColour;
    switch (scheduledItem.attendedState) {
      case 'ATTENDED':
        iconName = 'check';
        iconColour = 'green';
        break;
      case 'DID_NOT_ATTEND':
        iconName = 'close';
        iconColour = 'red';
        break;
      default:
        iconName = 'minus';
        iconColour = 'grey';
    }

    return {
      iconName,
      iconColour
    };
  }

  const iconProps = useMemo(buildIconProps, [scheduledItem]);

  const attendedStateListOptions = scheduledItemService.getAttendedStateListOptions(t);

  useEffect(() => {
    populatePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populatePermissions = async () => {
    setHasEditPermission(await PermissionsService.hasPermissionForSubject(
      subjectData?.groups,
      GroupPermission.UPDATE_VISITDATE_ATTENDED_STATE
    ));
  };


  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleIconClick = () => {
    setIsModalOpen(true);
  }

  const handleCancelClick = () => {
    closeModal();
  };

  const handleFormSubmit = async (feedbackReason) => {
    try {
      await scheduledItemService.updateAttendedState(subjectId, scheduledItem.id, attendedState, feedbackReason);
      if (onChange != null) {
        onChange();
      }
      closeModal();
    } catch (error) {
      console.error('[SubjectVisitDateAttendance][handleFormSubmit] Error', error);
    }
  };

  return (
    <>
      <Icon
        name={iconProps?.iconName}
        color={iconProps?.iconColour}
        size='large'
        className={hasEditPermission ? 'mouse-pointer' : undefined}
        onClick={hasEditPermission ? handleIconClick : undefined}
      />

      <Modal
        open={isModalOpen}
        closeIcon={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          <h3>{t(['SCHEDULED_ITEM_ATTENDEDSTATE_EDIT_TITLE', 'SUBJECT_VISITDATES_ATTENDEDSTATE_EDIT_TITLE'], 'Edit attendance state')}</h3>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field key='formField_attendedState'>
              <label>
                {t(['SCHEDULED_ITEM_ATTENDEDSTATE_EDIT_STATE', 'SUBJECT_VISITDATES_ATTENDEDSTATE_EDIT_STATE'], 'Attended?')}
              </label>
              <Dropdown
                selection
                options={attendedStateListOptions || []}
                onChange={(_e, data) => {
                  setAttendedState(data.value);
                }}
                value={attendedState}
              />
            </Form.Field>

            <ConfirmButtonWithFeedback
              buttonText={t(
                'GLOBAL_BUTTON_SAVE',
                'Save'
              )}
              headerText={t(['SCHEDULED_ITEM_ATTENDEDSTATE_EDIT_SAVE_TEXT', 'SUBJECT_VISITDATES_ATTENDEDSTATE_EDIT_SAVE_TEXT'], 'Enter a reason for this change')}
              confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
              cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
              onConfirm={(feedbackReason) => handleFormSubmit(feedbackReason)}
              mandatoryValidationText={t(
                "SCHEDULED_ITEM_ATTENDEDSTATE_EDIT_REASON_VALIDATION_TEXT",
                "Please supply a reason for the change."
              )}
            />
            <Button
              onClick={handleCancelClick}
            >
              {t('GLOBAL_BUTTON_CLOSE', 'Close')}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default withTranslation()(SubjectVisitDateAttendance);
