import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

const Notification = ({shouldShow, value}) => {
  if(!shouldShow) return null;
  return <div style={{position: 'relative', right: '-12px', top: '-4px', backgroundColor: '#f8991d',
    color: '#ffffff', height: '6px', width:'6px', fontSize:'16px', borderRadius: '100%' }}>
  </div>
}

const MenuItem = ({to, icon, active, badgeValue}) => {
  const iconStyle = {
    float:'left',
    width:30,
    color: active ? '#f8991d' : undefined,
  }
  return (
    <Menu.Item
      as={Link}
      to={to}
      style={{paddingLeft: '0px'}}
    >
      <div>
        <div style={iconStyle}>{icon && <Icon name={icon} />}</div>
        <Notification shouldShow={badgeValue!==0} value={badgeValue} />
      </div>
    </Menu.Item>
  );
};

export default MenuItem;
