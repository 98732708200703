import React from 'react'
import TextValueEditor from '../simpleValueEditors/TextValueEditor';
import NumberValueEditor from '../simpleValueEditors/NumberValueEditor';

function MinMaxConfigEditor(props) {
  const rendered = [];
  if (typeof props.value !== 'number') rendered.push(<TextValueEditor {...props} label={"Add Text"} key={props.pathKey + "_text"} />)
  if (typeof props.value !== 'string') rendered.push(<NumberValueEditor {...props} label={"Add Number"} key={props.pathKey + "_number"} />)
  return rendered;
}

export default MinMaxConfigEditor