import DOMPurify from "dompurify";
import React, {useMemo} from "react";
import { Form } from "semantic-ui-react";
import withShowHide from "./hocs/withShowHide";
import withContainer from "./hocs/withContainer";
import useSubjectContext from "../../hooks/useSubjectContext";

DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener");
  }
});

const Markup = ({ question, subjectId, value }) => {

  const renderAs =
    question?.config?.renderAs !== undefined
      ? question?.config?.renderAs
      : "label";

  let questionLabelOverride = question.label;
  if (renderAs === "value") {
    const isString = value != null && typeof(value) === 'string';
    if (isString && value.indexOf("|") >= 0) {
      questionLabelOverride = value.split("|");
    } else {
      questionLabelOverride = value;
    }
  }

    const {subjectContext, helpers} = useSubjectContext(subjectId, question.label);
    const displayLabel = useMemo(() => {
      if(Array.isArray(questionLabelOverride)) return questionLabelOverride
      return helpers.textSubstitution(questionLabelOverride, subjectContext)
    }, [helpers, questionLabelOverride, subjectContext])

  return <Form.Field>
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(displayLabel),
      }}
    />
  </Form.Field>
};

export default withContainer(withShowHide(Markup));
