import RequestHelper from "../RequestHelper";
import InternationalisationService from "../InternationalisationService";
import {serverAddress} from "./config/EnvConfig";

export default class ModuleService {
  static getModules() {
    return RequestHelper.send(
        serverAddress + "/modules",
        {
          "Accept-Language": InternationalisationService.getLanguage(),
        },
        "GET",
        null
    );
  }
}