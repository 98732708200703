import RequestHelper from "./RequestHelper";
import InternationalisationService from "./InternationalisationService";
import AuthService from "./services/AuthService";
import GroupPermission from "./GroupPermission";
import PermissionsService from "./services/PermissionsService";
import { typeHelper } from "atom5-branching-questionnaire";
import {serverAddress} from "./services/config/EnvConfig";

export default class StaffService {
  static MYPROFILE_QUERY = `
        {
            query: myProfile {
                id
                firstName
                lastName
                email
                drugManager
                superAdmin
                groupMappings {
                    group {
                        code
                    }
                    roles {
                        code
                        permissions
                        fullPermissions
                    }
                }
                linkedSubjectId
            }
        }
    `;

  static PASSWORD_RULES_QUERY = `
        {
            query: getPasswordRules {
                minLength
                minCharacterTypes
            }
        }
    `;

  static UPDATE_PROFILE_MUTATION = `
        mutation query($email: String!, $firstName: String!, $lastName: String!) {
            updateProfile(email: $email, firstName: $firstName, lastName: $lastName) {
                firstName
                lastName
                email
            }
        }
    `;

  static UPDATE_PASSWORD_MUTATION = `
        mutation query($currentPassword: String!, $newPassword: String!) {
            updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
                id
                firstName
                lastName
                email
            }
        }
    `;

  static LIST_STAFF_QUERY = `
        {
            query: listStaff {
                id
                firstName
                lastName
                email
                drugManager
                superAdmin
                state
                canBeEdited
                createdDate
                endDate
                groupMappings {
                    group {
                        code
                    }
                    roles {
                        code
                    }
                }
                linkedSubjectId
            }
        }
    `;

  static GET_STAFF_QUERY = `
        query($id: Long!) {
            query: getStaff(id: $id) {
                id
                firstName
                lastName
                email
                drugManager
                superAdmin
                createdDate
                endDate
                groupMappings {
                    group {
                        code
                    }
                    roles {
                        code
                    }
                }
                auditLastUpdatedBy
                auditLastUpdatedByEmail
                auditLastUpdatedByFirstName
                auditLastUpdatedByLastName
                auditLastUpdated
                canBeEdited
                linkedSubjectId
            }
        }
    `;

  static CREATE_STAFF_MUTATION = `
        mutation query($email: String!, $firstName: String!, $lastName: String!, $drugManager: Boolean!, $groupRoles: [GroupRoleInput], $superAdmin: Boolean!) {
            createStaff(email: $email, firstName: $firstName, lastName: $lastName, drugManager: $drugManager, groupRoles: $groupRoles, superAdmin: $superAdmin) {
                id
                firstName
                lastName
                email
                drugManager
                superAdmin
            }
        }
    `;

  static UPDATE_STAFF_MUTATION = `
        mutation query($id: Long!, $email: String!, $firstName: String!, $lastName: String!, $drugManager: Boolean!, $groupRoles: [GroupRoleInput], $superAdmin: Boolean!) {
            updateStaff(id: $id, email: $email, firstName: $firstName, lastName: $lastName, drugManager: $drugManager, groupRoles: $groupRoles, superAdmin: $superAdmin) {
                firstName
                lastName
                email
                drugManager,
                superAdmin
            }
        }
    `;

  static SEND_ACTIVATION_EMAIL_MUTATION = `
        mutation query($email: String!) {
            sendActivationEmail(email: $email) {
                email
            }
        }
    `;

  static SUSPEND_STAFF_MUTATION = `
    mutation query($email: String!, $feedbackReason: String) {
      toggleStaffSuspension(
        email: $email
        auditFeedbackInformation: {feedbackReason: $feedbackReason}
      ) {
        email
      }
    }
  `;

  static getMyProfile() {
    const redirectToLogin = async ()=>{
      // No point continuing with execution flow, need to go back to login page
      const currentPath = window.location.href
          .toString()
          .split(window.location.host)[1];
      const pathname = window.location.pathname || "";
      if (!pathname.startsWith("/login")) {
        window.location.href =
            window.location.origin +
            "/login?referrer=" +
            encodeURI(currentPath);
      }
    }
    // todo this seems hacky, review
    // if StaffService.DATA is undefined OR if timestamp is undefined or data is older than 5 seconds then fetch it again
    if (StaffService.DATA === undefined
      || StaffService.timestamp === undefined
      || (Date.now() - StaffService.timestamp) / 1000 > 5) {
      StaffService.timestamp = Date.now();
      StaffService.DATA = RequestHelper.graphQl(StaffService.MYPROFILE_QUERY);
    }

    return StaffService.DATA?.then((profile) => {
      const permissions = { anyGroupPermissions: {}, groupPermissions: {} };

      Object.keys(GroupPermission).forEach((gp) => {
        permissions.anyGroupPermissions[gp] = PermissionsService.hasPermissionInAnyGroupSynchronous(profile, gp);
      });

      profile.groupMappings.forEach((gm) => {
        const groupPermissions = {};
        Object.keys(GroupPermission).forEach((gp) => {
          groupPermissions[gp] = PermissionsService.hasPermissionInGroupSynchronous(profile, gm.group.code, gp);
        });
        permissions.groupPermissions[gm.group.code] = groupPermissions;
      });

      profile.permissions = permissions;

      profile.helpers = {
        hasPermissionInGroup: (groupCode, permission) => {
          if (permissions?.groupPermissions[groupCode]?.[permission] == null) {
            return false;
          }
          return typeHelper.parseBool(permissions.groupPermissions[groupCode][permission]);
        },
        hasPermissionInAnyGroup: (permission) => {
          if (permissions?.anyGroupPermissions[permission] == null) {
            return false;
          }
          return typeHelper.parseBool(permissions.anyGroupPermissions[permission]);
        }
      }
      return profile;
    }).catch((e) => redirectToLogin());
    // TODO: LONG TERM reduce number of calls to this and to server - periodic refresh allowed, every x mins
    // this becomes loaded on load only and on refresh, not wherevr, use context for that going forward
  }

  static updateMyProfile(email, firstName, lastName) {
    const variables = { email, firstName, lastName };
    return RequestHelper.graphQl(
      StaffService.UPDATE_PROFILE_MUTATION,
      variables
    );
  }

  static changePassword(currentPassword, newPassword) {
    const variables = { currentPassword, newPassword };
    return RequestHelper.graphQl(
      StaffService.UPDATE_PASSWORD_MUTATION,
      variables
    );
  }

  static getRolesForSuperAdminStaff() {
    return RequestHelper.send(
        serverAddress + "/staff/roles",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getRolesForStaffCreation(groupCode) {
    return RequestHelper.send(
        serverAddress + "/staff/roles/" + groupCode,
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getMyRoleTranslations() {
    return RequestHelper.send(
        serverAddress + "/staff/myRoleTranslations",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static createStaff(email, firstName, lastName, drugManager, groupRoles, superAdmin) {
    const variables = { email, firstName, lastName, drugManager, groupRoles, superAdmin };
    return RequestHelper.graphQl(StaffService.CREATE_STAFF_MUTATION, variables);
  }

  static updateStaff(id, email, firstName, lastName, drugManager, groupRoles, superAdmin) {
    const variables = {
      id,
      email,
      firstName,
      lastName,
      drugManager,
      groupRoles,
      superAdmin
    };
    return RequestHelper.graphQl(StaffService.UPDATE_STAFF_MUTATION, variables);
  }

  static sendActivationEmail(email) {
    return RequestHelper.graphQl(StaffService.SEND_ACTIVATION_EMAIL_MUTATION, {
      email,
    });
  }

  static toggleStaffSuspension(feedbackReason, staff) {
    const feedbackHeaderValue = feedbackReason
      ? `Staff ${staff.id}'s state`
      : undefined;
    const { initialHeaders: headers } = RequestHelper.createInitialRequestObjectsWithFeedback(
      feedbackReason,
      feedbackHeaderValue
    );
    return RequestHelper.graphQl(
      StaffService.SUSPEND_STAFF_MUTATION,
      {
        email: staff.email,
        feedbackReason: feedbackReason
      },
      headers
    );
  }

  static listStaff() {
    return RequestHelper.graphQl(StaffService.LIST_STAFF_QUERY);
  }

  static getStaff(id) {
    return RequestHelper.graphQl(StaffService.GET_STAFF_QUERY, { id });
  }

  static isPrimarySuperAdmin(staffEntity) {
    if (staffEntity == null) {
      return false;
    }
    return staffEntity?.superAdmin === true && staffEntity?.email != null && staffEntity?.email.toLowerCase() === "admin@aparito.com";
  }

  static linkStaffToSubject(staffId, subjectId) {
    if (staffId === undefined || subjectId === undefined) {
      return false;
    }

    const queryParams = { staffId:staffId, subjectId:subjectId };
    return RequestHelper.send(
        serverAddress +
        "/staff/linkToSubject",
        undefined,
        "GET",
        queryParams
    );
  }

  static linkStaffToSubjectOnEmail(email) {
    if (email === undefined) {
      return false;
    }

    const queryParams = { email: email };
    return RequestHelper.send(
        serverAddress +
        "/staff/linkToStaffAndSubjectByEmail",
        undefined,
        "GET",
        queryParams
    );
  }

  static getStaffIdFromLinkedSubjectId(subjectId) {
    if (subjectId === undefined) {
      return false;
    }

    const queryParams = { subjectId:subjectId };
    return RequestHelper.send(
        serverAddress +
        "/staff/getStaffIdFromLinkedSubjectId",
        undefined,
        "GET",
        queryParams
    );
  }

  static isStaffToSubjectLinkingEnabled() {
    return RequestHelper.send(
        serverAddress +
        "/staff/isStaffToSubjectLinkingEnabled",
        undefined,
        "GET",
        {}
    );
  }

  static isDisplayStaffToSubjectLinkingOnStaffListPageEnabled() {
    return RequestHelper.send(
        serverAddress +
        "/staff/isDisplayStaffToSubjectLinkingOnStaffListPageEnabled",
        undefined,
        "GET",
        {}
    );
  }

  static unlinkStaffFromSubject(staffId) {
    if (staffId === undefined) {
      return false;
    }

    return RequestHelper.send(
        serverAddress +
        "/staff/unlinkStaffFromSubject",
        {},
        "GET",
        { staffId: staffId }
    );
  }

  static listAndCreateOutstandingMandatoryPros() {
    return RequestHelper.send(
        serverAddress +
        "/staff/listAndCreateOutstandingMandatoryPros",
        {},
        "GET",
        {}
    );
  }

  static async redirectIfStaffHasOutstandingMandatoryPros(username, password) {
    //start check for mandatory PROs to complete
    let profile = await AuthService.getMyProfile();

    //staff account linked to a subject account?
    const linkedSubjectId = profile.linkedSubjectId;
    //if linked to a subject are there outstanding mandatory PROs
    if (linkedSubjectId) {
      let { outstandingQuestionnaires } = await StaffService.listAndCreateOutstandingMandatoryPros();
      console.log('Number of outstanding mandatory questionnaires:' + outstandingQuestionnaires.length);
      if (outstandingQuestionnaires.length > 0) {
        //we have outstanding mandatory questionnaires, inform the staff member in the modal
        //and provide a button for them to login as the subject and complete the mandatory training

        //this method first needs to log the user in as the staff member before switching to the participant
        await AuthService.login(username, password, AuthService.getAccountType());
        await AuthService.switchToSubjectAccount(linkedSubjectId);
      }
    } else {
      console.log('No linked subject account detected');
    }
  }
}
