import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isExportingQuestionnaireAsPdf: false
};

const globalValuesSlice = createSlice({
  name: 'globalValues',
  initialState,
  reducers: {
    setIsExportingQuestionnaireAsPdf: (state, action) => {
      const { payload } = action;
      state.isExportingQuestionnaireAsPdf = payload.value;
    }
  }
});

const getIsExportingQuestionnaireAsPdf = (store) => store.globalValues.isExportingQuestionnaireAsPdf;
export { getIsExportingQuestionnaireAsPdf };

const { setIsExportingQuestionnaireAsPdf } = globalValuesSlice.actions;
export { setIsExportingQuestionnaireAsPdf };

export default globalValuesSlice;
