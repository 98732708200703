import React, {useEffect, useState} from "react";
import {Button, Form, Grid, Header } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import {useLocation, useHistory} from "react-router-dom";
import {
  getContentDefinitions,
  getProDefinitions
} from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import {connect} from "react-redux";
import AuthService from "../../services/AuthService";
import { typeHelper } from "atom5-branching-questionnaire";
import encodedQuestionnaireListHelper from "./helpers/encodedQuestionnaireListHelper";

const QuestionnaireEnd = (props) => {
  const { t, definition, questionnaire, onFinish, onLink, linkingInfo, proDefinitions, contentDefinitions } = props;
  const {search} = useLocation();
  const history = useHistory();
  const regexForNextQuestionnaires = /\?nextQuestionnaires=\[(.*)]/gm
  const [nextQuestionnaireData, setNextQuestionnaireData] = useState(null);
  const [followingQuestionnaires, setFollowingQuestionnaires] = useState(null)

  const [questionnaireEndMessage, setQuestionnaireEndMessage] = useState();

  const initialize = async () => {
    let resolvedDefinition = definition; //
    if (!resolvedDefinition) { resolvedDefinition = lookupDefinition(proDefinitions); }
    if (!resolvedDefinition) { resolvedDefinition = lookupDefinition(contentDefinitions); }

    if (AuthService.isSubject() && linkingInfo?.isLinkValid) {
      const bypassEndScreenAndGoToQuestionnaire = typeHelper.parseBool(resolvedDefinition.config?.bypassEndScreenIfToIsValid);
      if (bypassEndScreenAndGoToQuestionnaire) {
        onLink(linkingInfo.questionnaire, linkingInfo.definition);
      }
    }

    const handleNextQuestionnaireRouting = async () => {
      const matches = [...search.matchAll(regexForNextQuestionnaires)]
      const hasFoundQuestionnaireRouting = matches.length > 0 && matches[0].length > 1 && matches[0][1].length > 0;

      if(!hasFoundQuestionnaireRouting) return;

      const encodedQuestionnairesToFollow = matches[0][1].split(",");
      const filteredEncodedQuestionnairesToFollow =
          await encodedQuestionnaireListHelper.getEncodedListWithMultiQuestionnairePresenceConsidered(encodedQuestionnairesToFollow,definition,questionnaire);

      setFollowingQuestionnaires(filteredEncodedQuestionnairesToFollow)
      filteredEncodedQuestionnairesToFollow.forEach(qD=>console.log(atob(qD)))

      if(filteredEncodedQuestionnairesToFollow.length === 0) return;
      setNextQuestionnaireData(atob(filteredEncodedQuestionnairesToFollow.shift()).split(","));
    }

    await handleNextQuestionnaireRouting()

    setQuestionnaireEndMessage(
        t(
            "QUESTIONNAIRE_COMPLETE_MESSAGE_" + resolvedDefinition?.code.toUpperCase(), //custom message if available
            t("QUESTIONNIARE_COMPLETE", "Questionnaire Completed")              //default message if not
        )
    );
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lookupDefinition = (type) => {
    return type.find((def) => def.id === questionnaire.definitionId);
  }

  let nextButtonText = "";
  if(nextQuestionnaireData && followingQuestionnaires){
    const [nextButtonStart, nextButtonEnd] = t("GLOBAL_NEXT_BUTTON").split('{0}');
    nextButtonText = [nextButtonStart,followingQuestionnaires.length + 1,nextButtonEnd].join("")
  }

  const navigateNext = () => {
    const newRoute = `/app/subject/${
        nextQuestionnaireData[0]
    }/questionnaire-type/${nextQuestionnaireData[1]}/${
        nextQuestionnaireData[2]
    }/submit/${nextQuestionnaireData[3]}?nextQuestionnaires=[${followingQuestionnaires}]`

    history.replace(newRoute)
  }

 return (
    <Form style={styles.container} onSubmit={onFinish}>
      <Grid centered padded style={{ maxWidth: "600px" }}>
        <Grid.Row>
          <Header as="h3" textAlign="center">
            {questionnaireEndMessage}
          </Header>
        </Grid.Row>
        {linkingInfo?.isLinkValid && (
          <Grid.Row>
            <Button
              primary
              fluid
              size="large"
              onClick={() => {
                onLink(linkingInfo.questionnaire, linkingInfo.definition);
              }}
            >
              {linkingInfo.definition.label}
            </Button>
          </Grid.Row>
        )}
        {nextQuestionnaireData && (
          <Grid.Row>
            <Button
              primary
              fluid
              size="large"
              onClick={navigateNext}
            >
              {nextButtonText}
            </Button>
          </Grid.Row>
        )}

        <Grid.Row>
          <Button type="submit" primary fluid size="large">
            {t("GLOBAL_BUTTON_BACK")}
          </Button>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};

const mapStateToProps = (state) => {
  return {
    proDefinitions: getProDefinitions(state),
    contentDefinitions: getContentDefinitions(state)
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(QuestionnaireEnd);
