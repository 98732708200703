import SubjectService from "../SubjectService";
import { setDefinitions } from "../redux/questionnaires/questionnaireDefinitionsSlice";
import RequestHelper from "../RequestHelper";
import {
  getFullState as getFullState_CurrentQuestionanire,
  getIntialState as getInitialState_CurrentQuestionanire,
  setCachedState as setCachedState_CurrentQuestionanire,
  updateLastCacheTime as updateLastCacheTime_CurrentQuestionnaire,
} from "../redux/questionnaires/currentQuestionnaireSlice";
import store from '../redux/store'
import {DateTime} from "luxon";
import ConfigService from "./ConfigService";
import {serverAddress} from "./config/EnvConfig";

export const frontendCacheKeys = {
  "CURRENT_QUESTIONNAIRE": "CURRENT_QUESTIONNAIRE"
}
const frontendCacheKeysArray = [frontendCacheKeys.CURRENT_QUESTIONNAIRE]

const AppStateService = {
  getQuestionnaireDefinitionsFromServer: async (dispatch) => {
    const definitions = await Promise.all([
      SubjectService.getQuestionnaireDefinitions("PRO"),
      SubjectService.getQuestionnaireDefinitions("CONTENT"),
      SubjectService.getQuestionnaireDefinitions("EVENT"),
      SubjectService.getQuestionnaireDefinitions("DATA"),
    ]);

    dispatch(
      setDefinitions({
        proDefinitions: definitions[0],
        contentDefinitions: definitions[1],
        eventDefinitions: definitions[2],
        dataDefinitions: definitions[3],
      })
    );
  },
  frontendCacheHistory: {"_comment": "Values here are stored as strings for comparison"},
  fetchLocalCachedState: async (dispatch) => {
    if(!ConfigService.isUsingFrontendStateCache()){
      return
    }

    const response = await RequestHelper.send(
        serverAddress + "/frontend-cache",
        {},
        "GET",
        null,
    );
    if(Array.isArray(response)){
      response.forEach(item => {
        try {
          const value = JSON.parse(item.value)
          dispatch(AppStateService.geSetLocalStateForNamespace(item.namespace)(value));
          AppStateService.frontendCacheHistory[item.namespace] = item.value;
        } catch (e) {
          console.error("[AppStateService] Error parsing string into state object")
        }
      })
    }
  },
  updateFrontendCacheOnBackend: async (dispatch, namespace, value) => {
    // if we dont pre-update the last cache time then it will lead to two updates for every change
    // however comparisons should be performed without this
    const newCacheTime = DateTime.now().toString();
    const valueWithNewLatestTime = {...value, lastCacheTime: newCacheTime}

    // If it is just the initial state dont bother caching it.
    if(JSON.stringify(value) === JSON.stringify(AppStateService.geInitialStateForNamespace(namespace))){
      return;
    }

    if(AppStateService.frontendCacheHistory[namespace] !== JSON.stringify(value)){
      const response = await RequestHelper.send(
          serverAddress + "/frontend-cache/namespace/" + namespace,
          {},
          "POST",
          null,
          {value: valueWithNewLatestTime}
      );

      if(response){
        dispatch(AppStateService.getSetLastCacheTimeForNamespace(namespace)({lastCacheTime: newCacheTime}));
        AppStateService.frontendCacheHistory[namespace] = JSON.stringify(valueWithNewLatestTime);
      }
    }
  },
  updateFrontendCacheOnBackendFromRedux: async (namespace) => {
    const value = AppStateService.getLocalStateForNamespace(namespace)
    const response = await RequestHelper.send(
        serverAddress + "/frontend-cache/namespace/" + namespace,
        {},
        "POST",
        null,
        {value: value}
    );

    if(response){
      AppStateService.frontendCacheHistory[namespace] = JSON.stringify(value);
    }
  },
  clearFrontendCache: async (namespace) => {
    const response = await RequestHelper.send(
        serverAddress + "/frontend-cache/namespace/" + namespace,
        {},
        "POST",
        null,
        {value: null}
    );

    if(response){
      AppStateService.frontendCacheHistory[namespace] = JSON.stringify(null);
    }
  },
  geSetLocalStateForNamespace: (namespace) => {
    switch (namespace){
      case "CURRENT_QUESTIONNAIRE":
        return setCachedState_CurrentQuestionanire;
      default:
        console.warn("[AppStateService] Attempting to set local state with unknown namespace: "+namespace)
        return ()=>{}
    }
  },
  getSetLastCacheTimeForNamespace: (namespace) => {
    switch (namespace){
      case "CURRENT_QUESTIONNAIRE":
        return updateLastCacheTime_CurrentQuestionnaire;
      default:
        console.warn("[AppStateService] Attempting to set local state with unknown namespace: "+namespace)
        return ()=>{}
    }
  },
  geInitialStateForNamespace: (namespace) => {
    switch (namespace){
      case "CURRENT_QUESTIONNAIRE":
        return getInitialState_CurrentQuestionanire();
      default:
        console.warn("[AppStateService] Attempting to set local state with unknown namespace: "+namespace)
        return ()=>{}
    }
  },
  getLocalStateForNamespace: (namespace) => {
    switch (namespace){
      case "CURRENT_QUESTIONNAIRE":
        return getFullState_CurrentQuestionanire(store.getState());
      default:
        console.warn("[AppStateService] Attempting to set local state with unknown namespace: "+namespace)
        return ()=>{}
    }
  },
  pollForLocalStateChanges: (dispatch) => {
    if(!ConfigService.isUsingFrontendStateCache()){
      return
    }

    setInterval(()=>{
      frontendCacheKeysArray.forEach(key=>{
        AppStateService.updateFrontendCacheOnBackend(dispatch, key, AppStateService.getLocalStateForNamespace(key))
      })
    }, 10000);
  }
};

export default AppStateService;
