import React, {useState, useContext, useEffect} from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import Page from "../../components/page/Page";
import { getDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import {Redirect, useHistory, useParams} from "react-router-dom";
import ConfigContext from "../../context/ConfigContext";
import Questionnaire from "../../questionnaires/Questionnaire";
import SubjectQuestionnaireService from "../../services/SubjectQuestionnaireService";
import QuestionnaireEnd from "../../components/questionnaire/QuestionnaireEnd";
import API_QUESTIONNAIRE_DEFINITION_TYPES from "../../constants/API_QUESTIONNAIRE_DEFINITION_TYPES";
import QuestionnaireContext from "../../context/QuestionnaireContext";
import SubjectService from "../../SubjectService";
import {Button, Header, Icon, Loader, Message} from "semantic-ui-react";
import { cloneDeep } from "lodash";
import UserContext from "../../context/UserContext";
import DashboardContext from "../../context/DashboardContext";
import DataSubmissionStatusModal from "../../components/questionnaire/DataSubmissionStatusModal";
import ParentQuestionnaireDisplay from "../../questionnaires/ParentQuestionnaireDisplay";
import VideoDisplay from "../../components/questionnaire/VideoDisplay";
import GroupPermission from "../../GroupPermission";
import questionnaireWorkflowHelper from "../../helpers/questionnaireWorkflowHelper";
import MultiQuestionnaire from "../../questionnaires/MultiQuestionnaire";
import useMultiQuestionnaireData from "../../questionnaires/hooks/useMultiQuestionnaireData";
import {DateTime} from "luxon";
import canStaffViewQuestionnaireAtTask from "./utility/canStaffViewQuestionnaireAtTask";


const ERROR_TYPE = {
  UNKNOWN: {
    title: "GLOBAL_ERROR_TITLE",
    message: "GLOBAL_ERROR_GENERIC_MESSAGE",
  },
  PERMISSION: {
    title: "GLOBAL_ERROR_TITLE",
    message: "PERMISSION",
    messageFallback: "You do not have permissions for this",
  },
};

const SubjectSubmitQuestionnairePage = (props) => {
  const { allDefinitions, t } = props;

  const history = useHistory();
  const { subjectId, questionnaireType, questionnaireId, definitionCode } =
    useParams();
  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const returnTo = useContext(QuestionnaireContext)?.returnTo;

  let [isCurrentQuestionnaireCompleted, setIsCurrentQuestionnaireCompleted] =
    useState(false);

  let [questionnaire, setQuestionnaire] = useState(null);
  let [definition, setDefinition] = useState(null);
  let [subjectCode, setSubjectCode] = useState(null);
  let [subjectGroups, setSubjectGroups] = useState(null);
  let [linkingInfo, setLinkingInfo] = useState(null);
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(null);
  let [renderTimings, setRenderTimings] = useState(null);

  let [shouldUseAltDisplay, setShouldUseAltDisplay] = useState(false);
  const dashboardContext = useContext(DashboardContext);

  const [isMultipart, multipartQuestionnaires, multipartDefinitions, hasMultiquestionnaireLoaded, multipartDataForCompletion] =
      useMultiQuestionnaireData(definition, questionnaire, allDefinitions);

  const handlePageChange = () => {
    dashboardContext.scrollToTop();
  };

  let additionalLabel;
  if(definition?.config?.encodeQuestionnaireId) {
    additionalLabel = btoa(questionnaire.id);
  }

  const [dataUploadProgress, setDataUploadProgress] = useState(null);
  const handleDataUploadProgress = (dataUploadProgress) => {
    setDataUploadProgress({...dataUploadProgress});
  };
  const renderTime = {
    timing: {
      questionnaireTiming: {
        render: undefined,
        end: undefined,
      },
    },
  };

  const initialize = async () => {
    if (!loading) {
      await setLoading(true);
    }

    if (isCurrentQuestionnaireCompleted) {
      setIsCurrentQuestionnaireCompleted(false);
    }

    const permissions = await SubjectService.getSubjectPermission(subjectId);
    const hasViewSubjectPermission = permissions.includes(GroupPermission.VIEW_SUBJECT);

    const subjectGroups = await SubjectService.getSubjectGroups(subjectId);
    setSubjectGroups(subjectGroups);
    if(hasViewSubjectPermission){
      const subjectData = await SubjectService.getSubjectData(subjectId);
      setSubjectCode(subjectData.subjectCode)
    }

    if (questionnaireId === "latest") {
      const latest = await SubjectService.getSubjectQuestionnairesActiveNotCompletedOnly(
            subjectId,
            definitionCode,
            undefined, undefined,
            1
          );

      if (latest.result.length > 0) {
        const questionnaire = latest.result[0];
        history.replace(
          `/app/subject/${subjectId}/questionnaire-type/${questionnaireType}/${definitionCode}/submit/${questionnaire.id}`
        );
      } else {
        history.push(
          `/app/subject/${subjectId}/tabs/${questionnaireType}/${definitionCode}`
        );
      }
      return;
    }

    const questionnaire = await SubjectService.getSubjectQuestionnaireByCodeAndId(
            subjectId,
            definitionCode,
            questionnaireId
          );

    // TODO: refactor when activation window is in the task
    const isComplete = questionnaire.complete === true;
    const isActive = DateTime.fromISO(questionnaire.activationFromDate) < DateTime.now() && DateTime.fromISO(questionnaire.activationToDate) > DateTime.now();
    if (!questionnaireWorkflowHelper.hasQuestionnaireWorkflow(questionnaire) && (isComplete || !isActive)) {
       history.replace(
          `/app/subject/${subjectId}/questionnaire-type/${questionnaireType}/${definitionCode}/view/${questionnaireId}`
        );
    }

    if(questionnaireWorkflowHelper.hasQuestionnaireWorkflow(questionnaire)){
      let taskBehaviour = questionnaireWorkflowHelper.getTaskFromQuestionnaire(questionnaire).type;
      if(taskBehaviour !== "USER"){
        history.replace(
            `/app/subject/${subjectId}/questionnaire-type/${questionnaireType}/${definitionCode}/view/${questionnaireId}`
        );
      }
    }

    const definition = cloneDeep(
      allDefinitions.find((d) => {
        if (questionnaire) {
          return d.id === questionnaire.definitionId;
        }
        return d.id === parseInt(questionnaireId);
      })
    );

    if (!definition || !definition.questions) {
      setError(ERROR_TYPE.UNKNOWN);
      return;
    }


    if (
      !SubjectQuestionnaireService.isQuestionnaireSubmittableByStaff(
        config,
        permissions,
        definition
      )
    ) {
      setError(ERROR_TYPE.PERMISSION);
      console.log(
        "[SubjectQuestionnaireTable][initialize] Questionnaire Submission not allowed"
      );
    }

    if (definition.config?.modules) {
      const tabsConfig = config.ui?.tabs ? config.ui?.tabs : [];
      const staffProfile = user.profile;

      const isModuleSubmissionAllowed =
        await SubjectQuestionnaireService.canStaffViewQuestionnaireModule(
          definition,
          subjectGroups,
          tabsConfig,
          staffProfile
        );

      if (!isModuleSubmissionAllowed) {
        setError(ERROR_TYPE.PERMISSION);
        console.log(
          "[SubjectQuestionnaireTable][initialize] Module Submission is Not Allowed"
        );
        return;
      }
    }

    const linkingInfo = await getlinkingInfo(definition, subjectGroups);
    setLinkingInfo(linkingInfo);

    const answers = {};
    definition.questions.forEach((question, index) => {
      let questionKey = definition.code + "_" + question.code;
      answers[question.code] = questionnaire[questionKey];

      if (
        questionnaire.customLabels &&
        questionnaire.customLabels.length !== 0
      ) {
        let customLabel = questionnaire.customLabels.find((label) => {
          return label.code === question.code;
        });

        if (customLabel) {
          definition.questions[index].label = customLabel.label;
        }
      }
    });

    //record rendering time here, this will be sent in the payload
    renderTime.timing.questionnaireTiming.render = new Date().toISOString();
    setRenderTimings(renderTime);

    // The questionnaire needs to be set before the definition in changed.
    await setQuestionnaire({ ...questionnaire, answers });
    setDefinition(definition);
    setLoading(false);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId, questionnaireId, definitionCode]);

  const submitQuestionnaire = async (e) => {
    // This will sometimes return true if the calls fails so
    // no way to show user it has failed.
    const response = await SubjectQuestionnaireService.submitQuestionnaire(
      definition,
      questionnaire,
      e,
      subjectId,
      handleDataUploadProgress,
      renderTimings
    );

    return response
  };

  const handleSubmission = async (answers) => {
    dashboardContext.scrollToTop();
    const responses = [];

    // TODO: Once confident we can use multiQuestionnaire exclusively
    // for now we use the default submission behaviour
    if(!isMultipart) {
      responses.push(await submitQuestionnaire(answers));
    } else {
      const fullQuestionnaires = [questionnaire, ...multipartQuestionnaires]
      const fullDefinitions = [definition, ...multipartDefinitions]


      for(let [i, def] of fullDefinitions.entries()) {
        const questionnaireAnswers = {};
        if (!answers[def.code]) continue;
        // populate answers from multiquestionnaire answers, converting definitionCode_questionCode => questionCode
        Object.entries(answers[def.code]).forEach((entry)=>questionnaireAnswers[entry[0].replace(def.code+"_", "")] = entry[1])
        const response = await SubjectQuestionnaireService.submitQuestionnaire(
            def,
            fullQuestionnaires[i],
            questionnaireAnswers,
            subjectId,
            handleDataUploadProgress,
            renderTimings
        );
        responses.push(response)
      }
    }

    // While these can check for submission, it does not mean that the questionnaire or related data has
    // been updated in the database due to backend message queue
    if (responses.every(res=>res.ok)) {
      setIsCurrentQuestionnaireCompleted(true);
    } else {
      setError(ERROR_TYPE.UNKNOWN);
    }
  };

  const goBackToList = () => {
    if (returnTo) {
      history.push(returnTo);
      return;
    }
    history.goBack();
  };

  const goToNext = (questionnaire, definition) => {
    setIsCurrentQuestionnaireCompleted(false);
    let newQuestionnaireId = questionnaire ? questionnaire.id : "latest";

    history.push(
      `/app/subject/${subjectId}/questionnaire-type/${questionnaireType}/${definition.code}/submit/${newQuestionnaireId}`
    );
  };

  const getlinkingInfo = async (questionnaireDefinition, subjectGroups) => {
    if (questionnaireDefinition?.config?.to) {
      let toArray;
      if (!Array.isArray(questionnaireDefinition?.config?.to)) {
        toArray = [questionnaireDefinition?.config?.to];
      } else {
        toArray = questionnaireDefinition?.config?.to;
      }

      for (let i = 0; i < toArray.length; i++) {
        const to = toArray[i];
        let [type, code] = to.split("://");

        // At this time staff cannot be linked to event or content type
        // questionnaires
        if (type === "event" || type === "content") {
          console.log(
            "[SubjectSubmitQuestionnairePage][linking] EVENT and CONTENT questionnaires cannot be linked to on web currently"
          );
          continue;
        }

        try {
          let foundDefinition = allDefinitions.find((def) => {
            return code === def.code && type.toUpperCase() === def.type;
          });

          const tabsConfig = config.ui.tabs ? config.ui.tabs : [];
          const staffProfile = user.profile;

          const isModuleSubmissionAllowed =
            await SubjectQuestionnaireService.canStaffViewQuestionnaireModule(
              foundDefinition,
              subjectGroups,
              tabsConfig,
              staffProfile
            );

          if (!isModuleSubmissionAllowed) {
            continue;
          }

          const activeNotCompleted =
            await SubjectService.getSubjectQuestionnairesActiveNotCompletedOnly(
              subjectId,
              foundDefinition.code,
              undefined, undefined,
              1
            );

          if (activeNotCompleted && activeNotCompleted.result && activeNotCompleted.result.length > 0) {
            return {
              isLinkValid: true,
              questionnaire: activeNotCompleted.result[0],
              definition: foundDefinition,
            };
          }
        } catch {
          console.error(
            "[SubjectQuestionnaireTable] Linking questionnaire not found"
          );
          return { isLinkValid: false, questionnaire: null, definition: null };
        }
      }
      return { isLinkValid: false, questionnaire: null, definition: null };
    } else {
      return { isLinkValid: false, questionnaire: null, definition: null };
    }
  };

  const buildErrorDisplay = (titleKey, messageKey, messageFallback) => {
    return (
      <Message error textAlign="left">
        <Message.Header>{t(titleKey)}</Message.Header>
        <Message.Content>{t(messageKey, messageFallback)}</Message.Content>
      </Message>
    );
  };

  const getErrorDisplay = () => {
    if (error) {
      return buildErrorDisplay(
        error.title,
        error.message,
        error.messageFallback
      );
    }
    return null;
  };

  if (!questionnaire) {
    if (loading) {
      return getErrorDisplay();
    } else {
      return <Redirect to="/app/home" />;
    }
  }

  if (!definition) {
    if (loading) {
      return <Loader active />;
    } else {
      return <Redirect to="/app/home" />;
    }
  }

  let isWorkflowControlled = questionnaire.questionnaireWorkflowInstance !== null;
  let isQuestionnaireViewableAtTaskForUser = canStaffViewQuestionnaireAtTask(user, questionnaire, subjectGroups);

  if (
    questionnaire?.completionDate !== null &&
      !isWorkflowControlled &&
      definition.type !== API_QUESTIONNAIRE_DEFINITION_TYPES.CONTENT
  ) {
    return <Redirect to="/app/home" />;
  }

  if(isWorkflowControlled && !isQuestionnaireViewableAtTaskForUser){
    return <Redirect to="/app/home" />;
  }

  const isMultipartLoadedIfRequired = !isMultipart || (isMultipart && hasMultiquestionnaireLoaded);
  if (loading || !isMultipartLoadedIfRequired) {
    return <Loader active />;
  }

  return (
    <Page
      key={questionnaireId}
      name="QUESTIONNAIRE_PAGE"
      header={() => (
        <Header as="h3">
          <Button
            color="orange"
            style={{ padding: "0.25rem 1rem " }}
            onClick={() => history.goBack()}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Icon name="arrow left" />
              {!subjectCode ? <h3 >{"GLOBAL_BACK"}</h3> : <h3 style={{margin: "0rem", marginLeft: "0.5rem"}}>
                {subjectCode}
              </h3>}
            </div>
          </Button>
          {" " + t("SUBMIT_SUBJECT_QUESTIONNAIRE_HEADER")}
        </Header>
      )}
    >
      {error && getErrorDisplay()}
      {definition && !isCurrentQuestionnaireCompleted && !error && (
        <div
          style={{
            display: "flex",
            flexDirection: shouldUseAltDisplay ? "column-reverse" : "row",
            marginTop: "2rem"
          }}
        >
          <div style={{ flexGrow: 1, minWidth: "800px", paddingRight: "2rem" }}>
            {additionalLabel && additionalLabel.length > 0 && <label style={{fontWeight: "bold", color: "#9D9FA2"}}>{additionalLabel}</label>}
            {!isMultipart && <Questionnaire
                key={definitionCode}
                definition={definition}
                questionnaire={questionnaire}
                onPageChange={handlePageChange}
                onSubmit={handleSubmission}
                subjectId={subjectId}
            />}
            {isMultipart && <MultiQuestionnaire
                questionnaires={multipartDataForCompletion.questionnaires}
                definitions={multipartDataForCompletion.definitions}
                onSubmit={handleSubmission}
                onPageChange={handlePageChange}
                shouldGroupAnswersByQuestionnaire={true}
                subjectId={subjectId}
            />}
          </div>
          <div
            style={{
              paddingBottom: "2rem",
            }}
          >
            <VideoDisplay definition={definition} questionnaire={questionnaire} />
            <ParentQuestionnaireDisplay
              questionnaire={questionnaire}
              subjectId={subjectId}
              allDefinitions={allDefinitions}
              shouldUseAltDisplay={shouldUseAltDisplay}
              setShouldUseAltDisplay={setShouldUseAltDisplay}
              shouldShow={!isCurrentQuestionnaireCompleted}
            />
          </div>
        </div>
      )}

      {isCurrentQuestionnaireCompleted && !error && (
        <QuestionnaireEnd
          questionnaire={questionnaire}
          definition={definition}
          onFinish={goBackToList}
          onLink={goToNext}
          linkingInfo={linkingInfo}
        />
      )}
      <DataSubmissionStatusModal dataUploadProgress={dataUploadProgress} />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    allDefinitions: getDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(SubjectSubmitQuestionnairePage);
