import {useEffect, useRef, useState} from "react";
import {FPSCalculator} from "../FPSCalculator";

const useCalculatedFPS = (video) => {
  const poll = useRef(null)
  const [fps, setFPS] = useState(null);
  const fpsCalculator = useRef(null);
  const [currentTime, setCurrentTime] = useState(null);

  useEffect(()=>{
    poll.current = setInterval(()=>{
      setFPS(fpsCalculator.current.fps)
      setCurrentTime(fpsCalculator.current.currentTime)
    }, 300);

    return () => {
      clearInterval(poll.current);
    }
  },[])

  useEffect(()=>{
    if(video){
      fpsCalculator.current = new FPSCalculator(video);
    }
  }, [video]);

  return {fps, currentTime};
}

export default useCalculatedFPS;
