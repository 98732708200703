import {QUESTION_TYPES, typeHelper} from "atom5-branching-questionnaire";
import QuestionHelper from "../../helpers/QuestionHelper";
import ScoringService from "../../services/ScoringService";
import {isNumber} from "lodash";
import {Button, Table} from "semantic-ui-react";
import DisplayQuestion from "../../questionnaires/display/DisplayQuestion";
import DateTimeService from "../../services/DateTimeService";
import ConfigService from "../../services/ConfigService";
import React from "react";
import shouldDisplayInDataTable from "./utility/shouldDisplayInDataTable";
import TriggerInfoDisplay from "./TriggerInfoDisplay";
import SubjectDataTableQuestionHeaderLabel from "./SubjectDataTableQuestionHeaderLabel";
import QuestionChart from "./graphs/chartcomponents/QuestionChart";
import {
    formatColumn,
    moduleColumnFieldOrExpression,
    moduleColumnKey,
    moduleHasColumnsForCompletedConfigured, parseColumnExpression
} from "../../services/helpers/moduleHelper";
import workflowHelper from "../../helpers/questionnaireWorkflowHelper";
import VisitDateDisplay from "../../questionnaires/display/VisitDateDisplay";

const SubjectDataTableCompletedDisplay = ({
  t,
  definition,
  questionnaires,
  history,
  permissions,
  subjectId,
  subjectData,
  isVisitDateEnabledForSubject,
  hasViewSubjectVisitDatesPermission,
  subjectVisitDates,
  hideAnswers,
  definitionCode,
  config,
  questionnaireType,
  questionnairesRequireSignoff,
  isGraphOpen,
  uiTabConfig
}) => {
  const questions = definition?.questions || [];
  const questionTotalsByValue = [];
  const questionTotalsByScore = [];

  let answersByQuestionnaire = {};

  let rowContents = [];
  let isNull = [];
  let isUsingTotal =
      !definition.config ||
      !definition.config.hideTotalScore;

  const showTotalScoreRow =
      !definition.config ||
      !definition.config.hideTotalScoreRow;

  const showTotalValueRow =
      !definition.config ||
      !definition.config.hideTotalValueRow;

  const showEncodedIdColumn = definition?.config?.encodeQuestionnaireId;

  const renderWebhooks = (webhooks) => {
        if(webhooks && webhooks.length > 0){
            const w = webhooks[0]
            return `${w.id} ${w.status.toLowerCase()} at ${DateTimeService.fromEpochMillis(w.lastUpdated*1000).toISOString()}`
        }else{
            return null;
        }
    }
  questionnaires.forEach((questionnaire, questionnaireIndex) => {
    let tableRow = [];
    let cellIndex = 0;

    // You cant filter by workflow task in filter as it affects column ordering
    questions
        .filter((q) => shouldDisplayInDataTable(null, q, hideAnswers, questionnaires, definitionCode))
        .forEach((question, index) => {
          let answer = questionnaire[`${definitionCode}_${question.code}`];

          if (question.type === QUESTION_TYPES.PARAGRAPH) {
            const renderAs = QuestionHelper.getConfigValue(
                question,
                "renderAs",
                "label"
            );
            if (renderAs === "label") {
              answer = question.label;
            }
          }

          // remove answer to keep track of isNull
          if(!shouldDisplayInDataTable(workflowHelper.getTaskFromQuestionnaire(questionnaire), question, hideAnswers, questionnaires, definitionCode)){
            answer = undefined;
          }

          // while Link stores an answer it should not be shown by default
          if(question.type === QUESTION_TYPES.LINK && !typeHelper.parseBool(question?.config?.showOnDashboard)){
            answer = undefined;
          }

          let questionScore = null;

          // Try the colour config for a calculation first
          if (question?.config.coloring && answer !== "") {
            const scoringInformation = ScoringService.getScoringInformation(
                answer,
                question?.config.coloring,
                questionnaire,
                definitionCode
            );
            if (scoringInformation && scoringInformation.score) {
              questionScore = scoringInformation.score;
            }
          }


          // No scoring information found via the color config
          if (questionScore === null) {
            if (question.type === QUESTION_TYPES.FIXED_VALUE) {
              const answers =
                  questionnaires[questionnaireIndex][`${definitionCode}_${question.code}`];
              let questionScoreSum = null;
              question.answers.forEach((answer) => {
                if (
                    typeof answers === "object" &&
                    answers.includes(answer.code)
                ) {
                  if (
                      "score" in answer &&
                      answer.score !== undefined &&
                      !isNaN(answer.score)
                  ) {
                    questionScoreSum =
                        (questionScoreSum !== null ? questionScoreSum : 0) +
                        answer.score;
                  }
                }
              });

              if (questionScoreSum !== null) {
                questionScore = ScoringService.calculateAnswerScore(
                    question,
                    questionScoreSum
                );
              }
            }
          }

          // Question Total - Score
          if (questionScore !== null) {
            const questionTotalScore =
                questionTotalsByScore[question.code] !== undefined
                    ? questionTotalsByScore[question.code]
                    : 0;
            questionTotalsByScore[question.code] =
                questionTotalScore + (!isNaN(questionScore) ? questionScore : 0);
          }

          // Question Total - Value
          if (isNumber(answer)) {
            questionTotalsByValue[question.code] =
                (questionTotalsByValue[question.code] !== undefined
                    ? questionTotalsByValue[question.code]
                    : 0) + answer;
          }

          if (
              answer === undefined &&
              isNull[index] !== false &&
              isNull[index] !== true
          ) {
            isNull[index] = true;
          } else if (answer !== undefined) {
            isNull[index] = false;
          }

          let backgroundColor = null;
          if (question?.config.coloring && answer !== "") {
            const scoringInformation = ScoringService.getScoringInformation(
                answer,
                question.config.coloring,
                questionnaire,
                definitionCode
            );
            if (scoringInformation) {
              backgroundColor = scoringInformation.color;
            }
          }
          tableRow.push(
              <Table.Cell style={{backgroundColor}} key={++cellIndex}>
                <DisplayQuestion
                    definition={definition}
                    question={question}
                    answer={answer}
                    showLabel={false}
                    permissions={permissions}
                    subjectId={subjectId}
                    containerType="table"
                />
              </Table.Cell>
          );
        });

    if (isUsingTotal) {
      questionTotalsByScore["totalScore"] =
          (questionTotalsByScore["totalScore"] !== undefined
              ? questionTotalsByScore["totalScore"]
              : 0) + questionnaire.totalScore;
      tableRow.unshift(
          <Table.Cell key={++cellIndex}>{questionnaire.totalScore}</Table.Cell>
      );
    }

      // start display individually configured columns if not module.columnsCompleted config
      if(moduleHasColumnsForCompletedConfigured(uiTabConfig)) {
          uiTabConfig.columnsCompleted.forEach(column => {

              if(column.field && column.field==='triggerActivityAudit'){
                  tableRow.unshift(
                      <Table.Cell key={`${moduleColumnKey(column)}_val_${questionnaire.id}`}>
                          <TriggerInfoDisplay t={t} questionnaire={questionnaire}/>
                      </Table.Cell>
                  )
              }else{
                  const rawValue = parseColumnExpression(column, {
                      q: questionnaire,
                      nullSafe: (val, nullValue) => val && val !== 'null' && val !== nullValue ? val : ''
                  })
                  tableRow.unshift(
                      <Table.Cell key={`${moduleColumnKey(column)}_val_${questionnaire.id}`}>
                          {formatColumn(column, rawValue)}
                      </Table.Cell>
                  )
              }

          })
      }else{
        if (ConfigService.shouldShowQuestionnaireCompletedByColumn()) {
            tableRow.unshift(
                <Table.Cell key={"completedBy"}>
                    {questionnaire.questionnaireAuthReference != null ? questionnaire.questionnaireAuthReference : ""}
                </Table.Cell>
            );
        }

        //Location Data
        if (ConfigService.shouldShowQuestionnaireLocationColumn()) {
            const location = (questionnaire.latitude == null || questionnaire.longitude == null)
                ? "-"
                : questionnaire.latitude + ', ' + questionnaire.longitude;

            tableRow.unshift(
                <Table.Cell key={"locationData"}>
                    {location}
                </Table.Cell>
            );
        }

          //Completed Date
          tableRow.unshift(
              <Table.Cell key={"completiondate"}>

                  <div className='tooltip'>
                      {DateTimeService.build.asDisplayDateTime(
                          questionnaire.completionDate
                      )}
                      {questionnaire.webhooks && questionnaire.webhooks.length > 0 &&
                      <span className='tooltiptext'>
                       {renderWebhooks(questionnaire.webhooks)}
                      </span>
                      }
                  </div>

              </Table.Cell>
          );

        if (ConfigService.shouldShowQuestionnaireDeliveredDateColumn()) {
            tableRow.unshift(
                <Table.Cell key={"deliveryDate"}>
                    {questionnaire.deliveryDate != null
                        ? DateTimeService.build.asDisplayDateTime(
                            questionnaire.deliveryDate
                        )
                        : t("SUBJECT_QUESTIONNAIRE_NOT_YET_RECEIVED")}
                </Table.Cell>
            );
        }

        if (showEncodedIdColumn) {
            tableRow.unshift(
                <Table.Cell key={"encodedId"}>
                    {btoa(questionnaire.id)}
                </Table.Cell>
            );
        }

        // Created Date
        if (ConfigService.shouldShowQuestionnairesCreatedDateColumn()) {
            tableRow.unshift(
                <Table.Cell key={"createddate"}>
                    {DateTimeService.build.asDisplayDateTime(
                        questionnaire.creationDate
                    )}
                </Table.Cell>
            );
        }

        if (ConfigService.shouldShowQuestionnaireIdColumn()) {
            tableRow.unshift(
                <Table.Cell key={"questionnaireId"}>
                    {questionnaire.id}
                </Table.Cell>
            );
        }

        if (ConfigService.shouldShowQuestionnairesTriggerColumn()) {
            // Trigger Info text
            tableRow.unshift(
                <Table.Cell key={"triggerInfo"}>
                    <TriggerInfoDisplay t={t} questionnaire={questionnaire}/>
                </Table.Cell>
            );
        }

        if (isVisitDateEnabledForSubject && hasViewSubjectVisitDatesPermission) {
            tableRow.unshift(
                <Table.Cell key={'visitDate'}>
                    <VisitDateDisplay
                        subjectData={subjectData}
                        subjectVisitDates={subjectVisitDates}
                        questionnaire={questionnaire}
                    />
                </Table.Cell>
            );
        }

    }
    // end display individually configured columns if not module.columnsCompleted config
    if (questionnairesRequireSignoff) {
      // Trigger Info text
      tableRow.unshift(
          <Table.Cell key={"requiresSignoff"}>
            <Button primary onClick={(e) => {
              e.stopPropagation()
              if (hideAnswers) {
                return;
              }
              history.push(
                  "/app/subject/" +
                  subjectId +
                  "/questionnaire-type/" +
                  questionnaireType +
                  "/" +
                  definitionCode +
                  "/sign-off/" +
                  questionnaire.id
              );
            }} >{t("SUBJECT_TAB_DATA_TABLE_REQUIRES_SIGN_OFF_BUTTON", "Sign Off")}</Button>
          </Table.Cell>
      );
    }

    rowContents.push(tableRow);
  });

  // if all answers in a column are null then remove
  if (
      (config && !config?.ui?.shouldDisplayNulls) ||
      !config
  ) {
    rowContents = rowContents.map((row) => {
      let offset = isUsingTotal ? 2 : 1;
      if(moduleHasColumnsForCompletedConfigured(uiTabConfig)) {
          offset = offset + uiTabConfig.columnsCompleted.length
      }else{
          if (ConfigService.shouldShowQuestionnaireCompletedByColumn()) {
              offset++;
          }
          if (ConfigService.shouldShowQuestionnaireDeliveredDateColumn()) {
              offset++;
          }
          if (ConfigService.shouldShowQuestionnaireLocationColumn()) {
              offset++;
          }
          if (ConfigService.shouldShowQuestionnairesCreatedDateColumn()) {
              offset++;
          }
          if (ConfigService.shouldShowQuestionnaireIdColumn()) {
              offset++;
          }
          if (ConfigService.shouldShowQuestionnairesTriggerColumn()) {
              offset++;
          }
      }
      if (questionnairesRequireSignoff) {
        offset++;
      }
      if (showEncodedIdColumn) {
        offset++;
      }
      let newrow = row.filter((col, index) => {
        if (index < offset) {
          return true;
        }
        return isNull[index - offset] !== true;
      });
      return newrow;
    });
  }

  questionnaires.forEach((questionnaire) => {
    answersByQuestionnaire[questionnaire.id] = [];
  });

  let headers = [];
  let footerRowTotalValue = [];
  let footerRowTotalScore = [];

  questions
      .filter((q) => shouldDisplayInDataTable(null, q, hideAnswers, questionnaires, definitionCode))
      .filter((question, index) => {
        if (
            (config && !config?.ui?.shouldDisplayNulls) ||
            !config
        ) {
          return !isNull[index];
        }
        return true;
      })
      .forEach((question) => {
        // Header
        headers = headers.concat(
            <Table.HeaderCell key={question.code}>
              <SubjectDataTableQuestionHeaderLabel question={question}/>
            </Table.HeaderCell>
        );

        // Footer
        if (showTotalValueRow) {
          const totalByValue =
              questionTotalsByValue[question.code] !== undefined
                  ? questionTotalsByValue[question.code]
                  : "";
          footerRowTotalValue = footerRowTotalValue.concat(
              <Table.HeaderCell key={question.code}>
                {totalByValue}
              </Table.HeaderCell>
          );
        }
        if (showTotalScoreRow) {
          const totalByScore =
              questionTotalsByScore[question.code] !== undefined
                  ? questionTotalsByScore[question.code]
                  : "";
          footerRowTotalScore = footerRowTotalScore.concat(
              <Table.HeaderCell key={question.code}>
                {totalByScore}
              </Table.HeaderCell>
          );
        }
      });

  if (isUsingTotal) {
    headers.unshift(
        <Table.HeaderCell key={"totalScore"}>
          {t("TOTAL_SCORE")}
        </Table.HeaderCell>
    );
    footerRowTotalValue.unshift(
        <Table.HeaderCell key={"totalValue"}></Table.HeaderCell>
    );
    const totalByScore =
        questionTotalsByScore["totalScore"] !== undefined
            ? questionTotalsByScore["totalScore"]
            : "";
    footerRowTotalScore.unshift(
        <Table.HeaderCell key={"totalScoreTotal"}>
          {totalByScore}
        </Table.HeaderCell>
    );
  }

    // start render individually configured headers if module doesnt have columns configured
    if(moduleHasColumnsForCompletedConfigured(uiTabConfig)) {
        uiTabConfig.columnsCompleted.forEach((column, i) => {
            headers.unshift(
                <Table.HeaderCell key={`${moduleColumnKey(column)}_header`}>
                    {column.labelKey ? t(column.labelKey, (column.label ? column.label : column.labelKey)) : (column.label ? column.label : moduleColumnFieldOrExpression(column))}
                </Table.HeaderCell>

            );

            // This is a quick fix
            // The first cell in the footers display a label
            // see SubjectDataTableWorkflowTaskDisplay for new wa of tracking columns
            if(i!==0){
              footerRowTotalValue.unshift(
                  <Table.HeaderCell key={`${moduleColumnKey(column)}_footer_total_value`}></Table.HeaderCell>
              );
              footerRowTotalScore.unshift(
                  <Table.HeaderCell key={`${moduleColumnKey(column)}_footer_total_score`}></Table.HeaderCell>
              );
            }
        })
    }else{
      if (ConfigService.shouldShowQuestionnaireDeliveredDateColumn()) {
          footerRowTotalValue.unshift(
              <Table.HeaderCell key={"completedBy"}></Table.HeaderCell>
          );
          footerRowTotalScore.unshift(
              <Table.HeaderCell key={"completedBy"}></Table.HeaderCell>
          );
      }

      if (ConfigService.shouldShowQuestionnaireCompletedByColumn()) {
          headers.unshift(
              <Table.HeaderCell key={"completedBy"}>
                  {t("SUBJECT_TAB_DATA_TABLE_COMPLETED_BY_HEADER", "Completed by")}
              </Table.HeaderCell>
          );
      }

      if (ConfigService.shouldShowQuestionnaireLocationColumn()) {
          headers.unshift(
              <Table.HeaderCell key={"locationData"}>
                  {t("SUBJECT_TAB_DATA_TABLE_LOCATION_HEADER", "Location")}
              </Table.HeaderCell>
          );
      }

      // Completion Date Header
      let completionDateHeaderText = t("SUBJECT_QUESTIONNAIRE_META_COMPLETED");
      if (
          completionDateHeaderText.charAt(completionDateHeaderText.length - 1) ===
          ":"
      ) {
          completionDateHeaderText = completionDateHeaderText.substr(
              0,
              completionDateHeaderText.length - 1
          );
      }

      headers.unshift(
          <Table.HeaderCell key={"completiondate"}>
              {completionDateHeaderText}
          </Table.HeaderCell>
      );

      if (ConfigService.shouldShowQuestionnaireDeliveredDateColumn()) {
          headers.unshift(
              <Table.HeaderCell key={"deliveryDate"}>
                  {t("SUBJECT_TAB_DATA_TABLE_DELIVERED_DATE_HEADER", "Delivered")}
              </Table.HeaderCell>
          );
      }

      if (showEncodedIdColumn) {
          headers.unshift(
              <Table.HeaderCell key={"encodedid"}>
                  {t("SUBJECT_TAB_DATA_TABLE_ENCODED_ID_HEADER")}
              </Table.HeaderCell>
          );
          footerRowTotalScore.unshift(<Table.HeaderCell key={"encodedid-footerscore"}></Table.HeaderCell>)
          footerRowTotalValue.unshift(<Table.HeaderCell key={"encodedid-footervalue"}></Table.HeaderCell>)
      }

      // Created Date Header
      if (ConfigService.shouldShowQuestionnairesCreatedDateColumn()) {
          headers.unshift(
              <Table.HeaderCell key={"createddate"}>
                  {t("SUBJECT_TAB_DATA_TABLE_CREATION_DATE_HEADER")}
              </Table.HeaderCell>
          );
      }

      if (ConfigService.shouldShowQuestionnaireIdColumn()) {
          headers.unshift(
              <Table.HeaderCell key={"questionnaireId"}>
                  {t("SUBJECT_TAB_DATA_TABLE_QUESTIONNAIRE_ID_HEADER", "Questionnaire ID")}
              </Table.HeaderCell>
          );
      }

      //Trigger Info Text Header
      if (ConfigService.shouldShowQuestionnairesTriggerColumn()) {
          let triggerInfoHeaderText = t("SUBJECT_TAB_DATA_TABLE_TRIGGER_CREATION_SOURCE", "Trigger");
          headers.unshift(
              <Table.HeaderCell key={"triggerInfo"}>
                  {triggerInfoHeaderText}
              </Table.HeaderCell>
          );
      }

        //Trigger Info Text Footer
        if (ConfigService.shouldShowQuestionnairesTriggerColumn()) {
            footerRowTotalValue.unshift(
                <Table.HeaderCell key={"triggerInfo"}></Table.HeaderCell>
            );
            footerRowTotalScore.unshift(
                <Table.HeaderCell key={"triggerInfo"}></Table.HeaderCell>
            );
        }

        if (isVisitDateEnabledForSubject && hasViewSubjectVisitDatesPermission) {
            headers.unshift(
                <Table.HeaderCell key={"header_visitDate"}>
                    {t('VISITDATE_LABEL', 'Visit')}
                </Table.HeaderCell>);
            footerRowTotalValue.unshift(
                <Table.HeaderCell key={"footer_visitDate_totalValue"}></Table.HeaderCell>
            );
            footerRowTotalScore.unshift(
                <Table.HeaderCell key={"footer_visitDate_totalScore"}></Table.HeaderCell>
            );
        }

        if (ConfigService.shouldShowQuestionnaireDeliveredDateColumn()) {
            footerRowTotalValue.unshift(
                <Table.HeaderCell key={"deliveryDate"}></Table.HeaderCell>
            );
            footerRowTotalScore.unshift(
                <Table.HeaderCell key={"deliveryDate"}></Table.HeaderCell>
            );
        }

        if (ConfigService.shouldShowQuestionnaireLocationColumn()) {
            footerRowTotalValue.unshift(
                <Table.HeaderCell key={"locationData"}></Table.HeaderCell>
            );
            footerRowTotalScore.unshift(
                <Table.HeaderCell key={"locationData"}></Table.HeaderCell>
            );
        }

        // Created Date Footer
        if (ConfigService.shouldShowQuestionnairesCreatedDateColumn()) {
            footerRowTotalValue.unshift(
                <Table.HeaderCell key={"createddate"}></Table.HeaderCell>
            );
            footerRowTotalScore.unshift(
                <Table.HeaderCell key={"createddate"}></Table.HeaderCell>
            );
        }

        if (ConfigService.shouldShowQuestionnaireIdColumn()) {
            footerRowTotalValue.unshift(
                <Table.HeaderCell key={"questionnaireId"}></Table.HeaderCell>
            );
            footerRowTotalScore.unshift(
                <Table.HeaderCell key={"questionnaireId"}></Table.HeaderCell>
            );
        }

    }
  // end render individually configured headers if module doesnt have columns configured
  //Trigger Info Text Header
  if (questionnairesRequireSignoff) {
    headers.unshift(
        <Table.HeaderCell key={"header-sign-off"}>
          {t("SUBJECT_TAB_DATA_TABLE_REQUIRES_SIGN_OFF_HEADER", "Sign Off")}
        </Table.HeaderCell>
    );
  }


  //Trigger Info Text Footer
  if (questionnairesRequireSignoff) {
    footerRowTotalValue.unshift(
        <Table.HeaderCell key={"footer-value-signo-off"}></Table.HeaderCell>
    );
    footerRowTotalScore.unshift(
        <Table.HeaderCell key={"footer-score-sign-ff"}></Table.HeaderCell>
    );
  }

  footerRowTotalValue.unshift(
      <Table.HeaderCell key={"totalValueRowHeading"}>
        {t("TOTAL_VALUE")}
      </Table.HeaderCell>
  );
  footerRowTotalScore.unshift(
      <Table.HeaderCell key={"totalValueRowScore"}>
        {t("TOTAL_SCORE")}
      </Table.HeaderCell>
  );

  let rowIndex = 0;
  let rows = rowContents.map((cells) => {
    let questionnaireId = questionnaires[rowIndex].id;
    if (cells.length === 0 && definition) {
      cells.push(
          <Table.Cell key={1} colSpan={headers.length}>
            {t("SUBJECT_TAB_DATA_TABLE_NO_ANSWERS")}{" "}
            {definition.label}
          </Table.Cell>
      );
    }
    return (
        <Table.Row

            style={hideAnswers ? {} : {cursor: "pointer"}}
            onClick={() => {
              if (hideAnswers) {
                return;
              }
              history.push(
                  "/app/subject/" +
                  subjectId +
                  "/questionnaire-type/" +
                  questionnaireType +
                  "/" +
                  definitionCode +
                  "/view/" +
                  questionnaireId
              );
            }}
            key={++rowIndex}
        >
          {cells}
        </Table.Row>
    );
  });

  return <>
    {!questionnairesRequireSignoff && <h4>
      {t("SUBJECT_TAB_DATA_TABLE_HEADER_COMPLETED_QUESTIONNAIRES")}
    </h4>}
    {questionnairesRequireSignoff && <h4>
      {t("SUBJECT_TAB_DATA_TABLE_HEADER_SIGN_OFF_QUESTIONNAIRES")}
    </h4>}
    {rows.length > 0 && (
        <Table id={"subjectDataTable"} selectable celled>
          <Table.Header>
            <Table.Row>{headers}</Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
          {showTotalValueRow && (
              <Table.Footer>
                <Table.Row key={"footerRowTotalValue"}>
                  {footerRowTotalValue}
                </Table.Row>
              </Table.Footer>
          )}
          {showTotalScoreRow && (
              <Table.Footer>
                <Table.Row key={"footerRowTotalScore"}>
                  {footerRowTotalScore}
                </Table.Row>
              </Table.Footer>
          )}
        </Table>
    )}
    {rows.length === 0 && <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY")}</p>}
    {isGraphOpen && (
        <QuestionChart
            questionnaires={questionnaires}
            definition={definition}
            isNull={isNull}
            isUsingTotal={isUsingTotal}
            subjectId={subjectId}
        />
    )}
  </>

}

export default SubjectDataTableCompletedDisplay;
