import RequestHelper from "../../RequestHelper";
import {serverAddress} from "../config/EnvConfig";

const CacheService = {
  refreshExpressionContextAnswers: async () => {
    return RequestHelper.send(
      serverAddress +
        "/cache/refresh/expression_context_answers",
      {},
      "POST",
      null,
      null
    );
  },
  refreshQuestionnaireDefinitions: async () => {
    return RequestHelper.send(
      serverAddress +
        "/cache/refresh/questionnaire_definitions",
      {},
      "POST",
      null,
      null
    );
  },
  refreshDynamicSubjectDataExpressionContext: async () => {
    return RequestHelper.send(
        serverAddress +
        "/cache/refresh/dynamic_subject_data_expression_context",
      {},
      "POST",
      null,
      null
    );
  },
  refreshDashboardReports: async () => {
    return RequestHelper.send(
        serverAddress +
        "/cache/refresh/dashboard_reports",
        {},
        "POST",
        null,
        null
    );
  },
};

export default CacheService;
