import RequestHelper from "./RequestHelper";
import InternationalisationService from "./InternationalisationService";
import SUBJECT_AUTHENTICATION_STRATEGY from "./constants/SUBJECT_AUTHENTICATION_STRATEGY";
import _ from "lodash";
import {serverAddress} from "./services/config/EnvConfig";
export default class TrialService {
  static getCurrentTrial(invalidateCache = false) {
    const uri = invalidateCache ? '/trial/current?invalidateCache=true' : '/trial/current';

    return RequestHelper.send(
        serverAddress + uri,
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getGroups() {
    return RequestHelper.send(
      serverAddress + "/trial/groups",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static async getConfiguration() {
    const webConfig = await RequestHelper.send(
      serverAddress +
        "/trial/configuration/electron-clinician-web",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
    const commonConfig = await RequestHelper.send(
      serverAddress +
        "/trial/configuration/electron-subject-common",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
    return _.merge(commonConfig, webConfig);
  }

  static getDefaultSubjectAuthenticationStrategy() {
    return SUBJECT_AUTHENTICATION_STRATEGY.EMAIL_PWD;
  }

  static async getSubjectAuthenticationStrategy() {
    let trial;
    try {
      trial = await TrialService.getCurrentTrial();
    } catch (err) {
      console.error("Error getting trial");
    }
    const subjectAuthenticationStrategy =
      trial.subjectAuthenticationStrategy ||
      TrialService.getDefaultSubjectAuthenticationStrategy();
    return subjectAuthenticationStrategy;
  }

  static updateTrial(formTrial, feedbackReason) {
    const feedbackHeaderValue = feedbackReason
        ? `Updating Trial`
        : undefined;
    const { initialRequestBody, initialHeaders: headers } =
        RequestHelper.createInitialRequestObjectsWithFeedback(
            feedbackReason,
            feedbackHeaderValue
        );
    const requestBody = { ...initialRequestBody, formTrial};
    return RequestHelper.send(
        serverAddress +
        "/trial/update/" + formTrial.id,
        headers,
        "POST",
        null,
        requestBody
    );
  }
}
