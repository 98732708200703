import React from 'react'
import { Button } from 'semantic-ui-react';

/**
 * Wraps an object. Modifies props of children.
 */
function ObjectGroup({ children, pathKey, label, value, onChange, additionalProps, defaultValue }) {
  const handleChange = (value) => {
    onChange && onChange(pathKey, value)
  }

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      // No not attempt to render conditionally false children
      if (!child) return null;
      // Props are passed automaticaly, with automcatically passed values
      // being overwritable via child props
      return React.cloneElement(child, {
        ...{ pathKey, label, value, onChange, additionalProps },
        ...child.props,
        pathKey: pathKey + '.' + child.props.pathKey,
        value: child.props.pathKey ? value[child.props.pathKey] : value,
      });
    });
  };

  return <div style={{ padding: '4px' }}>
    {value === undefined && <Button primary compact size='small' onClick={() => handleChange(defaultValue ? defaultValue : {})} >{"Add " + label}</Button>}
    {value !== undefined && <div style={{ display: 'flex', alignItems: 'baseline' }}><h4 style={{ paddingRight: '8px' }}>{label}</h4><Button color={'red'} compact size='small' onClick={() => handleChange(undefined)} >{"Remove " + label}</Button></div>}
    {value !== undefined && <div style={{ margin: '8px 4px', paddingLeft: '4px', borderLeft: '2px solid gainsboro' }}>{renderChildren()}</div>}
  </div>;
}

export default ObjectGroup