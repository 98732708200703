export default {
  ANSWER: "ANSWER",
  CLOSE: "CLOSE",
  LINK_TO_QUESTIONNAIRE: "LINK_TO_QUESTIONNAIRE",
  LINK_TO_SUBJECT: "LINK_TO_SUBJECT"
};

export const DATA_QUERY_TABLE_ACTION_TRANSLATIONS = {
  ANSWER: {
    translationKey: 'DATA_QUERY_TABLE_ACTION_ANSWER',
    fallbackText: 'Answer',
  },
  CLOSE: {
    translationKey: 'DATA_QUERY_TABLE_ACTION_CLOSE',
    fallbackText: 'Close',
  },
  LINK_TO_QUESTIONNAIRE: {
    translationKey: 'DATA_QUERY_TABLE_ACTION_LINK_TO',
    fallbackText: 'Goto questionnaire',
  },
  LINK_TO_SUBJECT: {
    translationKey: 'DATA_QUERY_TABLE_ACTION_LINK_TO_SUBJECT',
    fallbackText: 'Goto particpant',
  },
};
