import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import BatchAttachmentService from '../../../../services/BatchAttachmentService';
import Files from 'react-files'
import { Button, Icon, List, Loader, Modal, Segment, Table } from 'semantic-ui-react';
import GeneralHelpers from '../../../../helpers/GeneralHelpers';
import DateTimeService from '../../../../services/DateTimeService';

const MultiFileSelector = (props) => {
  const { t } = props;

  const [uploadConfiguration, setUploadConfiguration] = useState();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileSize, setSelectedFileSize] = useState(0);

  const [invalidFiles, setInvalidFiles] = useState([]);
  const [errorFiles, setErrorFiles] = useState([]);

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    populateConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);

  const populateConfig = async () => {
    const cfg = await BatchAttachmentService.getUploadConfiguration();
    cfg.allowedFilePattern = cfg.allowedFilePattern != null ? cfg.allowedFilePattern : 'image/*, video/*';
    cfg.allowedFilePatterns = cfg.allowedFilePattern.split(',').map(i => i.trim());
    cfg.maxNumberOfFiles = cfg.maxNumberOfFiles != null ? cfg.maxNumberOfFiles : 15;
    cfg.maxFileSize = cfg.maxFileSize != null ? cfg.maxFileSize : (180 * 1024 * 1024); // pre-existing yml file value
    setUploadConfiguration(cfg);
  };

  useEffect(() => {
    let total = 0;
    selectedFiles.forEach(f => total += f.size);
    setSelectedFileSize(total);
  }, [selectedFiles]);

  const handleSelectedFilesError = (error, file) => {
    let errorMessage;

    if (typeof error === 'string') {
      errorMessage = error;
    } else if (typeof error === 'object') {
      errorMessage = error.message;
    } else {
      errorMessage = t('MULTIFILESELECTOR_ERRORS_GENERIC', 'There was a problem with the selected files');
    }

    if (errorMessage.includes('not a valid file type')) {
      errorMessage = t('MULTIFILESELECTOR_ERRORS_INVALIDTYPE', 'At least one of the files in selection was not a valid type. None were added to the selected files list.');
    }

    file.valid = false;
    file.error = errorMessage;
    setErrorFiles([file]);
  };

  const handleSelectedFilesChange = useCallback(async (newFiles) => {
    setInvalidFiles([]);

    const validatedFiles = await Promise.all(
      newFiles.map((f, index) => validateFile(f, index))
    );
    const validFiles = validatedFiles.filter(f => f.valid);
    const invalidFiles = validatedFiles.filter(f => !f.valid);

    setInvalidFiles(invalidFiles);

    const updatedSelectedFiles = [...selectedFiles, ...validFiles];
    setSelectedFiles(updatedSelectedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [selectedFiles, uploadConfiguration]);

  const handleRemoveSelectedFileClick = (file) => {
    const idx = selectedFiles.findIndex(i => i.name === file.name);
    if (idx > -1) {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(idx, 1);
      setSelectedFiles(updatedFiles);
    }
  };

  const validateFile = async (file, fileIndex) => {
    if (file == null) {
      return {
        valid: false,
        error: t('MULTIFILESELECTOR_ERRORS_NOFILE', 'No file')
      };
    }

    // Maximum files
    if (selectedFiles.length + (fileIndex + 1) > uploadConfiguration?.maxNumberOfFiles) {
      file.valid = false;
      file.error = t('MULTIFILESELECTOR_ERRORS_TOOMANYFILES', 'Too many files');
      return file;
    }

    // Unique against already selected
    if (selectedFiles.some(f => f.name.toLowerCase() === file.name.toLowerCase()) === true) {
      file.valid = false;
      file.error = t('MULTIFILESELECTOR_ERRORS_DUPLICATEFILENAME', 'Duplicate filename');
      return file;
    }

    // Max single file size
    if (file.size > uploadConfiguration.maxFileSize) {
      file.valid = false;
      file.error = t('MULTIFILESELECTOR_ERRORS_FILETOOLARGE', 'File too large');
      return file;
    }

    // Unique on server
    const doesFileExistOnServer = await BatchAttachmentService.doesFileExistOnServer(file.name);
    if (doesFileExistOnServer) {
      file.valid = false;
      file.error = t('MULTIFILESELECTOR_ERRORS_DUPLICATEFILENAME', 'Duplicate filename');
      return file;
    }

    file.valid = true;
    return file;
  };

  const handleInvalidSelectionModalCloseClick = () => {
    setErrorFiles([]);
    setInvalidFiles([]);
  };

  const handleClearSelectedFilesClick = () => {
    setSelectedFiles([]);
  }

  const handleUploadClick = async () => {
    setIsUploading(true);
    const workingFiles = [...selectedFiles];

    for (let i = 0; i < selectedFiles.length; i++) {
      const fileToProcess = selectedFiles[i];
      try {
        await BatchAttachmentService.uploadFile(fileToProcess);
        fileToProcess.status = t("MULTIFILESELECTOR_SELECTEDFILES_TABLE_STATUS_UPLOADED", "Uploaded");
      } catch (error) {
        fileToProcess.status = t("MULTIFILESELECTOR_SELECTEDFILES_TABLE_STATUS_ERROR", "Error");
      }
      setSelectedFiles(workingFiles);
    }

    setIsUploading(false);
  }

  const handleUploadAbortClick = () => {
    setIsUploading(false);
  }

  if (uploadConfiguration == null) {
    return <Loader />;
  }

  const getFileSizeForDisplay = (sizeInBytes) => {
    const fileSizeMB = sizeInBytes == null ? 0 : GeneralHelpers.round(sizeInBytes / 1024 / 1024, 2);
    const fileSizeText = `${fileSizeMB} ${t('MULTIFILESELECTOR_SELECTEDFILES_TABLE_FILESIZE_UNITS', 'MB')}`;
    return fileSizeText;
  };

  const buildDataRow = (file, index) => {
    const fileSize = getFileSizeForDisplay(file?.size);
    return (
      <Table.Row key={index}>
        <Table.Cell width={1} key={index + '_status'}>
          {file.status || t("MULTIFILESELECTOR_SELECTEDFILES_TABLE_STATUS_NEW", "New")}
        </Table.Cell>
        <Table.Cell width={6} key={index + '_fileName'}>
          {file.name}
        </Table.Cell>
        <Table.Cell width={1} key={index + '_size'}>
          {fileSize}
        </Table.Cell>
        <Table.Cell width={2} key={index + '_datetime'}>
          {DateTimeService.build.asDisplayDateTime(
            file.datetime
          )}
        </Table.Cell>
        <Table.Cell width={1} key={index + '_type'}>
          {file.type}
        </Table.Cell>
        <Table.Cell width={2} key={index + '_controls'} textAlign={'right'}>
          <Icon
            color='red'
            size='large'
            name='times circle'
            onClick={() => handleRemoveSelectedFileClick(file)}
            style={{ cursor: 'pointer' }}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  const countText = `${t('MULTIFILESELECTOR_SELECTEDFILES_COUNT', 'Count')} ${selectedFiles.length}`;
  const showInvalidSelectionModal = [...invalidFiles, ...errorFiles].length > 0;
  const hasSelectedFiles = selectedFiles.length > 0;

  return (
    <>
      <Modal
        size={'fullscreen'}
        onClose={handleInvalidSelectionModalCloseClick}
        open={showInvalidSelectionModal}
        closeIcon={true}
        closeOnDimmerClick={true}
        closeOnDocumentClick={true}
      >
        <Modal.Header>
          <h3>{t('MULTIFILESELECTOR_SELECTEDFILES_INVALID_TITLE', 'Some files were invalid')}</h3>
        </Modal.Header>
        <Modal.Content scrolling>
          <List as='ul'>
            {[...invalidFiles, ...errorFiles].map((f, index) => <List.Item key={index} as='li'>{f.name} - {f.error}</List.Item>)}
          </List>
          <Button onClick={handleInvalidSelectionModalCloseClick}>
            {t("GLOBAL_BUTTON_CLOSE", "Close")}
          </Button>
        </Modal.Content>
      </Modal>

      <Modal
        size={'fullscreen'}
        open={isUploading}
        closeIcon={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          <h3>{t('MULTIFILESELECTOR_IS_UPLOADING_TITLE', 'Uploading')}</h3>
        </Modal.Header>
        <Modal.Content>
          {t('MULTIFILESELECTOR_IS_UPLOADING_TEXT',
            'The files are uploading. Please wait for this to complete, and do not close this window. This message will disappear when the uploads are complete.')}
        </Modal.Content>
      </Modal>

      <Segment>
        {t('MULTIFILESELECTOR_INTRO', 'You can drag multiple files into the area below, or you click and it will show you a file selector window. Any files you select will be validated based on file type, file size, duplicate files. Any which are not valid will be automatically removed from the selection.')}
      </Segment>

      <div>
        <li>
          Allowed file types: {uploadConfiguration.allowedFilePattern}
        </li>
        <li>
          Max files size: {getFileSizeForDisplay(uploadConfiguration.maxFileSize)}
        </li>
        <li>
          Files: {selectedFiles.length} / {uploadConfiguration.maxNumberOfFiles}
        </li>

      </div>

      <Files
        clickable
        multiple
        accepts={uploadConfiguration.allowedFilePatterns}
        onChange={handleSelectedFilesChange}
        onError={handleSelectedFilesError}
      >
        <div style={styles.dropZoneContainer}>
          {t('MULTIFILESELECTOR_LABEL', 'Drag files here, or click to see a file selector window')}
        </div>
      </Files>

      <h2>{t('MULTIFILESELECTOR_SELECTEDFILES_TITLE', 'Selected files')}</h2>

      <Segment>
        {countText} ({getFileSizeForDisplay(selectedFileSize)})
      </Segment>

      <Table id={'selectedFileListTable'} selectable columns={12} compact basic size='small'>
        <Table.Header>
          <Table.Row key={'header'}>
            <Table.HeaderCell width={1} key={'header_status'}>
              {t('MULTIFILESELECTOR_SELECTEDFILES_TABLE_HEADER_STATUS', 'Status')}
            </Table.HeaderCell>
            <Table.HeaderCell width={6} key={'header_fileName'}>
              {t('MULTIFILESELECTOR_SELECTEDFILES_TABLE_HEADER_FILENAME', 'Filename')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1} key={'header_size'}>
              {t('MULTIFILESELECTOR_SELECTEDFILES_TABLE_HEADER_SIZE', 'Size')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} key={'header_datetime'}>
              {t('MULTIFILESELECTOR_SELECTEDFILES_TABLE_HEADER_DATETIME', 'Date & TIme')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1} key={'header_type'}>
              {t('MULTIFILESELECTOR_SELECTEDFILES_TABLE_HEADER_TYPE', 'Type')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} key={'header_controls'}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {selectedFiles &&
            selectedFiles.map((selectedFiles, index) =>
              buildDataRow(selectedFiles, index)
            )}
        </Table.Body>
      </Table>

      {hasSelectedFiles > 0 && !isUploading && (
        <Button primary onClick={handleUploadClick}>
          {t('MULTIFILESELECTOR_FILES_UPLOAD', 'Upload')}
        </Button>
      )}

      {isUploading && (
        <Button secondary onClick={handleUploadAbortClick}>
          {t('MULTIFILESELECTOR_FILES_UPLOAD_ABORT', 'Abort')}
        </Button>
      )}

      {hasSelectedFiles > 0 && !isUploading && (
        <Button onClick={handleClearSelectedFilesClick}>
          {t('MULTIFILESELECTOR_FILES_CLEARALL', 'Clear all')}
        </Button>
      )}
    </>
  );
};

const styles = {
  dropZoneContainer: {
    padding: 10,
    margin: 5,
    borderWidth: 3,
    borderRadius: 10,
    borderStyle: 'solid',
    borderColor: '#ffcc00',
    backgroundColor: '#eeeeee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 120,
    fontSize: 20
  }
};


export default withTranslation()(MultiFileSelector);
