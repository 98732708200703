import InternationalisationService from "../../InternationalisationService";
import RequestHelper from "../../RequestHelper";
import {serverAddress} from "../config/EnvConfig";


const QuestionnaireDefinitionService = {
  getQuestionnaireDefinitions: (searchTerm) => {
    return RequestHelper.send(
      serverAddress + "/questionnaire-definitions/list",
      {},
      'GET',
      { searchQuery: searchTerm }
    );
  },
  getQuestionnaireDefinitionData: (id) => {
    return RequestHelper.send(
      serverAddress +
        "/questionnaire-definitions/" +
        id +
        "/data",
      {},
      'GET'
    );
  },
  getQuestionnaireDefinitionJsonExport: (code) => {
      return RequestHelper.send(
        serverAddress + "/questionnaire-definitions/exportFile",
        {},
        "GET",
        {code: code}
      );
    },
  editQuestionnaireDefinition: (questionnaireDefinition, feedbackReason) => {
    const feedbackHeaderValue = feedbackReason
      ? `Editing questionnaire definition [${questionnaireDefinition.id}]`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    const requestBody = { ...initialRequestBody, questionnaireDefinition };

    return RequestHelper.send(
      serverAddress +
        "/questionnaire-definitions/" +
        questionnaireDefinition.id,
      headers,
      'PUT',
      null,
      requestBody
    );
  },

  editQuestionOrderingForQuestionnaire: (
    questionnaireDefinitionId,
    questionDefinitions,
    previousQuestionDefinitions,
    feedbackReason
  ) => {
    const feedbackHeaderValue = feedbackReason
      ? `Editing questionnaire definition [${questionnaireDefinitionId}] New: ${JSON.stringify(
        questionDefinitions.map((q) => {
          return { id: q.id, sequence: q.sequence };
        })
      )} Previous: ${JSON.stringify(
        previousQuestionDefinitions.map((q) => {
          return { id: q.id, sequence: q.sequence };
        })
      )}`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    const requestBody = { ...initialRequestBody, questionDefinitions };

    return RequestHelper.send(
      serverAddress +
        "/questionnaire-definitions/" +
        questionnaireDefinitionId +
        "/question-definitions/sequence",
      headers,
      'PUT',
      null,
      requestBody
    );
  },

  cloneQuestionnaireDefinition: (
    questionnaireDefinitionCloneJson,
    feedback
  ) => {
    const feedbackHeader = feedback
      ? { 'x-feedback-available': 'Cloning QuestionnaireDefinition' }
      : {};
    return RequestHelper.send(
      serverAddress + "/questionnaire-definitions/clone",
      {
        'Accept-Language': InternationalisationService.getLanguage(),
        ...feedbackHeader
      },
      'POST',
      null,
      { ...questionnaireDefinitionCloneJson, feedback }
    );
  },

  validateQuestionnaireDefinitionJson: (questionnaireDefinitionJson) => {
    return RequestHelper.send(
      serverAddress + "/questionnaire-definitions/validate",
      {
        'Accept-Language': InternationalisationService.getLanguage()
      },
      'POST',
      null,
      { ...questionnaireDefinitionJson }
    );
  },

  createQuestionnaireDefinition: (questionnaireDefinitionJson, feedback) => {
    const feedbackHeader = feedback
      ? { 'x-feedback-available': 'Adding new QuestionnaireDefinition' }
      : {};
    return RequestHelper.send(
      serverAddress + "/questionnaire-definitions/create",
      {
        'Accept-Language': InternationalisationService.getLanguage(),
        ...feedbackHeader
      },
      'POST',
      null,
      { ...questionnaireDefinitionJson, feedback }
    );
  },

  submitQuestionnaireDefinitionAndTranslationChanges: async (questionnaireDefinitionChangeObject, feedbackReason) => {
    const feedbackHeaderValue = feedbackReason
      ? `Editing questionnaire definition hierarchy [${questionnaireDefinitionChangeObject?.questionnaireDefinition?.code}]`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } = RequestHelper.createInitialRequestObjectsWithFeedback(
      feedbackReason,
      feedbackHeaderValue
    );
    headers['Accept-Language'] = InternationalisationService.getLanguage();

    const translationRequestBody = {
      ...initialRequestBody,
      translationArray: questionnaireDefinitionChangeObject.translationArray
    };

    // Translations
    const translationResponse = await RequestHelper.send(
        serverAddress + '/translations/mgmt/data/bulk-update-questionnaire-translations',
      headers,
      'POST',
      null,
      translationRequestBody
    );

    // Questionnaire Definition
    const requestBody = {
      ...initialRequestBody,
      questionnaireDefinition: questionnaireDefinitionChangeObject.questionnaireDefinition
    };
    const questionnaireResponse = await RequestHelper.send(
      serverAddress + "/questionnaire-definitions/questionnaireDefinitionHierarchy",
      headers,
      'PUT',
      null,
      requestBody
    );

    return {
      errors: { ...translationResponse.errors, ...questionnaireResponse.errors },
      warnings: { ...translationResponse.warnings, ...questionnaireResponse.warnings }
    };
  },

  submitTranslationChanges: async (translationArray, feedbackReason) => {
    const feedbackHeaderValue = feedbackReason
      ? 'Editing translations'
      : undefined;

    const { initialRequestBody, initialHeaders: headers } = RequestHelper.createInitialRequestObjectsWithFeedback(
      feedbackReason,
      feedbackHeaderValue
    );
    headers['Accept-Language'] = InternationalisationService.getLanguage();

    const translationRequestBody = {
      ...initialRequestBody,
      translationArray
    };

    // Translations
    const translationResponse = await RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + '/translations/mgmt/data/bulk-update-questionnaire-translations',
      headers,
      'POST',
      null,
      translationRequestBody
    );

    return {
      errors: translationResponse.errors,
      warnings: translationResponse.warnings
    };
  }
};

export default QuestionnaireDefinitionService;
