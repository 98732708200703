import InternationalisationService from "../InternationalisationService";
import RequestHelper, { CONTENT_TYPE } from "../RequestHelper";
import {serverAddress} from "./config/EnvConfig";

const AuditService = {
  getAuditedEntities: async () => {
    const url = `${serverAddress}/audit/auditedentities`;
    const response = await RequestHelper.send(
      url,
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
    return response;
  },
  getBySearchCriteria: async (searchCriteria, format) => { 
    const getUrl = (format) => {
      switch (format) {
        case "xls":
          return `${serverAddress}/audit/search/export/${format}`;
        default:
          return `${serverAddress}/audit/search`;
      }
    };
    const addSpecificHeaders = (headers) => {
      switch (format) {
        case "xls":
          headers['Accept'] = CONTENT_TYPE.APPLICATION_OCTETSTREAM;
          break;
        default:
          break;
      }
      return headers;
    };

    const url = getUrl(format);
    const requestBody = {
      entityTypes:
        searchCriteria?.entityTypes !== undefined
          ? searchCriteria.entityTypes
          : [],
      dateFrom: searchCriteria.dateFrom,
      dateTo: searchCriteria.dateTo,
    };
    const headers = addSpecificHeaders({ "Accept-Language": InternationalisationService.getLanguage() });
    const response = await RequestHelper.send(
      url,
      headers,
      "POST",
      null,
      requestBody
    );
    return response;
  },
};

export default AuditService;
