import React, { useState } from "react";
import Questionnaire from "./Questionnaire";

const MultiQuestionnaire = ({
  definitions,
  questionnaires,
  onSubmit,
  onPageChange,
  shouldGroupAnswersByQuestionnaire,
  subjectId,
}) => {
  const [answerMap, setAnswerMap] = useState({});
  const [cursor, setCursor] = useState(0);

  const getCurrentDefinition = () => {
    return definitions[cursor];
  };

  const getCurrentQuestionnaire = () => {
    if(!questionnaires) return undefined;
    return questionnaires[cursor];
  }

  const onQuestionnaireSubmit = (answers, setHasSubmitted) => {
    let currentDefinition = getCurrentDefinition();

    const newAnswerMap = { ...answerMap };
    newAnswerMap[currentDefinition.code] = answers;

    if (cursor < definitions.length - 1) {
      setAnswerMap(newAnswerMap);
      setCursor(cursor + 1);
    } else if (onSubmit) {
      let collatedAnswers = {};

      Object.entries(newAnswerMap).forEach(
        ([questionnaireEntryKey, questionnaireEntryValue]) => {
          Object.entries(questionnaireEntryValue).forEach(
            ([questionEntryKey, questionEntryValue]) => {
              const collatedAnswerKey =
                questionnaireEntryKey + "_" + questionEntryKey;
              if (shouldGroupAnswersByQuestionnaire) {
                if (collatedAnswers[questionnaireEntryKey] == null) {
                  collatedAnswers[questionnaireEntryKey] = {};
                }
                collatedAnswers[questionnaireEntryKey][collatedAnswerKey] =
                  questionEntryValue;
              } else {
                collatedAnswers[collatedAnswerKey] = questionEntryValue;
              }
            }
          );
        }
      );

      onSubmit(collatedAnswers, setHasSubmitted);
    } else {
      console.warn("No onSubmit specified for MultiQuestionnaire");
    }
  };

  return (
    <>
      {definitions.length > 0 && (
        <Questionnaire
          definition={getCurrentDefinition()}
          questionnaire={getCurrentQuestionnaire()}
          onPageChange={onPageChange}
          onSubmit={onQuestionnaireSubmit}
          subjectId={subjectId}
          isMultipart
        />
      )}
    </>
  );
};

export default MultiQuestionnaire;
