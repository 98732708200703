import React, { useEffect, useState } from "react";
import { Button, Modal, Loader, Message } from "semantic-ui-react";
import { getDataDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import Questionnaire from "../../questionnaires/Questionnaire";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import SubjectService from "../../SubjectService";

const ConfirmButtonWithFeedback = (props) => {
  const {
    onConfirm,
    buttonText,
    definitionCode,
    dataDefinitions,
    color,
    disabled,
    t,
  } = props;

  const params = useParams();
  const { subjectId } = params;

  const [isOpen, setIsOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [foundDefinition, setFoundDefinition] = useState(null);

  useEffect(() => {
    if (!dataDefinitions?.length) {
      setShowError(true);
      return;
    }
    const foundDefinition = dataDefinitions.find(
      (def) => def.code === definitionCode
    );

    if (!foundDefinition) {
      setShowError(true);
      return;
    }

    setFoundDefinition(foundDefinition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDefinitions, definitionCode]);

  const handleConfirm = async (answers) => {
    let answerMap = {};
    Object.entries(answers).forEach((entry) => {
      answerMap[definitionCode + "_" + entry[0]] = entry[1];
    });

    try {
      const response = await SubjectService.createQuestionnaire(
        subjectId,
        definitionCode,
        answerMap
      );

      if (!response.ok) {
        throw new Error("Failed to send questionnaire successfully");
      } else {
        onConfirm();
      }
    } catch (e) {
      showError(true);
      console.warn(e);
    }
  };

  const theButton = (
    <Button
      color={color ? color : "orange"}
      disabled={disabled}
      onClick={() => setIsOpen(true)}
    >
      {buttonText}
    </Button>
  );

  return (
    <>
      {theButton}
      <Modal
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        open={isOpen}
      >
        <Modal.Content>
          <Loader active={!foundDefinition && !showError} />
          {foundDefinition && (
            <Questionnaire
              definition={foundDefinition}
              onSubmit={handleConfirm}
              subjectId={subjectId}
            />
          )}
          {showError && <Message>{t("GLOBAL_GENERIC_ERROR_MESSAGE")}</Message>}
        </Modal.Content>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dataDefinitions: getDataDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(ConfirmButtonWithFeedback);
