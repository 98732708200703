import InternationalisationService from "../InternationalisationService";
import RequestHelper, {CONTENT_TYPE} from "../RequestHelper";
import _ from "lodash";
import {serverAddress} from "./config/EnvConfig";

const LookupService = {
  getLookupListByLanguage: async (language) => {
    return RequestHelper.send(
        serverAddress +
        "/lookup/language/" +
        language,
        {}
    );
  },

  getLookupListByCode: async (code) => {
    return RequestHelper.send(
        serverAddress +
        "/lookup/" +
        code,
        {}
    );
  },

  editLookupByLanguageAndLookupCode: async (language, code, name) => {
    return RequestHelper.send(
        serverAddress +
        "/lookup/" +
        code +
        "/language/" +
        language,
        {},
        "PUT",
        null,
        {name}
    );
  },

  getLookupItemsByLanguageAndLookupCode: async (language, code, q) => {
    return RequestHelper.send(
        serverAddress +
        "/lookup/" +
        code +
        "/language/" +
        language +
        "/item",
        {},
        "GET",
        {q: q ? q : ''}
    );
  },

  downloadLookupItems: async (language, code,progressCallback) => {
    return RequestHelper.send(
        serverAddress +
        "/lookup/" +
        code +
        "/language/" +
        language +
        "/item/download",
        {Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM},
        "GET",
        {},
        undefined,
        undefined,
         progressCallback
    );
  },
  createLookupsByLookupCode: async (code, lookupDataList) => {
    return RequestHelper.send(
        serverAddress +
        "/lookup/" +
        code,
        {},
        "POST",
        null,
        {lookupDataList}
    );
  },

  editLookupItemByLanguageAndLookupCodeAndRef: async (language, lookupCode, ref, value, data) => {
    return RequestHelper.send(
        serverAddress +
        "/lookup/" +
        lookupCode +
        "/language/" +
        language +
        "/item/" +
        ref,
        {},
        "PUT",
        null,
        {value, data}
    );
  },

  editLookupItemByLookupItemId: async (lookupItemId, ref, value, data, priority, source) => {
    return RequestHelper.send(
        serverAddress +
        "/lookup/item/" +
        lookupItemId,
        {},
        "PUT",
        null,
        {ref, value, data, priority, source}
    );
  },


  createLookupItemsByLookupCode: async (code, lookupItemDataList) => {
    return RequestHelper.send(
        serverAddress +
        "/lookup/" +
        code +
        "/item",
        {},
        "POST",
        null,
        {lookupItemDataList}
    );
  },

  getLookupItemsByQuery: async (lookupCode, maxNumberToShow, query) => {
    return RequestHelper.send(
      serverAddress +
        "/lookup/find-items/" +
        lookupCode +
        "/" +
        maxNumberToShow +
        "/" +
        query,
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  },
  buildFreeTextItem: (value) => {
    return { value: value };
  },
  performLookup: async (
    minInputCharacters,
    lookupCode,
    query,
    maxNumberToShow
  ) => {
    try {
      let response = {};
      const trimmedQuery = query?.trim() || "";
      if (lookupCode && trimmedQuery.length >= minInputCharacters) {
        response = await LookupService.getLookupItemsByQuery(
          lookupCode,
          maxNumberToShow,
          trimmedQuery
        );
      }

      const itemsToReturn = response?.items || [];
      const itemsCount = response?.count || 0;
      const hasMoreItems = itemsCount > itemsToReturn.length;

      const data = {
        isValidQuery: trimmedQuery.length >= minInputCharacters,
        hasItems: itemsToReturn.length > 0,
        items: itemsToReturn,
        count: itemsCount,
        hasMoreItems: hasMoreItems,
      };
      return data;
    } catch (error) {
      console.error(
        "LookupService - performLookup - Error getting data from api",
        error
      );
      return null;
    }
  },

  populateRelatedQuestionValues: (
    selectedValue,
    otherQuestionValues,
    changeAnswerMapValue
  ) => {
    if (!otherQuestionValues) {
      return;
    }

    const dataAsString = selectedValue?.data;
    let dataAsJson = null;
    if (dataAsString) {
      dataAsJson = JSON.parse(dataAsString);
    }

    Object.entries(otherQuestionValues).forEach(
      ([questionCode, expression]) => {
        changeAnswerMapValue(
          questionCode,
          expression === "data" ? dataAsString : _.get(dataAsJson, expression)
        );
      }
    );
  },
};

export default LookupService;
