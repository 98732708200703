import React, {useEffect, useRef, useState} from 'react';
import useFullPageLayoutPortal from "../../hooks/useFullPageLayoutPortal";
import {Card, Transition} from "semantic-ui-react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {DateTime} from "luxon";
import ConfigService from "../../services/ConfigService";

const QuestionnaireSavedDisplay = ({lastCacheTime, t}) => {
  const [, FixedPosition] = useFullPageLayoutPortal();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const assumedWidth = 400;
  const calculatedPosition = pageWidth/2 - assumedWidth/2

  const [isVisible, setIsVisible] = useState(false);

  // There is an issue where fetching the initial state will update the display
  // so display is only allowed after a number of seconds
  const displayAllowedAfter = useRef(DateTime.now().plus({"seconds": 5}))

  const showSavedDisplay = () => {
    setIsVisible(true)
    setTimeout(()=>{
      setIsVisible(false)
    }, 3000)
  }
  useEffect(()=>{
    // This is a bit of a work-around to stop it from displaying when the state is hydrated from server
    const isDisplayAllowed = DateTime.now() > displayAllowedAfter.current
    if(isDisplayAllowed && lastCacheTime){
      showSavedDisplay()
    }
  }, [lastCacheTime])

  const handleResize = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if(!ConfigService.isUsingFrontendStateCache()){
    return null;
  }

  return <FixedPosition left={calculatedPosition} bottom={24}>
    <Transition visible={isVisible} animation='fade up' duration={300}>
      <Card style={{width: 400, bottom: 0, textAlign: 'center', fontSize: '1rem'}}>
        <Card.Content>
          <strong>{t("FRONTEND_CACHE_SAVE_MESSAGE", 'Progress Saved')}</strong>
        </Card.Content>
      </Card>
    </Transition>
  </FixedPosition>;
}

const mapStateToProps = (state) => {
  return {
    lastCacheTime: state.currentQuestionnaire.lastCacheTime
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(QuestionnaireSavedDisplay);