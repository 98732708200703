import { createSlice } from "@reduxjs/toolkit";

import { cloneDeep } from "lodash";
import {DateTime} from "luxon";

const isCurrentQuestionnaireInDate = (historic, questionnaire) => {
  if(historic?.completionDate !== questionnaire?.completionDate){
    return false
  }
  const isCurrentQuestionnaireWorkflowControlled = questionnaire.questionnaireWorkflowInstance != null;
  if(!isCurrentQuestionnaireWorkflowControlled){
    return true
  }

  const currentQuestionnaireTasks = historic.questionnaireWorkflowInstance?.questionnaireWorkflowTaskInstances
  const questionnaireTasks = questionnaire.questionnaireWorkflowInstance?.questionnaireWorkflowTaskInstances
  // questionnaireWorkflowTaskInstances is a list so order is retained
  for(let i = 0; i++; i< questionnaireTasks.length){
    if(currentQuestionnaireTasks[i]?.completionDate !== questionnaireTasks[i]?.completionDate){
      return false
    }
  }
  return true
}

const currentQuestionnaireSlice = createSlice({
  name: "currentQuestionnaire",
  initialState: {
    id: null,
    state: null,
    subjectId: null,
    questionnaire: {},
    answerMap: {},
    cursor: 0,
    cursorHistoryStack: [],
    isCompleted: false,
    questionnaireUserHistory: [],
    lastCacheTime: null // The key of this is needed in the AppStateService
  },
  reducers: {
    resetState: (state, action) => {
      state.id = null;
      state.state = null;
      state.subjectId = null;
      state.questionnaire = {};
      state.answerMap = {};
      state.cursor = 0;
      state.cursorHistoryStack = [];
      state.isCompleted = false;
      state.questionnaireUserHistory = [];
      state.lastCacheTime = null;
    },
    setCachedState: (state, action) => {
      const keysToCheck = ["id", "state", "subjectId", "questionnaire", "answerMap", "cursor", "cursorHistoryStack", "isCompleted", "questionnaireUserHistory", "lastCacheTime"];

      // check that all keys are present
      if (keysToCheck.every((key) => action.payload[key] !== undefined)) {
        keysToCheck.forEach((key) => {
          state[key] = action.payload[key];
        });
      }
    },
    updateLastCacheTime: (state, action) => {
      const {
        payload: { lastCacheTime },
      } = action;
      state.lastCacheTime = lastCacheTime;
    },
    resetCursorHistory: (state, action) => {
      state.cursorHistoryStack = [];
    },
    clearLocalData: (state, action) => {
      state.answerMap = {}
      state.cursor = 0
      state.cursorHistoryStack = []
    },
    updateCurrentQuestionnaire: (state, action) => {
      const {
        payload: { questionnaire, subjectId },
      } = action;
      const { id, answerMap, cursorHistoryStack, cursor } = state;

      if (!questionnaire.id) {
        questionnaire.id = questionnaire?.definition?.id;
      }

      // if a new questionnaire is being loaded
      // or if a new subject is being submitted for
      if (state.id !== questionnaire.id || state.subjectId !== subjectId) {
        if (state.id !== null) {
          state.questionnaireUserHistory.push({
            id,
            subjectId: state.subjectId,
            answerMap,
            cursor,
            cursorHistoryStack,
            questionnaire: state.questionnaire
          });
        }

        state.id = questionnaire.id;
        state.subjectId = subjectId;
        state.questionnaire = questionnaire;
        state.isCompleted = false;
        state.questionnaireLength = questionnaire.definition.questions.length;

        // includes costly clone as editing is not possible
        if ("customLabels" in questionnaire) {
          const definition = cloneDeep(questionnaire.definition);
          questionnaire.customLabels.forEach((customLabel) => {
            let questionWithLabel = definition.questions.find(
              (question) => question.code === customLabel.code
            );
            questionWithLabel.label = customLabel.label;
          });
          state.questionnaire.definition = definition;
        }

        // Search for user data from history
        const foundUserDetailsIndex = state.questionnaireUserHistory.findIndex(
          (questionnaireUserDetails) => {
            return (
              questionnaireUserDetails.id === questionnaire.id &&
              questionnaireUserDetails.subjectId === subjectId
            );
          }
        );



        if (foundUserDetailsIndex !== -1) {
          const foundUserDetails =
            state.questionnaireUserHistory[foundUserDetailsIndex];
          // TODO: Should check that calculated values are
          // present and unchanged.

          const isQuestionnaireInDate = isCurrentQuestionnaireInDate(foundUserDetails.questionnaire, state.questionnaire )
          if( !isQuestionnaireInDate) {
            state.answerMap = questionnaire.answers ? questionnaire.answers : {};
            state.cursorHistoryStack = [];
            state.cursor = 0;
          } else {
            // add user data to current questionnaire
            state.answerMap = foundUserDetails.answerMap;
            state.cursorHistoryStack = foundUserDetails.cursorHistoryStack;
            state.cursor = foundUserDetails.cursor;
          }

          // remove old data
          state.questionnaireUserHistory.splice(foundUserDetailsIndex, 1);
        } else {
          state.answerMap = questionnaire.answers ? questionnaire.answers : {};
          state.cursorHistoryStack = [];
          state.cursor = 0;
        }
      }
    },
    replaceCursorHistoryStack: (state, action) => {
      const { cursorHistoryStack, cursor } = action.payload;
      if (Array.isArray(cursorHistoryStack)) {
        state.cursorHistoryStack = cursorHistoryStack;
        state.cursor = cursor;
      }
    },
    replaceAnswerMap: (state, action) => {
      state.answerMap = action.payload;
    },
    setCurrentQuestionnaireAsCompleted: (state) => {
      state.id = null;
      state.state = null;
      state.subjectId = null;
      state.questionnaire = {};
      state.answerMap = {};
      state.cursorHistoryStack = [];
      state.cursor = 0;
      state.lastCacheTime = DateTime.now().toString();
    },
  },
});

export const {
  resetState,
  setCachedState,
  updateLastCacheTime,
  resetCursorHistory,
  updateCurrentQuestionnaire,
  replaceCursorHistoryStack,
  replaceAnswerMap,
  setCurrentQuestionnaireAsCompleted,
  clearLocalData
} = currentQuestionnaireSlice.actions;

const getCurrentQuestionnaire = (state) =>
  state.currentQuestionnaire.questionnaire;
const getCurrentCompletedState = (state) =>
  state.currentQuestionnaire.isCompleted;
const getFullState = (state) => state.currentQuestionnaire;
const getIntialState = currentQuestionnaireSlice.getInitialState;

export { getCurrentQuestionnaire, getCurrentCompletedState, getFullState, getIntialState };

export default currentQuestionnaireSlice;
