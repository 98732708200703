import {useCallback, useEffect, useMemo, useState} from 'react'
import SubjectService from "../../SubjectService";

const useMultiQuestionnaireData = (definition, questionnaire, allDefinitions) => {
  const isMultipart = definition?.config?.multiQuestionnaire !== undefined;
  const [multipartQuestionnaires, setMultipartQuestionnaires] = useState([]);
  const [multipartDefinitions, setMultipartDefinitions] = useState([]);


  const multipartDataForCompletion = useMemo(()=>{
    const createFilterOutMissingPros = (definitions, questionnaires) => (_v,i) => {
      return !(definitions[i]?.type === "PRO" && questionnaires[i] === null)
    };
    const outMissingPros = createFilterOutMissingPros([definition, ...multipartDefinitions], [questionnaire, ...multipartQuestionnaires])

    return {
      definitions:[definition, ...multipartDefinitions].filter(outMissingPros),
      questionnaires: [questionnaire, ...multipartQuestionnaires].filter(outMissingPros)
    }
  }, [definition, multipartDefinitions, questionnaire, multipartQuestionnaires]);

  const determineMultiQuestionnaires = useCallback(async () => {
    if(!isMultipart) return

    const multipartDefinitionCodes = definition?.config?.multiQuestionnaire?.definitions;
    const questionMatch = definition?.config?.multiQuestionnaire?.onQuestionMatch;

    const multipartDefinitions = multipartDefinitionCodes.map(def=>allDefinitions.find(d=>d.code===def));
    const multipartQuestionnaires = [];

    for(const def of multipartDefinitions){
      // event and data have no questionnaires associated with them
      // loading completion is checked via array length, so null is used
      if(["EVENT", "DATA"].includes(def.type)) multipartQuestionnaires.push(null)

      const activeQuestionnairesForCode = await SubjectService.getSubjectQuestionnairesActiveNotCompletedOnly(
          questionnaire.subjectId,
          def.code,
          undefined, undefined,
          questionMatch ? undefined : 1
      );
      if(!questionMatch) {
        activeQuestionnairesForCode.count > 0 ?
            multipartQuestionnaires.push(activeQuestionnairesForCode.result[0]) :
            multipartQuestionnaires.push(null)
        continue;
      }

      let matchingQuestionnaire = null;
      for(let activeQuestionnaireForCode of activeQuestionnairesForCode.result){
        if(activeQuestionnaireForCode[`${def.code}_${questionMatch}`] === questionnaire[`${definition.code}_${questionMatch}`]){
          matchingQuestionnaire = activeQuestionnaireForCode;
          break
        }
      }
      multipartQuestionnaires.push(matchingQuestionnaire)
    }

    setMultipartQuestionnaires(multipartQuestionnaires)
    setMultipartDefinitions(multipartDefinitions)
  }, [allDefinitions, definition, isMultipart, questionnaire])

  useEffect(()=>{
    if(!definition) return;
    determineMultiQuestionnaires()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [determineMultiQuestionnaires])

  // Loaded depends on the raw arrays, not filtered for missing PROs
  const hasMultiquestionnaireLoaded = useMemo(()=>{
    if(!isMultipart) return false;
    if(!definition) return false;

    // These may contain nulls, but lengths should match
    const hasDefinitions = definition?.config?.multiQuestionnaire?.definitions?.length === multipartDefinitions.length;
    const hasQuestionnaires = definition?.config?.multiQuestionnaire?.definitions?.length === multipartQuestionnaires.length;
    return hasDefinitions && hasQuestionnaires;
  }, [definition, isMultipart, multipartDefinitions.length, multipartQuestionnaires.length])

  return [isMultipart, multipartQuestionnaires, multipartDefinitions, hasMultiquestionnaireLoaded, multipartDataForCompletion]
}

export default useMultiQuestionnaireData;