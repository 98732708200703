import React from 'react'
import ObjectGroup from './simpleValueEditors/ObjectGroup'
import ArrayGroup from './simpleValueEditors/ArrayGroup'
import TextValueEditor from './simpleValueEditors/TextValueEditor'
import BooleanValueEditor from './simpleValueEditors/BooleanValueEditor'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function VideoConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <BooleanValueEditor pathKey={"useCamera"} label={"Should Use Camera?"} />
      <BooleanValueEditor pathKey={"enableCapturePreview"} label={"Enable Capture Preview?"} />
      <BooleanValueEditor pathKey={"showInPagePreview"} label={"Show In Page Preview?"} />
      <BooleanValueEditor pathKey={"enableDeleteButton"} label={"Show Delete Button?"} />
      <BooleanValueEditor pathKey={"saveToCameraroll"} label={"Save To Cameraroll?"} />
      <BooleanValueEditor pathKey={"enableAudioCapture"} label={"Enable Audio Capture?"} />
      <BooleanValueEditor pathKey={"frontFacingCamera"} label={"Front Facing Camera?"} />
      <BooleanValueEditor pathKey={"frontFacingCamera"} label={"Ultra Wide Camera?"} />
      <TextValueEditor pathKey={"buttonTextKey"} label={"Button Text Key"} />
      <ObjectGroup pathKey={"trackingConfig"} label={"Tracking Configuration"} >
        <BooleanValueEditor pathKey={"enabled"} label={"Enabled?"} />
        <BooleanValueEditor pathKey={"poseTracking"} label={"Pose Tracking?"} />
        <BooleanValueEditor pathKey={"showTrackingPoints"} label={"Show Tracking Points?"} />
        <BooleanValueEditor pathKey={"showTrackingPointsTable"} label={"Show Tracking Points Table?"} />
        <TextValueEditor pathKey={"model"} label={"Model"} />
        <BooleanValueEditor pathKey={"saveSkeleton"} label={"Save Skeleton?"} />
        <BooleanValueEditor pathKey={"showTrackingPosture"} label={"Show Tracking Posture?"} />
        <BooleanValueEditor pathKey={"showFullBodyInFrameHint"} label={"Show Full Body In Frame Hint?"} />
        <BooleanValueEditor pathKey={"qualityCheck"} label={"Quality Check?"} />
        <ArrayGroup pathKey={"analysis"} label={"Analysis"} >
          <TextValueEditor />
        </ArrayGroup>
      </ObjectGroup>
      <ObjectGroup pathKey={"trackingConfig"} label={"Tracking Configuration"}>
        <ArrayGroup pathKey={"START_CAMERA"} label={"Start Camera"} >
          <TextValueEditor />
        </ArrayGroup>
      </ObjectGroup>
    </ConfigWrapper>
  )
}

export default VideoConfigEditor