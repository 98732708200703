import React from "react";
import { withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import Page from "../../components/page/Page";

import RequestHelper from "../../RequestHelper";
import InternationalisationService from "../../InternationalisationService";
import {serverAddress} from "../../services/config/EnvConfig";

const AdminModulesEditPage = ({ t }) => {
  const recalculateModules = () => {
    RequestHelper.send(serverAddress + '/modules/refresh',
        {
          "Accept-Language": InternationalisationService.getLanguage(),
        },
        "POST",
        null,
        {}
    );
  }

  return (
      <Page
          name="Admin Questionnaire Module Utilities"
          header={t(
              "ADMIN_QUESTIONNAIRE_MODULES_HEADER",
              "Admin Questionnaire Modules"
          )}
      >
        <Button
            type="submit"
            primary
            fluid
            size="large"
            onClick={recalculateModules}
        >
          {t("QUESTIONNAIRE_MODULE_REFRESH_BUTTON", "Refresh Modules")}
        </Button>
      </Page>
  )
};

export default withTranslation()(AdminModulesEditPage);
