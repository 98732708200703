import AuthService from "../services/AuthService";

//to be added to dynamic config in phase 2
export const keyMappings = [
    {key: 'c', path: "/app/utils/config-tool"},
    {key: 'r', path: "/app/utils/translations"},
    {key: 'q', path: "/app/utils/questionnaire-definitions"},
    {key: 'w', path: "/app/utils/questionnaire-workflow"},
    {key: 't', path: "/app/utils/triggers"}
];

const KeyboardHotkeyMappings = () => {
}

KeyboardHotkeyMappings.handleKeyPress = (event) => {
    //default to input if unsure to reject hotkey press
    const nodeName = event.target.nodeName == null ? "input" : event.target.nodeName.toLowerCase();
    if (event.metaKey || event.ctrlKey || (/textarea|select|input|div/i.test(nodeName))) {
        return;
    }

    const keyMap = keyMappings.filter(row => row.key === event.key)[0];
    if (keyMap) {
        window.location.href = window.location.origin + keyMap.path;
    }
}

KeyboardHotkeyMappings.assignKeys = () => {
    //add keyboard mappings hotkeys listener
    if (AuthService.isStaff()) {
        document.addEventListener('keydown', KeyboardHotkeyMappings.handleKeyPress);
    }
}

KeyboardHotkeyMappings.unAssignKeys = () => {
    document.removeEventListener('keydown', KeyboardHotkeyMappings.handleKeyPress);
}

export default KeyboardHotkeyMappings;
