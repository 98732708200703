import InternationalisationService from "../InternationalisationService";
import RequestHelper, { CONTENT_TYPE } from "../RequestHelper";
import {serverAddress} from "./config/EnvConfig";

const serviceBaseUrl = `${serverAddress}/attachments/batch`;

const BatchAttachmentService = {

  getBackgroundJobs: async () => {
    const url = `${serviceBaseUrl}/backgroundJobs`;
    const response = await RequestHelper.send(
      url,
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
    return response;
  },

  getUploadedFiles: async () => {
    const url = `${serviceBaseUrl}/getUploadedFiles`;
    const response = await RequestHelper.send(
      url,
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
    return response;
  },

  deleteUploadedFile: async (fileName) => {
    const url = `${serviceBaseUrl}/deleteUploadedFile`;
    const response = await RequestHelper.send(
      url,
      { "Accept-Language": InternationalisationService.getLanguage() },
      "DELETE",
      { fileName }
    );
    return response;
  },
  
  deleteUploadedFiles: async (fileNames) => {
    if (!Array.isArray(fileNames)) {
      console.log('[BatchAttachmentService][deleteUploadedFiles] fileNames is not an array', fileNames);
      return;
    }
    const url = `${serviceBaseUrl}/deleteUploadedFiles`;
    const response = await RequestHelper.send(
      url,
      { "Accept-Language": InternationalisationService.getLanguage() },
      "DELETE",
      null,
      fileNames
    );
    return response;
  },

  getUploadFilesUrl: () => {
    return `${serviceBaseUrl}/uploadFile`;
  },

  uploadFile: async (file) => {
    const url = BatchAttachmentService.getUploadFilesUrl();
    const formData = new FormData();
    formData.append("file", file);
    const response = await RequestHelper.send(
      url,
      { 
        "Accept-Language": InternationalisationService.getLanguage(), 
        "Content-Type": CONTENT_TYPE.MULTIPART_FORMDATA
      },
      "POST",
      null,
      formData
    );
    return response;
  },

  getUploadConfiguration: async () => {
    const url = `${serviceBaseUrl}/getUploadConfiguration`;
    const response = await RequestHelper.send(
      url,
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET"
    );
    return response;
  },

  doesFileExistOnServer: async (fileName) => {
    const url = `${serviceBaseUrl}/doesFileExist`;
    const response = await RequestHelper.send(
      url,
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      { fileName }
    );
    return response;
  },

  uploadData: async (validateOnly, csvFile, feedbackReason) => {
    const feedbackHeaderValue = feedbackReason
      ? `Batch Attachment Upload`
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    const url = validateOnly
        ? `${serviceBaseUrl}/validateData`
        : `${serviceBaseUrl}/processData`;

    const formData = RequestHelper.jsonRequestBodyToFormData(initialRequestBody);    
    formData.append("file", csvFile);

    headers["Accept-Language"] = InternationalisationService.getLanguage();
    headers["Content-Type"] = CONTENT_TYPE.MULTIPART_FORMDATA;
    headers["Content-Length"] = csvFile?.size;

    const response = await RequestHelper.send(
      url,
      headers,
      "POST",
      null,
      formData
    );
    return response;
  }

};

export default BatchAttachmentService;
