import React from 'react';
import { Menu, Header } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import IconMenuItem from "./IconMenuItem";

const ProfileMenuHeader = (props) => {
  const { profile, t, isStaff, isExpanded } = props;

  const location = useLocation();

  const LinkMenuItem = isExpanded ? MenuItem : IconMenuItem;

  if(isStaff && !isExpanded){
    return null;
  }

  return (
    <>
      {profile.firstName && profile.lastName && (
        <Menu.Item>
          <Header as={"h3"} style={styles.header}>
            {profile.firstName + " " + profile.lastName}
          </Header>
        </Menu.Item>
      )}
      <LinkMenuItem to={"/"} active={location.pathname === "/"} icon={"info"} text={t("MENU_STUDY")} isButtonStyle={!isStaff} />
    </>
  );
};

const styles = {
  header: {
    textAlign: "center",
  },
};

export default withTranslation()(ProfileMenuHeader);
