import React, { useEffect, useState } from "react";
import { Button, Container, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import useFirstRender from "../hooks/useFirstRender";

const STRING_DATE_FORMAT = "YYYY-MM-DD";

const DateRangeControls = (props) => {
  const { t } = props;

  const isFirstRender = useFirstRender();
  
  const [selectedDates, setSelectedDates] = useState({
    startAsString: moment.isMoment(props.start) ? props.start.format(STRING_DATE_FORMAT) : props.start,
    endAsString: moment.isMoment(props.end) ? props.end.format(STRING_DATE_FORMAT) : props.end
  });

  const getStartDateAsMoment = () => {
    return moment(selectedDates.startAsString);
  };

  const getEndDateAsMoment = () => {
    return moment(selectedDates.endAsString);
  };

  const getRangeInDays = () => {
    return getEndDateAsMoment().diff(getStartDateAsMoment(), "days");
  };

  const notifyParentOnLoad = props.notifyParentOnLoad !== undefined
    ? props.notifyParentOnLoad
    : true;

  const disableFormSubmit = props.disableFormSubmit || false;

  useEffect(() => {
    if (isFirstRender && !notifyParentOnLoad) {
      return;
    }
    props.onChange(
      selectedDates.startAsString,
      selectedDates.endAsString
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates.startAsString, selectedDates.endAsString]);

  const onChangeStart = (event) => {
    const asMoment = moment(event.target.value);
    if (!asMoment?.isValid()) {
      return;
    }
    changeDates(asMoment, null);
  }

  const onChangeEnd = (event) => {
    const asMoment = moment(event.target.value);
    if (!asMoment?.isValid()) {
      return;
    }
    changeDates(null, asMoment);
  }

  const changeDates = (newStartDateAsMoment, newEndDateAsMoment) => {
    newStartDateAsMoment = newStartDateAsMoment ?? getStartDateAsMoment();
    newEndDateAsMoment = newEndDateAsMoment ?? getEndDateAsMoment();

    if (newStartDateAsMoment > newEndDateAsMoment) {
      newEndDateAsMoment = newStartDateAsMoment;
    }

    setSelectedDates((prevState) => ({
      ...prevState,
      startAsString: newStartDateAsMoment?.format(STRING_DATE_FORMAT) ?? prevState.startAsString,
      endAsString: newEndDateAsMoment?.format(STRING_DATE_FORMAT) ?? prevState.endAsString
    }));
  };

  const shiftDates = (amount) => {
    const newStartDateAsMoment = getStartDateAsMoment()?.add(amount, "days");
    const newEndDateAsMoment = getEndDateAsMoment()?.add(amount, "days");

    setSelectedDates((prevState) => ({
      ...prevState,
      startAsString: newStartDateAsMoment?.format(STRING_DATE_FORMAT) ?? prevState.startAsString,
      endAsString: newEndDateAsMoment?.format(STRING_DATE_FORMAT) ?? prevState.endAsString
    }));
  };

  return (
    <Container textAlign="center">
      <Button
        name="shiftBack"
        icon
        primary
        size='tiny'
        onClick={(e) => {
          shiftDates(-getRangeInDays());
          disableFormSubmit && e.preventDefault();
        }}
      >
        <Icon name="arrow left" />
      </Button>
      <input
        type="date"
        name="start"
        value={selectedDates?.startAsString ?? ''}
        onChange={onChangeStart}
      />
      &nbsp;{t("MEDICATION_DATE_RANGE_TO")}&nbsp;
      <input
        type="date"
        name="end"
        value={selectedDates?.endAsString ?? ''}
        onChange={onChangeEnd}
      />
      &nbsp;
      <Button
        name="shiftForward"
        icon
        primary
        size='tiny'
        onClick={(e) => {
          shiftDates(getRangeInDays());
          disableFormSubmit && e.preventDefault();
        }}
      >
        <Icon name="arrow right" />
      </Button>
    </Container>
  )
};

export default withTranslation()(DateRangeControls);