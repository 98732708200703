import React, { useContext, useEffect, useRef, useState} from "react";
import {  Message, Progress } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AttachmentService, {
  MEDIA_STATE,
} from "../../services/AttachmentService";
import {QUESTION_TYPES} from "atom5-branching-questionnaire";
import AttachmentPreview from "../../components/attachments/AttachmentPreview";
import VideoPlayer from "../../components/media/VideoPlayer/VideoPlayer";
import AttachmentDownload from "./AttachmentDownload";
import ConfigContext from "../../context/ConfigContext";
import AttachmentWorkflowStatusOverride from "./AttachmentWorkflowStatusOverride";
import {getHandsAnalysis, HAND_SIDE, MediapipeHand, ChannelList} from "atom5-data-analysis";
import VideoPlayerWithHandsOverlay from "./VideoPlayerWithHandsOverlay";
import ChannelListScatterChart from "../../data-analysis/ui/graphs/ChannelListScatterChart";
import {   addAnnotation, addRectangleAnnotation } from "atom5-data-analysis/ui/web"
import {getVideoPlayerConfig} from "./AttachmentInline";
import {v4 as uuid_v4} from "uuid";
const MEDIA_SIZE = {
  SMALL: {
    width: 500,
  },
  NORMAL: {
    width: 750,
  },
  LARGE: {
    width: 1000,
  },
};



const AttachmentInlineWithAnalysisVhot = ({ question, reference, subjectId, t, blurredOnly, hasAllowSubjectMediaAudioPermission }) => {
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaState, setMediaState] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [blobData, setBlobData] = useState(undefined);
  const [annotations, setAnnotations] = useState([])
  const config = useContext(ConfigContext);
  //const trackingEnabled = typeHelper.parseBool(question.config?.trackingConfig?.enabled)

  const trackingEnabled = true;

  const [leftHand, setLeftHand] = useState(new MediapipeHand(HAND_SIDE.LEFT));
  const [rightHand, setRightHand] = useState(new MediapipeHand(HAND_SIDE.RIGHT));


  const vHotAnalysis = useRef(getHandsAnalysis('vhot'));
  const [channelList] = useState(new ChannelList(20,100));

  const [videoPlayerAnnotations, setVidePlayerAnnotations] = useState([]);

  useEffect(()=>{
    channelList.addDataChannel(vHotAnalysis.current.channelLeftHandExtensionNorm,"blue",2,"y");
    channelList.addDataChannel(vHotAnalysis.current.channelRightHandExtensionNorm,"red",2,"y");
    channelList.addDataChannel(vHotAnalysis.current.channelLeftHandExtensionChange,"darkBlue",2,"y1");
    channelList.addDataChannel(vHotAnalysis.current.channelRightHandExtensionChange,"darkRed",2,"y1");
  }, [channelList])

  //channelList.addDataChannel(vHotAnalysis.channelLeftHandOpenCloseState,"black",2,"y1");
  //channelList.addDataChannel(vHotAnalysis.channelRightHandOpenCloseState,"black",2,"y1");

  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0); // Initial slider value
  const [maxTimeSeconds, setMaxTimeSeconds] = useState(0)

  const updateCharts =() => {
    channelList.emitDataChangedEvent();
    setAnnotations(extractHotAnnotations(vHotAnalysis.current.vHots))
  };

  function extractHotAnnotations (vHots) {

    const annotations = [];
    const fontSize = 14;
    const rectHeight = 0.1;
    let line = 1.5;

    if(vHots.leftHand.length > 0){
      const vHot = vHots.leftHand[0];
      addAnnotation(annotations, vHot.endTimeClosed, line, 'Opening Left Hand ' + vHot.durationOpening + " ms",fontSize);
      addRectangleAnnotation(annotations, vHot.initTimeClosed, vHot.endTimeClosed, line, rectHeight, "blue");
      line -= 0.2
      addRectangleAnnotation(annotations, vHot.initTimeOpening, vHot.endTimeOpening, line, rectHeight, "darkBlue");
    }

    if(vHots.rightHand.length > 0){
      const vHot = vHots.rightHand[0];
      addAnnotation(annotations, vHot.endTimeClosed, line, 'Opening Right Hand ' + vHot.durationOpening + " ms",fontSize);
      addRectangleAnnotation(annotations, vHot.initTimeClosed, vHot.endTimeClosed, line, rectHeight, "red");
      line -= 0.2
      addRectangleAnnotation(annotations, vHot.initTimeOpening, vHot.endTimeOpening, line, rectHeight, "darkRed");
    }

    return annotations;
  }

  const loadContent = async () => {
    const attachmentResponse = await AttachmentService.getAttachment(
        subjectId,
        reference,
        blurredOnly
    );

    const attachmentVariants = await AttachmentService.getAttachmentVariants(subjectId,reference,blurredOnly);
    const handAttachment = attachmentVariants.find(map => map["mediaType"]?.includes("hand.mediapipe") || false);

    if(handAttachment && AttachmentService.getMediaStateFromString(handAttachment.state) === MEDIA_STATE.COMPLETED){
      AttachmentService.getAttachmentInline(
          subjectId,
          reference,
          blurredOnly,
          (progress) => {
            //console.log(progress.percentage);
          },
          (handDataUrl, blob) => {
            vHotAnalysis.current.loadAttachment(handDataUrl,displayResults,{})

          },
          (err) => {
            console.error('Error getAttachmentInline', err)
          },
          handAttachment.variantReference,
      )
    }
    setAttachment(attachmentResponse);
    if (attachmentResponse && attachmentResponse.state) {
      setMediaState(AttachmentService.getAttachmentVariantStateMapping(attachmentResponse.type,attachmentResponse.category,attachmentResponse.state));
    }
  };

  const displayResults = (results) => {
    setMaxTimeSeconds(Math.max(vHotAnalysis.current.leftHandChannel.getDuration() / 1000,
        vHotAnalysis.current.rightHandChannel.getDuration() / 1000))
    setVidePlayerAnnotations(extractVideoPlayerAnnotations(vHotAnalysis.current.vHots.leftHand,vHotAnalysis.current.vHots.rightHand))
    updateCharts();
  }

  const extractVideoPlayerAnnotations = (vhotLeft, vhotRight) => {
    const annotations = [];
    if(vhotLeft){
      vhotLeft.forEach((vhot) => {
        annotations.push({ id: uuid_v4(), timeStart: vhot.initTimeOpening, timeEnd: vhot.endTimeOpening, level: 1, category: { type: 'INTERVAL', code: 'HOT_LEFT', color: "blue"} })
      });
    }

    if(vhotRight){
      vhotRight.forEach((vhot) => {
        annotations.push({ id: uuid_v4(), timeStart: vhot.initTimeOpening, timeEnd: vhot.endTimeOpening, level: 1, category: { type: 'INTERVAL', code: 'HOT_RIGHT', color: "red"} })

      });
    }
    return annotations;
  }

  useEffect(() => {
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (
        mediaState !== MEDIA_STATE.COMPLETED &&
        mediaState !== MEDIA_STATE.LEGACY
    ) {
      return;
    }
    AttachmentService.getAttachmentInline(
        subjectId,
        reference,
        blurredOnly,
        (progress) => {
          setLoadingPercentage(progress.percentage);
        },
        (mediaDataUrl, blob) => {
          setMediaUrl(mediaDataUrl);
          setBlobData(blob);
          setIsLoaded(true);
        },
        (err) => {
          console.error('Error getAttachmentInline', err)
          setError(err);
          setIsLoaded(false);
        }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState]);

  const onMediaLoadError = (err) => {
    console.error('onMediaLoadError', err)

    setError(err);
  };

  const onTimeUpdate = (timeMS) => {
    setLeftHand(vHotAnalysis.current.leftHandChannel.getDataTime(timeMS));
    setRightHand(vHotAnalysis.current.rightHandChannel.getDataTime(timeMS));
    setCurrentTimeSeconds(timeMS / 1000);
  }

  const getPlayer = () => {
    const annotationCategories = [
      {"code":"HOT_LEFT","type":"INTERVAL","titleDefault":"HOT Left Hand","color": "red", "level":1, "titleTranslationKey":"VIDEO_PLAYER_MARKERS_CATEGORY_HOT_LEFT"},
      {"code":"HOT_RIGHT","type":"INTERVAL","titleDefault":"HOT Right Hand","color": "blue", "level":1, "titleTranslationKey":"VIDEO_PLAYER_MARKERS_CATEGORY_HOT_RIGHT"}
    ]
    const videoPlayerConfig = getVideoPlayerConfig(config,question,mediaUrl,hasAllowSubjectMediaAudioPermission,onMediaLoadError,annotationCategories);

    if(trackingEnabled){
      return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <VideoPlayerWithHandsOverlay
                {...videoPlayerConfig}
                onTimeUpdate={onTimeUpdate}
                leftHand={leftHand}
                rightHand={rightHand}
                annotations = {videoPlayerAnnotations}
            />
            <ChannelListScatterChart externalAnnotations = {annotations} channelList = {channelList} selectedPosition = {currentTimeSeconds}  minLeft = {0}  maxLeft = {+1} minRight = {0}  maxRight = {1} minX = {0} maxX = {Math.round(maxTimeSeconds)} />
          </div>
      );
    }else {

      return <VideoPlayer {...videoPlayerConfig} />;
    }
  }

  return (
      <>
        {!isLoaded &&
            !error &&
            (mediaState === MEDIA_STATE.COMPLETED ||
                mediaState === MEDIA_STATE.LEGACY) && (
                <Progress percent={loadingPercentage} indicating />
            )}
        {error &&
            (mediaState === MEDIA_STATE.COMPLETED ||
                mediaState === MEDIA_STATE.LEGACY) && (
                // Error
                <Message negative>{t("ERROR_LOADING_VIDEO")}</Message>
            )}
        {mediaState !== null &&
            mediaState !== MEDIA_STATE.COMPLETED &&
            mediaState !== MEDIA_STATE.LEGACY && (
                // Have state, Not Completed, Not Legacy - show info
                <>
                  <Message
                      icon={mediaState?.displayProperties?.icon}
                      info={mediaState?.displayProperties?.isInfo}
                      content={t(mediaState?.translationKey, mediaState?.fallbackText)}
                      style={{ width: "600px" }}
                  />
                </>
            )}
        {(mediaState === MEDIA_STATE.COMPLETED ||
            mediaState === MEDIA_STATE.LEGACY) && (
            // Completed or Legacy - Display It
            <>
              {(question.type === QUESTION_TYPES.IMAGE ||
                  question.type === QUESTION_TYPES.SIGNATURE) && (
                  <>
                    {isLoaded && !error && (
                        <>
                          <img
                              width={MEDIA_SIZE.SMALL.width}
                              src={mediaUrl}
                              alt={reference}
                              data-value={reference}
                          />
                        </>
                    )}
                  </>
              )}
              {question.type === QUESTION_TYPES.VIDEO && (
                  <>
                    {isLoaded && getPlayer()}
                  </>
              )}
              {question.type === QUESTION_TYPES.FILE && (
                  <>
                    {isLoaded && !error && (
                        <AttachmentPreview
                            question={question}
                            attachment={attachment}
                            url={mediaUrl}
                            blob={blobData}
                        />
                    )}
                  </>
              )}

              <AttachmentDownload
                  subjectId={subjectId}
                  reference={reference}
                  blurredOnly={blurredOnly}
                  mediaState={mediaState}
              />
              <AttachmentWorkflowStatusOverride
                  subjectId={subjectId}
                  attachmentId={attachment?.attachmentId}
                  reference={reference}
                  workflowStatus={attachment.workflowStatus}
              />
            </>
        )}
      </>
  );
};

export default withTranslation()(AttachmentInlineWithAnalysisVhot);
