import React, { memo } from 'react';
import { Grid, TextArea } from 'semantic-ui-react';

const SimpleTranslationTableRow = memo(({ i, baseTranslation, editingTranslation, approxCharPerLine, questionCode, questionType, showEmptyBase, updateTranslation }) => {
  return (
    <Grid.Row style={{ backgroundColor: i % 2 === 0 ? '#fff8' : '', borderRadius: '4px' }}>
      <Grid.Column
        width={8}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {showEmptyBase && <div style={{ fontSize: '12px', color: '#444' }}><span style={{ fontWeight: 900, paddingRight: '4px' }}>{`${questionCode}  `}</span>{`${questionType}`}</div>}
          <div>{baseTranslation}</div>
        </div>
      </Grid.Column>
      <Grid.Column width={8}>
        <TextArea
          rows={editingTranslation.translation.length / approxCharPerLine + 1} fluid value={editingTranslation.translation} onChange={(_e, v) => {
            updateTranslation({ ...editingTranslation, translation: v.value });
          }}
        />
      </Grid.Column>
    </Grid.Row>
  );
});

export default SimpleTranslationTableRow;
