import React from 'react'
import BooleanValueEditor from '../simpleValueEditors/BooleanValueEditor'

function DefaultConfigEditors({ value, onChange }) {
  return (
    <div style={{ padding: "20px 0px" }}>
      <h4>Default</h4>
      <div style={{ paddingLeft: "20px" }}>
        <BooleanValueEditor pathKey="noValidationOnHidden" label={"No Validation on Hidden"} value={value?.noValidationOnHidden} onChange={onChange} />
        <BooleanValueEditor pathKey="isReadOnly" label={"is Read Only?"} value={value?.isReadOnly} onChange={onChange} />
        <BooleanValueEditor pathKey="useMarkup" label={"use Markup?"} value={value?.useMarkup} onChange={onChange} />
        <BooleanValueEditor pathKey="isCustomisable" label={"is Label Customisable?"} value={value?.isCustomisable} onChange={onChange} />
        <BooleanValueEditor pathKey="showInExport" label={"Show in Export"} value={value?.showInExport} onChange={onChange} />
        <BooleanValueEditor pathKey="showOnDashboard" label={"Show on Dashboard"} value={value?.showOnDashboard} onChange={onChange} />
      </div>
    </div>
  )
}

export default DefaultConfigEditors