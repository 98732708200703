import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ChildQuestionnaire from "./ChildQuestionnaire";
import React from "react";

const RelatedQuestionnaireHelpers = {
  buildQuestionnaireItemLink: (
    t,
    questionnaire,
    questionnaireDefinition
  ) => {
    const isCompleted = questionnaire.completionDate != null;
    const submitOrViewUrlSegment = isCompleted ? '/view/' : '/submit/';
    const questionnaireLink = (
      <Link
        to={
          '/app/subject/' +
          questionnaire.subjectId +
          '/questionnaire-type/' +
          questionnaireDefinition.type +
          '/' +
          questionnaireDefinition.code +
          submitOrViewUrlSegment +
          questionnaire.id
        }
      >
        {t(
          ['RELATED_QUESTIONNAIRE_GOTO_QUESTIONNAIRE', 'ATTACHMENTS_SEARCH_RESULTS_GOTO_QUESTIONNAIRE'],
          'Go to Questionnaire'
        )}
      </Link>
    );
    return questionnaireLink;
  },

  buildChildQuestionnaireItem: (t, questionnaire, questionnaireDefinition) => {
    const questionnaireLink = RelatedQuestionnaireHelpers.buildQuestionnaireItemLink(
      t,
      questionnaire,
      questionnaireDefinition
    );
    const isCompleted = questionnaire.completionDate != null;
    const completedStatusText = isCompleted
      ? t(['RELATED_QUESTIONNAIRE_STATE_COMPLETED', 'ATTACHMENTS_SEARCH_QUESTIONNAIRE_STATE_COMPLETED'], 'Completed')
      : t(['RELATED_QUESTIONNAIRE_STATE_NOT_COMPLETED', 'ATTACHMENTS_SEARCH_QUESTIONNAIRE_STATE_NOT_COMPLETED'], 'Not completed'
      );
    const ariaLabelText = `${questionnaireDefinition.label}: ${completedStatusText}`;
    return (
      <li key={questionnaire.id}>
        <ChildQuestionnaire
          label={questionnaireDefinition.label}
          questionnaire={questionnaire}
          isCompleted={isCompleted}
          completedStatusText={completedStatusText}
          ariaLabelText={ariaLabelText}
          questionnaireLink={questionnaireLink}
        />
      </li>
    );
  }
}

export default RelatedQuestionnaireHelpers;
