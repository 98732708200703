import React from 'react'
import TextValueEditor from './simpleValueEditors/TextValueEditor'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import LanguageGroup from './simpleValueEditors/LanguageGroup'
import ArrayGroup from './simpleValueEditors/ArrayGroup'
import ObjectGroup from './simpleValueEditors/ObjectGroup'
import NumberValueEditor from './simpleValueEditors/NumberValueEditor'
import SelectValueEditor from './simpleValueEditors/SelectValueEditor'
import BooleanValueEditor from './simpleValueEditors/BooleanValueEditor'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function SliderConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <SelectValueEditor pathKey={'type'} label={"Type"} arrayOfValues={["EQ5D", "NRS"]} />
      <NumberValueEditor pathKey="step" label={"Step"} />
      {config.type === "EQ5D" && <>
        <LanguageGroup pathKey='label1' label={"Top label"} />
        <LanguageGroup pathKey='label2' label={"Value label"} />
        <LanguageGroup pathKey='label3' label={"Bottome label"} />
      </>}
      <ArrayGroup pathKey='icons' label="Icons" >
        <ObjectGroup >
          <TextValueEditor pathKey="icon" label={"Icon"} />
          <NumberValueEditor pathKey="value" label={"Value (Optional)"} />
          <TextValueEditor pathKey="color" label={"Color"} />
        </ObjectGroup>
      </ArrayGroup>

      {config.marks !== undefined && <SelectValueEditor pathKey="marksComponent" arrayOfValues={["VALUE_LABEL"]} label={"Marks Component"} />}
      {config.marks !== undefined && <TextValueEditor pathKey="marksSpacing" label={"Marks Spacing"} />}
      <ArrayGroup pathKey="marks" label={"Marks"}>
        <ObjectGroup >
          <NumberValueEditor pathKey="value" label={"Value"} />
          <LanguageGroup pathKey='label' label={"Label"} />
        </ObjectGroup>
      </ArrayGroup>

      <LanguageGroup pathKey='labelMin' label={"Label Min"} />
      <LanguageGroup pathKey='labelMax' label={"Label Max"} />
      <TextValueEditor pathKey="displaySuffix" label={"Display Suffix"} />
      <BooleanValueEditor pathKey="shouldBookendSuffix" label={"Should Bookend Suffix"} />
    </ConfigWrapper>
  )
}

export default SliderConfigEditor