import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

const DeviceVersionStatus = ({ currentInstalledVersionSupported, currentInstalledVersion, t }) => {
    let className = '';
    let icon = null;
    const version = currentInstalledVersion ? currentInstalledVersion : "-";
    if (version === '-'){
        return { className, content: <>{version}</> };
    }
    if (currentInstalledVersionSupported === 0) {
        className = 'positive';
        icon = <Popup trigger={<Icon name='checkmark' />} content={t("DEVICE_LIST_TABLE_TOOLTIP_ON_LATEST_VERSION", "On latest version")} basic />;
    } else if (currentInstalledVersionSupported === 1) {
        className = 'warning';
        icon = <Popup trigger={<Icon name='exclamation' />} content={t("DEVICE_LIST_TABLE_TOOLTIP_NEWER_VERSION_AVAILABLE", "Newer version available")} basic />;
    } else if (currentInstalledVersionSupported === 2) {
        className = 'negative';
        icon = <Popup trigger={<Icon name='attention' />} content={t("DEVICE_LIST_TABLE_TOOLTIP_UNSUPPORTED_VERSION", "Unsupported version, Needs upgrade")} basic />;
    } else if (currentInstalledVersionSupported === 3) {
        className = 'negative';
        icon = <Popup trigger={<Icon name='close' />} content={t("DEVICE_LIST_TABLE_TOOLTIP_PLATFORM_NOT_FOUND", "Platform not supported")} basic />;
    }

    return { className, content: <>{icon} {version}</> };
};

export default DeviceVersionStatus;