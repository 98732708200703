import React from "react";
import { withTranslation } from "react-i18next";
import {Table} from "semantic-ui-react";
import Page from "../../components/page/Page";
import licenses from "../../licenses.json"

const LicensesPage = (props) => {
  const { t } = props;

  return (
    <Page
      name="DEPENDENCYLICENSES"
      header={t("DEPENDENCY_PAGE_HEADER", "Dependency Licenses")}
    >
      <Table columns={4}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("DEPENDENCY_NAME", "Name")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("DEPENDENCY_LICENSE", "License")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("DEPENDENCY_URL", "URL")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {licenses.map(dependency => {
            let dependencyLink = dependency.link;
            if (dependencyLink.startsWith('git+')) {
              dependencyLink = dependencyLink.replace('git+', '');
            }
            if (dependencyLink.startsWith('git://')) {
              dependencyLink = dependencyLink.replace('git://', 'https://');
            }
            if (dependencyLink.startsWith('ssh://git@')) {
              dependencyLink = dependencyLink.replace('ssh://git@', 'https://');
            }
            return (
              <Table.Row key={dependency.name}>
                <Table.Cell>{dependency.name}</Table.Cell>
                <Table.Cell>{dependency.licenseType}</Table.Cell>
                <Table.Cell>
                  {dependencyLink.startsWith("http") &&
                    <a
                      href={dependencyLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {dependencyLink}
                    </a>
                    }
                </Table.Cell>
            </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Page>
  );
};

export default withTranslation()(LicensesPage);
