import {
  QUESTION_SUBTYPES,
  fixedValueEvaluator,
} from "atom5-branching-questionnaire";
import React, {useCallback, useEffect, useState} from "react";
import DOMPurify from "dompurify";
import { Button, Form } from "semantic-ui-react";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import { QUESTION_TYPES } from "atom5-branching-questionnaire";
import SelectorDisplay from "../additional/SelectorDisplay";
import AparitoSwitch from "../../components/questionnaire/AparitoSwitch";
import withContainer from "./hocs/withContainer";

const FixedValue = ({ question, subtype, value, changeValue, isReadOnly }) => {
  const [isUnselectableArray, setIsUnselectableArray] = useState(
    Array(question.answers.length).fill(false)
  );

  const updateUnselectable = useCallback((selectedValues) => {
    let isDisabled = [];
    question.answers.forEach((answer) => {
      if (question?.config?.answers?.[answer.code]?.condition) {
        if (
            !fixedValueEvaluator.evaluateTree(
                question.config.answers[answer.code].condition,
                selectedValues
            )
        ) {
          isDisabled.push(answer.code);
        }
      }
    });
    return isDisabled
  }, [question.answers, question.config.answers]);

  useEffect(()=>{
    const unselectableValues = updateUnselectable(value)
    setIsUnselectableArray(unselectableValues)
  }, [updateUnselectable, value])

  const getOnChange = (isCheckbox) => {
    if (isCheckbox) {
      return toggleChecked;
    } else {
      return (changedValue) => {
        changeValue([changedValue]);
      };
    }
  };

  const toggleChecked = async (changedValue) => {
    if (isUnselectableArray.includes(changedValue)) {
      return;
    }

    let toggledAnswerCode = changedValue;
    let modifiedValueArray = [...value];

    // If the pressed value was in the array it is about to be deselected, so remove it.
    // If it was not in the array is is about to be selected, so add it. Therefore the new
    // validity can be calculated based on current change.
    if (modifiedValueArray.includes(toggledAnswerCode)) {
      modifiedValueArray = modifiedValueArray.filter(
        (val) => val !== toggledAnswerCode
      );
    } else {
      modifiedValueArray = modifiedValueArray.concat([toggledAnswerCode]);
    }

    const isDisabled = updateUnselectable(modifiedValueArray);
    setIsUnselectableArray(isDisabled);

    let selectedValues = [...value];
    let wasNewValuePresent = false;

    value.forEach((answer, i) => {
      if (isDisabled.includes(answer) || answer === changedValue) {
        selectedValues.splice(i, 1);
        if (answer === changedValue) {
          wasNewValuePresent = true;
        }
      }
    });

    if (!wasNewValuePresent) {
      selectedValues.push(changedValue);
    }

    changeValue(selectedValues);
  };

  const getFormOnChange = (isCheckbox) => {
    const onChange = getOnChange(isCheckbox);
    return (v) => {
      onChange(v);
    };
  };

  const labels = question.answers.map((answer, index) => {
    if (question?.type === QUESTION_TYPES.SINGLE_FIXED_VALUE) {
      if (question?.config?.useMarkup) {
        return (
          <div
            htmlFor={question.code + "_" + index}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(answer.label),
            }}
          />
        );
      }
    }

    if (question.config?.answers?.[answer.code]?.useMarkup) {
      return (
        <div
          htmlFor={question.code + "_" + index}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer.label) }}
        />
      );
    }
    return (
      <label
        htmlFor={question.code + "_" + index}
        style={question?.config?.style?.label}
      >
        {answer.label}
      </label>
    );
  });

  const getDropdown = (multiple) => {
    const options = question.answers.map((answer, index) => {
      return {
        key: question.code + "_" + index,
        text: answer.label,
        value: answer.code,
      };
    });

    return (
      <Form.Dropdown
        selection
        options={options}
        multiple={multiple}
        onChange={(e, { value: newValue }) => {
          changeValue(multiple ? newValue : [newValue]);
        }}
        value={multiple ? value : value[0]}
        disabled={isReadOnly}
      />
    );
  };

  const getButtons = (isCheckbox) => {
    const options = question.answers.map((answer, index) => {
      const selected = value && value.indexOf(answer.code) >= 0;
      const buttonStyle = selected
        ? styles.selectedButton
        : styles.unselectedButton;
      const setStateFn = getFormOnChange(isCheckbox);
      return (
        <Button
          type="button"
          size="large"
          fluid
          onClick={() => setStateFn(answer.code)}
          key={question.code + "_" + index}
          value={answer.code}
          disabled={isReadOnly || isUnselectableArray.includes(answer.code)}
          style={{
            ...buttonStyle,
            ...styles.commonButton,
            ...question?.config?.style?.selector,
          }}
        >
          <span>{labels[index]}</span>
        </Button>
      );
    });

    return options;
  };

  const getCheckboxOrRadios = (isCheckbox) => {
    const options = question.answers.map((answer, index) => (
      <SelectorDisplay
        key={question.code + "_" + index}
        onChange={getFormOnChange(isCheckbox)}
        value={answer.code}
        isCheckbox={isCheckbox}
        checked={value && value.indexOf(answer.code) >= 0}
        disabled={isReadOnly || isUnselectableArray.includes(answer.code)}
        label={labels[index]}
      />
    ));

    return options;
  };

  const getSwitches = (isCheckbox) => {
    const options = question.answers.map((answer, index) => (
      <AparitoSwitch
        checked={value && value.indexOf(answer.code) >= 0}
        onChange={() => getOnChange(isCheckbox)(answer.code)}
        label={labels[index]}
        isReadOnly={isReadOnly}
      />
    ));
    return options;
  };

  switch (subtype) {
    case QUESTION_SUBTYPES.FIXED_VALUE.BUTTON_MULTIPLE:
      return getButtons(true);

    case QUESTION_SUBTYPES.FIXED_VALUE.BUTTON_SINGLE:
      return getButtons(false);

    case QUESTION_SUBTYPES.FIXED_VALUE.CHECKBOX:
      return getCheckboxOrRadios(true);

    case QUESTION_SUBTYPES.FIXED_VALUE.DROPDOWN_SINGLE:
      return getDropdown(false);
    
      case QUESTION_SUBTYPES.FIXED_VALUE.DROPDOWN_MULTIPLE:
      return getDropdown(true);

    case QUESTION_SUBTYPES.FIXED_VALUE.RADIO:
      return getCheckboxOrRadios(false);

    case QUESTION_SUBTYPES.FIXED_VALUE.SWITCH_MULTIPLE:
      return getSwitches(true);

    case QUESTION_SUBTYPES.FIXED_VALUE.SWITCH_SINGLE:
      return getSwitches(false);

    default:
      if (isReadOnly) {
        const displayValues = question.answers
          .map((answer) => {
            if (value?.indexOf(answer.code) >= 0) {
              return answer.label;
            }
            return null;
          })
          .filter((label) => label !== null);

        return (
          <div
            data-question-answer-value={displayValues}
            name={question.code}
            style={{ paddingTop: 14, paddingLeft:11, paddingBottom: 14 }}
            id={question.code}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                displayValues.length > 0 ? displayValues.join(", ") : "X"
              ),
            }}
          />
        );
      }

      console.error(`Unsupported FIXED_VALUE subtype '${subtype}'.`);
      return null;
  }
};

const accentColor = "#F8991D";

const styles = {
  commonButton: {
    border: "3px solid " + accentColor,
    marginBottom: 10,
    marginTop: 10,
  },
  selectedButton: {
    backgroundColor: "#F8991D",
    color: "#FFFFFF",
  },
  unselectedButton: {
    backgroundColor: "#FFFFFF",
    color: "#F8991D",
  },
};

export default withContainer(withLabel(withError(FixedValue)));

export {FixedValue as FixedValueWithoutWrapping}
