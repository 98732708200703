import React, { useContext, useEffect, useState } from "react";
import BrandingService from "../../services/BrandingService";
import LocalDataService from "../../services/LocalDataService";
import ReactDOM from "react-dom";
import UserContext from "../../context/UserContext";
import STATIC_PAGE_INSERT_KEY_PAIRS from "../../constants/STATIC_PAGE_INSERT_KEY_PAIRS";

const StaticPage = ({ staticPageKey }) => {
  const [pageHtml, setPageHtml] = useState(null);
  const [isPageHtmlReady, setIsPageHtmlReady] = useState(false);
  const user = useContext(UserContext);

  useEffect(() => {
    const getPageHtml = async () => {
      const html = await BrandingService.getStaticPage(staticPageKey);
      setPageHtml(html);
    };
    getPageHtml();
  }, [staticPageKey]);

  useEffect(() => {
    if (pageHtml == null) {
      return;
    }
    setIsPageHtmlReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHtml]);

  // TODO: make a loading component
  if (pageHtml == null) {
    return <></>;
  }

  const configureVideoAutoStart = async () => {
    /*
    find all videos with the class atom5-video-autoplay-once
    if we have not yet hit the landing page, play the video(s)
    if we have hit the landing oage already, don't play!
    */
    let videos =
      (await document.getElementsByClassName("atom5-video-autoplay-once")) ||
      [];
    let hasHitLandingPage = LocalDataService.getHasHitLandingPage();
    if (hasHitLandingPage === false) {
      for (let i = 0; i < videos.length; i++) {
        let video = videos[i];
        if (video) {
          video.play();
        }
      }
    }
    LocalDataService.setHasHitLandingPage(true);
  };

  const configureVideoControlStop = async () => {
    /*
    find all videos with the class atom5-video-controlstop
    bind a click event to these to call stopVideosPlaying
    */
    let elms =
      (await document.getElementsByClassName("atom5-video-controlstop")) || [];
    for (let i = 0; i < elms.length; i++) {
      let elm = elms[i];
      if (elm) {
        elm.onclick = function () {
          stopVideosPlaying();
        };
      }
    }
  };

  const stopVideosPlaying = async () => {
    let videos =
      (await document.getElementsByClassName("atom5-video-allowstop")) || [];
    for (let i = 0; i < videos.length; i++) {
      let video = videos[i];
      if (video) {
        video.pause();
      }
    }
  };

  const inPageOnLoadScript = async () => {
    await configureVideoAutoStart();
    await configureVideoControlStop();
    LocalDataService.setHasHitLandingPage(true);
  };

  const renderCustomComponents = () => {
    const passedProps = { user };
    const elms = Object.entries(STATIC_PAGE_INSERT_KEY_PAIRS).map(([key, Component]) => {
      if (document.getElementById(key)) {
        return ReactDOM.createPortal(
          <Component {...passedProps} />,
          document.getElementById(key)
        );
      }
      return null;
    });
    return elms;
  };

  return (
    <>
      <script
        async
        dangerouslySetInnerHTML={{
          __html: inPageOnLoadScript(),
        }}
      />
      {isPageHtmlReady && renderCustomComponents()}
      <div dangerouslySetInnerHTML={{ __html: pageHtml }} />
    </>
  );
};

export default StaticPage;
