// Box.js
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {clamp} from "lodash";
import useKey from "../../../../hooks/useKey";

const Box = React.forwardRef(({ mousePoint, mouseDown, scaleFactor, halfXDeadzone, videoRect, halfYDeadzone, boxRect0, boxSelectActive, initiateBoxDrag0, handle, updateRect }, ref) =>
{
    const handleBox = useRef(null);
    // Handle Box Interaction
    const [offset, setOffset] = useState([0,0]);
    const [initial, setInitial] = useState([0,0]);
    let [isDragging, setIsDragging] = useState(false);
    let [isResizing, setIsResizing] = useState(false);
    const box = useRef(null);
    const initiateBoxDrag = (e) => {
        if (e.target === handleBox.current) {
            setIsResizing(true)
            setInitial([e.clientX, e.clientY])
        } else {
            setIsDragging(true)
            const offsetX = e.clientX - box.current.getBoundingClientRect().left;
            const offsetY = e.clientY - box.current.getBoundingClientRect().top;
            setOffset([offsetX,offsetY]);
        }
    }

    useKey("KeyI", () => nudgeBox(0, -1));
    useKey("KeyK", () => nudgeBox(0, 1));
    useKey("KeyJ", () => nudgeBox(-1, 0));
    useKey("KeyL", () => nudgeBox(1, 0));
    useKey("KeyU", ()=>resizeBox(-1,-1))
    useKey("KeyO", ()=>resizeBox(1,1))

    React.useImperativeHandle(ref, () => ({
        coords() {
            return coords;
        },
        _setBoxRect(_boxRect) {
            setBoxRect(_boxRect);
        },
        hide(){
            setBoxRect((prev) => ({ ...prev, visible: false }));
        },
    }));

    function nudgeBox(dx, dy) {
        setBoxRect((prev) => ({
            ...prev,
            x: prev.x + dx,
            y: prev.y + dy,
        }));
    }
    function resizeBox(dx, dy) {
        setBoxRect((prev) => ({
            ...prev,
            height: prev.height + dy,
            width: prev.width + dx,
        }));
    }

    useEffect(() => {
            if (isDragging) {
                const x = mousePoint.x - offset[0] - videoRect.x;
                const y = mousePoint.y - offset[1] - videoRect.y;
                if(boxRect.x !== x || boxRect.y !== y){
                    setBoxRect(prev=>({
                        ...prev,
                        x: x,
                        y: y,
                    }))
                }

            }
            if (isResizing) {
                const width = box.current.offsetWidth + (mousePoint.x - initial[0]);
                const height = box.current.offsetHeight + (mousePoint.y - initial[1]);
                setInitial([mousePoint.x,mousePoint.y])
                if(width !== boxRect.width || height !== boxRect.height){
                    setBoxRect(prev=>({
                        ...prev,
                        width: width,
                        height: height,
                    }))
                }

            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mousePoint]);

    useEffect(() => {

        const x = mouseDown.x
        const y = mouseDown.y


        if(!isDragging && !isResizing && !boxSelectActive){

            const newX = x - boxRect.width / 2;
            const newY = y - boxRect.height / 2;
            if(boxRect.x !== newX || boxRect.y !== newY){
                setBoxRect(prev=>({
                    ...prev,
                    x: x - prev.width / 2,
                    y: y - prev.height / 2,
                    width: prev.width,
                    height: prev.height,
                }))
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mouseDown]);



    const [boxRect, setBoxRect] = useState({
        visible: true,
        x: 0,
        y: 0,
        width: 100,
        height: 100,
    });

    // Calculate video coords based on scale and margin.
    // Video native size could be used as max clamp, but this does assure visible clamp consistency as
    // boxCoords are derived from coords on release.
    const coords = useMemo(() => {
        const x1 = Math.round(
            clamp(
                (boxRect.x - halfXDeadzone) / scaleFactor,
                0,
                (videoRect.width  - boxRect.width - halfXDeadzone * 2) / scaleFactor
            )
        );
        const y1 = Math.round(
            clamp(
                (boxRect.y - halfYDeadzone) / scaleFactor,
                0,
                (videoRect.height - boxRect.height - halfYDeadzone * 2) / scaleFactor
            )
        );

        const x2 = Math.round(
            clamp(
                x1 + boxRect.width / scaleFactor,
                0,
                (videoRect.width - halfXDeadzone * 2 ) / scaleFactor
            )
        );
        const y2 = Math.round(
            clamp(
                y1 + boxRect.height / scaleFactor,
                0,
                (videoRect.height - halfYDeadzone * 2) / scaleFactor
            )
        );

        return { x1, y1, x2, y2};
    }, [boxRect.height, boxRect.width, boxRect.x, boxRect.y, halfXDeadzone, halfYDeadzone, scaleFactor, videoRect.height, videoRect.width]);



    const stopBoxDrag = useCallback(() => {
        setIsDragging(false)
        setIsResizing(false)
        setBoxRect(prev=>({
            ...prev,
            x: coords.x1 * scaleFactor + halfXDeadzone,
            y: coords.y1 * scaleFactor + halfYDeadzone,
            height: (coords.y2 - coords.y1) * scaleFactor ,
            width: (coords.x2 - coords.x1) * scaleFactor,
        }))
    }, [coords.x1, coords.x2, coords.y1, coords.y2, halfXDeadzone, halfYDeadzone, scaleFactor]);


    useEffect(()=> {
        if(updateRect && boxSelectActive){
            updateRect(boxRect)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boxRect])

    if(!boxRect.visible){
        return <div></div>
    }else{
        return (
    <div
        className="box"
        ref={box}
        onMouseDown={initiateBoxDrag}
        onMouseUp={stopBoxDrag}
        style={{
            position: "absolute",
            left: boxRect.x,
            top: boxRect.y,
            width: boxRect.width,
            height: boxRect.height,
            resize: "both",
            borderStyle: "solid",
            borderWidth: 2,
            borderColor: boxSelectActive ? "#1111cc" : "#f8991d",
            backgroundColor: "#f8991d22",
            zIndex: 1000,
        }}
    >
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            padding: '2px 5px',
            fontSize: '20px',
            zIndex: 1001,
        }}>
            {boxRect.boxId}
        </div>
        <div
            ref={handleBox}
            className="handle"
            style={{
                width: '10px',
                height: '10px',
                backgroundColor: "#f8991d",
                position: 'absolute',
                bottom: 0,
                right: 0,
                cursor: 'se-resize',
            }}
        ></div>
    </div>)
    }}
);

export default Box;
