import GroupPermission from "../../GroupPermission";
import {DateTime} from "luxon";
import {Button, Table} from "semantic-ui-react";
import DateTimeService from "../../services/DateTimeService";
import SubjectQuestionnaireService from "../../services/SubjectQuestionnaireService";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import TriggerInfoDisplay from "./TriggerInfoDisplay";
import QuestionnaireActivationDateUpdateModal from "./QuestionnaireActivationDateUpdateModal";
import VisitDateDisplay from "../../questionnaires/display/VisitDateDisplay";

const SubjectDataTableMissedDisplay = ({t, permissions, definition, questionnaires, config, subjectId, subjectData, isVisitDateEnabledForSubject, hasViewSubjectVisitDatesPermission, subjectVisitDates, definitionCode, questionnaireType, refreshQuestionnaires}) => {

  const [activationModalQuestionnaire, setActivationModalQuestionnaire] = useState(null)

  const handleModalClosure = (hasUpdated) => {
    setActivationModalQuestionnaire(null);
    hasUpdated && refreshQuestionnaires && refreshQuestionnaires()
  };

  const hasModifyActivationWindowPermission =
      permissions.includes(
          GroupPermission.MODIFY_ACTIVATION_WINDOW
      );

  let missedQuestionnaireRows = questionnaires.map(
      (questionnaire) => {

        let showFillInButton = DateTime.fromISO(questionnaire.activationFromDate) < DateTime.utc()
            && (questionnaire.activationToDate == null || (DateTime.fromISO(questionnaire.activationToDate) > DateTime.utc()))
            && (questionnaire.closingDate == null || (DateTime.fromISO(questionnaire.closingDate) > DateTime.utc()));

        let preventSubmit = true;

        return (
            <Table.Row key={questionnaire.id}>
              {isVisitDateEnabledForSubject && hasViewSubjectVisitDatesPermission && (
                <Table.Cell>
                  <VisitDateDisplay
                    subjectData={subjectData}
                    subjectVisitDates={subjectVisitDates}
                    questionnaire={questionnaire}
                  />
                </Table.Cell>
              )}

              <Table.Cell>
                <TriggerInfoDisplay t={t} questionnaire={questionnaire} />
              </Table.Cell>
              <Table.Cell>
                {DateTimeService.build.asDisplayDateTime(
                    questionnaire.creationDate
                )}
              </Table.Cell>
              <Table.Cell>
                {questionnaire.deliveryDate
                    ? DateTimeService.build.asDisplayDateTime(
                        questionnaire.deliveryDate
                    )
                    : t("SUBJECT_QUESTIONNAIRE_NOT_YET_RECEIVED")}
              </Table.Cell>
              <Table.Cell>
                {questionnaire.activationFromDate
                    ? DateTimeService.build.asDisplayDateTime(
                        questionnaire.activationFromDate
                    )
                    : t("SUBJECT_QUESTIONNAIRE_NOT_YET_ACTIVATED")}
              </Table.Cell>
              <Table.Cell>
                {DateTimeService.build.asDisplayDateTime(
                    questionnaire.activationToDate
                )}
              </Table.Cell>
              <Table.Cell>
                {questionnaire.notifiedDate
                    ? DateTimeService.build.asDisplayDateTime(
                        questionnaire.notifiedDate
                    )
                    : t("SUBJECT_TAB_DATA_TABLE_SUBJECT_NOT_YET_NOTIFIED")}
              </Table.Cell>
              {hasModifyActivationWindowPermission && (
                  <Table.Cell>
                    <Button primary
                            onClick={() => setActivationModalQuestionnaire(questionnaire)}>
                      {t("SUBJECT_TAB_DATA_TABLE_UPDATE_ACTIVATION_WINDOW", "Update")}
                    </Button>
                  </Table.Cell>)
              }
              {!preventSubmit && SubjectQuestionnaireService.isQuestionnaireSubmittableByStaff(
                  config,
                  permissions,
                  definition
              ) && (
                  <Table.Cell>
                    {showFillInButton && (
                        <Button
                            as={Link}
                            primary
                            to={
                              "/app/subject/" +
                              subjectId +
                              "/questionnaire-type/" +
                              questionnaireType +
                              "/" +
                              definitionCode +
                              "/submit/" +
                              questionnaire.id
                            }
                        >
                          {t("GOTO_QUESTIONNAIRE")}
                        </Button>
                    )}
                  </Table.Cell>
              )}
            </Table.Row>
        );
      }
  );


  return <>
      <h4>
        {t("SUBJECT_TAB_DATA_TABLE_HEADER_MISSED_QUESTIONNAIRES", "Missed")}
      </h4>
      <Table selectable celled>
        <Table.Header>
          <Table.Row>
            {isVisitDateEnabledForSubject && hasViewSubjectVisitDatesPermission && (
              <Table.HeaderCell key={"header_visitDate"}>
                {t(['SUBJECT_TAB_DATA_TABLE_VISIT_DATE', 'VISITDATE_LABEL'], 'Visit')}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>
              {t("SUBJECT_TAB_DATA_TABLE_TRIGGER_CREATION_SOURCE","Trigger")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("SUBJECT_TAB_DATA_TABLE_CREATION_DATE_HEADER")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("SUBJECT_TAB_DATA_TABLE_DELIVERED_HEADER")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("SUBJECT_TAB_DATA_TABLE_AVAILABILITY_FROM_HEADER")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("SUBJECT_TAB_DATA_TABLE_AVAILABILITY_HEADER")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("SUBJECT_TAB_DATA_TABLE_SUBJECT_NOTIFIED")}
            </Table.HeaderCell>
            {hasModifyActivationWindowPermission && (
                <Table.HeaderCell>
                  {t("SUBJECT_TAB_DATA_TABLE_MODIFY_ACTIVATION_WINDOW", "Activation Window")}
                </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{missedQuestionnaireRows}</Table.Body>
      </Table>
    <QuestionnaireActivationDateUpdateModal
        isOpen={activationModalQuestionnaire !== null}
        questionnaire={activationModalQuestionnaire}
        handleModalClosure={handleModalClosure}
    />
  </>
}

export default SubjectDataTableMissedDisplay;