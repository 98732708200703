import React from 'react'
import { Button } from 'semantic-ui-react';

/**
 * Turns children into an array. Modifies props of children.
 */
function ArrayGroup({ children, pathKey, label, value, onChange, additionalProps }) {
  const handleChange = (value) => {
    onChange && onChange(pathKey, value)
  }

  const renderChildren = (value, index) => {
    return React.Children.map(children, (child) => {
      // Props are passed automaticaly, with automcatically passed values
      // being overwritable via child props
      return <React.Fragment key={pathKey + index}>
        {React.cloneElement(child, {
          ...{ pathKey, label, value, onChange, additionalProps },
          ...child.props,
          pathKey: pathKey + `[${index}]`,
          value: value,
        })}
      </React.Fragment>
    });
  };

  return <div style={{ padding: '4px' }}>
    {value === undefined && <Button primary compact size='small' onClick={() => handleChange([])} >{"Add " + label}</Button>}
    {Array.isArray(value) && <div style={{ display: 'flex', alignItems: 'baseline' }}><h4 style={{ paddingRight: '8px' }}>{label}</h4><Button color={'red'} compact size='small' onClick={() => handleChange(undefined)} >{"Remove " + label}</Button></div>}
    {Array.isArray(value) &&
      value.map((_v, i) => <div style={{ margin: '8px 4px', paddingLeft: '4px', borderLeft: '2px solid gainsboro' }} key={pathKey + "-div-" + i}>{renderChildren(_v, i)}</div>)
    }
    {Array.isArray(value) &&
      <div style={{ margin: '8px 4px', paddingLeft: '4px', borderLeft: '2px solid gainsboro' }}>{renderChildren(undefined, value.length)}</div>
    }
  </div>;
}

export default ArrayGroup;