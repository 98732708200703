import React, { Component } from "react";
import RSVP from "rsvp";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Input,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import MultiQuestionnaire from "../../questionnaires/MultiQuestionnaire";
import SubjectGroupSelect from "./SubjectGroupSelect";
import TrialService from "../../TrialService";
import DashboardContext from "../../context/DashboardContext";
import Page from "../../components/page/Page";

class SubjectCreatePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      questionnaires: [],
      inputPartSubjectNumber: "",
      fullSubjectNumber: "",
      subjectAlreadyExists: false,
      loading: true,
      selectedGroupCodes: this.props.match.params.groupCode,
      creating: false,
      error: false,
      errorMessage: undefined,
      invalidSubjectNumber: false,
    };

    const subjectRecordsPromise =
      SubjectService.getSubjectRecordQuestionnaireDefinitions()
        .then((questionnaires) => {
          questionnaires = questionnaires.filter(
            (questionnaire) =>
              !questionnaire.config.tab ||
              questionnaire.config.tab === "details"
          );
          this.setState({ questionnaires });
        })
        .catch(this.errorHandler);

    const trialPromise = TrialService.getCurrentTrial()
      .then((trial) => {
        this.setState({ trial });
      })
      .catch(this.errorHandler);

    RSVP.all([subjectRecordsPromise, trialPromise]).finally(() => {
      this.setState({ loading: false });
    });
  }

  getPrefix = (selectedGroupCodes) => {
    if (
      this.state.trial.subjectCodeGeneratorStrategy === "TRIAL_PREFIX_MANUAL"
    ) {
      return this.state.trial.code + this.state.trial.subjectCodeSeparator;
    }

    if (
        this.state.trial.subjectCodeGeneratorStrategy ===
        "TRIAL_GROUP_PREFIX_MANUAL"
    ) {
      const selectedGroupCodesToUse =
          selectedGroupCodes !== undefined
              ? selectedGroupCodes
              : this.state.selectedGroupCodes;
      if (selectedGroupCodesToUse.length >= 1) {
        return (
            this.state.trial.code +
            this.state.trial.subjectCodeSeparator +
            selectedGroupCodesToUse +
            this.state.trial.subjectCodeSeparator
        );
      }
    }

    if (
        this.state.trial.subjectCodeGeneratorStrategy ===
        "GROUP_PREFIX_MANUAL"
    ) {
      const selectedGroupCodesToUse =
          selectedGroupCodes !== undefined
              ? selectedGroupCodes
              : this.state.selectedGroupCodes;
      if (selectedGroupCodesToUse.length >= 1) {
        return (
            selectedGroupCodesToUse +
            this.state.trial.subjectCodeSeparator
        );
      }
    }

    if (this.state.trial.subjectCodeGeneratorStrategy === "MANUAL") {
      return "";
    }

    return this.props.t("GLOBAL_ERROR_TITLE");
  };

  errorHandler = (error) => {
    console.error("[SubjectCreatePage][errorHandler] Error", error);
    const friendlyErrorMessageKey = this.getFriendlyErrorMessageKey(error);
    const friendlyErrorMessage = this.props.t(friendlyErrorMessageKey);
    this.setState({
      creating: false,
      error: error,
      errorMessage: friendlyErrorMessage,
    });
  };

  onGroupsSubmitted = () => {
    if (this.isSubjectInvalid()) {
      return;
    }
    if (this.state.questionnaires.length > 0) {
      this.setState({ showQuestionnaires: true });
    } else {
      this.onSubmit({});
    }
  };

  onNumberChange = async (e) => {
    this.clearError();
    let subjectNumber = e.target.value.trim();
    this.setState({
      inputPartSubjectNumber: subjectNumber,
    });

    if (subjectNumber !== "") {
      const fullSubjectNumber = this.getPrefix() + subjectNumber;
      await this.checkDuplicateSubjectCode(fullSubjectNumber);
    } else {
      this.setState({
        fullSubjectNumber: subjectNumber,
        subjectAlreadyExists: false,
      });
    }
  };

  onGroupSelected = async (selectedGroupCodes) => {
    this.clearError();
    this.setState({ selectedGroupCodes });
    if (this.state.inputPartSubjectNumber !== "") {
      const fullSubjectNumber =
        this.getPrefix(selectedGroupCodes) + this.state.inputPartSubjectNumber;
      await this.checkDuplicateSubjectCode(fullSubjectNumber);
    }
  };

  clearError = async () => {
    this.setState({ error: false, errorMessage: undefined });
  };

  checkDuplicateSubjectCode = async (fullSubjectNumber) => {
    const doesSubjectExistWithCode =
      await SubjectService.doesSubjectExistWithCode(fullSubjectNumber);
    this.setState({
      fullSubjectNumber: fullSubjectNumber,
      subjectAlreadyExists: doesSubjectExistWithCode,
    });
    if (!doesSubjectExistWithCode) {
      this.clearError();
    }
  };

  isSubjectInvalid = () => {
    this.setState({ invalidSubjectNumber: false });
    if (this.state.loading) {
      return true;
    }

    if (this.state.selectedGroupCodes.length === 0) {
      return true;
    }

    //using a single value drop down makes this check redundant - RD-200
    //if (
    //  this.state.selectedGroupCodes.length !== 0 &&
    //  this.state.trial.subjectCodeGeneratorStrategy ===
    //    "TRIAL_GROUP_PREFIX_MANUAL"
    //) {
    //  return true;
    //}

    // If there is a subjectNumber required, from config
    if (this.state.trial.subjectCodeGeneratorStrategy.includes("MANUAL")) {
      // if subject already exists, it's invalid
      if (this.state.subjectAlreadyExists) {
        return true;
      }
      // this regex could/should be supplied through study config
      if (this.state.trial.subjectCodeRegex) {
        const regex = new RegExp(this.state.trial.subjectCodeRegex);
        if (!regex.test(this.state.inputPartSubjectNumber)) {
          this.setState({ invalidSubjectNumber: true });
          return true;
        }
      }
    }
    return false;
  };

  handlePageChange = () => {
    this.context.scrollToTop();
  };

  onSubmit = (answerMap, setHasSubmitted) => {
    this.setState({ creating: true });
    SubjectService.createSubject(
      [this.state.selectedGroupCodes],
      answerMap,
      this.state.inputPartSubjectNumber
    )
      .then((subject) => {
        this.props.history.replace("/app/subject/" +
            subject.Id +
            "/tabs/subject-record/details?new=true")
      })
      .catch((e) => {
        this.errorHandler(e);
        setHasSubmitted(false);
      });
  };

  getFriendlyErrorMessageKey = (errorResponse) => {
    if (!errorResponse) {
      return "GLOBAL_ERROR_GENERIC_MESSAGE";
    }
    if (
      errorResponse?.status === 409 ||
      errorResponse?.error === "Conflict" ||
      errorResponse?.message?.indexOf("already exists") > -1
    ) {
      return "ERROR_DUPLICATE_SUBJECT_NUMBER";
    }
    return "GLOBAL_ERROR_GENERIC_MESSAGE";
  };

  renderInputTextBox = () => {
    return this.getPrefix().length > 0 ?
        (
            <Input
              label={this.getPrefix()}
              onChange={this.onNumberChange}
              required={true}
              regex={this.state.trial.subjectCodeRegex}
            />
        ) : (
            <Input
              onChange={this.onNumberChange}
              required={true}
              regex={this.state.trial.subjectCodeRegex}
            />
        );
  }

  render() {
    const { t } = this.props;
    const groupsSubmitTextKey =
      this.state.questionnaires.length > 0
        ? "GLOBAL_BUTTON_NEXT"
        : "GLOBAL_BUTTON_CREATE";

    return (
      <Page
        header={t("SUBJECT_CREATE_HEADER")}
        subheader={t("SUBJECT_CREATE_SUBHEADER")}
      >
        {this.state.error && (
          <Message
            error
            header={t("GLOBAL_ERROR_TITLE")}
            content={this.state.errorMessage}
          />
        )}

        {!this.state.loading && (
          <Grid columns={"equal"}>
            <Grid.Column>
              {!this.state.showQuestionnaires && (
                <Segment>
                  <Form onSubmit={this.onGroupsSubmitted}>
                    <Header>{t("STAFF_FORM_DETAILS_HEADER")}</Header>
                    {this.state.trial.subjectCodeGeneratorStrategy.includes(
                      "MANUAL"
                    ) && (
                      <Form.Field>
                        <label>{t("SUBJECT_ID")}</label>
                        {this.renderInputTextBox()}
                        {this.state.subjectAlreadyExists && (
                          <p>{t("ERROR_DUPLICATE_SUBJECT_NUMBER")}</p>
                        )}
                        {this.state.invalidSubjectNumber && (
                          <p>{t("ERROR_INVALID_SUBJECT_NUMBER")}</p>
                        )}
                      </Form.Field>
                    )}
                    <SubjectGroupSelect
                      class="ui padded"
                      initialGroupsSelected={this.props.match.params.groupCode}
                      allowMultiple={false}
                      onGroupSelected={this.onGroupSelected}
                    />
                    <Button>{t(groupsSubmitTextKey)}</Button>
                  </Form>
                </Segment>
              )}
              {this.state.showQuestionnaires && (
                <Segment>
                  <MultiQuestionnaire
                    definitions={this.state.questionnaires}
                    onPageChange={this.handlePageChange}
                    onSubmit={this.onSubmit}
                    subjectId={-1}
                  />
                </Segment>
              )}
            </Grid.Column>
          </Grid>
        )}
      </Page>
    );
  }
}

SubjectCreatePage.contextType = DashboardContext;

export default withTranslation()(SubjectCreatePage);
