import RequestHelper from "../../RequestHelper";
import {serverAddress} from "../config/EnvConfig";

const TriggerService = {
  getTriggers: (triggerState, searchTerm) => {
    return RequestHelper.send(
      serverAddress + "/admin/triggers",
      {},
      "GET",
      { state: triggerState, searchQuery: searchTerm }
    );
  },
  getTrigger: (triggerId) => {
    return RequestHelper.send(
      serverAddress + "/admin/triggers/" + triggerId,
      {},
      "GET"
    );
  },
  getTriggersByActionContainsQuestionnaireDefinition: (questionnaireDefinitonCode) => {
    return RequestHelper.send(
      `${serverAddress}/admin/triggers/actionContainsQuestionnaireDefinition/${questionnaireDefinitonCode}`,
      {},
      "GET",
    );
  },
  editTrigger: (trigger, feedbackReason) => {
    const feedbackHeaderValue = feedbackReason
      ? `Editing trigger [${trigger.id}]`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    const requestBody = { ...initialRequestBody, trigger };

    return RequestHelper.send(
      serverAddress + "/admin/triggers/" + trigger.id,
      headers,
      "PUT",
      null,
      requestBody
    );
  },
  createTrigger: (trigger, feedbackReason) => {
    const feedbackHeaderValue = feedbackReason
      ? `Creating trigger: ${JSON.stringify(trigger)}`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    const requestBody = { ...initialRequestBody, trigger };

    return RequestHelper.send(
      serverAddress + "/admin/triggers",
      headers,
      "POST",
      null,
      requestBody
    );
  },
  getTriggerData: (triggerId) => {
    return RequestHelper.send(
      serverAddress +
        "/admin/triggers/" +
        triggerId +
        "/data",
      {},
      "GET"
    );
  },

  validateCronExpression(cronExpression) {
    const body = { cronExpression };
    return RequestHelper.send(
      serverAddress +
        "/admin/triggers/validatecronexpression",
      {},
      "POST",
      null,
      body
    );
  },

  getTriggersAsJson() {
    return RequestHelper.send(
      serverAddress + "/admin/triggers/asjson",
      {},
      "GET"
    );
  },

  updateTriggerIsActive: (triggerId, isActive, feedbackReason) => {
    const feedbackHeaderValueDescription = isActive ? "Active" : "Inactive";
    const feedbackHeaderValue = feedbackReason
      ? `Updating Trigger [${triggerId}] to be ${feedbackHeaderValueDescription}`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    const requestBody = { ...initialRequestBody, isActive: isActive };

    return RequestHelper.send(
      serverAddress +
        "/admin/triggers/" +
        triggerId +
        "/active",
      headers,
      "POST",
      null,
      requestBody
    );
  },
};

export default TriggerService;
