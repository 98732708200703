export class FPSCalculator {
  constructor(video) {
    this.video = video;
    this.lastMediaTime = null;
    this.lastFrameNum = null;
    this.hasSeeked = false;
    this.framesLength = [];
    this.fps = null;
    this.currentTime = null;

    const boundTicker = this.ticker.bind(this);
    const boundHandleSeek = this.handleSeek.bind(this);

    this.video.requestVideoFrameCallback(boundTicker)
    this.video.addEventListener("seeked", boundHandleSeek);

  }


  // https://stackoverflow.com/questions/72997777/how-do-i-get-the-frame-rate-of-an-html-video-with-javascript
  ticker(useless, metadata) {
    const boundTicker = this.ticker.bind(this);

    if(this.lastMediaTime === null){
      this.lastMediaTime = metadata.mediaTime
    }
    if(this.lastFrameNum === null){
      this.lastFrameNum = metadata.presentedFrames
    }
    const mediaTimeLength = Math.abs(metadata.mediaTime - this.lastMediaTime);
    const frameNumDiff = Math.abs(metadata.presentedFrames - this.lastFrameNum);
    if(frameNumDiff === 0 || mediaTimeLength === 0 ){
      this.video.requestVideoFrameCallback(boundTicker);
      return
    }

    const diff = mediaTimeLength / frameNumDiff;
    if (
        diff &&
        diff < 1 &&
        !this.hasSeeked &&
        this.framesLength.length < 50 &&
        this.video.playbackRate === 1 &&
        document.hasFocus()
    ) {

      this.framesLength.push(diff)
      this.fps = Math.round(1 / this.getAvg(this.framesLength));
    }

    this.currentTime = metadata.mediaTime;

    this.hasSeeked = false
    this.lastMediaTime = metadata.mediaTime;
    this.lastFrameNum = metadata.presentedFrames;

    this.video.requestVideoFrameCallback(boundTicker);
  }

  handleSeek() {
    this.framesLength.pop()
    this.hasSeeked = true;
  }

  getAvg(array) {
    return array.reduce((a, b) => a + b, 0) / array.length;
  }
}


