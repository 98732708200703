import RequestHelper from "../RequestHelper";
import {serverAddress} from "./config/EnvConfig";

export default {
  getUnsentDigestAlerts: () => {
    return RequestHelper.send(
        serverAddress +
        "/digest-alerts/unsent",
        {},
        "GET",
        null
    );
  },
  forceSendAllDigestAlerts: () => {
    return RequestHelper.send(
        serverAddress +
        "/digest-alerts/forceSendAllUnsent",
        {},
        "POST",
        null
    );
  }
};
