import React, { useMemo } from 'react'
import { Button, Dropdown } from 'semantic-ui-react';

function SelectValueEditor({ pathKey, arrayOfValues, value, onChange, label }) {
  const handleChange = (value) => {
    onChange && onChange(pathKey, value)
  }

  const options = useMemo(() => arrayOfValues.map((v) => {
    return { key: v, text: v, value: v }
  }), [arrayOfValues]);

  return (
    <div style={{ padding: '4px' }}>
      {value === undefined && <Button primary compact size='small' onClick={() => handleChange(arrayOfValues[0])} >{"Add " + label}</Button>}
      {value !== undefined && <div style={{ display: "flex", alignItems: 'center' }}>
        <p style={{ paddingRight: "20px" }}><b>{label}</b></p>
        <Dropdown
          placeholder='Select'
          fluid
          selection
          options={options}
          onChange={(_v1, v2) => handleChange(v2.value)}
          value={value}
        /><div style={{ paddingLeft: '20px' }}>
          <Button inverted color='red' compact onClick={() => { handleChange(undefined) }} >{"x"}</Button>
        </div></div>}
    </div>
  )
}

export default SelectValueEditor