import React from 'react'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import TextValueEditor from './simpleValueEditors/TextValueEditor'
import NumberValueEditor from './simpleValueEditors/NumberValueEditor'
import BooleanValueEditor from './simpleValueEditors/BooleanValueEditor'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function SignatureConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <TextValueEditor pathKey={"startButtonTranslationKey"} label={"Start Button Translation Key"} />
      <TextValueEditor pathKey={"returnedFromThirdPartyTranslationKey"} label={"Returned From Third Party Translation Key"} />
      <TextValueEditor pathKey={"successTranslationKey"} label={"Success Translation Key"} />
      <TextValueEditor pathKey={"documentTemplate"} label={"Document Template"} />
      <TextValueEditor pathKey={"signatureAnchorString"} label={"Signature Anchor String"} />
      <NumberValueEditor pathKey={"signatureAnchorOffsetPixelsY"} label={"Signature Anchor Offset Pixels Y"} />
      <NumberValueEditor pathKey={"signatureAnchorOffsetPixelsX"} label={"Signature Anchor Offset Pixels X"} />
      <BooleanValueEditor pathKey={"noValidationOnHidden"} label={"No Validation On Hidden?"} />
    </ConfigWrapper>
  )
}

export default SignatureConfigEditor