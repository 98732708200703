import React from 'react'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import ObjectGroup from './simpleValueEditors/ObjectGroup'
import ArrayGroup from './simpleValueEditors/ArrayGroup'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'
import MinMaxConfigEditor from './compoundValueEditors/MinMaxConfigEditor'

function MonthYearConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <ObjectGroup label={'Date Time Validation'} pathKey={'dateTimeValidation'} >
        <ArrayGroup pathKey='min' label={"Min"} >
          <MinMaxConfigEditor />
        </ArrayGroup>
        <ArrayGroup pathKey='max' label={"Max"} >
          <MinMaxConfigEditor />
        </ArrayGroup>
      </ObjectGroup>
    </ConfigWrapper>
  )
}
export default MonthYearConfigEditor