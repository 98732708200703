import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Page from '../../components/page/Page';
import GroupPermission from '../../GroupPermission';
import { Redirect, useParams } from 'react-router-dom';
import PermissionsService from '../../services/PermissionsService';
import { withTranslation } from 'react-i18next';
import VisitDateService from '../../services/VisitDateService';
import { Button, Dropdown, Form, Icon, Input, Message, Modal, Table } from 'semantic-ui-react';
import GeneralHelpers from '../../helpers/GeneralHelpers';
import ConfirmButtonWithFeedback from '../../components/dashboard/ConfirmButtonWithFeedback';
import Sortable from 'sortablejs/modular/sortable.core.esm.js'; // Lightweight import from docs, no excess plugins etc
import ConfirmSwitchWithFeedback from '../../components/dashboard/ConfirmSwitchWithFeedback';
import GroupsContext from '../../context/GroupsContext';
import { v4 as uuid_v4 } from "uuid";

const RELATIVE_TO_OPTIONS = ['consentDate', 'today'];

const UNIT_OPTIONS = [
  {
    key: 'DAYS',
    text: 'Days',
    value: 'DAYS'
  },
  {
    key: 'WEEKS',
    text: 'Weeks',
    value: 'WEEKS'
  },
  {
    key: 'MONTHS',
    text: 'Months',
    value: 'MONTHS'
  }, {
    key: 'YEARS',
    text: 'Years',
    value: 'YEARS'
  }
];

const VisitDateManagement = (props) => {
  const { t } = props;
  const { groupCode } = useParams();
  const isTrialLevel = groupCode == null;

  const sortableContainerRef = useRef();
  const groups = useContext(GroupsContext);

  const [hasPermission, setHasPermission] = useState();

  const [isEnabled, setIsEnabled] = useState(false);
  const [group, setGroup] = useState();

  const [configValue, _setConfigValue] = useState([]);
  const configValueRef = useRef(configValue);
  const setConfigValue = (arr) => {
    configValueRef.current = arr;
    _setConfigValue(arr);
  };
  const [reorderPromptData, setReorderPromptData] = useState();

  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedObject, setSelectedObject] = useState();

  const [addEditErrorMessage, setAddEditErrorMessage] = useState();
  const [deleteErrorMessage, setDeleteErrorMessage] = useState();

  const clearErrorMessages = () => {
    setAddEditErrorMessage();
    setDeleteErrorMessage();
  };

  useEffect(() => {
    populatePermissions();
    return () => {
      sortableContainerRef.current = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTrialLevel) {
      return;
    }
    popuateGroupInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCode]);

  const popuateGroupInformation = async () => {
    console.log('popuateGroupInformation groupCode', groupCode);
    const g = groups.find((group) => group.code === groupCode);
    console.log('popuateGroupInformation g', g);
    setGroup(g);
  };

  const populatePermissions = async () => {
    if (isTrialLevel) {
      setHasPermission(await PermissionsService.hasPermissionInAnyGroup(GroupPermission.MANAGE_TRIAL_VISITDATES));
    } else {
      setHasPermission(await PermissionsService.hasPermissionInGroup(groupCode, GroupPermission.MANAGE_SITE_VISITDATES));
    }
  };

  const handleItemReorder = async (e) => {
    setReorderPromptData({
      from: e.oldIndex,
      to: e.newIndex
    });
  };

  const handleItemReorderSubmit = async (feedbackReason) => {
    const updatedValue = [...configValueRef.current];
    GeneralHelpers.moveArrayElement(updatedValue, reorderPromptData.from, reorderPromptData.to);
    try {
      await setConfiguration(feedbackReason, updatedValue);
    } catch (error) {
      console.error('[VisitDateManagement][handleItemReorder]', error)
      // Reload on error to reinstate old order
      populateVisitDateConfigurations();
    }
    setReorderPromptData();
  }

  const handleItemReorderCancelClick = () => {
    populateVisitDateConfigurations();
    setReorderPromptData();
  }

  useEffect(() => {
    if (!hasPermission) {
      return;
    }

    sortableContainerRef.current = Sortable.create(
      document.getElementById('sortableTableBody'),
      {
        handle: '.handle-reorder',
        onEnd: handleItemReorder
      }
    );
    populateCoreSettings();
    populateVisitDateConfigurations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermission])

  const getData = async () => {
    if (isTrialLevel) {
      return await VisitDateService.Configuration.Dates.getConfigurationForTrial();
    } else {
      return await VisitDateService.Configuration.Dates.getConfigurationForSite(groupCode);
    }
  }

  const populateCoreSettings = async () => {
    try {
      if (isTrialLevel) {
        setIsEnabled(await VisitDateService.Configuration.Enabled.getIsEnabledForTrial());
      } else {
        setIsEnabled(await VisitDateService.Configuration.Enabled.getIsEnabledForSite(groupCode));
      }

    } catch (error) {
      console.error('[VisitDateManagement][populateCoreSettings]', error)
    }
  };

  const handleIsEnabledChange = async (feedbackReason, value) => {
    if (isTrialLevel) {
      await VisitDateService.Configuration.Enabled.setIsEnabledForTrial(feedbackReason, value);
    } else {
      await VisitDateService.Configuration.Enabled.setIsEnabledForSite(groupCode, feedbackReason, value);
    }
    setIsEnabled(value);
  };

  const populateVisitDateConfigurations = async () => {
    setConfigValue();
    clearErrorMessages();
    try {
      const value = await getData();
      setConfigValue(value || []);
    } catch (error) {
      console.error('[VisitDateManagement][populateData]', error)
    }
  };

  useEffect(() => {
    // None Selected
    if (selectedIndex == null) {
      setSelectedObject();
      return;
    }

    // New
    if (selectedIndex < 0) {
      setSelectedObject({
        internalId: uuid_v4(),
        dateRelativeUnit: 'MONTHS',
        durationUnit: 'MONTHS'
      });
      return;
    }

    // Edit
    setSelectedObject(configValue?.[selectedIndex]);
  }, [selectedIndex, configValue]);

  const handleEditClick = (selectedIndex) => {
    clearErrorMessages();
    setSelectedIndex(selectedIndex);
  };

  const setConfiguration = async (feedbackReason, updatedValue) => {
    if (isTrialLevel) {
      return await VisitDateService.Configuration.Dates.setConfigurationForTrial(feedbackReason, updatedValue);
    } else {
      return await VisitDateService.Configuration.Dates.setConfigurationForSite(groupCode, feedbackReason, updatedValue);
    }
  }

  const handleDeleteClick = async (selectedIndex, feedbackReason) => {
    clearErrorMessages();

    const updatedValue = [...configValue];
    updatedValue.splice(selectedIndex, 1);
    try {
      await setConfiguration(feedbackReason, updatedValue);
      populateVisitDateConfigurations();
    } catch (error) {
      setDeleteErrorMessage(GeneralHelpers.errorToString(error));
    }
  };

  const handleCopyFromTrialClick = async (feedbackReason) => {
    clearErrorMessages();
    await VisitDateService.Configuration.Dates.cloneDatesFromTrial(feedbackReason, groupCode);
    populateVisitDateConfigurations();
  };

  const buildVisitDateTableDataRow = (dateConfigEntry, i) => {
    return (
      <Table.Row key={i}>
        <Table.Cell key={i + '_reorderhandle'}>
          <Icon name='move' className={'handle-reorder mouse-pointer-handle'} />
        </Table.Cell>
        <Table.Cell key={i + '_relativeTo'} verticalAlign='top'>
          {dateConfigEntry.relativeTo}
        </Table.Cell>
        <Table.Cell key={i + '_dateRelative'} verticalAlign='top'>
          {dateConfigEntry.dateRelativeQuantity} {dateConfigEntry.dateRelativeUnit}
        </Table.Cell>
        <Table.Cell key={i + '_duration'} verticalAlign='top'>
          {dateConfigEntry.durationQuantity} {dateConfigEntry.durationUnit}
        </Table.Cell>
        <Table.Cell key={i + '_name'} verticalAlign='top'>
          {dateConfigEntry.name}
        </Table.Cell>
        <Table.Cell key={i + '_control'} verticalAlign='top'>
          <Button onClick={() => handleEditClick(i)}>
            {t('GLOBAL_BUTTON_EDIT', 'Edit')}
          </Button>
          <ConfirmButtonWithFeedback
            buttonText={t(
              'GLOBAL_BUTTON_DELETE',
              'Delete'
            )}
            headerText={t('VISITDATES_MANAGE_RESULTS_DELETE_TEXT', 'Enter a reason for this change')}
            confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
            color='red'
            cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
            onConfirm={(feedbackReason) => handleDeleteClick(i, feedbackReason)}
            mandatoryValidationText={t(
              "VISITDATES_MANAGE_RESULTS_DELETE_REASON_VALIDATION_TEXT",
              "Please supply a reason for the change."
            )}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  const buildVisitDateTable = () => {
    return (
      <>
        <Table selectable columns={12} compact>
          <Table.Header>
            <Table.Row key='header'>
              <Table.HeaderCell width={1} key='header_reorderhandle'></Table.HeaderCell>
              <Table.HeaderCell width={3} key='header_relativeTo'>
                {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_RELATIVETO', 'Relative To')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1} key='header_dateRelative'>
                {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_DATERELATIVE', 'Relative offset')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1} key='header_duration'>
                {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_DURAION', 'Duration')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2} key='header_name'>
                {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_NAME', 'Name')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2} key='header_controls'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body
            onClick={(e) => {
              e.preventDefault();
            }}
            id='sortableTableBody'
          >
            {configValue?.map((d, i) =>
              buildVisitDateTableDataRow(d, i)
            )}
          </Table.Body>
        </Table>
      </>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableOutput = useMemo(buildVisitDateTable, [configValue]);

  const getDefaultRelativeToListOptions = () => {
    return RELATIVE_TO_OPTIONS.map(t => {
      return <option key={t} value={t} />
    });
  };

  const relativeToListOptions = useMemo(getDefaultRelativeToListOptions, []);

  const buildPageTitle = () => {
    if (isTrialLevel) {
      return t('VISITDATES_MANAGE_PAGE_TITLE_TRIAL_LEVEL', 'Manage Trial Level Visit Dates');
    } else {
      return t('VISITDATES_MANAGE_PAGE_TITLE_SITE_LEVEL', 'Manage {{groupLabel}} Visit Dates', {
        groupLabel: group?.label,
      })
    }
  }

  const pageTitle = useMemo(buildPageTitle, [isTrialLevel, t, group]);

  if (hasPermission === false) {
    return <Redirect to='/' />;
  }

  const handleAddNewClick = () => {
    clearErrorMessages();
    setSelectedIndex(-1);
  };

  const closeModal = () => {
    setSelectedIndex();
  };

  const handleFormSubmit = async (feedbackReason) => {
    clearErrorMessages();
    setDeleteErrorMessage();

    const isEdit = selectedIndex >= 0;

    const updatedValue = [...configValue];
    if (isEdit) {
      updatedValue.splice(selectedIndex, 1, selectedObject);
    } else {
      updatedValue.push(selectedObject);
    }

    try {
      await setConfiguration(feedbackReason, updatedValue);
      populateVisitDateConfigurations();
      closeModal();
    } catch (error) {
      setAddEditErrorMessage(GeneralHelpers.errorToString(error));
    }
  };

  const handleAddEditCancelClick = () => {
    closeModal();
  };

  return (
    <Page
      name='VISITDATEMANAGEMENT'
      header={pageTitle}
    >
      {deleteErrorMessage && (
        <Message
          error
          header={t('GLOBAL_ERROR_TITLE', 'Error')}
          content={
            deleteErrorMessage?.length > 0 ? deleteErrorMessage : 'Fatal error, if it persists contact support'
          }
        />
      )}

      <h2>Is enabled?</h2>
      <ConfirmSwitchWithFeedback
        isChecked={isEnabled}
        onConfirm={handleIsEnabledChange}
        label={t(['GLOBAL_BUTTON_IS_ENABLED'], 'Is Enabled?')}
        headerText={t('VISITDATES_MANAGE_ENABLED_CONFIRM_TITLE', 'Are you sure you want to change this setting?')}
        confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
        cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
      />

      <h2>Dates</h2>
      {tableOutput}

      <Button
        primary
        onClick={handleAddNewClick}
      >
        {t(['GLOBAL_BUTTON_ADD'], 'Add')}
      </Button>

      {!isTrialLevel && configValue?.length === 0 && (
        <ConfirmButtonWithFeedback
          buttonText={t(
            'VISITDATES_MANAGE_COPY_FROM_TRIAL',
            'Copy from Trial level Visit Dates'
          )}
          headerText={t('VISITDATES_MANAGE_COPY_FROM_TRIAL_REASON_PROMPT', 'Enter a reason for this change')}
          confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
          color='grey'
          cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
          onConfirm={(feedbackReason) => handleCopyFromTrialClick(feedbackReason)}
          mandatoryValidationText={t(
            "VISITDATES_MANAGE_COPY_FROM_TRIAL_REASON_VALIDATION_TEXT",
            "Please supply a reason for the change."
          )}
        />
      )}

      <Modal
        open={selectedObject != null}
        closeIcon={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          <h3>{t('VISITDATES_MANAGE_ADDEDIT_TITLE', 'Manage visit')}</h3>
        </Modal.Header>
        <Modal.Content>

          {addEditErrorMessage && (
            <Message
              error
              header={t('GLOBAL_ERROR_TITLE', 'Error')}
              content={
                addEditErrorMessage?.length > 0 ? addEditErrorMessage : 'Fatal error, if it persists contact support'
              }
            />
          )}
          <Form>
            <Form.Field key='formField_relativeTo'>
              <label>
                {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_RELATIVETO', 'Relative To')}
              </label>
              <datalist id='relativeToList'>
                {relativeToListOptions}
              </datalist>
              <Input
                list='relativeToList'
                required={true}
                //  (Code Length x 2) + 1
                maxLength='511'
                onChange={(_e, data) => {
                  clearErrorMessages();
                  setSelectedObject({
                    ...selectedObject,
                    relativeTo: data?.value.trim().length > 0 ? data.value.trim() : undefined
                  });
                }}
                value={selectedObject?.relativeTo || ''}
              />
              <div>
                <small>
                  {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_RELATIVETO_NOTES', "Possible values are consentDate, subject.data['QuestionnaireDefinitionCode_QuestionDefinitionCode']")}
                </small>
              </div>
            </Form.Field>
            <Form.Field key='formField_dateRelative'>
              <label>
                {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_DATERELATIVE', 'Relative offset')}
              </label>
              <Input
                style={{ width: '100px' }}
                required={true}
                type='number'
                onChange={(_e, data) => {
                  setSelectedObject({
                    ...selectedObject,
                    dateRelativeQuantity: data?.value.trim().length > 0 ? data.value.trim() : undefined
                  });
                }}
                value={selectedObject?.dateRelativeQuantity || ''}
              />
              <Dropdown
                style={{ width: '200px' }}
                selection
                options={UNIT_OPTIONS}
                onChange={(_e, data) => {
                  setSelectedObject({
                    ...selectedObject,
                    dateRelativeUnit: data?.value.trim().length > 0 ? data.value.trim() : undefined
                  });
                }}
                value={selectedObject?.dateRelativeUnit}
              />
            </Form.Field>
            <Form.Field key='formField_duration'>
              <label>
                {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_DURATION', 'Duration')}
              </label>
              <Input
                style={{ width: '100px' }}
                required={true}
                type='number'
                onChange={(_e, data) => {
                  setSelectedObject({
                    ...selectedObject,
                    durationQuantity: data?.value.trim().length > 0 ? data.value.trim() : undefined
                  });
                }}
                value={selectedObject?.durationQuantity || ''}
              />
              <Dropdown
                style={{ width: '200px' }}
                selection
                options={UNIT_OPTIONS}
                onChange={(_e, data) => {
                  setSelectedObject({
                    ...selectedObject,
                    durationUnit: data?.value.trim().length > 0 ? data.value.trim() : undefined
                  });
                }}
                value={selectedObject?.durationUnit}
              />
            </Form.Field>
            <Form.Field key='formField_name'>
              <label>
                {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_NAME', 'Name')}
              </label>
              <Input
                required={true}
                maxLength='255'
                onChange={(_e, data) => {
                  setSelectedObject({
                    ...selectedObject,
                    name: data?.value.length > 0 ? data.value : undefined
                  });
                }}
                value={selectedObject?.name || ''}
              />
              <div>
                <small>
                  {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_NAME_NOTES', 'Has to be unique')}
                </small>
              </div>
            </Form.Field>
            <Form.Field key='formField_ref'>
              <label>
                {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_REF', 'Ref')}
              </label>
              <Input
                disabled={selectedIndex >= 0}
                required={true}
                maxLength='255'
                onChange={(_e, data) => {
                  setSelectedObject({
                    ...selectedObject,
                    ref: data?.value.length > 0 ? data.value : undefined
                  });
                }}
                value={selectedObject?.ref || ''}
              />
              <div>
                <small>
                  {t('VISITDATES_MANAGE_RESULTS_TABLE_HEADER_REF_NOTES', 'Has to be unique')}
                </small>
              </div>
            </Form.Field>
            <ConfirmButtonWithFeedback
              buttonText={t(
                'GLOBAL_BUTTON_SAVE',
                'Save'
              )}
              headerText={t('VISITDATES_MANAGE_SAVE_TEXT', 'Enter a reason for this change')}
              confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
              cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
              onConfirm={(feedbackReason) => handleFormSubmit(feedbackReason)}
              mandatoryValidationText={t(
                "VISITDATES_MANAGE_SAVE_REASON_VALIDATION_TEXT",
                "Please supply a reason for the change."
              )}
            />
            <Button
              onClick={handleAddEditCancelClick}
            >
              {t('GLOBAL_BUTTON_CLOSE', 'Close')}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>

      <Modal
        open={reorderPromptData != null}
        closeIcon={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        size='small'
      >
        <Modal.Header>
          <h3>{t('VISITDATES_MANAGE_REORDER_TITLE', 'Are you sure you want to reorder this item?')}</h3>
        </Modal.Header>
        <Modal.Content>
          <ConfirmButtonWithFeedback
            buttonText={t(
              'GENERIC_YES',
              'Yes'
            )}
            headerText={t('VISITDATES_MANAGE_SAVE_TEXT', 'Enter a reason for this change')}
            confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
            cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
            onConfirm={(feedbackReason) => handleItemReorderSubmit(feedbackReason)}
            mandatoryValidationText={t(
              "VISITDATES_MANAGE_SAVE_VALIDATION_TEXT",
              "Please supply a reason for the change."
            )}
          />
          <Button
            onClick={handleItemReorderCancelClick}
          >
            {t('GENERIC_NO', 'No')}
          </Button>
        </Modal.Content>
      </Modal>

    </Page >
  );
};

export default withTranslation()(VisitDateManagement);
