import React, { useEffect, useState, useContext } from "react";
import {withTranslation} from "react-i18next";
import {Button, Dropdown, Form, Grid, Icon, Loader, Message, Popup, Table} from "semantic-ui-react";
import Page from "../../components/page/Page";
import NotificationService from "../../services/NotificationService";
import UserContext from "../../context/UserContext";
import Pagination from "semantic-ui-react/dist/commonjs/addons/Pagination";
import DateRangeControls from "../../components/DateRangeControls";
import moment from "moment/moment";
import DOMPurify from "dompurify";
import {DATE_FORMATS} from "../../services/DateTimeService";
import GroupsContext from "../../context/GroupsContext";
import FileSaver from 'file-saver';

function AdminNotificationListPage({t}){
    const groups = useContext(GroupsContext);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [auditLogEntries, setAuditLogEntries] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment().add(1,"days"));
    const [notificationType,setNotificationType] = useState("PUSH_NOTIFICATION");
    const [groupId,setGroupId] = useState();
    const [subjectCode,setSubjectCode] = useState();
    const [email,setEmail] = useState();

    const user = useContext(UserContext);
    const isSuperAdmin = user.profile.superAdmin;

    const handleExport = async () => {
        try{
            const csvContent = await NotificationService.searchNotificationActivityAudit(pageNo - 1, startDate.format(DATE_FORMATS.DATE), endDate.format(DATE_FORMATS.DATE), notificationType, subjectCode, email, groupId, true);
            let blob = new Blob([csvContent], {
                type: "application/octet-stream"
            });
            let jsDate = new Date();
            //jsDate.setTime( jsDate.getTime() + jsDate.getTimezoneOffset() * 60 * 1000 );
            FileSaver.saveAs(blob, "notification-audit-" + jsDate.toISOString().slice(0, 19) + ".csv");
        }catch(response){
            console.log("Error exporting notificaiton audit:" + response);
        }
    }

    useEffect( () => {
        async function init() {
            try {
                setLoading(true);
                const pagedData = await NotificationService.searchNotificationActivityAudit(pageNo - 1, startDate.format(DATE_FORMATS.DATE), endDate.format(DATE_FORMATS.DATE), notificationType, subjectCode, email, groupId, false);
                setTotalPages(pagedData.totalPages);
                setAuditLogEntries(pagedData.content);
            } catch (err) {
                console.error('Error fetching data', err)
                setError(true)
            } finally {
                setLoading(false);
            }
        }
        init();
    }, [pageNo,startDate,endDate,notificationType,subjectCode, email, groupId]);


    const handlePaginationChange = (e, { activePage }) => {
        setPageNo(activePage)
    }

    const handleDateChange = (startDate, endDate) => {
       setStartDate(moment(startDate));
       setEndDate(moment(endDate));
    };


    const handleNotificationTypeChange = (e, data) => {
        setNotificationType( data.value );
    }
    const handleGroupChange = (e, data) => {
        setGroupId( data.value );
    }

    const createTable = () => {

        const headers = [
            {title: ["ADMIN_NOTIFICATION_LIST_DATE", "Log Date"], config: {width: 2}},
            {title: ["ADMIN_NOTIFICATION_LIST_SUBJECT", "Subject Code"], config: {width: 1}},
            {title: ["ADMIN_NOTIFICATION_LIST_STAFF", "Staff ID"], config: {width: 1}},
            {title: ["ADMIN_NOTIFICATION_LIST_TRIGGER", "Trigger ID\\Name"], config: {width: 1}},
            {title: ["ADMIN_NOTIFICATION_LIST_REASON", "Reason"], config: {width: 2}},
            {title: ["ADMIN_NOTIFICATION_LIST_STATUS", "Status"], config: {width: 2}},
            {title: ["ADMIN_NOTIFICATION_LIST_VIEWMSG", "View Msg"], config: {width: 1}}
        ];

        const headerRow = (
            <Table.Header>
                <Table.Row>
                    {headers.map((header,index) => (
                        <Table.HeaderCell key={index} {...header?.config}>
                            {t(header.title)}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
        );

        const configListRows = auditLogEntries?.map((notificationItem) => {
            let cleanHTML = DOMPurify.sanitize(notificationItem.payload);
            return (
                <Table.Row
                    key={notificationItem.id}>
                    <Table.Cell >
                        { new Date(notificationItem.logDate).toUTCString()}
                    </Table.Cell>
                    <Table.Cell >
                        {notificationItem.subjectCode}
                        {notificationItem.deviceModel && notificationItem.deviceModel.length>0
                        && <Popup content={notificationItem.deviceModel} trigger={  <Icon name='info' color='orange' style={{cursor:'pointer'}}></Icon>} />}
                    </Table.Cell>
                    <Table.Cell >
                        {notificationItem.staffId}
                    </Table.Cell>
                    <Table.Cell >
                        {notificationItem.triggerName}
                    </Table.Cell>
                    <Table.Cell >
                        {notificationItem.reason}
                    </Table.Cell>
                    <Table.Cell >
                        {notificationItem.status}
                    </Table.Cell>
                    <Table.Cell >
                        {notificationItem.notificationType === "PUSH_NOTIFICATION" &&
                            (<Popup content={notificationItem.payload} trigger={  <Icon name='envelope' color='orange' style={{cursor:'pointer'}}></Icon>} />)}
                        {(notificationItem.notificationType === "EMAIL_NOTIFICATION" ||
                            notificationItem.notificationType === "DIGEST_EMAIL_NOTIFICATION") &&
                            (<Popup trigger={<Icon name='envelope' color='orange'></Icon>} closeOnTriggerBlur={false} closeOnTriggerMouseLeave={false}>
                                <Popup.Header>{t("GENERIC_TERM_EMAIL", "Email")}</Popup.Header>
                                <Popup.Content>
                                    <div dangerouslySetInnerHTML={{__html: cleanHTML}} />
                                </Popup.Content>
                            </Popup>)}
                    </Table.Cell>
                </Table.Row>

            )}
        );

        return (
            <>
                {headerRow}
                <Table.Body>
                    {configListRows}
                </Table.Body>
            </>
        );
    }

    return (
        <Page
            name="Notification Activity Audit"
            header={t("ADMIN_NOTIFICATION_LIST_PAGETITLE", "Admin Notification Activity Audit")}>
            {error && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={"Error, if it persist contact support:" + error.message}
                />
            )}
            {loading && (
                <Loader active={loading} />
            )}
            {!isSuperAdmin && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN", "NOT AUTHORIZED")}
                />
            )}

            <Grid>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Form>
                         <Form.Field>
                                <label>
                                    {t("ADMIN_NOTIFICATION_LIST_DROPDOWN_PLACEHOLDER", "Select Notification Type")}
                                </label>
                                <Dropdown
                                    placeholder={t("ADMIN_NOTIFICATION_LIST_DROPDOWN_PLACEHOLDER", "Select Notification Type")}
                                    options={[
                                        {key: 'ANY',text:t("ADMIN_NOTIFICATION_LIST_DROPDOWN_ANY", "Any"),value:undefined },
                                        {key: 'PUSH',text:t("ADMIN_NOTIFICATION_LIST_DROPDOWN_PUSH", "Push Notification"),value:'PUSH_NOTIFICATION' },
                                        {key: 'EMAIL',text:t("ADMIN_NOTIFICATION_LIST_DROPDOWN_EMAIL", "Email Notification"),value:'EMAIL_NOTIFICATION' },
                                        {key: 'EMAIL_DIGEST',text:t("ADMIN_NOTIFICATION_LIST_DROPDOWN_DIGEST_EMAIL", "Digest Email Notification"),value:'DIGEST_EMAIL_NOTIFICATION' }
                                    ]}
                                    onChange={handleNotificationTypeChange}
                                    selection
                                    value={notificationType}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>
                                    {t("ADMIN_NOTIFICATION_LIST_GROUPS_FILTER", "Select Group")}
                                </label>
                                <Dropdown
                                    placeholder={t("ADMIN_NOTIFICATION_LIST_GROUPS_FILTER", "Select Group")}
                                    options={[...[{key: 'ANY', text:'Any', value:undefined}], ...(groups ? groups.map(g=>{
                                        return {key: g.code,text:g.label,value:g.id }
                                    }) : [])]
                                    }
                                    onChange={handleGroupChange}
                                    selection
                                    value={groupId}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Button primary fluid onClick={handleExport} >{t("NOTIFICATION_AUDIT_EXPORT_LABEL", "Export as CSV")}</Button>
                            </Form.Field>
                        </Form>

                    </Grid.Column>
                    <Grid.Column width={6}>

                            <label>{t("ADMIN_NOTIFICATION_LIST_DATERANGE", "Date Range:")}</label>
                            <DateRangeControls
                                onChange={handleDateChange}
                                start={startDate}
                                end={endDate}
                                notifyParentOnLoad={false}
                            />

                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form>
                            <Form.Field>
                            <label>{t("ADMIN_NOTIFICATION_LIST_SEARCH_SUBJECTCODE", "Subject Code:")}</label>
                                    <input value={subjectCode} onChange={(val) => setSubjectCode(val.target.value)} />
                            </Form.Field>
                        </Form>
                        <Form>
                            <Form.Field>
                                <label>{t("ADMIN_NOTIFICATION_LIST_SEARCH_EMAIL", "Email:")}</label>
                                <input value={email} onChange={(val) => setEmail(val.target.value)} />
                            </Form.Field>
                        </Form>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={4}>
                        { auditLogEntries && (
                        <Pagination
                            activePage={pageNo}
                            onPageChange={handlePaginationChange}
                            totalPages={totalPages}
                            disabled={typeof auditLogEntries === 'undefined' || auditLogEntries.length === 0}
                        />)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table fixed id={"adminNotificationList"}>
                {createTable()}
            </Table>

            <div style={{marginTop:20}}>
                {t("ADMIN_NOTIFICATION_LIST_NAVIGATE_TO_SEARCH",
                    "Cant find what you're looking for? " +
                    "Your alert may have been scheduled for sending ")}
                    <a href="/app/utils/digest-alerts">
                        {t("ADMIN_NOTIFICATION_LIST_DIGEST_ALERT_LISTS", "Scheduled Digest Alert list")}
                    </a>
            </div>
        </Page>)

}

export default withTranslation()(AdminNotificationListPage);
