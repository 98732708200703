import React from "react";
import { withTranslation } from "react-i18next";
import { Menu } from "semantic-ui-react";
import CollapseButton from "./CollapseButton";
import MenuItem from "./MenuItem";
import ProfileMenuHeader from "./ProfileMenuHeader";
import StaffMenu from "./StaffMenu";
import SubjectMenu from "./SubjectMenu";
import useCollapseNavInfo from "../../hooks/useCollapseNavInfo";
import HelpSection from "../helpSection/HelpSection";

const NavLeft = (props) => {
  const { t, user, trial, groups } = props;
  const { isLoggedIn, profile } = user;

  const [isExpanded, , toggleExpanded] = useCollapseNavInfo();


  // There doesnt seem to be a direct staff/subject identifier
  const isStaff =
    user?.accountType === 'staff' &&
    isLoggedIn &&
    profile !== null &&
    "drugManager" in profile &&
    "groupMappings" in profile &&
    "superAdmin" in profile;

  const isSubject = user?.accountType === 'subject' && isLoggedIn && !isStaff;

  let menuWidth = isExpanded ? "240px" : "30px";

  if (isLoggedIn) {
    return (
      <div id="nav-left" style={{ display: "flex", overflow: "visible" }}>
        <Menu vertical style={{ ...styles.menu, width: menuWidth, paddingRight: isExpanded ? '8px' : undefined }}>
          <div style={{width: '100%', display: "flex", justifyContent: 'flex-end'}}><CollapseButton fn={toggleExpanded} isExpanded={isExpanded} /></div>
          {(isExpanded || !isStaff) && <>
            {profile && <ProfileMenuHeader profile={profile} isStaff={isStaff} isExpanded={isExpanded} />}
            {isStaff && (
              <>
                <StaffMenu user={user} trial={trial} groups={groups} />
                <MenuItem to={"/app/settings"} icon={"settings"} text={t("MENU_SETTINGS")} />
                <HelpSection />
              </>
            )}
            {isSubject && <SubjectMenu user={user} trial={trial} isExpanded={isExpanded} />}
          </>}
        </Menu>
      </div>
    );
  }
  return null;
};

const styles = {
  menu: {
    margin: 0,
    borderWidth: 0,
    padding: "90px 2px 0px 8px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2) ",
    zIndex: 10,
    overflowY: "scroll",
    scrollbarWidth: "thin",
    overflowX: "hidden",
    position: "relative",
    height: "100vh",
  },
  portal: {
    position: "relative",
    left: "220px",
    top: "100px",
    zIndex: 100,
  },
};


export default withTranslation()(NavLeft);
