const canStaffViewQuestionnaireAtWorkflowTask = (user, questionnaire, subjectGroups) => {
  if(typeof questionnaire.questionnaireWorkflowInstance !== "object") {
    console.warn("[canStaffViewQuestionnaireAtWorkflowTask] Non-workflow questionnaire asked for task permission")
    return true;
  }

  if(user.accountType !== 'staff') {
    console.warn("[canStaffViewQuestionnaireAtWorkflowTask] Staff permission check used on non-staff user")
    return false;
  }

  const staffRolesThatApplyToSubjectGroups = [];
  subjectGroups.forEach(group => {
    const applicableGroupMapping = user.profile.groupMappings.find(gM => gM.group.code === group.code);
    staffRolesThatApplyToSubjectGroups.push(...applicableGroupMapping.roles.map(r => r.code));
  })

  return questionnaire
      ?.questionnaireWorkflowInstance
      ?.atTask                                     // currentTask
      ?.questionnaireWorkflowTask                   // definition for task
      ?.rolesAssignedTo                            // roles for that task
      ?.map(rAT => rAT.code)                       // to codes
      ?.some(roleCode =>
          staffRolesThatApplyToSubjectGroups
              .some(r=>r===roleCode))             // any codes that are shared
}


export default canStaffViewQuestionnaireAtWorkflowTask;
