import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import QuestionnaireDefinitionService from "../../services/admin/QuestionnaireDefinitionService";
import ConfirmButtonWithFeedback from "../dashboard/ConfirmButtonWithFeedback";

function AdminQuestionOrderModal({
  t,
  isOpen,
  setIsOpen,
  questionnaireDefinition,
  questionDefinitions,
  serverTranslations,
}) {
  const [lastMovedQuestionId, setLastMovedQuestionId] = useState(null);

  // While this will keep the original order, it will override the sequence to be sequential
  // and starting from 0.
  // e.g 2,4,7,8,9 -> 0,1,2,3,4
  const [orderedQuestionDefinitions, setOrderedQuestionDefinitions] = useState(
    questionDefinitions
      .sort((a, b) => a.sequence - b.sequence)
      .map((q, i) => {
        return { ...q, sequence: i };
      })
  );

  const getQuestionOrderChangeButton = (
    onComplete,
    buttonText,
    isPositive = true
  ) => {
    return (
      <ConfirmButtonWithFeedback
        fluid
        size="small"
        buttonText={t(buttonText)}
        headerText={t(buttonText)}
        contentText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PROMPT",
          "Please give a reason why this is being changed and confirm."
        )}
        confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
        cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
        onConfirm={onComplete}
        placeholderText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT",
          "Reason"
        )}
        mandatoryValidationText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_VALIDATION_TEXT",
          "Please supply a reason for the change."
        )}
        color={!isPositive ? "negative" : "orange"}
      />
    );
  };

  const changeSequence = async (index, direction) => {
    if (
      index + direction > orderedQuestionDefinitions.length ||
      index + direction < 0
    ) {
      return;
    }

    await setLastMovedQuestionId(orderedQuestionDefinitions[index].id);
    const reorderedQuestionDefinitions = [...orderedQuestionDefinitions];
    reorderedQuestionDefinitions[index].sequence = index + direction;
    reorderedQuestionDefinitions[index + direction].sequence = index;
    reorderedQuestionDefinitions.sort((a, b) => a.sequence - b.sequence);
    setOrderedQuestionDefinitions(reorderedQuestionDefinitions);
  };

  const reset = () => {
    setOrderedQuestionDefinitions(
      questionDefinitions.map((q, i) => {
        return { ...q, sequence: i };
      })
    );
  };

  const save = async (feedback) => {
    await QuestionnaireDefinitionService.editQuestionOrderingForQuestionnaire(
      questionnaireDefinition.id,
      orderedQuestionDefinitions.map((qD) => {
        return { ...qD, questionnaireDefinition };
      }),
      questionDefinitions,
      feedback
    );

    window.location.reload(false);
  };

  const createQuestionTable = (questions) => {
    if (!questions) {
      return;
    }

    const headers = [
      {
        title: ["ADMIN_QUESTION_SEQUENCE", ""],
      },
      {
        title: ["ADMIN_QUESTION_LABEL", "Label"],
      },
      {
        title: ["ADMIN_QUESTION_CODE", "Code"],
      },
      {
        title: ["ADMIN_QUESTION_TYPE", "Type"],
      },
      {
        title: ["ADMIN_QUESTION_CONDITIONS", "Conditions"],
      },
      {
        title: ["ADMIN_QUESTION_NEW_SEQUENCE", "New Sequence"],
      },
    ];

    const headerRow = (
      <Table.Header>
        {headers.map((header) => (
          <Table.HeaderCell key={"adminquestionorder-" + header.title}>{t(header.title)}</Table.HeaderCell>
        ))}
      </Table.Header>
    );

    const questionRows = questions.map((question, index) => (
      <Table.Row key={'questionrow-' + question.id} positive={question.id === lastMovedQuestionId}>
        <Table.Cell>
          <Button.Group>
            <Button
              icon
              primary
              onClick={() => {
                changeSequence(index, -1);
              }}
            >
              <Icon name="angle up" />
            </Button>
            <Button icon primary>
              <Icon
                name="angle down"
                onClick={() => {
                  changeSequence(index, +1);
                }}
              />
            </Button>
          </Button.Group>
        </Table.Cell>
        <Table.Cell
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "200px",
          }}
        >
          {
            serverTranslations.find(
              (sT) =>
                sT.code ===
                `questionnaire_${questionnaireDefinition.code}_questions_${question.code}_label`
            )?.translation
          }
        </Table.Cell>
        <Table.Cell>{question.code}</Table.Cell>
        <Table.Cell>{question.type}</Table.Cell>
        <Table.Cell>{JSON.stringify(question.conditions)}</Table.Cell>
        <Table.Cell>{question.sequence}</Table.Cell>
      </Table.Row>
    ));
    return (
      <>
        {headerRow}
        {questionRows}
      </>
    );
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Content scrolling>
        <Modal.Description>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <h4 style={{ paddingRight: "1rem" }}>
              {t("ADMIN_QUESTION_QUESTIONS", "Questions")}
            </h4>
            <Button size="small" onClick={reset}>
              {t("ADMIN_QUESTION_ORDER_Reset", "Reset")}
            </Button>
            <div>
              {getQuestionOrderChangeButton(
                save,
                t("ADMIN_QUESTION_ORDER_SAVE", "Save")
              )}
            </div>
          </div>

          {questionDefinitions && (
            <Table>{createQuestionTable(orderedQuestionDefinitions)}</Table>
          )}
          {!questionDefinitions && (
            <p>
              {t(
                "QUESTIONNAIRE_DEFINITION_QUESTIONS_EMPTY",
                "This questionnaire has no questions."
              )}
            </p>
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default withTranslation()(AdminQuestionOrderModal);
