const ERROR_TRANSLATION_MAP = {
  NO_VALUE: "No value is not supported",
  INCORRECT_FORMAT: "Incorrect Format",
  DEFINITION_NOT_FOUND: "Questionnaire Definition not found",
  QUESTION_NOT_FOUND: "Question not found",
  QUESTION_TYPE_INCORRECT: "Question type incorrect",
  TRIGGER_SUBJECT_POST_SCHEME_RESTRICTION:
    "Subject trigger is not allowed this post scheme",
  CONDITION_PARSE_FAILURE: "Condition failed to parse",
  DURATION_PARSE_FAILURE: "Duration failed to parse",
  DURATION_INVALID: "Duration is invalid for this trigger",
  DEPRECATED: "This has been depricated",
  TRIGGER_NOT_FOUND: "This trigger was not found to make changes",
  TRIGGER_ALREADY_EXISTS:
    "This trigger already exists. Please check trigger list, including inactive triggers.",
  ID_MISMATCH:
    "The id passed in the url is not the same as the url within the trigger",
  UNKNOWN_SCHEME:
    "The scheme (scheme://post-scheme) is not known. If this is in error please contact support.",
};

export default ERROR_TRANSLATION_MAP;
