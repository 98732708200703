import React, { useEffect, useState, useContext } from "react";
import { withTranslation } from "react-i18next";

import Page from "../../components/page/Page";
import {
  Button,
  Dropdown,
  Form,
  Grid, Header,
  Input,
  Message,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader
} from "semantic-ui-react";
import UserContext from "../../context/UserContext";
import TrialContext from "../../context/TrialContext";
import AparitoSwitch from "../../components/questionnaire/AparitoSwitch";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import TrialService from "../../TrialService";

const AdminStudyManagementPage = ({ t }) => {

  const user = useContext(UserContext);
  const {trial, refreshTrial} = useContext(TrialContext);

  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [formTrial, setFormTrial] = useState(trial);
  const [buttonState, setButtonState] = useState({})
  const [formElements, setFormElements] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [showNonEditable, setShowNonEditable] = useState(false);
  const [hideUneditedItems, setHideUneditedItems] = useState(true);

  const isSuperAdmin = user.profile.superAdmin;

  useEffect(() => {
    delete trial.groups;
    const frmElements = trialColumnConfig.map(column => {
      if (showNonEditable) {
        return generateFormElements(column);
      } else if (!showNonEditable && column.editable) {
        return generateFormElements(column);
      }
      return null;
    });
    setFormElements(frmElements);
    setSubmitEnabled(anyElementsModified());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formTrial, showNonEditable, buttonState]);

  const anyElementsModified = () => {
    const modified = trialColumnConfig.map(col => {
      let displayFormValue = formTrial[col.column] === null ? 'null' : formTrial[col.column]?.toString();
      let displayOriginalValue = trial[col.column] === null ? 'null' : trial[col.column]?.toString();
      return (displayFormValue === displayOriginalValue);
    });

    return modified.some(item => item === false);
  }

  const onChange = (e, item) => {
    e.preventDefault();
    setFormTrial((oldTrial) => {
      return { ...oldTrial, [e.target.name]: e.target.value };
    });
  }

  const onDropdownChange = (e, item, dropDownField) => {
    setFormTrial((oldTrial) => {
      return { ...oldTrial, [dropDownField]: item.value };
    });
  }

  const makeNullButtonVisible = (component) => {
    setButtonState((prev) => {
      return { ...prev, [component]: true};
    })
  }

  const makeValueNull = (e) => {
    setFormTrial((oldTrial) => {
      return { ...oldTrial, [e]: 'null' };
    });
  }

  const makeNullButtonHidden = (component) => {
    setTimeout(()=>
      setButtonState((prev) => {
        return { ...prev, [component]: false};
      }), 1000);
  }

  const generateFormElements = (col) => {
    let inputComponent;
    switch (col.type) {
      case 'string': {
        inputComponent = (
            <Input
                onMouseEnter={()=> makeNullButtonVisible(col.column)}
                onMouseLeave={()=> makeNullButtonHidden(col.column)}
                disabled={!col.editable}
                style={{width: 400}}
                name={col.column}
                value={formTrial[col.column]}
                onChange={onChange}
                maxLength="255"
            />
        );
        break;
      }
      case 'boolean' : {
        const options = [
          { key: 'true', text: 'true', value: 'true' },
          { key: 'false', text: 'false', value: 'false' }
        ];

        inputComponent = (
            <Dropdown fluid
                label="Dropdown button"
                options={options}
                onChange={(e, item) => { onDropdownChange(e, item, col.column) }}
                value={formTrial[col.column]?.toString()}
                style={{width: 100}}/>
        );
        break;
      }
      default: console.log('No type specified for ', col.column);
    }

    return (
      <Form.Field key={`form-field-${col.column}`} style={{display: 'flex', flexDirection: 'row'}}>
          <Grid.Column style={{width:300, borderBottom:"solid 1px #aaa"}} floated="left" >
            <label>{t("TRIAL_EDIT_" + col.column.toUpperCase(), col.humanReadable)}</label>
            {col.help && (<><br></br><label style={{color:'grey'}}>{col.help}</label></>)}
          </Grid.Column>
          <Grid.Column width={5}>
            {inputComponent}
          </Grid.Column>
          <Grid.Column style={{width:300}} >
            {buttonState[col.column] && col.editable && (
                <Button
                    onMouseEnter={()=> makeNullButtonVisible(col.column)}
                    onClick={() => makeValueNull(col.column)}>
                  Set to null
                </Button>
            )}
          </Grid.Column>
      </Form.Field>
    );
  }

  const resetForm = () => {
    setFormTrial(trial);
  }

  const prepareForReviewInModal = () => {
    //replace string null values with actual null values
    Object.keys(formTrial).map(key => {
      if (formTrial[key] === 'null') {
        formTrial[key] = null;
      }
      return null;
    });
    setModalOpen(true);
  }

  const handleSubmit = async (feedback) => {

    try {
      await TrialService.updateTrial(formTrial, feedback);
    } catch (errorMsg) {
      setError(`${errorMsg.error} - ${errorMsg.message}`);
      return;
    }
    setModalOpen(false);
    //refresh the trial context
    await refreshTrial();
    setMessage(t("ADMIN_STUDY_MANAGEMENT_CHANGES_DETAILED", "Your changes have been saved!"));
    setSubmitEnabled(false);
  }

  return (
    <>
      <Page
          name="Admin Study Management"
          header={t("ADMIN_STUDY_MANAGEMENT_HEADER", "Admin Study Management")}>
        {!isSuperAdmin && (
          <Message
            error
            header={t("GLOBAL_ERROR_TITLE", "Error")}
            content={t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN", "NOT AUTHORIZED")}
          />
        )}
        {isSuperAdmin && (
          <>
            <Grid>
              <Grid.Row>
                <AparitoSwitch
                    checked={showNonEditable}
                    onChange={() => setShowNonEditable(!showNonEditable)}
                    label={t("ADMIN_STUDY_MANAGEMENT_SHOW_NON_EDITABLE", "Show non editable columns?")}
                />
              </Grid.Row>
              <Grid.Row>
                <Form className='formStyle'>
                  {formElements}
                </Form>
              </Grid.Row>
            </Grid>
            <Grid.Row>
              {message && (
                  <Message
                      style={{width:650, marginTop:30}}
                      positive
                      header={t("GLOBAL_ADMIN_CHANGES_SAVED", "Changes saved")}
                      content={message}
                  />)}
            </Grid.Row>
            <Grid.Row>
              <Button
                  primary={submitEnabled}
                  disabled={!submitEnabled}
                  style={{marginTop:30, marginLeft:330}}
                  onClick={resetForm}>
                {t("ADMIN_STUDY_MANAGEMENT_CANCEL_CHANGES", "Reset form")}
              </Button>
              <Button
                  primary={submitEnabled}
                  disabled={!submitEnabled}
                  style={{marginTop:30}}
                  onClick={prepareForReviewInModal}>
                {t("ADMIN_STUDY_MANAGEMENT_SHOW_CHANGES_SUMMARY", "Review trial changes summary?")}
              </Button>
            </Grid.Row>
          </>
        )}
      </Page>

      <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onOpen={() => setModalOpen(true)}
      >
        <ModalHeader>{t("ADMIN_STUDY_MANAGEMENT_CONFIRM_CHANGES", "Summary - confirm your changes!")}</ModalHeader>
        <ModalContent>
          <Header>{t("ADMIN_STUDY_MANAGEMENT_HIGHLIGHT_CHANGES", "Your changes are highlighted in orange")}</Header>

          {error && (
              <Message negative>
                {error.indexOf("at [Source") > 0 ? error.substr(0, error.indexOf("at [Source")) : error}
              </Message>
          )}
          <AparitoSwitch
              checked={hideUneditedItems}
              onChange={() => setHideUneditedItems(!hideUneditedItems)}
              label={t("ADMIN_STUDY_MANAGEMENT_SHOW_UNEDITED_ITEMS", "Hide unedited items?")}
          />

          <Grid style={{marginTop:25, border: '1px solid #ddd'}}>
            <Grid.Row>
              <Grid.Column width={5}>{t("ADMIN_STUDY_MANAGEMENT_GRID_COLUMN", "Column")}</Grid.Column>
              <Grid.Column width={5}>{t("ADMIN_STUDY_MANAGEMENT_GRID_OLD_VALUE", "Old value")}</Grid.Column>
              <Grid.Column width={5}>{t("ADMIN_STUDY_MANAGEMENT_GRID_NEW_VALUE", "New value")}</Grid.Column>
            </Grid.Row>
          {
            trialColumnConfig.map(col => {

              let displayFormValue = formTrial[col.column] === null ? 'null' : formTrial[col.column]?.toString();
              let displayOriginalValue = trial[col.column] === null ? 'null' : trial[col.column]?.toString();

              if (displayFormValue === displayOriginalValue && hideUneditedItems) {
                return null;
              }

              return (
                  <Grid.Row key={`row-${col.column}`} style={{
                    color:displayFormValue !== displayOriginalValue ? 'orange': 'green'}}>
                    <Grid.Column width={5}>{col.humanReadable}</Grid.Column>
                    <Grid.Column width={5}>{displayOriginalValue}</Grid.Column>
                    <Grid.Column width={5}>{displayFormValue}</Grid.Column>
                  </Grid.Row>
              )
            })
          }
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => {
            setError(null);
            setModalOpen(false);
          }}>
            {t("CANCEL", "Cancel")}
          </Button>
          <ConfirmButtonWithFeedback
              buttonText={t(
                  'GLOBAL_BUTTON_SAVE',
                  'Save'
              )}
              onConfirm={handleSubmit}
              mandatoryValidationText={t('ADMIN_STUDY_MANAGEMENT_VALIDATION_TEXT', 'Please provide a reason for this change!')}
              headerText={t('ADMIN_STUDY_MANAGEMENT_ENTER_A_REASON', 'Enter a reason for this change')}
              confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
              cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
          />
        </ModalActions>
      </Modal>
    </>
  );
}

const trialColumnConfig = [
  {type: 'string', column: "id", editable: false, humanReadable: "Id", id: 0},
  {type: 'string', column: "code", editable: true, humanReadable: "Code", id: 1},
  {type: 'string', column: "timezone", editable: true, humanReadable: "Timezone", id: 2},
  {type: 'string', column: "description", editable: true, humanReadable: "Description", id: 3},
  {type: 'string', column: "dashboardSubjectSummaryFields", editable: true, humanReadable: "Dashboard Subject Summary Fields", id: 4},
  {type: 'string', column: "startDate", editable: true, humanReadable: "Start Date", id: 5, "help":"timestamp (milliseconds - 10 digits)"},
  {type: 'string', column: "endDate", editable: true, humanReadable: "End Date", id: 6, "help":"timestamp (milliseconds - 10 digits)"},
  {type: 'string', column: "deletedAt", editable: true, humanReadable: "Deleted At", id: 7, "help":"timestamp (milliseconds - 10 digits)"},
  {type: 'string', column: "name", editable: true, humanReadable: "Name", id: 8},
  {type: 'string', column: "subjectCodeGeneratorStrategy", editable: true, humanReadable: "Subject Code Generator Strategy", id: 9},
  {type: 'string', column: "subjectOnboardedReferenceDate", editable: true, humanReadable: "Subject Onboarded Reference Date", id: 10},
  {type: 'string', column: "subjectCodeSeparator", editable: true, humanReadable: "Subject Code Separator", id: 11},
  {type: 'string', column: "subjectCodeNumbering", editable: true, humanReadable: "Subject Code Numbering", id: 12},
  {type: 'string', column: "subjectCodeRegex", editable: true, humanReadable: "Subject Code Regex", id: 13},
  {type: 'string', column: "tokenValidityLength", editable: true, humanReadable: "Token Validity Length", id: 14},
  {type: 'string', column: "emailTokenExpirationDuration", editable: true, humanReadable: "Email Token Expiration Duration", id: 15, help: "This is in the format 'P1D' = 1 day"},
  {type: 'string', column: "deviceCodeLength", editable: true, humanReadable: "Device Code Length", id: 16},
  {type: 'string', column: "deviceCodeStrategy", editable: true, humanReadable: "Device Code Strategy", id: 17},
  {type: 'string', column: "subjectAuthenticationStrategy", editable: true, humanReadable: "Subject Authentication Strategy", id: 18},
  {type: 'string', column: "enabledQuestionnaireTypes", editable: true, humanReadable: "Enabled Questionnaire Types", id: 19},
  {type: 'string', column: "pathwayQuestionnaire", editable: true, humanReadable: "Pathway Questionnaire", id: 20},
  {type: 'string', column: "groupMetadataQuestionnaire", editable: true, humanReadable: "Group Metadata Questionnaire", id: 21},
  {type: 'string', column: "pathwayQuestion", editable: true, humanReadable: "Pathway Question", id: 22},
  {type: 'boolean', column: "shouldCreateDeviceOnSelfOnboard", editable: true, humanReadable: "Should Create Device On Self Onboard", id: 23},
  {type: 'boolean', column: "isTest", editable: true, humanReadable: "Is Test", id: 24},
  {type: 'boolean', column: "signatureRequiredForChanges", editable: true, humanReadable: "Signature Required For Changes", id: 25},
  {type: 'boolean', column: "selfOnboardingPassthruEnabled", editable: true, humanReadable: "Self Onboarding Passthru Enabled", id: 2},
  {type: 'boolean', column: "selfOnboardingEnabled", editable: true, humanReadable: "Self Onboarding Enabled", id: 27},
  {type: 'string', column: "timezoneNullSafe", editable: true, humanReadable: "Timezone Null Safe", id: 28}
];

export default withTranslation()(AdminStudyManagementPage);
