import React from 'react';
import { HandLandmarkIds } from 'atom5-data-analysis';


const HandView = ({ leftHand, rightHand, invertY, svgWidth, svgHeight, scale, frontView ,offset }) => {

    //svgWidth = window.innerWidth;
    //svgHeight = window.innerHeight;
    function renderHandLines(hand) {
        if (!hand) return null; // If no hand is provided, return nothing

        return bones.map((jointIds, index) => {
            const joint1 = hand.getJoint(jointIds[0]);
            const joint2 = hand.getJoint(jointIds[1]);

            return (
                <line
                    key={`line-${hand}-${index}`} // Unique key for each line
                    x1={frontView ? (joint1.x * svgWidth * scale.x) : ((joint1.z + offset.z) * svgWidth * scale.z)}
                    y1={joint1.y * svgHeight * scale.y}
                    x2={frontView ? (joint2.x * svgWidth * scale.x) : ((joint2.z + offset.z) * svgWidth * scale.z)}
                    y2={joint2.y * svgHeight * scale.y}
                    stroke={hand.isRightHand ? 'red' : 'blue'}
                    strokeWidth='2'
                />
            );
        });
    }

    function renderHandJoints(hand) {
        if (!hand) return null; // If no hand is provided, return nothing

        return jointIds.map((jointId, index) => {
            const joint = hand.getJoint(jointId);
            return (
                <circle
                    key={`circle-${index}-${hand.isRightHand}`}
                    cx={frontView?(joint.x * svgWidth * scale.x):((joint.z + offset.z) * svgWidth * scale.z)}
                    cy={joint.y * svgHeight * scale.y }
                    r={5}
                    stroke={"black"}
                    strokeWidth={2}
                    fill={hand.isRightHand ? 'red' : 'blue'}
                />
            );
        })
    }
    const jointIds = [
        HandLandmarkIds.WRIST,
        HandLandmarkIds.THUMB_CMC,
        HandLandmarkIds.THUMB_MCP,
        HandLandmarkIds.THUMB_IP,
        HandLandmarkIds.THUMB_TIP,
        HandLandmarkIds.INDEX_FINGER_MCP,
        HandLandmarkIds.INDEX_FINGER_PIP,
        HandLandmarkIds.INDEX_FINGER_DIP,
        HandLandmarkIds.INDEX_FINGER_TIP,
        HandLandmarkIds.MIDDLE_FINGER_MCP,
        HandLandmarkIds.MIDDLE_FINGER_PIP,
        HandLandmarkIds.MIDDLE_FINGER_DIP,
        HandLandmarkIds.MIDDLE_FINGER_TIP,
        HandLandmarkIds.RING_FINGER_MCP,
        HandLandmarkIds.RING_FINGER_PIP,
        HandLandmarkIds.RING_FINGER_DIP,
        HandLandmarkIds.RING_FINGER_TIP,
        HandLandmarkIds.PINKY_MCP,
        HandLandmarkIds.PINKY_PIP,
        HandLandmarkIds.PINKY_DIP,
        HandLandmarkIds.PINKY_TIP
    ];

    const bones = [
        [HandLandmarkIds.WRIST, HandLandmarkIds.THUMB_CMC],
        [HandLandmarkIds.THUMB_CMC, HandLandmarkIds.THUMB_MCP],
        [HandLandmarkIds.THUMB_MCP, HandLandmarkIds.THUMB_IP],
        [HandLandmarkIds.THUMB_IP, HandLandmarkIds.THUMB_TIP],

        [HandLandmarkIds.WRIST, HandLandmarkIds.INDEX_FINGER_MCP],
        [HandLandmarkIds.INDEX_FINGER_MCP, HandLandmarkIds.INDEX_FINGER_PIP],
        [HandLandmarkIds.INDEX_FINGER_PIP, HandLandmarkIds.INDEX_FINGER_DIP],
        [HandLandmarkIds.INDEX_FINGER_DIP, HandLandmarkIds.INDEX_FINGER_TIP],

        [HandLandmarkIds.WRIST, HandLandmarkIds.MIDDLE_FINGER_MCP],
        [HandLandmarkIds.MIDDLE_FINGER_MCP, HandLandmarkIds.MIDDLE_FINGER_PIP],
        [HandLandmarkIds.MIDDLE_FINGER_PIP, HandLandmarkIds.MIDDLE_FINGER_DIP],
        [HandLandmarkIds.MIDDLE_FINGER_DIP, HandLandmarkIds.MIDDLE_FINGER_TIP],

        [HandLandmarkIds.WRIST, HandLandmarkIds.RING_FINGER_MCP],
        [HandLandmarkIds.RING_FINGER_MCP, HandLandmarkIds.RING_FINGER_PIP],
        [HandLandmarkIds.RING_FINGER_PIP, HandLandmarkIds.RING_FINGER_DIP],
        [HandLandmarkIds.RING_FINGER_DIP, HandLandmarkIds.RING_FINGER_TIP],

        [HandLandmarkIds.WRIST, HandLandmarkIds.PINKY_MCP],
        [HandLandmarkIds.PINKY_MCP, HandLandmarkIds.PINKY_PIP],
        [HandLandmarkIds.PINKY_PIP, HandLandmarkIds.PINKY_DIP],
        [HandLandmarkIds.PINKY_DIP, HandLandmarkIds.PINKY_TIP],
    ];

    return (
        <svg width={svgWidth} height={svgHeight} style={svgStyle}>
            <rect
                x={1}
                y={1}
                width={svgWidth-1}
                height = {svgHeight-1}
                fill="transparent" stroke="black" strokeWidth="2"
            />
            { renderHandLines(leftHand)  }
            { renderHandLines(rightHand) }
            { renderHandJoints(leftHand) }
            { renderHandJoints(rightHand) }
        </svg>

    );
};
const svgStyle = {
    backgroundColor: 'transparent',
};

export default HandView;
