import AuthService from './AuthService';
import fetchProgress from 'fetch-progress';
import RequestHelper from '../RequestHelper';
import InternationalisationService from '../InternationalisationService';
import { QUESTION_TYPES } from 'atom5-branching-questionnaire';
import { CONTENT_TYPE } from '../RequestHelper';
import moment from "moment/moment";
import RSVP from "rsvp";
import {v4 as uuid_v4} from "uuid";
import AppStateService from "./AppStateService";
import GeneralHelpers from '../helpers/GeneralHelpers';
import {serverAddress} from "./config/EnvConfig";

// Matches AttachmentState Enum in API
export const MEDIA_STATE = {
  NEW: {
    translationKey: 'MEDIA_UPLOAD_STATE_NEW',
    fallbackText:
      'We are waiting for the file to be uploaded. The time this takes depends on the duration of the video, the file size, and network conditions.',
    displayProperties: {
      isInfo: true,
      icon: 'info circle'
    },
    attachmentSearch: {
      translationKey: 'MEDIA_UPLOAD_STATE_NEW_SEARCH_FILTER',
      fallbackText: 'Awaiting upload'
    }
  },
  RECEIVED: {
    translationKey: 'MEDIA_UPLOAD_STATE_RECEIVED',
    fallbackText:
      'We have received the file, and it is in the queue to be processed.',
    displayProperties: {
      isInfo: true,
      icon: 'file outline icon'
    },
    attachmentSearch: {
      translationKey: 'MEDIA_UPLOAD_STATE_RECEIVED_SEARCH_FILTER',
      fallbackText: 'Uploaded'
    }
  },
  ORPHAN: {
    // Intentionally the same as RECEIVED - the difference in the states is a server side difference, to the user there is no difference.
    translationKey: 'MEDIA_UPLOAD_STATE_RECEIVED',
    fallbackText:
      'We have received the file, and it is in the queue to be processed.',
    displayProperties: {
      isInfo: true,
      icon: 'file outline icon'
    },
    attachmentSearch: {
      showInFilter: false
    }
  },
  READY_FOR_PROCESSING: {
    // Intentionally the same as RECEIVED - the difference in the states is a server side difference, to the user there is no difference.
    translationKey: 'MEDIA_UPLOAD_STATE_RECEIVED',
    fallbackText:
      'We have received the file, and it is in the queue to be processed.',
    displayProperties: {
      isInfo: true,
      icon: 'file outline icon'
    },
    attachmentSearch: {
      showInFilter: false
    }
  },
  PROCESSING: {
    translationKey: 'MEDIA_UPLOAD_STATE_PROCESSING',
    fallbackText: 'The file is being processed.',
    displayProperties: {
      isInfo: true,
      icon: 'spinner icon'
    },
    attachmentSearch: {
      translationKey: 'MEDIA_UPLOAD_STATE_PROCESSING_SEARCH_FILTER',
      fallbackText: 'Processing'
    }
  },
  COMPLETED: {
    translationKey: 'MEDIA_UPLOAD_STATE_COMPLETED',
    fallbackText: 'This file upload is complete.',
    attachmentSearch: {
      translationKey: 'MEDIA_UPLOAD_STATE_COMPLETED_SEARCH_FILTER',
      fallbackText: 'Ready'
    }
  },
  FAILED: {
    translationKey: 'MEDIA_UPLOAD_STATE_FAILED',
    fallbackText: 'The processing of the file encountered too many errors.',
    displayProperties: {
      icon: 'exclamation triangle icon'
    },
    attachmentSearch: {
      translationKey: 'MEDIA_UPLOAD_STATE_FAILED_SEARCH_FILTER',
      fallbackText: 'Failed'
    }
  },
  LEGACY: {
    attachmentSearch: {
      showInFilter: false // We treat this the same as COMPLETED
    }
  }
};

export const WORKFLOW_STATUS_NOT_SPECIFIED = '_NOT_SPECIFIED_';

export const INTERNAL_WORKFLOW_STATUS = {
  // The workflowStatus column has no value i.e. null or blank string for items which are not set, we need to be able to see these.
  WORKFLOW_STATUS_NOT_SPECIFIED: {
    value: WORKFLOW_STATUS_NOT_SPECIFIED,
  },
  // Ignore the workflow status in the search
  '_ANY_': {
    value: '_ANY_',
  }
};

const AttachmentService = {
  getMediaState: (response) => {
    const headerValue = response.headers.get('X-Attachment-State');
    const state = AttachmentService.getMediaStateFromString(headerValue);
    return state;
  },
  getAttachmentVariantStateMapping(attachmentType, attachmentCategory, attachmentState) {
    let stateMappings = {
      'ORIGINAL_VIDEO_NEW': 'NEW',
      'ORIGINAL_VIDEO_ORPHAN': 'ORPHAN',
      'ORIGINAL_VIDEO_RECEIVED': 'RECEIVED',
      'ORIGINAL_VIDEO_COMPLETED': 'READY_FOR_PROCESSING',
      'OPTIMIZED_VIDEO_NEW': 'RECEIVED',
      'OPTIMIZED_VIDEO_READY_FOR_PROCESSING': 'READY_FOR_PROCESSING',
      'OPTIMIZED_VIDEO_PROCESSING': 'PROCESSING'
    };
    let stateKey = attachmentType.toUpperCase() + '_' + attachmentCategory.toUpperCase() + '_' + attachmentState.toUpperCase();
    return AttachmentService.getMediaStateFromString(stateMappings[stateKey]) || AttachmentService.getMediaStateFromString(attachmentState);
  },
  getMediaFilenameFromHeader: (response) => {
    return response.headers.get('X-Attachment-Filename');
  },
  getAttachmentVariantReferenceFromHeader: (response) => {
    return response.headers.get('X-Attachment-Variant-Reference');
  },
  getMediaStateFromString: (stringState) => {
    const state = MEDIA_STATE[stringState];
    return state;
  },
  getAttachmentInline: async (
    subjectId,
    reference,
    blurredOnly,
    progressCallbackHandler,
    responseCallbackHandler,
    errorCallbackHandler,
    variantReference,
    feedback
  ) => {

    const requestPromise = AttachmentService.buildRestRequest.bind(
        this,
        subjectId,
        reference,
        blurredOnly,
        progressCallbackHandler,
        responseCallbackHandler,
        errorCallbackHandler,
        variantReference,
        feedback
    );

    if (AuthService.getTokenExpiry().isBefore(moment())) {
      return AuthService.refreshAccessToken().then(requestPromise);
    } else {
      return requestPromise();
    }
  },
  isPermittedToViewAttachment: (subjectId, reference) => {
    const url = serverAddress +
    '/subjects/' +
    subjectId +
    '/attachments/' +
    reference +
    '/permitted';
    return RequestHelper.send(
      url,
      {
        'Accept': CONTENT_TYPE.TEXT_PLAIN
      },
      'GET'
    );
  },
  getAttachment: async (subjectId, reference, blurredOnly) => {
    let url;

    if (blurredOnly) {
      url =
          serverAddress +
          '/subjects/' +
          subjectId +
          '/attachments/blurred/' +
          reference;
    } else {
      url =
          serverAddress +
          '/subjects/' +
          subjectId +
          '/attachments/' +
          reference;
    }

    const response = await RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() },
      'GET',
      null
    );
    return response;
  },
  getAttachmentVariants: async (subjectId, reference, blurredOnly) => {
    let url =
        serverAddress +
        '/subjects/' +
        subjectId +
        '/attachments/' +
        reference +
        '/variants'
    ;
    if(blurredOnly) {
      url = url + "?filter=blurred";
    }
    const response = await RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() },
      'GET',
      null
    );
    return response;
  },
  getIcon: (question) => {
    let iconAppend = '';
    switch (question.type) {
      case QUESTION_TYPES.VIDEO:
        iconAppend = ' video';
        break;
      case QUESTION_TYPES.IMAGE:
        iconAppend = ' image';
        break;
      default:
        iconAppend = '';
    }
    return 'file' + iconAppend;
  },
  injectAttachmentForUpload: (t,changeAnswerMapValue,fileComponentCode, blobData, fileType,fileExtension, attachmentRef, srcAttachmentVariantRef, variantType, forceNewVariant) => {
    attachmentRef = attachmentRef ? attachmentRef : uuid_v4(); //If we are passed in a null\undefined attachment ref created one
    fileExtension = fileExtension ? '.' + fileExtension : '';
    //check fileComponent exists
    const questionnaireState = AppStateService.getLocalStateForNamespace("CURRENT_QUESTIONNAIRE");
    if (!questionnaireState.answerMap.hasOwnProperty(fileComponentCode)){
        throw new Error(t("ERROR_INJECTING_ATTACHMENT_FOR_UPLOAD","Error injecting blob for upload, component  not found in questionnaire. - ") + fileComponentCode);
    }
    const file = new File([blobData], attachmentRef + fileExtension, { type: fileType });
    const createDate = new Date(Date.now());
    const wrappedAnswer = {
      variantSource: srcAttachmentVariantRef,
      variantType: variantType,
      forceNewVariant: forceNewVariant,
      answer: attachmentRef,
      data: file,
      mediaCreateDate: createDate.toISOString()
    };
    changeAnswerMapValue(fileComponentCode,wrappedAnswer,true)
  },
  updateWorkflowStatus: async (attachmentId, workflowStatus) => {
    const url = `${serverAddress}/attachments/updateWorkflowStatus`;
    const requestBody = { attachmentId, workflowStatus };
    return RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() },
      'POST',
      null,
      requestBody
    );
  },
  manualOverrideWorkflowStatus: async (subjectId, feedbackReason, attachmentId, workflowStatus) => {
    const url = `${serverAddress}/attachments/manualOverrideWorkflowStatus/${subjectId}`;

    const feedbackHeaderValue = feedbackReason
      ? `Manual override of attachment workflow status [${attachmentId}]`
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    headers['Accept-Language'] = InternationalisationService.getLanguage();

    const requestBody = { ...initialRequestBody, attachmentId, workflowStatus };
    return RequestHelper.send(
      url,
      headers,
      'POST',
      null,
      requestBody
    );
  },
  getSearchCriteriaRequestBody:  (searchCriteria) => {
    const hasPrimaryQuestionnaireAnswerValueSearchCriteria = searchCriteria?.primaryQuestionnaireAnswerValueSearchCriteria != null && searchCriteria?.primaryQuestionnaireAnswerValueSearchCriteria?.value != null && searchCriteria?.primaryQuestionnaireAnswerValueSearchCriteria?.value.trim().length > 0;
    const requestBody = {
        attachmentState:
          searchCriteria?.attachmentState != null
            ? searchCriteria.attachmentState
            : 'COMPLETED',
        subjectCodes:
          searchCriteria?.subjects != null
            ? searchCriteria.subjects.map(subject => subject.subjectCode)
            : [],
        questionnaireDefinitionCodes:
          searchCriteria?.questionnaireDefinitionCodes != null
            ? searchCriteria.questionnaireDefinitionCodes
            : [],
        attachmentWorkflowStatus:
          searchCriteria?.attachmentWorkflowStatus != null && searchCriteria?.attachmentWorkflowStatus !== ''
            ? searchCriteria.attachmentWorkflowStatus
            : INTERNAL_WORKFLOW_STATUS._ANY_.value,
        primaryQuestionnaireIds:
          searchCriteria?.primaryQuestionnaireId != null
            ? [searchCriteria?.primaryQuestionnaireId]
            : [],
        primaryQuestionnaireAnswerValueSearchCriteria:
          hasPrimaryQuestionnaireAnswerValueSearchCriteria
            ? [searchCriteria?.primaryQuestionnaireAnswerValueSearchCriteria]
            : []
      };
      return requestBody;
  },
  getBySearchCriteria: async (searchCriteria) => {
    const url = `${serverAddress}/attachments/search`;
    const requestBody = AttachmentService.getSearchCriteriaRequestBody(searchCriteria);
    const response = await RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() },
      'POST',
      null,
      requestBody
    );
    return response;
  },
  extractAdditionalData: (attachment, additionalDataKey) => {
    if (!attachment?.additionalData || !additionalDataKey) {
      return undefined;
    }
    const value = attachment.additionalData[additionalDataKey];
    return value;
  },
  buildRestRequest: (subjectId,
    reference,
    blurredOnly,
    progressCallbackHandler,
    responseCallbackHandler,
    errorCallbackHandler, variantReference, feedback) => {
    return new RSVP.Promise((resolve, reject) => {
      let url;
      if(blurredOnly){
        url =
            serverAddress +
            '/subjects/' +
            subjectId +
            '/attachments/blurred/' +
            reference +
            '/inline';
      } else {
        url =
            serverAddress +
            '/subjects/' +
            subjectId +
            '/attachments/' +
            reference +
            '/inline';
      }

      let urlWithParams = url;
      let auditUrlWithParams = url;

      const headers = {
        Authorization: 'Bearer ' + AuthService.getAuthToken()
      };

      let params = {}

      if (feedback) {
        params["feedback"] = feedback;
        headers['x-feedback-available'] = 'Staff: Attachment Download';
      }

      if (variantReference) {
        params["variantReference"] = variantReference;
      }

      if(Object.keys(params).length > 0){
        const queryString = Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');
        urlWithParams = `${url}?${queryString}`;
        //update url to feedback api call
        url = url.replace('/inline', '/auditDownload')
        auditUrlWithParams = `${url}?${queryString}`;
      }

      fetch(urlWithParams, {
        headers: headers
      })
        .then(
          fetchProgress({
            onProgress(progress) {
              progressCallbackHandler(progress);
            },
            onError(err) {
              errorCallbackHandler(err);
            }
          })
        )
        .then(async (response) => {
          if (!response.ok) {
            throw new Error('Error in response');
          }
          const state = AttachmentService.getMediaState(response);
          const filename = AttachmentService.getMediaFilenameFromHeader(response);
          const blob = await response.blob();
          //adding a 'string' check, sometimes the final parameter (feedback) in method call is an object
          //we only want to create audit entries if feedback is provided
          if (typeof feedback === 'string') {
            fetch(auditUrlWithParams, { headers: headers })
              .then((response) => {
                console.log('[AttachmentService] Download audit call response: ' + response);
              });
          }
          return { blob, state, filename};
        })
        .then((mediaData) => {
          const mediaDataUrl = URL.createObjectURL(mediaData.blob);
          responseCallbackHandler(mediaDataUrl, mediaData.blob, mediaData.filename);
        })
        .catch((err) => {
          errorCallbackHandler(err);
        });
    });
  },
  getWorkflowStatusText: (t, key) => {
    const getFallbackText = () => {
      switch (key) {
        case WORKFLOW_STATUS_NOT_SPECIFIED:
          return 'Not specified';
        case '_ANY_':
          return 'Any';
        default:
          return GeneralHelpers.convertTranslationKeyToPascalCase(key);
      }
    };
    const translationKey = `ATTACHMENT_WORKFLOW_STATUS_${key}`;
    const fallbackText = getFallbackText();
    return t(translationKey, fallbackText);
  }
};

export default AttachmentService;
