import React from 'react'
import { Checkbox, Button } from 'semantic-ui-react'

function BooleanValueEditor({ pathKey, label, value, onChange }) {
  const handleChange = (v) => {
    onChange && onChange(pathKey, v)
  }

  return (
    <div style={{ padding: '4px' }}>
      {value === undefined && <Button primary compact size='small' onClick={() => handleChange(false)} >{"Add " + label}</Button>}
      {value !== undefined && <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Checkbox checked={value} label={label} onChange={(_v1, v2) => handleChange(v2.checked)} />
        <div style={{ paddingLeft: '20px' }}>
          <Button inverted color='red' compact size='tiny' onClick={() => { handleChange(undefined) }} >{"x"}</Button>
        </div>
      </div>}
    </div>
  )
}

export default BooleanValueEditor