import React from 'react'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import BooleanValueEditor from './simpleValueEditors/BooleanValueEditor'
import TextValueEditor from './simpleValueEditors/TextValueEditor'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function ImageConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <BooleanValueEditor pathKey={"useCamera"} label={"Should Use Camera?"} />
      <BooleanValueEditor pathKey={"enableCapturePreview"} label={"Enable Capture Preview?"} />
      <BooleanValueEditor pathKey={"showInPagePreview"} label={"Show In Page Preview?"} />
      <BooleanValueEditor pathKey={"enableDeleteButton"} label={"Show Delete Button?"} />
      <BooleanValueEditor pathKey={"saveToCameraroll"} label={"Save To Cameraroll?"} />
      <TextValueEditor pathKey={"buttonTextKey"} label={"Button Text Key"} />
    </ConfigWrapper>
  )
}

export default ImageConfigEditor