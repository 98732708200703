import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import InternationalisationService from '../../../../../InternationalisationService';
import TextValueEditor from './TextValueEditor';

function LanguageGroup({ pathKey, label, value, onChange }) {
  // this is not great for performance and could be move higher, but its simple and
  // does not fetch if its not got a value
  const [languages, setLanguages] = useState([])
  const getLanguages = useCallback(async () => {
    const languages = await InternationalisationService.getLanguages()
    const mobileLanguages =  await InternationalisationService.getMobileLanguages()
    const mergedLanguages = [...languages, ...mobileLanguages].reduce((acc, currentLang) => {
      const found = acc.find(lang => lang.code === currentLang.code);
      if (!found) {
        acc.push(currentLang);
      }
      return acc;
    },[]);
    setLanguages(mergedLanguages)
  }, [])
  useEffect(() => {
    if (languages.length > 0) return
    getLanguages()
  }, [getLanguages, languages.length])

  const handleChange = (v) => {
    onChange && onChange(pathKey, v)
  }

  return (
    <div style={{ padding: '4px' }}>
      {value === undefined && <Button primary compact size='small' onClick={() => handleChange({})} >{"Add " + label}</Button>}
      {typeof value === "string" && <><h5>{label}</h5><p>{"Translation is plain string, edit with translation editor"}</p></>}
      {typeof value === "object" && <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <h5>{label}</h5><div style={{ paddingLeft: '20px' }}>
          <Button inverted color='red' compact size='tiny' onClick={() => { handleChange(undefined) }} >{"x"}</Button>
        </div>
        <div style={{ flex:'1', flexDirection:'column' }}>
          {languages.map((l,idx) => {
          return <TextValueEditor key={pathKey+'-'+idx} pathKey={pathKey + '.' + l.code} label={l.code} value={value[l.code]} onChange={onChange} />
        })}
        </div>
      </div>}
    </div>
  )
}

export default LanguageGroup
