import RequestHelper from "../../RequestHelper";
import {serverAddress} from "../config/EnvConfig";

const QuestionDefinitionService = {
  getQuestionDefinitionData: (id) => {
    return RequestHelper.send(
      serverAddress +
        "/admin/question-definitions/" +
        id +
        "/data",
      {},
      "GET"
    );
  },
  editQuestionDefinition: (questionDefinition, feedbackReason) => {
    const feedbackHeaderValue = feedbackReason
      ? `Editing questionnaire definition [${questionDefinition.id}]`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    const requestBody = { ...initialRequestBody, questionDefinition };

    return RequestHelper.send(
      serverAddress +
        "/admin/question-definitions/" +
        questionDefinition.id,
      headers,
      "PUT",
      null,
      requestBody
    );
  },
  createQuestionDefinition: (
    questionnaireDefinitionId,
    questionDefinition,
    feedbackReason
  ) => {
    const feedbackHeaderValue = feedbackReason
      ? `Creating question definition`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    const requestBody = { ...initialRequestBody, questionDefinition };

    return RequestHelper.send(
      serverAddress +
        "/questionnaire-definitions/" +
        questionnaireDefinitionId +
        "/question-definitions/new",
      headers,
      "POST",
      null,
      requestBody
    );
  },
};

export default QuestionDefinitionService;
