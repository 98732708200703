import React from 'react';
import * as PropTypes from 'prop-types';

export function VTUGTable ({ vtugResults }) {
    const stages = [
        { name: 'Stand', field: 'stand' },
        { name: 'Walk Front', field: 'walk' },
        { name: 'Turn', field: 'turn180' },
        { name: 'Walk Back', field: 'walkBack' },
        { name: 'Turn & Sit', field: 'turnSit' },
        { name: 'Sit', field: 'sit' }

    ];

    const tableStyle = {
        maxHeight: '200px', // Adjust the desired maximum height
        overflowY: 'auto',
        borderCollapse: 'collapse',
        border: '2px solid black',
        width: '100%',
        marginLeft: '20px', // Adjust this value as needed for spacing
        padding: '20px'
    };

    const titleStyle = {
        textAlign: 'center',
        color: 'black'

    };

    const boldTextStyle = {
        fontWeight: 'bold'
    };

    const containerStyle = {
        display: 'flex', // Use flexbox to arrange items horizontally
        spacing: '10px'
    };

    const cellStyle = {
        border: '2px solid',
        padding: '5px'
    }

    return (
        <div style={containerStyle}>
            {vtugResults.map((vtug, index) => (
                <div key={index } style={ { display: 'flex', flexDirection: 'row'} }>
                <div key={index + 'table'}>
                    <h2 style={titleStyle}>vTUG {index + 1}</h2>
                    <table style={tableStyle}>
                        <thead>
                        <tr>
                            <th>Action</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Steps</th>
                            <th>Duration</th>
                            <th>Pause</th>
                        </tr>
                        </thead>
                        <tbody>
                        {stages.map((stage, stage_index) => (
                            <tr key={index + '.' + stage_index}>
                                <td style={{ ...cellStyle }}>{stage.name}</td>
                                <td style={{ ...cellStyle, textAlign: 'right' }}>{(vtug.getEvent(stage.field).start / 1000).toFixed(2)} s</td>
                                <td style={{ ...cellStyle, textAlign: 'right' }}>{(vtug.getEvent(stage.field).end / 1000).toFixed(2)} s</td>
                                <td style={{ ...cellStyle, textAlign: 'right' }}>{vtug.getEvent(stage.field).steps}</td>
                                <td style={{ ...cellStyle, textAlign: 'right' }}>{(vtug.getEvent(stage.field).duration / 1000).toFixed(2)} s</td>
                                <td style={{...cellStyle, textAlign: 'right' }}>{(vtug.getEvent(stage.field).pause / 1000).toFixed(2)} s</td>
                            </tr>
                        ))}
                        <tr key={index + 'total'}>
                            <td style={{ ...cellStyle, ...boldTextStyle }}>Total vTUG</td>
                            <td style={{ ...cellStyle, textAlign: 'right', ...boldTextStyle }}>{(vtug.start / 1000).toFixed(2)} s</td>
                            <td style={{ ...cellStyle, textAlign: 'right', ...boldTextStyle }}>{(vtug.end / 1000).toFixed(2)} s</td>
                            <td style={{ ...cellStyle, textAlign: 'right', ...boldTextStyle }}>{vtug.totalSteps}</td>
                            <td style={{ ...cellStyle, textAlign: 'right', ...boldTextStyle }}>{(vtug.duration / 1000).toFixed(2)} s</td>
                            <td style={{ ...cellStyle, textAlign: 'right', ...boldTextStyle }}></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ width: 20, height: 10}}></div>
        </div>
            ))}
        </div>
    );
}

VTUGTable.propTypes = { data: PropTypes.any };
