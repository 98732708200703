/**
 * access object property using string . notation
 * e.g. getProperty(questionnaire, 'triggerActivityAudit.triggerName')
 */
export const getDescendantProperty = (obj, desc) => {
    const arr = desc.split(".");
    const result = arr.reduce((a, b) => a?.[b], obj);
    return result;
}

export const parseExpression = (expression, context) => {
    let res = ''
    try {

        const c = context // c in referenced in expressions
        // eslint-disable-next-line no-new-func
        const evalFn = new Function('c', 'return `' + expression + '`')
        res = evalFn(c)
    } catch (error) {
        console.warn("[objectUtils][parseExpression] failed to parse expression ", expression, context)
    }
    return res !== undefined && res !== null && res !== 'null' ? res : '';
}