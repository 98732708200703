import RSVP from "rsvp";
import moment from "moment";
import AuthService from "./services/AuthService";
import fetchProgress from "fetch-progress";
import {serverAddress} from "./services/config/EnvConfig";

export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT'
};

export const CONTENT_TYPE = {
  APPLICATION_JSON: "application/json",
  APPLICATION_OCTETSTREAM: "application/octet-stream",
  MULTIPART_FORMDATA: "multipart/form-data",
  TEXT_PLAIN: "text/plain",
  FORM_URL_ENCODED:"application/x-www-form-urlencoded"
};

export default class RequestHelper {
  static signatureModalInstance = null;

  static send(url, headers = {}, method = "GET", queryParams, body, options, progressCallback) {
    let requestUrl = url;
    let refreshTokenParam = queryParams ? queryParams.refresh_flow : undefined;

    if (queryParams) {
      const requestParams = Object.keys(queryParams)
        .map(
          (k) => {
            const val = queryParams[k]
            if(val && val.constructor === Array){
              return encodeURIComponent(k) + "=" + val.join(',')
            }else{
              return encodeURIComponent(k) + "=" + encodeURIComponent(val)
            }

          }
        )
        .join("&");

      requestUrl += "?" + requestParams;
    }

    const requestPromise = RequestHelper.buildRestRequest.bind(
      RequestHelper,
      url,
      requestUrl,
      queryParams,
      method,
      headers,
      body,
      options,
        progressCallback
    );

    if (AuthService.getTokenExpiry().isBefore(moment()) && !refreshTokenParam) {
      return AuthService.refreshAccessToken().then(requestPromise);
    } else {
      return requestPromise();
    }
  }

  /**
   * This is an inner/private function to create the promise which sends the request
   * It needs to be within a function so that it is evaluated after StaffService.refreshAccessToken() completes
   */
  static buildRestRequest(
    url,
    requestUrl,
    queryParams,
    method,
    headers,
    body,
    options,
    progressCallback
  ) {
    return new RSVP.Promise((resolve, reject) => {
      if (!headers["Accept"]) {
        headers["Accept"] = CONTENT_TYPE.APPLICATION_JSON;
      }
      if (!headers["Content-Type"]) {
        headers["Content-Type"] = CONTENT_TYPE.APPLICATION_JSON;
      }
      if (!headers["Authorization"]) {
        const token = AuthService.getAuthToken();
        if (token) {
          headers["Authorization"] = "Bearer " + token;
        }
      }

      let formattedBody = JSON.stringify(body);
      if (
        headers &&
        headers["Content-Type"] === CONTENT_TYPE.MULTIPART_FORMDATA
      ) {
        formattedBody = body;
        // Setting the Content-Type as multipart/form-data maually has some weird side effects, the suggested approach is to not set it, and let fetch set it automatically.
        delete headers["Content-Type"];
      } else if (
        headers &&
        headers["Content-Type"] === CONTENT_TYPE.TEXT_PLAIN
      ) {
        formattedBody = body;
      } else if (
          headers &&
          headers["Content-Type"] === CONTENT_TYPE.FORM_URL_ENCODED
      ) {
        formattedBody = body;
      }



      const isOctetStream =
        headers["Content-Type"] === CONTENT_TYPE.APPLICATION_OCTETSTREAM ||
        headers["Accept"] === CONTENT_TYPE.APPLICATION_OCTETSTREAM;

      // Unset headers as set in options
      if (options) {
        if (options.headers.useEmptyAccept === true) {
          headers && delete headers["Accept"];
        }
      }

      fetch(requestUrl, {
        method: method,
        redirect: "follow",
        cache: "no-cache",
        headers: headers,
        body: formattedBody,
      })
          .then((res)=> {
            if(res.status === 204) return res;
            return fetchProgress({
              onProgress(progress) {
                if (progressCallback !== undefined) {
                  progressCallback(progress)
                }
              },
              onError(err) {
                console.log(err);
              }
            })(res)
          })
        .then((r) => {
          if (r.ok) {
            if (r.headers.get("Content-Length") === "0" || r.status === 204) {
              resolve(r);
            } else if (r.redirected) {
              window.location = r.url;
            } else {
              if (isOctetStream) {
                r.blob().then(resolve).catch(reject);
              } else if (headers["Accept"] === CONTENT_TYPE.TEXT_PLAIN) {
                r.text().then(resolve).catch(reject);
              } else {
                r.json().then(resolve).catch(reject);
              }
            }
          } else if (r.redirected) {
            window.location = r.url;
          } else {
            if (headers["Accept"] === CONTENT_TYPE.TEXT_PLAIN) {
              r.text()
                .then((e) => reject(e))
                .catch(reject);
            } else {
              if (isOctetStream) {
                r.blob().then((response) => {
                  console.log(`Request Error`, response);
                  reject('Error retrieving blob stream');
                });
              } else {
                r.json()
                  .then((errorResponse) => {
                    if (RequestHelper.isMissingSignature(errorResponse)) {
                      RequestHelper.signatureModalInstance.activate(
                        (signatureToken) => {
                          RequestHelper.send(
                            url,
                            {
                              ...headers,
                              "X-Signature": signatureToken,
                            },
                            method,
                            queryParams,
                            body
                          )
                            .then(resolve)
                            .catch(reject);
                        }
                      );
                    } else {
                      console.log(`Request Error`, errorResponse)
                      reject(errorResponse);
                    }
                  })
                  .catch((error) => reject(error));
                }
            }
          }
        })
        .catch((r) => {
          reject(r);
        });
    });
  }

  static graphQl(query, variables, headers = {}) {
    const buildGraphQlRequest = RequestHelper.buildGraphQlRequest.bind(
      RequestHelper,
      query,
      variables,
      headers
    );
    if (AuthService.getTokenExpiry().isBefore(moment())) {
      return AuthService.refreshAccessToken().then(buildGraphQlRequest);
    } else {
      return buildGraphQlRequest();
    }
  }

  /**
   * This is an inner/private function to create the promise which sends the request
   * It needs to be within a function so that it is evaluated after StaffService.refreshAccessToken() completes
   */
  static buildGraphQlRequest(query, variables, headers = {}) {
    return new RSVP.Promise((resolve, reject) => {
      fetch(serverAddress + "/graphql", {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": CONTENT_TYPE.APPLICATION_JSON,
          Accept: CONTENT_TYPE.APPLICATION_JSON,
          Authorization: "Bearer " + AuthService.getAuthToken(),
          ...headers,
        },
        body: JSON.stringify({
          query: query,
          variables: variables,
        }),
      })
        .then((r) => {
              if (r.status === 401) {
                return reject(new Error("Unauthorized 401"))
              } else {
                return r.json()
              }
            }
        )
        .then((d) => {
          if (d.errors) {
            if (RequestHelper.isMissingSignature(d.errors[0])) {
              RequestHelper.signatureModalInstance.activate(
                (signatureToken) => {
                  RequestHelper.graphQl(query, variables, {
                    ...headers,
                    "X-Signature": signatureToken,
                  })
                    .then(resolve)
                    .catch(reject);
                }
              );
            } else {
              reject(d.errors);
            }
          } else if (d.data.query) {
            resolve(d.data.query);
          } else {
            resolve(d.data);
          }
        })
        .catch((response) => {
          console.log('response catch', response)
          reject(response)
        });
    });
  }

  static isMissingSignature(errorResponse) {
    if (errorResponse.message) {
      return (
        errorResponse.message.includes(
          "This method requires an X-Signature header value"
        ) ||
        errorResponse.message.includes("The supplied signature is invalid.")
      );
    } else {
      return false;
    }
  }

  static createInitialRequestObjectsWithFeedback(
    feedbackReason,
    feedbackHeaderValue
  ) {
    const initialHeaders = {};
    if (feedbackHeaderValue) {
      initialHeaders["x-feedback-available"] = feedbackHeaderValue;
    }
    const initialRequestBody = {};
    if (feedbackReason) {
      initialRequestBody["feedback"] = feedbackReason;
    }

    return { initialHeaders, initialRequestBody };
  }

  static jsonRequestBodyToFormData(requestBody) {
    const formData = new FormData();
    Object.keys(requestBody).forEach(k => formData.append(k, requestBody[k]));
    return formData;
  }

}
