import React from 'react'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import TextValueEditor from './simpleValueEditors/TextValueEditor'
import SelectValueEditor from './simpleValueEditors/SelectValueEditor'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function HeadingConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <SelectValueEditor pathKey={'renderAs'} label={"Render as"} arrayOfValues={["value"]} />
      <TextValueEditor pathKey={'backgroundColor'} label={"Background Color"} />
    </ConfigWrapper>
  )
}

export default HeadingConfigEditor