import React, { useState, useContext} from "react";
import {withTranslation} from "react-i18next";
import {Button, Form, Grid,  Loader, Message} from "semantic-ui-react";
import Page from "../../../components/page/Page";
import UserContext from "../../../context/UserContext";
import DateRangeControls from "../../../components/DateRangeControls";
import moment from "moment/moment";
import SubjectCodeLookup from "../../../components/subjects/SubjectCodeLookup";
import SelectorDisplay from "../../../questionnaires/additional/SelectorDisplay";
import {
    recalculateAnswersForAllSubjects,
    recalculateAnswersForSingleSubject
} from "../../../services/admin/AdminBatchManagamentService";

function AdminRecalculateAnswersPage({t}) {
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);
    const [loading, setLoading] = useState(false);

    const [params, setParams] = useState({
        questionnaireCode: undefined,
        questionCode: undefined,
        allSubjects: true,
        subject: undefined,
        missingOnly: true,
        completeOnly: true,
        ignoreExpressionErrors: false,
        startDate: moment().add(-1, "months"),
        endDate: moment()
    })
    const user = useContext(UserContext);
    const isSuperAdmin = user.profile.superAdmin;

    const handleDateChange = (startDate, endDate) => {
        if(moment(startDate).diff(params.startDate) !== 0 && moment(endDate).diff(params.endDate) !== 0){
            setParams({...params, startDate: moment(startDate), endDate:moment(endDate)});
        }
    };

    const doRecalculateAnswers = async () => {
        setLoading(true)
        setError(false)
        setMessage(false)
        try {
            if(!params.allSubjects && params.subject === undefined){
                throw new Error("Select all  or find one subject")
            }
            if(!params.questionCode || !params.questionnaireCode){
                throw new Error("Questionnaire code and question code are required")
            }
            if (params.allSubjects) {
                await recalculateAnswersForAllSubjects(params.questionnaireCode, params.questionCode,
                    params.missingOnly, params.completeOnly, params.ignoreExpressionErrors,
                    params.startDate.format('yyyy-MM-DD'), params.endDate.format('yyyy-MM-DD'))
            } else {
                await recalculateAnswersForSingleSubject(params.questionnaireCode, params.questionCode,
                    params.missingOnly, params.completeOnly, params.ignoreExpressionErrors,
                    params.startDate.format('yyyy-MM-DD'), params.endDate.format('yyyy-MM-DD'),
                    params.subject.subjectId)

            }
            setMessage({message: "Recalculation successful."})
        }catch (e){
            console.log('Error during answer recalculation', e)
            if(e.error) {
                setError({message: e.error})
            }else if(e.message){
                setError(e)
            }else {
                setError({message: e})
            }
        }finally {
            setLoading(false);
        }
    }

    return (
        <Page
            name="Answers Recalculation"
            header={t("ADMIN_ANSWERS_RECALCULATE_PAGE_TITLE", "Admin Answers Recalculation")}>
            {error && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={"Error during processing: " + error.message}
                />
            )}
            {message && (
                <Message
                    success
                    header={t("GLOBAL_MESSAGE_TITLE", "Message")}
                    content={message.message}
                />
            )}
            {loading && (
                <Loader active={loading}/>
            )}
            {!isSuperAdmin && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN", "NOT AUTHORIZED")}
                />
            )}

            <Grid>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Form>
                            <Form.Field>
                                <label>
                                    {t("ADMIN_ANSWERS_QUESTIONNAIRE_CODE", "Enter questionnaire code (case sensitive)")}
                                </label>
                                <input
                                    name="questionnaireCode"
                                    value={params.questionnaireCode}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setParams({...params, questionnaireCode: e.target.value});
                                    }}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>
                                    {t("ADMIN_ANSWERS_QUESTION_CODE", "Enter question code (case sensitive)")}
                                </label>
                                <input
                                    name="questionCode"
                                    value={params.questionCode}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setParams({...params, questionCode: e.target.value});
                                    }}
                                />
                                <SelectorDisplay
                                    value={true}
                                    onChange={() => {
                                        const newValue = !params.missingOnly
                                        setParams({
                                            ...params,
                                            missingOnly: newValue
                                        })

                                    }}
                                    isCheckbox={true}
                                    checked={params.missingOnly}
                                    disabled={false}  //Disabled checkbox

                                    label={`Don't update existing answers`}/>
                                <SelectorDisplay
                                    value={params.completeOnly}
                                    onChange={() => {
                                        setParams(p=>{
                                            return {...p, completeOnly: !p.completeOnly}
                                        })
                                    }}
                                    isCheckbox={true}
                                    checked={params.completeOnly}
                                    disabled={false}  //Disabled checkbox
                                    label={`Dont update incomplete questionnaires`}/>

                                <SelectorDisplay
                                    value={true}
                                    onChange={() => {
                                        const newValue = !params.ignoreExpressionErrors
                                        setParams({
                                            ...params,
                                            ignoreExpressionErrors: newValue
                                        })
                                    }}
                                    isCheckbox={true}
                                    checked={params.ignoreExpressionErrors}
                                    disabled={false}  //Disabled checkbox

                                    label={`Ignore expression errors`}/>
                            </Form.Field>
                            <Form.Field>
                                <SelectorDisplay
                                    value={true}
                                    onChange={() => {
                                        const newValue = !params.allSubjects
                                        setParams({
                                            ...params,
                                            allSubjects: newValue,
                                            subject: (newValue ? undefined : params.subject)
                                        })

                                    }}
                                    isCheckbox={true}
                                    checked={params.allSubjects}
                                    disabled={false}  //Disabled checkbox

                                    label='Apply to all subjects'/>

                                <label>
                                    {t("ADMIN_ANSWERS_SUBJECT", "Apply for selected subject")}
                                </label>
                                <SubjectCodeLookup
                                    placeholder={params.allSubjects ? 'Disabled' : undefined}
                                    onChange={(subjects) => {
                                        if (!params.allSubjects) {
                                            setParams({
                                                ...params,
                                                subject: subjects && subjects.length > 0 ? subjects[0] : undefined
                                            })
                                        }
                                    }}
                                    value={params.subject ? [params.subject] : []}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Button primary fluid onClick={doRecalculateAnswers}>{t("ADMIN_ANSWERS_RECALCULATE_BUTTON", "Recalculate")}</Button>
                            </Form.Field>
                        </Form>

                    </Grid.Column>
                    <Grid.Column width={6}>

                        <label>{t("ADMIN_NOTIFICATION_LIST_DATERANGE", "Activation From (First Active) Date Range:")}</label>
                        <DateRangeControls
                            onChange={handleDateChange}
                            start={params.startDate}
                            end={params.endDate}
                        />


                    </Grid.Column>

                </Grid.Row>

            </Grid>

        </Page>)

}

export default withTranslation()(AdminRecalculateAnswersPage);
