import React, {useCallback, useContext, useEffect, useState} from "react";
import LocalStorageHelper from "../helpers/LocalStorageHelper";

const IS_NAV_EXPANDED_KEY = 'IS_NAV_EXPANDED';
const CollapseNavContext = React.createContext([]);
const useCollapseNavInfo = () => useContext(CollapseNavContext);

const CollapseNavProvider = ({children}) => {
  const isExpandedSavedValue = LocalStorageHelper.getBoolean(IS_NAV_EXPANDED_KEY, window.screen.availWidth >= 800)
  const [isExpanded, setIsExpandedState] = useState(isExpandedSavedValue);

  const setIsExpanded = useCallback((isExpanded) => {
    setIsExpandedState(isExpanded);
  }, []);
  const toggleExpanded = useCallback(() => {
    setIsExpandedState(isExpandedOld => !isExpandedOld);
  },[]);

  useEffect(()=>{
    LocalStorageHelper.setBoolean(IS_NAV_EXPANDED_KEY, isExpanded);
  }, [isExpanded])


  return <CollapseNavContext.Provider value={[isExpanded, setIsExpanded, toggleExpanded]} >
    {children}
  </CollapseNavContext.Provider>
}

export default useCollapseNavInfo;
export {CollapseNavProvider}
