import React from "react";
import {QUESTION_TYPES, typeHelper} from "atom5-branching-questionnaire";
import QUESTION_RENDERERS from "../question-renderers/QuestionnaireRenderers";
import QuestionnaireDiaryTable from "../DiaryTable";
import DisplayMediaQuestion from "./DisplayMediaQuestion";
import DataQueryButtons from "../../components/dataquery/DataQueryButtons";
import QuestionHelper from "../../helpers/QuestionHelper";
import { getIsExportingQuestionnaireAsPdf } from "../../redux/ui/globalValuesSlice";
import { connect } from "react-redux";

const DisplayQuestion = ({
  definition,
  question,
  answer,
  containerType = null,
  questionnaireId,
  subjectId = false,
  permissions = [],
  showLabel = true,
  printable = false,
  showQueryButtons = false,
  isExportingQuestionnaireAsPdf
}) => {
  const hasAnswer = answer !== null && answer !== undefined;
  const showOnDashboardConfig = QuestionHelper.getConfigValue(
      question,
      "showOnDashboard",
      undefined
  );
  // no answer and no config to show: hide
  if(!hasAnswer && !typeHelper.parseBool(showOnDashboardConfig)){
    return null
  }

  const renderer = QUESTION_RENDERERS[question.type];

  if (QUESTION_TYPES.isAttachmentType(question.type)) {
    return (
      <DisplayMediaQuestion
        question={question}
        answer={answer}
        subjectId={subjectId}
        permissions={permissions}
        showLabel={showLabel}
        containerType={containerType}
        printable={printable}
      />
    );
  }

  if (question.type === QUESTION_TYPES.DIARY) {
    return <QuestionnaireDiaryTable question={question} answer={answer} />;
  }

  let displaySliderValueOnly = false;
  if (question.type === QUESTION_TYPES.SLIDER && containerType === "table") {
    displaySliderValueOnly = true;
  }

  const renderedQuestion = React.createElement(renderer, {
    definition,
    question: question,
    value: answer,
    isReadOnly: true,
    showLabel,
    printable,
    containerType,
    displaySliderValueOnly: displaySliderValueOnly,
    subjectId,
    questionnaireId
  });

  return (
      <>
      {renderedQuestion}
      {!isExportingQuestionnaireAsPdf && showQueryButtons && question.type !== QUESTION_TYPES.PARAGRAPH && (
        <div style={{ display: "inline-block" }}>
          <DataQueryButtons
            question={question}
            questionnaireId={questionnaireId}
            renderedQuestion={renderedQuestion}
            subjectId={subjectId}
          />
        </div>
      )}
      </>
  );
};


const mapStateToProps = (state) =>
{
  return {
    isExportingQuestionnaireAsPdf: getIsExportingQuestionnaireAsPdf(state),
  };
};

export default connect(mapStateToProps)(DisplayQuestion);
