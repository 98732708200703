import React, {useEffect, useRef, useState} from "react";
import { Button, Form, Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import withLabel from "./hocs/withLabel";
import ConfirmButton from "../../components/dashboard/ConfirmButton";
import { v4 as uuid_v4 } from "uuid";
import withError from "./hocs/withError";
import { compose } from "redux";
import withContainer from "./hocs/withContainer";
import DisplayMediaQuestion from "../display/DisplayMediaQuestion";
import SubjectService from "../../SubjectService";

const File = ({ question, value, subjectId, changeValue, isReadOnly, label, t }) => {
  const fileInputRef = useRef();
  const [permissionsForSubject, setPermissionsForSubject] = useState([])

  useEffect(()=>{
    const getPermissions = async () => setPermissionsForSubject(await SubjectService.getSubjectPermission(subjectId));
    getPermissions()
  }, [subjectId])

  const buttonTextKey = question?.config?.buttonTextKey
    ? question.config.buttonTextKey
    : null;
  const enableDeleteButton = question?.config?.enableDeleteButton
    ? question.config.enableDeleteButton
    : false;
  const enableSelectedFilename = question?.config?.enableSelectedFilename
    ? question.config.enableSelectedFilename
    : true;
  const fileInputAccept = question?.config?.allowedFileMimeTypes
    ? question.config.allowedFileMimeTypes
    : "";

  const hasValue = value != null;
  const showDeleteButton = enableDeleteButton && !isReadOnly && hasValue;
  const showSelectedFilename =
    enableSelectedFilename && !isReadOnly && hasValue;

  const handleInitClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = async (event) => {
    const file = event?.target?.files[0];
    const reference = uuid_v4();

    const wrappedAnswer = {
      answer: reference,
      data: file,
      mediaCreateDate:  file.lastModifiedDate.toISOString()
    };
    changeValue(wrappedAnswer);
  };

  const deleteValue = () => {
    changeValue(null);
  };

  const buttonContent = (() => {
    if (buttonTextKey !== null) {
      return t(buttonTextKey);
    } else {
      return label;
    }
  })();

  return (
    <Form.Field>
      {!isReadOnly ? (
        <div>
          {!hasValue && (
            <Form.Field style={{ paddingBottom: 25 }}>
              <Button
                primary={true}
                content={buttonContent}
                labelPosition="left"
                icon={"file"}
                onClick={handleInitClick}
              />
              <input
                ref={fileInputRef}
                type="file"
                hidden
                accept={fileInputAccept}
                onChange={handleFileSelect}
              />
            </Form.Field>
          )}

          {hasValue && (
            <div>
              {showSelectedFilename && (
                <Message positive>{value?.data?.name}</Message>
              )}
              {showDeleteButton && (
                <ConfirmButton
                  buttonText={t("FILE_DELETE")}
                  contentText={t("FILE_DELETE_PROMPT")}
                  confirmButtonText={t("GENERIC_YES")}
                  cancelButtonText={t("GENERIC_NO")}
                  onConfirm={deleteValue}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          <DisplayMediaQuestion question={question} showLabel={false} answer={value} subjectId={subjectId} permissions={permissionsForSubject} />
          {/* FUTURE: Unsure how best to handle the documents being retrievable, need to consider a per question config allowing it as it may not always be allowed. */}
        </>
      )}
    </Form.Field>
  );
};

const enhance = compose(withContainer, withTranslation(), withError, withLabel);

export default enhance(File);
