import React, { useState, useEffect } from "react";
import { Form, Input } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

const GroupAndRoleSearch = (props) => {

  const { t,
          groupLabels,
          roleLabels,
          initialFilterForGroups,
          handleFilteredStaffList
  } = props;

  const [groupRoleSearchText, setGroupRoleSearchText] = useState("");

  useEffect(() => {
    performGroupRoleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupRoleSearchText]);

  const performGroupRoleSearch = () => {

    if (!groupRoleSearchText) {
      handleFilteredStaffList(initialFilterForGroups);
      return;
    }

    const searchStrings = groupRoleSearchText.split(",");
    let concatenatedSearchResults = [];
    searchStrings.forEach(searchString => {
      if (searchString) {
        searchString = searchString.trim().toLowerCase();
        /* Group searches ------------------------*/
        initialFilterForGroups.forEach(staffMember => {
          staffMember.groupMappings.forEach(g => {
            if (groupLabels[g.group.code].label.toLowerCase().includes(searchString)) {
              if (concatenatedSearchResults.indexOf(staffMember) === -1) {
                concatenatedSearchResults.push(staffMember);
              }
            }
          })
        });

        /* Role searches ------------------------*/
        initialFilterForGroups.forEach(staffMember => {
          staffMember.groupMappings.forEach(g => {
            g.roles.forEach(r => {
              let label = roleLabels[r.code];
              if ((label && roleLabels[r.code].toLowerCase().includes(searchString))
                || (r.code.toLowerCase().includes(searchString))) {
                  if (concatenatedSearchResults.indexOf(staffMember) === -1) {
                    concatenatedSearchResults.push(staffMember);
                  }
              }
            });
          })
        });
      }
    });
    handleFilteredStaffList(concatenatedSearchResults);
  };

  return (
      <Form onSubmit={(() => setGroupRoleSearchText(""))}>
        <Input
        onChange={(value) => setGroupRoleSearchText(value.target.value)}
        style={{ marginLeft: 20, paddingRight: "120px" }}
        value={groupRoleSearchText}
        placeholder={t("STAFF_GROUP_AND_ROLE_FILTER", "Group and Role filter")}
        action={t("STAFF_CLEAR_SEARCH_BUTTON", "Clear")}
        />
      </Form>
  );
}

export default withTranslation()(GroupAndRoleSearch);
