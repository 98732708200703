import RequestHelper from "../../RequestHelper";
import {serverAddress} from "../config/EnvConfig";


export const  updateLogLevel = async (logName, logLevel) => {
    const data = {}
    data[logName] = logLevel;
    return RequestHelper.send(
      serverAddress +
        "/trial/configuration/logging",
      {},
      "PUT",
      null,
      data
    );
  }