import { QUESTION_TYPES } from 'atom5-branching-questionnaire';
import getQuestionSubtype from 'atom5-branching-questionnaire/src/helpers/getQuestionSubtype';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import QUESTION_RENDERERS from '../../questionnaires/question-renderers/QuestionnaireRenderers';
import DataQueryService from '../../services/DataQueryService';
import DataQueryModalStyles from './DataQueryModalStyles';

const AnswerDataQueryModal = ({t, open, onClose, query, question, renderedQuestion, subjectId}) => {
  const [comment, setComment] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [answer, setAnswer] = useState(QUESTION_TYPES.getDefaultValue(question.type));

  const renderer = QUESTION_RENDERERS[question.type];
  const newValueQuestion = React.createElement(renderer, {
    question: {...question},
    subtype: getQuestionSubtype(question),
    value: answer,
    changeValue: setAnswer,
    isReadOnly: false
  });

  const onSubmit = async () => {
    if (comment.length === 0 || (!isCorrect && answer === null)) {
      return;
    }
    await DataQueryService.answerQuery(query.id, subjectId, comment, answer);
    setComment("");
    setIsCorrect(null);
    setAnswer(null);
    onClose();
  };
  
  return (
    <Modal size={"large"} open={open} onClose={onClose} style={{ padding: 15 }}>
      <Form>
        <h2>{t("DATA_QUERY_ANSWER_HEADER")}</h2>
        <p>{t("DATA_QUERY_ANSWER_DESCRIPTION")}</p>

        <div style={DataQueryModalStyles.important}>{renderedQuestion}</div>

        <p>
          {t("DATA_QUERY_ANSWER_RAISER_COMMENT").replace("{}", query.raisedBy)}
        </p>
        <p style={DataQueryModalStyles.important}>{query.raiserComment}</p>

        <div style={{ marginBottom: 5 }}>
          <Input
            type={"radio"}
            id={"isCorrect"}
            onChange={(e) => setIsCorrect(e.target.value === "true")}
            name={"isCorrect"}
            value={true}
            checked={isCorrect === true}
            required={true}
          />
          <label style={styles.radioLabel} htmlFor="isCorrect">
            {" "}
            {t("DATA_QUERY_ANSWER_IS_CORRECT")}
          </label>
        </div>

        <div style={{ marginBottom: 10 }}>
          <Input
            type={"radio"}
            id={"isNotCorrect"}
            onChange={(e) => setIsCorrect(e.target.value === "true")}
            name={"isCorrect"}
            value={false}
            checked={isCorrect === false}
            required={true}
          />
          <label style={styles.radioLabel} htmlFor="isNotCorrect">
            {" "}
            {t("DATA_QUERY_ANSWER_IS_NOT_CORRECT")}
          </label>
        </div>

        {isCorrect === false && newValueQuestion}

        <Form.Input
          label={t("DATA_QUERY_ANSWER_COMMENT")}
          type="text"
          name="comment"
          id="comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          required={true}
        />

        <Button onClick={onSubmit}>
          {t("GLOBAL_BUTTON_SUBMIT", "Submit")}
        </Button>
      </Form>
    </Modal>
  );
};

const styles = {
  radioLabel: {
    padding: "0.25rem 0rem 0.25rem 1rem"
  },
};

export default withTranslation()(AnswerDataQueryModal);