import React from 'react'
import SelectValueEditor from './simpleValueEditors/SelectValueEditor'
import ObjectGroup from './simpleValueEditors/ObjectGroup'
import TextValueEditor from './simpleValueEditors/TextValueEditor'
import NumberValueEditor from './simpleValueEditors/NumberValueEditor'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import ArrayGroup from './simpleValueEditors/ArrayGroup'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function FixedValueConfigEditor({ config, onChange }) {

  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <SelectValueEditor pathKey={'type'} label={'Type'} arrayOfValues={['SWITCH', 'DROPDOWN', 'BUTTON', 'ANATOMY']} />
      {config.type === 'ANATOMY' ? <SelectValueEditor pathKey={'type'} label={'Type'} arrayOfValues={['FRONTASYM', 'FRONT']} /> : <></>}
      <NumberValueEditor pathKey={'answersPerRow'} label={"Answers Per Row"} />
      <ObjectGroup pathKey={"answerDisplayOrder"} label={"Answer Display Order"}>
        <SelectValueEditor pathKey={"mode"} label={"Mode"} arrayOfValues={["RANDOM"]} />
        <ArrayGroup
          pathKey={"appendToEnd"}
          label={"Append To End"}
        >
          <TextValueEditor label={""} />
        </ArrayGroup>
      </ObjectGroup>
      <ArrayGroup
        label={"Coloring"}
        pathKey={"coloring"}
      >
        <ObjectGroup label={"Coloring"} defaultValue={{ color: "", questionCode: "", value: 0 }}>
          <TextValueEditor pathKey={"color"} label={"Color"} />
          <TextValueEditor pathKey={"questionCode"} label={"Question Code"} />
          <NumberValueEditor pathKey={"value"} label={"Value"} />
        </ObjectGroup>
      </ArrayGroup>
    </ConfigWrapper >
  )
}

export default FixedValueConfigEditor