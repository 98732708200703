function sanitizeString(str) {
  return String(str).replace(/[&<>"'`=/]/g, function (s) {
    const entityMap = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      '/': '&#x2F;',
      '`': '&#x60;',
      '=': '&#x3D;'
    };
    return entityMap[s];
  });
}

export default { sanitizeString }