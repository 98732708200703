import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getDefinitions } from '../../../redux/questionnaires/questionnaireDefinitionsSlice';
import RelatedQuestionnaireHelpers from '../../../questionnaires/display/RelatedQuestionnaireHelpers';
import { Icon } from 'semantic-ui-react';

const SubjectVisitDateQuestionnaires = (props) => {
  const { t, allQuestionnaireDefinitions, questionnaires } = props;

  const [countCompleted, setCountCompleted] = useState();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (questionnaires == null) {
      return;
    }
    const completed = questionnaires?.filter(q => q.completionDate !== null);
    setCountCompleted(completed.length);
  }, [questionnaires]);

  if (questionnaires == null) {
    return null;
  }

  const getQuestionnaireDefinition = (questionnaireDefinitionId) => {
    const questionnaireDefinition = allQuestionnaireDefinitions.find(
      (qd) => qd.id === questionnaireDefinitionId
    );
    return questionnaireDefinition;
  };

  const relatedQuestionnaireItems = questionnaires.map((q) => {
    const questionnaireDefinition = getQuestionnaireDefinition(
      q.definitionId
    );
    if (questionnaireDefinition == null) {
      return null;
    }
    return RelatedQuestionnaireHelpers.buildChildQuestionnaireItem(t, q, questionnaireDefinition);
  }).filter((q) => q != null);

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div style={{marginBottom: 20}}>
        {countCompleted} / {questionnaires.length}
        {!isExpanded && <Icon name='plus square outline' onClick={handleExpandToggle} className={'mouse-pointer'} aria-label={t('GENERIC_EXPAND', 'Open')} />}
        {isExpanded && <Icon name='minus square outline' onClick={handleExpandToggle} className={'mouse-pointer'} aria-label={t('GENERIC_SHRINK', 'Close')} />}
      </div>

      {isExpanded && (
        relatedQuestionnaireItems.length > 0 && (
          <ol style={{ margin: 0, paddingInlineStart: 0 }}>
            {relatedQuestionnaireItems}
          </ol>
        )
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allQuestionnaireDefinitions: getDefinitions(state)
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(SubjectVisitDateQuestionnaires);
