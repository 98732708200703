import React, { Component } from "react";
import { Button, Form, Grid, Header, Segment, Image } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import AccountTypeSelector from "../../components/login/AccountTypeSelector";
import LanguageSelection from "../../components/languageselection/LanguageSelection";
import SubjectService from "../../SubjectService";
import BrandingService from "../../services/BrandingService";
import TrialService from "../../TrialService";
import SUBJECT_AUTHENTICATION_STRATEGY from "../../constants/SUBJECT_AUTHENTICATION_STRATEGY";
import InternationalisationService from "../../InternationalisationService";
import ConfigService from "../../services/ConfigService";
import StaffService from "../../StaffService";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    if (Window.configuration.selfOnboardingEnabled === "false") {
      AuthService.setAccountType("staff");
    }

    const brandImageUrl = BrandingService.getBrandImageForOnBoarding();

    this.state = {
      username: "",
      password: "",
      accountType: AuthService.getAccountType(),
      error: null,
      message: null,
      requestPending: false,
      forgottenPassword: false,
      forgottenPasswordEmailSent: false,
      brandImageUrl: brandImageUrl,
      subjectAuthenticationStrategy:
        TrialService.getDefaultSubjectAuthenticationStrategy(),
      isTestEnvironment: false,
      validateUsernameAsEmailAddress: true,
      showForgottenPasswordForm: true,
    };

    if (props?.location?.search) {
      const query = new URLSearchParams(this.props.location.search);
      const type = query.get("type");
      const email = query.get("email");

      if (type === "subject" || type === "subject") {
        AuthService.setAccountType(type);
        this.state.accountType = type;
      }

      if (email) {
        this.state.username = email;
      }
    }
  }

  componentDidMount = () => {
    this.fetchTrialSettings();
    this.fetchTrialAuthenticationSettings();
  };

  fetchTrialSettings = async () => {
    const trial = await TrialService.getCurrentTrial();
    this.setState({
      isTestEnvironment: trial.isTest,
    });
    this.shoulDisablePasswordAutoComplete =
      !trial.isTest && ConfigService.shoulDisablePasswordAutoComplete();
  };

  fetchTrialAuthenticationSettings = async () => {
    const subjectAuthenticationStrategy =
      await TrialService.getSubjectAuthenticationStrategy();
    const validateUsernameAsEmailAddress =
      await AuthService.validateUsernameAsEmailAddress();
    const showForgottenPasswordForm =
      AuthService.getAccountType() === "staff" ||
      (AuthService.getAccountType() === "subject" &&
        subjectAuthenticationStrategy ===
          SUBJECT_AUTHENTICATION_STRATEGY.EMAIL_PWD);
    this.setState({
      showForgottenPasswordForm: showForgottenPasswordForm,
      subjectAuthenticationStrategy: subjectAuthenticationStrategy,
      validateUsernameAsEmailAddress: validateUsernameAsEmailAddress,
    });
  };

  setAccountType = (accountType) => {
    AuthService.setAccountType(accountType);
    this.setState({ accountType, error: null });
    this.fetchTrialAuthenticationSettings();
  };

  validateForm() {
    const isValidUsername =
      (!this.state.validateUsernameAsEmailAddress &&
        this.state.username.length > 0) ||
      (this.state.validateUsernameAsEmailAddress &&
        AuthService.EMAIL_REGEX.test(this.state.username));
    return (
      (isValidUsername && this.state.password.length > 0) ||
      this.state.forgottenPassword
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { username, password, accountType } = this.state;
    this.setState({ error: null, message: null, requestPending: true });

    try {
      await AuthService.login(username, password, accountType);

      let showScreeningQuestionnaires = false;

      if (AuthService.getAccountType() === "subject") {
        showScreeningQuestionnaires = await SubjectService.shouldShowScreeningQuestionnaire();
      } else {
        //are we a staff member, if so check for outstanding mandatory pros
        await StaffService.redirectIfStaffHasOutstandingMandatoryPros(username, password);
      }

      AuthService.redirectAfterLogin(showScreeningQuestionnaires);
    } catch (error) {
      this.setState({ requestPending: false, password: "", error });
    }
  };

  resetPassword = (event) => {
    const { t } = this.props;
    event.preventDefault();
    const { username, accountType } = this.state;
    AuthService.sendForgotPassword(username, accountType)
      .then(() => {
        this.setState({
          requestPending: false,
          password: "",
          error: null,
          message: t(
            "RESET_PASSWORD_SENT",
            "If an account exists for that email address, an email will be sent to allow your password to be reset."
          ),
        });
      })
      .catch((error) => {
        this.setState({ requestPending: false, password: "", error });
      });
  };

  toggleForgottenPassword = () => {
    this.setState({
      forgottenPassword: !this.state.forgottenPassword,
      message: null,
      error: null,
    });
  };

  render() {
    const { t } = this.props;
    const { accountType } = this.state;
    let displayForm;

    if (accountType) {
      displayForm = (
        <Form
          size="large"
          onSubmit={
            this.state.forgottenPassword
              ? this.resetPassword
              : this.handleSubmit
          }
        >
          <Segment>
            <Form.Input
              fluid
              icon={
                this.state.subjectAuthenticationStrategy ===
                SUBJECT_AUTHENTICATION_STRATEGY.SUBJECTCODE_PWD
                  ? "user"
                  : "mail"
              }
              iconPosition="left"
              name="username"
              placeholder={
                this.state.subjectAuthenticationStrategy ===
                SUBJECT_AUTHENTICATION_STRATEGY.SUBJECTCODE_PWD
                  ? t("GLOBAL_LABEL_USERNAME")
                  : t("GLOBAL_LABEL_EMAIL_ADDRESS", "Email address")
              }
              value={this.state.username}
              onChange={this.handleChange}
              disabled={this.state.requestPending}
            />
            {!this.state.forgottenPassword && (
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                name="password"
                placeholder={t("GLOBAL_LABEL_PASSWORD", "Password")}
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
                disabled={this.state.requestPending}
                autoComplete={
                  this.shoulDisablePasswordAutoComplete ? "off" : null
                }
              />
            )}

            <Button
              type="submit"
              primary
              fluid
              size="large"
              disabled={!this.validateForm() || this.state.requestPending}
            >
              {!this.state.forgottenPassword &&
                t("GLOBAL_BUTTON_SUBMIT", "Submit")}
              {this.state.forgottenPassword &&
                t("RESET_PASSWORD_BUTTON_SUBMIT", "Reset password")}
            </Button>
          </Segment>
        </Form>
      );
    } else {
      displayForm = <AccountTypeSelector handleClick={this.setAccountType} />;
    }

    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{ maxWidth: 450 }}
          textAlign={InternationalisationService.isRTL() ? "right" : undefined}
        >
          {this.state.brandImageUrl && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image src={this.state.brandImageUrl} height={70} />
            </div>
          )}

          <Header as="h2" textAlign="center">
            {!this.state.forgottenPassword && (
              <>
                {this.state.accountType === "subject" &&
                  t(
                    "LOGIN_HEADER_TITLE_SUBJECT",
                    "Log in to your patient account"
                  )}
                {this.state.accountType === "staff" &&
                  t(
                    "LOGIN_HEADER_TITLE_STAFF",
                    "Log in to your clinician account"
                  )}
                {this.state.accountType !== "subject" &&
                  this.state.accountType !== "staff" &&
                  t("LOGIN_SELECT_ACCOUNT", "Select a type of account")}
              </>
            )}
            {this.state.forgottenPassword &&
              t("RESET_PASSWORD_HEADER_TITLE", "Reset password")}
          </Header>
          {!this.state.forgottenPassword && displayForm}
          {this.state.forgottenPassword &&
            this.state.showForgottenPasswordForm &&
            displayForm}
          {this.state.forgottenPassword &&
            !this.state.showForgottenPasswordForm && (
              <Segment>{t("RESET_PASSWORD_INFO_TEXT")}</Segment>
            )}
          {this.state.error && (
            <div className="ui negative message">
              <div className="header">{t("LOGIN_FAILED")}</div>
              {this.state.error === "account locked" && (
                <p>
                  {t(
                    "LOGIN_FAILED_LOCKED",
                    "Your account has been locked, please reset your password to regain access to your account."
                  )}
                </p>
              )}
              {this.state.error === "bad credentials" && (
                <p>
                  {t(
                    "LOGIN_FAILED_CREDENTIALS",
                    "The email address or password entered was incorrect."
                  )}
                </p>
              )}
              {this.state.error === "password expired" && (
                <p>
                  {t(
                    "LOGIN_FAILED_EXPIRED",
                    "Your account's password has expired, please reset your password to regain access to your account."
                  )}
                </p>
              )}
              {this.state.error === "unknown" && (
                <p>{t("LOGIN_FAILED_UNKNOWN", "An unknown error occured")}</p>
              )}
              {(this.state.error === "account locked" ||
                this.state.error === "password expired") && (
                <Button onClick={this.toggleForgottenPassword} primary basic>
                  {this.state.error === "account locked" &&
                    t("LOGIN_FAILED_LOCKED_BUTTON", "Reset Password")}
                  {this.state.error === "password expired" &&
                    t("LOGIN_FAILED_EXPIRED_BUTTON", "Reset Password")}
                </Button>
              )}
            </div>
          )}

          {this.state.forgottenPasswordEmailSent && (
            <div className="ui negative message">
              <div className="header">{t("FORGOTTEN_PASSWORD_SENT")}</div>
            </div>
          )}

          {this.state.message && (
            <div className="ui message">
              <div className="header">{this.state.message}</div>
            </div>
          )}

          {accountType && (
            <Segment
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={this.toggleForgottenPassword}
                disabled={this.state.requestPending}
                primary
                basic
              >
                {!this.state.forgottenPassword &&
                  t("FORGOTTEN_PASSWORD", "Forgotten Password")}
                {this.state.forgottenPassword &&
                  t("RETURN_TO_LOGIN", "Return to Login")}
              </Button>
              {!this.state.forgottenPassword &&
                this.state.accountType &&
                Window.configuration.selfOnboardingEnabled === "true" && (
                  <Button
                    onClick={() => this.setAccountType(null)}
                    disabled={this.state.requestPending}
                    primary
                    basic
                  >
                    {t(`LOGIN_NOT_${this.state.accountType.toUpperCase()}`)}
                  </Button>
                )}
            </Segment>
          )}

          <LanguageSelection inline style={{ float: "right" }} />
        </Grid.Column>
      </Grid>
    );
  }
}

export default withTranslation()(LoginPage);
