import React, { memo } from 'react';
import Controls from './Controls';
import ProgressSlider from './ProgressSlider';
import ControlButton from './ControlButton';
import { withTranslation } from 'react-i18next';

const ControlPanel = ({
  t,
  viewingProgress,
  controlState,

  options,
  timeSkipOptions,
  playbackRateOptions,
  annotationOptions,

  annotations, setAnnotations,

  onControlClick,
  onProgressChange,
  onAnnotationChange
}) => {


    return (
    <>
      { <div
        style={{
          marginTop: -20,
          position: 'absolute',
          right: 0
        }}
      >
        <ControlButton
          buttonText={controlState.isControlPanelLockedOpen
            ? t('ENHANCED_VIDEO_PLAYER_CONTROLS_PANEL_LOCK', 'Auto hide controls')
            : t('ENHANCED_VIDEO_PLAYER_CONTROLS_PANEL_UNLOCK', 'Lock controls open')}
          iconName={controlState.isControlPanelLockedOpen ? 'lock' : 'lock-open'}
          iconSize='1x'
          action={controlState.isControlPanelLockedOpen ? 'controlpanel-unlock' : 'controlpanel-lock'}
          onClick={onControlClick}
        />
      </div> }

      <div style={{ marginBottom: '10px' }}>
        <ProgressSlider
          controlState={controlState}
          currentTimeMilliseconds={viewingProgress.currentTimeMilliseconds}
          durationMilliseconds={viewingProgress.durationMilliseconds}
          onProgressChange={onProgressChange}
          annotations={annotations}
          setAnnotations={setAnnotations}
        />
      </div>
      <Controls
        viewingProgress={viewingProgress}
        controlState={controlState}
        onControlClick={onControlClick}
        options={options}
        timeSkipOptions={timeSkipOptions}
        playbackRateOptions={playbackRateOptions}
        annotationOptions={annotationOptions}
        annotations={annotations}
      />
    </>
  );
};

export default memo(withTranslation()(ControlPanel));
