import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Message,
  TextArea
} from 'semantic-ui-react';
import Page from '../../components/page/Page';
import LocalStorageHelper from '../../helpers/LocalStorageHelper';
import RequestHelper from '../../RequestHelper';
import AuthService from '../../services/AuthService';
import _ from 'lodash';
import { LOCALDATA_KEYS } from '../../services/LocalDataService';
import SubjectCodeLookup from '../../components/subjects/SubjectCodeLookup';
import {serverAddress} from "../../services/config/EnvConfig";

const AdminUtilsPage = (props) => {
  const { t } = props;

  const [errorMessage, setErrorMessage] = useState();
  const [hasPermission, setHasPermission] = useState();

  const defaultCriteria = {
    subjects: [],
    questionnaireId: undefined,
    spelExpression: '',
    formatAsJson: true,
    useTriggerContext: false
  };
  const [criteria, setCriteria] = useState(defaultCriteria);
  const [spelResult, setSpelResult] = useState();

  const genericErrorMessage = 'Please check the Subject Code/ID, SPEL Expression, and optional Questionnaire ID';

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    await checkPermisisons();
    const persistedCriteria = LocalStorageHelper.getJsonObject(
      LOCALDATA_KEYS.ADMIN_UTILS_SPELCHECKER
    );
    if (!_.isEmpty(persistedCriteria)) {
      // If persistedCriteria has a value for subjectIdentifier, it is the v1 of the search criteria, so we don't want that now, so we will ignore it.
      if (persistedCriteria.subjectIdentifier == null) {
        setCriteria(persistedCriteria);
      }
    }
  };

  const checkPermisisons = async () => {
    try {
      const profile = await AuthService.getMyProfile();
      setHasPermission(profile.superAdmin);
    } catch (error) {
      setHasPermission(false);
    }
  };

  const handleResetCriteria = (e) => {
    e.preventDefault();
    initRequestState();
    setCriteria(defaultCriteria);
  };

  const initRequestState = async () => {
    setErrorMessage();
    setSpelResult();
  };

  const createSpelCheckerRequest = async (subjectId) => {
    const url = `${serverAddress}/subjects/${subjectId}/spelchecker`;
    const requestBody = {
      spelExpression: criteria.spelExpression,
      jsonFormat: criteria.formatAsJson,
      questionnaireId: criteria.questionnaireId,
      useTriggerContext: criteria.useTriggerContext
    };

    const response = await RequestHelper.send(
      url,
      {},
      'POST',
      null,
      requestBody
    );

    if (!_.isObject(response) || response.spelResult == null) {
      throw new Error(genericErrorMessage);
    }

    return response.spelResult;
  };

  const handleFormSubmit = async () => {
    initRequestState();
    LocalStorageHelper.setJson(
      LOCALDATA_KEYS.ADMIN_UTILS_SPELCHECKER,
      criteria
    );

    try {
      if (criteria?.subjects == null || criteria.subjects.length === 0) {
        setErrorMessage('Invalid Subject');
        return;
      }

      if (criteria.questionnaireId != null) {
        const parsedQuestionnaireId = Number(criteria.questionnaireId);
        if (isNaN(parsedQuestionnaireId) || !_.isNumber(parsedQuestionnaireId)) {
          setErrorMessage('Invalid Questionnaire ID');
          return;
        }
      }

      const subjectId = criteria.subjects[0].subjectId;
      const result = await createSpelCheckerRequest(subjectId);

      setSpelResult(result);
    } catch (error) {
      setErrorMessage(error?.message || genericErrorMessage);
    }
  };

  return (
    <Page name='Admin Utilities' header={t('ADMIN_UTILS_HEADER')}>
      {hasPermission && (
        <>
          <Grid columns='equal'>
            <Grid.Column>
              <Form onSubmit={handleFormSubmit}>
                <Form.Field>
                  <label>{t('SUBJECT_VIEW_HEADER')}</label>
                  <SubjectCodeLookup
                    onChange={(subjects) => {
                      const newCriteria = { ...criteria };
                      newCriteria.subjects = subjects;
                      setCriteria(newCriteria);
                    }}
                    value={criteria?.subjects}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{t('QUESTIONNAIRE_ID', 'Questionnaire ID (Optional)')}</label>
                  <div>
                    <input
                      name='questionnaireId'
                      value={criteria.questionnaireId}
                      onChange={(e) => {
                        const newCriteria = { ...criteria };
                        const id = e.target.value.trim().length > 0 ? e.target.value.trim() : undefined;
                        newCriteria.questionnaireId = id;
                        setCriteria(newCriteria);
                      }}
                    />
                  </div>
                </Form.Field>

                <Form.Field>
                  <label>{t('ADMIN_UTILS_SPEL_EXPRESSION')}</label>
                  <input
                    name='spel'
                    value={criteria.spelExpression}
                    onChange={(e) => {
                      const newCriteria = { ...criteria };
                      newCriteria.spelExpression = e.target.value;
                      setCriteria(newCriteria);
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <Checkbox
                    label={t('ADMIN_UTILS_FORMAT_AS_JSON')}
                    checked={criteria.formatAsJson}
                    onChange={(e) => {
                      const newCriteria = { ...criteria };
                      newCriteria.formatAsJson = !criteria.formatAsJson;
                      setCriteria(newCriteria);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                      label={t('ADMIN_UTILS_USE_TRIGGER_CONTEXT', "Expression used in trigger condition?")}
                      checked={criteria.useTriggerContext}
                      onChange={(e) => {
                        const newCriteria = { ...criteria };
                        newCriteria.useTriggerContext = !criteria.useTriggerContext;
                        setCriteria(newCriteria);
                      }}
                  />
                </Form.Field>

                <Button type='submit' primary>
                  {t('GLOBAL_BUTTON_SUBMIT', 'Submit')}
                </Button>
                <Button secondary onClick={handleResetCriteria}>
                  {t('GLOBAL_BUTTON_RESET', 'Reset')}
                </Button>
              </Form>
              {errorMessage && (
                <Message
                  error
                  header={t('GLOBAL_ERROR_TITLE', 'Error')}
                  content={errorMessage}
                />
              )}
              {spelResult && (
                <Form style={{ marginTop: 10 }}>
                  <label>{t('ADMIN_UTILS_SPEL_RESULT')}</label>
                  <TextArea
                    style={{ minHeight: 400 }}
                    value={spelResult}
                  />
                </Form>
              )}
            </Grid.Column>
          </Grid>

          <hr style={{ marginTop: 50 }} />
          <Button
            type='submit'
            primary
            onClick={() => {
              props.history.push('/app/utils/translations');
            }}
          >
            {t('TRANSLATIONS', 'Admin Translations')}
          </Button>
          <Button
            type='submit'
            primary
            onClick={() => {
              props.history.push('/app/utils/triggers');
            }}
          >
            {t('TRIGGERS', 'Triggers')}
          </Button>
          <Button
            primary
            onClick={() => {
              props.history.push('/app/utils/questionnaire-definitions');
            }}
          >
            {t('QUESTIONNAIRE_DEFINITIONS', 'Questionnaire Definitions')}
          </Button>
          <Button
            primary
            onClick={() => {
              props.history.push('/app/utils/modules');
            }}
          >
            {t('MODULES', 'Modules')}
          </Button>
          <Button
            type='submit'
            primary
            onClick={() => {
              props.history.push('/app/utils/attachment-monitoring');
            }}
          >
            {t('ATTACHMENT_MONITORING', 'Attachment Monitoring')}
          </Button>
          <Button
            type='submit'
            primary
            onClick={() => {
              props.history.push('/app/utils/attachment-blurring');
            }}
          >
            {t('ATTACHMENT_BLURRING', 'Attachment Blurring')}
          </Button>
          <Button
            type='submit'
            primary
            onClick={() => {
              props.history.push('/app/utils/config-tool');
            }}
          >
            {t('CONFIG_TOOLS', 'Config tools')}
          </Button>
          <Button
            type='submit'
            primary
            onClick={() => {
              props.history.push("/app/utils/column-overrides");
                }}
            >
              {t("COLUMN_OVERRIDES", "Export Configuration")}
            </Button>
            <Button
              type="submit"
              primary
              onClick={() => {
                props.history.push('/app/utils/cache-management');
            }}
          >
            {t('ADMIN_CACHE_MANAGEMENT', 'Cache Management')}
          </Button>
          <Button
            type='submit'
            primary
            onClick={() => {
              props.history.push('/app/utils/notification-auditactivity');
            }}
          >
            {t('ADMIN_NOTIFICATION_AUDIT', 'Notification Activity Audit')}
          </Button>
          <Button
              type='submit'
              primary
              onClick={() => {
                props.history.push('/app/utils/remotejob-processing-queues');
              }}
          >
            {t('ADMIN_REMOTE_PROCESSING_QUEUES', 'Remote Job Processing Queues')}
          </Button>
          <Button
            type='submit'
            primary
            onClick={() => {
              props.history.push('/app/utils/tech-support');
            }}
          >
            {t('TECHSUPPORT', 'Tech Support')}
          </Button>
          <Button
              type='submit'
              primary
              onClick={() => {
                props.history.push('/app/utils/lookup');
              }}
          >
            {t('LOOKUP', 'Lookups')}
          </Button>
          <Button
              type='submit'
              primary
              onClick={() => {
                props.history.push("/app/utils/questionnaire-workflow");
              }}
          >
            {t("QUESTIONNAIRE_WORKFLOW", "Questionnaire Workflow Admin")}
          </Button>
          <Button
              type='submit'
              primary
              onClick={() => {
                props.history.push("/app/utils/answers/recalculate");
              }}
          >
            {t("QUESTIONNAIRE_ANSWER_CALCULATION", "Recalculate Answers")}
          </Button>
          <Button
              type='submit'
              primary
              onClick={() => {
                props.history.push("/app/utils/study-management");
              }}
          >
            {t("STUDY_MANAGEMENT", "Study Management")}
          </Button>
        </>
        )}

      {!hasPermission &&
        t('ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN')}
    </Page>
  );
};

export default withTranslation()(AdminUtilsPage);
