import React from 'react'
import TextValueEditor from './simpleValueEditors/TextValueEditor'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import BooleanValueEditor from './simpleValueEditors/BooleanValueEditor'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function FileConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <TextValueEditor pathKey="buttonTextKey" label={"Button Text Key"} />
      <TextValueEditor pathKey="allowedFileMimeTypes" label={"Allowed File Mime Types"} />
      <BooleanValueEditor pathKey="enableDeleteButton" label={"Enable Delete Button"} />
    </ConfigWrapper>
  )
}

export default FileConfigEditor