import i18n from "i18next";
import RequestHelper from "./RequestHelper";
import moment from "moment";
import LocalDataService from "./services/LocalDataService";
import {serverAddress} from "./services/config/EnvConfig";

export default class InternationalisationService {
  static async changeLanguage(language) {
    return i18n.changeLanguage(language).then(() => {
      LocalDataService.setLanguage(language);

      const momentLng = moment.locale(language);

      if (!language.includes(momentLng)) {
        console.log(
          `Warning: Passed Language String "${language}" resulted in the Moment Locale "${momentLng}".`
        );
      }

      InternationalisationService.setLanguageDirection(language);
    });
  }

  static setLanguageDirection(language) {
    const rtlLanguages = [
      // "en_",
      "ar_",
      "dv_",
      "fa_",
      "ha_",
      "he_",
      "ks_",
      "ps_",
      "sd_",
      "ur_",
      "yi_",
    ];
    const isRtl = rtlLanguages.some((rtlLanguage) => {
      return language.startsWith(rtlLanguage);
    });
    document.dir = isRtl === true ? "rtl" : "ltr";
  }

  static isRTL() {
    return document?.dir === "rtl" ? true : false;
  }

  static getLanguage() {
    return LocalDataService.getLanguage();
  }

  static getBrowserLanguage() {
    const browserLanguageRaw =
      window?.navigator?.userLanguage || window?.navigator?.language;

    if (browserLanguageRaw === undefined || browserLanguageRaw.length < 3) {
      // None found, or two letter one we cannot use
      return undefined;
    }

    const browserLanguage = browserLanguageRaw?.replace("-", "_");
    return browserLanguage;
  }

  static getLanguages() {
    return RequestHelper.send(
        serverAddress +
        "/i18n/electron-clinician-web/languages"
    );
  }

  static getMobileLanguages() {
    return RequestHelper.send(
      serverAddress +
        "/i18n/electron-subject-mobile/languages"
    );
  }

  static getTranslations(language) {
    return RequestHelper.send(
        serverAddress +
        "/i18n/electron-clinician-web/languages/" +
        language +
        "/translations"
    );
  }

  static clearServerCache(language) {
    return RequestHelper.send(
        serverAddress + "/translations/mgmt/clearcache",
      {},
      "POST",
      null,
      null
    );
  }
}
