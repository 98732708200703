import React from 'react'

/**
 * Should wrap all editors in ConfigEditorValueModalInsert. Modifies props of children.
 * @param {value} value
 * @param {function} onChange 
 * @returns 
 */
function ConfigWrapper({ children, value, onChange }) {
  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      // No not attempt to render conditionally false children
      if (!child) return null;
      // Props are passed automaticaly, with automcatically passed values
      // being overwritable via child props
      return React.cloneElement(child, {
        ...{ value, onChange },
        ...child.props,
        pathKey: child.props.pathKey,
        value: child.props.pathKey ? value[child.props.pathKey] : value,
      });
    });
  };

  return renderChildren();
}

export default ConfigWrapper