import React, { useEffect, useState } from "react";
import {
  Header,
  Image,
  Menu,
  Popup,
  PopupContent,
  Modal,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Package from "../../../package.json";
import {Link, withRouter} from "react-router-dom";
import LanguageSelection from "../../components/languageselection/LanguageSelection";
import LoginModalInsert from "./LoginModalInsert";
import ForgottenPasswordModalInsert from "./ForgottenPasswordModalInsert";
import ConsentModalInsert from "./ConsentModalInsert";
import RegisterEmailModalInsert from "./RegisterEmailModalInsert";
import NotificationIcon from "../notification/NotificationIcon";
import PickLanguageModalInsert from "./PickLanguageModalInsert";
import InternationalisationService from "../../InternationalisationService";
import { useDispatch } from "react-redux";
import AppStateService from "../../services/AppStateService";
import DropDownMenu from "./DropDownMenu";
import SupportButton from "../support/SupportButton";
import AuthService from "../../services/AuthService";
import StaffService from "../../StaffService";
import MandatoryTrainingModalInsert from "./MandatoryTrainingModalInsert";
import LocalDataService from "../../services/LocalDataService";
import SubjectService from "../../SubjectService";
import {typeHelper} from "atom5-branching-questionnaire";

const NavBar = (props) => {
  const { trial, config, user, t, history } = props;
  const { isLoggedIn } = user;

  const isStaff = AuthService.isStaff();

  const [isAccountCreationModalOpen, setIsAccountCreationModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isMandatoryModalOpen, setIsMandatoryModalOpen] = useState(false);
  const [isShowingForgottenPassword, setIsShowingForgottenPassword] = useState(false);

  const [hasConsented, setHasConsented] = useState(false);
  const [hasPickedLanguage, setHasPickedLanguage] = useState(false);

  const [subjectAccountCreationData, setSubjectAccountCreationData] = useState({});

  const switchAccountSubjectId = user?.profile?.linkedSubjectId;
  const [switchAccountStaffId, setSwitchAccountStaffId] = useState(undefined);
  const [outstandingMandatoryQuestionnaires, setOutstandingMandatoryQuestionnaires] = useState([]);

  const dispatch = useDispatch();

  const shouldUseLanguageModal = config.ui?.selfOnboarding?.startWithLanguagePicker === "true";

  const hasSeenMandatoryTrainingModal = LocalDataService.getHasSeenMandatoryTrainingModal();

  const enforceCompletionOfMandatoryPros = typeHelper.parseBool(config?.ui?.mandatoryStaffTraining?.enforce);

  const isTest = trial.isTest;

  const onConsented = async (subjectData) => {
    await setSubjectAccountCreationData(subjectData);
    setHasConsented(true);
  };

  const onLanguageSelected = async (language) => {
    await InternationalisationService.changeLanguage(language.code);
    AppStateService.getQuestionnaireDefinitionsFromServer(dispatch);
  };

  const onPickedLanguage = async (language) => {
    setHasPickedLanguage(true);
  };

  const openModalsBasedOnHash = () => {
    const modal = window.location.hash;
    if (modal === "#join") {
      openAccountCreationModal();
    }
    setIsLoginModalOpen(modal === "#login" || modal === "#password");
    setIsShowingForgottenPassword(modal === "#password");
  };

  const openAccountCreationModal = () => {
    if (!shouldUseLanguageModal) {
      setHasPickedLanguage(true);
    }
    setIsAccountCreationModalOpen(true);
  };

  const getStaffIdFromLinkedSubjectId = async () => {
    if (switchAccountSubjectId === undefined && user?.profile?.Id != null) {
      const staff = await StaffService.getStaffIdFromLinkedSubjectId(user.profile.Id);
      if (staff?.id !== undefined) {
        setSwitchAccountStaffId(staff.id);
      } else {
        console.log('No staff Id obtained');
      }
    }
  }

  const listOutstandingMandatoryPros = async () => {
    if (switchAccountSubjectId == null && user?.profile?.Id != null) {
      //we should only make it here if we are a subject so switchAccountSubjectId will always be null for a subject
      let { outstandingQuestionnaires } = await SubjectService.listAndCreateOutstandingMandatoryProsForLinkedStaff();
      setOutstandingMandatoryQuestionnaires(outstandingQuestionnaires);
    }
  }

  useEffect(() => {
    openModalsBasedOnHash();
    getStaffIdFromLinkedSubjectId();
    listOutstandingMandatoryPros();

    const unlisten = history.listen(openModalsBasedOnHash);

    return unlisten;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (outstandingMandatoryQuestionnaires.length > 0) {
      setIsMandatoryModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outstandingMandatoryQuestionnaires]);

  const handleMandatoryModalClose = async () => {
    setIsMandatoryModalOpen(false);
    //set the flag so modal is only shown once
    LocalDataService.setHasSeenMandatoryTrainingModal(true);
    const path = await SubjectService.shouldShowScreeningQuestionnaire() === true ? `/app/subject/screening` : `/app/subject/pro`;
    history.push(path);
  }

  return (
      <Menu borderless fluid size={"huge"} fixed={"top"} style={styles.menu}>
        <Menu.Item as={Link} to={"/"} fitted={"vertically"}>
          <Image
              src={
                "/images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
              }
              height={30}
              alt={"Home"}
          />
        </Menu.Item>
        <Menu.Item header>
          <Popup
              mouseEnterDelay={1000}
              mouseLeaveDelay={500}
              on="hover"
              flowing
              trigger={
                <Header
                    as="h1"
                    dir="ltr"
                    content="Atom5™"
                    style={{ fontSize: "1.28571429rem" }}
                />
              }
          >
            <PopupContent>Nucleus: {config.nucleusVersion}</PopupContent>
            <PopupContent>Clinician-Web: {Package.version}</PopupContent>
          </Popup>
        </Menu.Item>
        <Menu.Item>
          <Header as="h1" style={{ fontSize: "1.28571429rem" }}>
            {trial.name}
            <Header.Subheader style={{ fontSize: "1rem" }}>
              {trial.description}
            </Header.Subheader>
          </Header>
        </Menu.Item>
        {isTest && (
            <Menu.Item>
              <Header
                  as="h2"
                  style={{
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 2,
                    paddingBottom: 2,
                    border: `3px solid #f8991d`,
                    color: "#f8991d",
                    borderRadius: 20,
                  }}
              >
                {t("THIS_IS_A_TEST_INSTANCE")}
              </Header>
            </Menu.Item>
        )}
        <Menu.Menu position="right">
          <Menu.Item>
            <NotificationIcon />
          </Menu.Item>
          <Menu.Item>
            {isLoggedIn && isStaff && (
                <SupportButton />
            )}
          </Menu.Item>
          <Menu.Item>
            <LanguageSelection inline />
          </Menu.Item>
          {!isLoggedIn && trial.selfOnboardingEnabled && (
              <Menu.Item
                  className="atom5-video-controlstop"
                  onClick={() => {
                    openAccountCreationModal();
                  }}
              >
                <span>{t("GLOBAL_BUTTON_CREATE_ACCOUNT")}</span>
              </Menu.Item>
          )}
          {!isLoggedIn && (
              <Menu.Item
                  className="atom5-video-controlstop"
                  onClick={() => setIsLoginModalOpen(true)}
              >
                <span>{t("GLOBAL_BUTTON_LOG_IN")}</span>
              </Menu.Item>
          )}

          {isLoggedIn && (
              <DropDownMenu
                  outstandingMandatoryQuestionnaires={outstandingMandatoryQuestionnaires}
                  switchAccountSubjectId={switchAccountSubjectId}
                  switchAccountStaffId={switchAccountStaffId}
              />
          )}

          <Modal
              size={"small"}
              open={isLoginModalOpen}
              onClose={() => setIsLoginModalOpen(false)}
          >
            {!isShowingForgottenPassword && (
                <LoginModalInsert
                    handleForgottenPassword={() => {
                      setIsShowingForgottenPassword(true);
                    }}
                />
            )}
            {isShowingForgottenPassword && (
                <ForgottenPasswordModalInsert
                    handleForgottenPassword={() => {
                      setIsShowingForgottenPassword(false);
                    }}
                />
            )}
          </Modal>
          <Modal
              size={"small"}
              open={isAccountCreationModalOpen}
              onClose={() => setIsAccountCreationModalOpen(false)}
          >
            {!hasPickedLanguage && (
                <PickLanguageModalInsert
                    onLanguageSelected={onLanguageSelected}
                    onPickedLanguage={onPickedLanguage}
                />
            )}
            {!hasConsented && hasPickedLanguage && (
                <ConsentModalInsert onConsented={onConsented} />
            )}
            {hasConsented && hasPickedLanguage && (
                <RegisterEmailModalInsert
                    subjectAccountCreationData={subjectAccountCreationData}
                />
            )}
          </Modal>
          <Modal
              size={"small"}
              open={isMandatoryModalOpen
                  && !hasSeenMandatoryTrainingModal
                  && enforceCompletionOfMandatoryPros}
              onClose={handleMandatoryModalClose}
          >
            <MandatoryTrainingModalInsert
                handleClose={handleMandatoryModalClose}
                outstandingMandatoryQuestionnaires={outstandingMandatoryQuestionnaires} />
          </Modal>
        </Menu.Menu>
      </Menu>
  );
};

const styles = {};

const enhance = compose(withTranslation(), withRouter);

export default enhance(NavBar);
