import React from 'react'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import SelectValueEditor from './simpleValueEditors/SelectValueEditor'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function ParagraphConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <SelectValueEditor pathKey={'renderAs'} label={"Render as"} arrayOfValues={["value"]} />
    </ConfigWrapper>
  )
}

export default ParagraphConfigEditor