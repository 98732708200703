import React, { useState, useContext, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import Page from "../../components/page/Page";
import SubjectAppStateService from "../../services/SubjectAppStateService";
import {
  getCurrentFull,
  getCompletedFull,
} from "../../redux/questionnaires/subjectQuestionnairesSlice";
import { getDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { shouldShowCompletedQuestionnaire } from "../../helpers/completedQuestionnairesHelper";
import ConfigContext from "../../context/ConfigContext";
import Questionnaire from "../../questionnaires/Questionnaire";
import SubjectQuestionnaireService from "../../services/SubjectQuestionnaireService";
import QuestionnaireEnd from "../../components/questionnaire/QuestionnaireEnd";
import ReadOnlyQuestionnaire from "../../questionnaires/ReadOnlyQuestionnaire";
import API_QUESTIONNAIRE_DEFINITION_TYPES from "../../constants/API_QUESTIONNAIRE_DEFINITION_TYPES";
import QuestionnaireContext from "../../context/QuestionnaireContext";
import DashboardContext from "../../context/DashboardContext";
import DataSubmissionStatusModal from "../../components/questionnaire/DataSubmissionStatusModal";
import UserContext from "../../context/UserContext";
import ParentQuestionnaireDisplay from "../../questionnaires/ParentQuestionnaireDisplay";

const QuestionnairePage = (props) => {
  const { allQuestionnaires, allDefinitions } = props;

  const location = useLocation();
  const history = useHistory();
  const config = useContext(ConfigContext);
  const dispatch = useDispatch();
  const dashboardContext = useContext(DashboardContext);
  const user = useContext(UserContext);

  const returnTo = useContext(QuestionnaireContext)?.returnTo;

  const pathArray = location.pathname.split("/");
  const questionnaireId = pathArray[pathArray.length - 1];

  const [isCurrentQuestionnaireCompleted, setIsCurrentQuestionnaireCompleted] =
    useState(false);

  const [shouldUseAltDisplay, setShouldUseAltDisplay] = useState(false);

  const [dataUploadProgress, setDataUploadProgress] = useState(null);

  const questionnaire = allQuestionnaires.find((q) => {
    return q.id === parseInt(questionnaireId);
  });

  const definition = allDefinitions.find((d) => {
    if (questionnaire) {
      return d.id === questionnaire.definitionId;
    }
    return d.id === parseInt(questionnaireId);
  });

  let [renderTimings, setRenderTimings] = useState(null);
  const renderTime = {
    timing: {
      questionnaireTiming: {
        render: undefined,
        end: undefined,
      },
    },
  };

  const initialize = async () => {
    //record rendering time here, this will be sent in the payload
    renderTime.timing.questionnaireTiming.render = new Date().toISOString();
    setRenderTimings(renderTime);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId]);

  const submitQuestionnaire = async (e) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    await SubjectQuestionnaireService.submitQuestionnaire(
      definition,
      questionnaire,
      e,
      null, // subjectId
      handleDataUploadProgress,
      renderTimings
    );
    SubjectAppStateService.getSubjectQuestionnairesFromServer(dispatch);
    setIsCurrentQuestionnaireCompleted(true);
    dashboardContext.scrollToTop();
  };

  const handleDataUploadProgress = (dataUploadProgress) => {
    setDataUploadProgress(dataUploadProgress);
  };

  const handlePageChange = () => {
    dashboardContext.scrollToTop();
  };

  const goBackToList = () => {
    if (returnTo) {
      history.push(returnTo);
      return;
    }

    const type = questionnaire.type.toLowerCase();
    history.push("/app/subject/" + type);
  };

  const goToNext = (questionnaire, definition) => {
    setIsCurrentQuestionnaireCompleted(false);
    let newId = questionnaire ? questionnaire.id : definition.id;
    history.push("/app/subject/questionnaire/" + newId);
  };

  const getlinkingInfo = (questionnaireDefinition) => {
    if (questionnaireDefinition?.config?.to) {
      let toArray;
      if (!Array.isArray(questionnaireDefinition?.config?.to)) {
        toArray = [questionnaireDefinition?.config?.to];
      } else {
        toArray = questionnaireDefinition?.config?.to;
      }

      for (let i = 0; i < toArray.length; i++) {
        const to = toArray[i];
        let [type, code] = to.split("://");
        try {
          let foundDefinition = allDefinitions.find((def) => {
            return code === def.code && type.toUpperCase() === def.type;
          });

          let foundQuestionnaire = null;
          const shouldQuestionnaireBePresent = type !== "event";
          if (shouldQuestionnaireBePresent) {
            // Only allow incomplete linking at questionnaire level
            foundQuestionnaire = allQuestionnaires
              .filter((q) => q.completionDate === null)
              .find((q) => q.definitionId === foundDefinition.id);
          }

          if (
            foundDefinition &&
            (!shouldQuestionnaireBePresent || foundQuestionnaire)
          ) {
            return {
              isLinkValid: true,
              questionnaire: foundQuestionnaire,
              definition: foundDefinition,
            };
          }
        } catch {
          console.error(
            "[SubjectQuestionnaireTable] Linking questionnaire not found"
          );
          return { isLinkValid: false, questionnaire: null, definition: null };
        }
      }
      return { isLinkValid: false, questionnaire: null, definition: null };
    } else {
      return { isLinkValid: false, questionnaire: null, definition: null };
    }
  };

  const linkingInfo = getlinkingInfo(definition);

  const QuestionnaireComponent =
    questionnaire && questionnaire.completionDate !== null
      ? ReadOnlyQuestionnaire
      : Questionnaire;

  let isCompletedAndFiltered =
    questionnaire &&
    questionnaire.completionDate !== null &&
    definition &&
    !shouldShowCompletedQuestionnaire(definition, config.data);

  if (
    isCompletedAndFiltered &&
    (definition.type !== API_QUESTIONNAIRE_DEFINITION_TYPES.CONTENT ||
      (definition.type === API_QUESTIONNAIRE_DEFINITION_TYPES.CONTENT &&
        config?.data?.[definition.type]?.completed))
  ) {
    return <Redirect to="/app/home" />;
  }

  return (
    <Page key={questionnaireId} name="QUESTIONNAIRE_PAGE">
      {definition && !isCurrentQuestionnaireCompleted && (
        <div
          style={{
            display: "flex",
            flexDirection: shouldUseAltDisplay ? "column-reverse" : "row",
          }}
        >
          <div style={{ flexGrow: 1, paddingRight: "2rem" }}>
            <QuestionnaireComponent
              shouldShowTitle={false}
              definition={definition}
              questionnaire={questionnaire}
              onPageChange={handlePageChange}
              onSubmit={submitQuestionnaire}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: shouldUseAltDisplay ? "row-reverse" : "column",
              paddingBottom: "2rem",
            }}
          >
            <ParentQuestionnaireDisplay
              questionnaire={questionnaire}
              subjectId={user.profile.Id}
              allDefinitions={allDefinitions}
              shouldUseAltDisplay={shouldUseAltDisplay}
              setShouldUseAltDisplay={setShouldUseAltDisplay}
              shouldShow={!isCurrentQuestionnaireCompleted}
            />
          </div>
        </div>
      )}
      {isCurrentQuestionnaireCompleted && (
        <QuestionnaireEnd
            definition={definition}
            questionnaire={questionnaire}
            onFinish={goBackToList}
            onLink={goToNext}
            linkingInfo={linkingInfo}
        />
      )}
      <DataSubmissionStatusModal dataUploadProgress={dataUploadProgress} />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    allQuestionnaires: [...getCurrentFull(state), ...getCompletedFull(state)],
    allDefinitions: getDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(QuestionnairePage);
