import React, { useEffect, useState } from 'react';
import { Button, Grid, Tab } from 'semantic-ui-react';
import DATA_QUERY_TABS_TO_FILTER from '../../constants/dataquery/DATA_QUERY_TABS_TO_STATUSES';
import DATA_QUERY_TABLE_COLUMNS from '../../constants/dataquery/DATA_QUERY_TABLE_COLUMNS';
import DataQueryService from '../../services/DataQueryService';
import DataQueryTable from './DataQueryTable';
import { withTranslation } from 'react-i18next';
import DATA_QUERY_TABLE_ACTIONS from '../../constants/dataquery/DATA_QUERY_TABLE_ACTIONS';
import { useHistory } from "react-router-dom";

const DataQueryListTab = ({t, tab, queryCount}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [queries, setQueries] = useState([]);

  const [page, setPage] = useState(0);

  const columns = [
    DATA_QUERY_TABLE_COLUMNS.SUBJECT_CODE,
    DATA_QUERY_TABLE_COLUMNS.QUESTIONNAIRE_CODE,
    DATA_QUERY_TABLE_COLUMNS.QUESTION_CODE,
    DATA_QUERY_TABLE_COLUMNS.RAISED_BY,
    DATA_QUERY_TABLE_COLUMNS.ANSWERED_BY,
    DATA_QUERY_TABLE_COLUMNS.CLOSED_BY,
    DATA_QUERY_TABLE_COLUMNS.STATUS,
  ];

  const pageSize = 20;

  useEffect(() => {
    const refreshQueries = async () => {
      setIsLoading(true);

      const queries = await DataQueryService.getAllQueries(pageSize, page, {filterStatus: DATA_QUERY_TABS_TO_FILTER[tab]});
      setQueries(queries);

      setIsLoading(false);
    };
    refreshQueries();
  }, [tab, page, pageSize]);

  useEffect(() => {
    setPage(0);
  }, [tab]);

  const nextDisabled = Math.floor((queryCount - 1) / pageSize) <= page;

  const actionsToShow = [
    DATA_QUERY_TABLE_ACTIONS.LINK_TO_QUESTIONNAIRE,
    DATA_QUERY_TABLE_ACTIONS.LINK_TO_SUBJECT
  ];

  let on = {};
  on[DATA_QUERY_TABLE_ACTIONS.LINK_TO_SUBJECT] = (query) => {
    const subjectId = query[DATA_QUERY_TABLE_COLUMNS.asPropertyString(DATA_QUERY_TABLE_COLUMNS.SUBJECT_ID)];
    const subjectUrl = `/app/subject/${subjectId}/tabs/subject-record/details`;
    history.push(subjectUrl);    
  };
  on[DATA_QUERY_TABLE_ACTIONS.LINK_TO_QUESTIONNAIRE] = (query) => {
    const subjectId = query[DATA_QUERY_TABLE_COLUMNS.asPropertyString(DATA_QUERY_TABLE_COLUMNS.SUBJECT_ID)];
    const questionnaireDefinitionType = query[DATA_QUERY_TABLE_COLUMNS.asPropertyString('QUESTIONNAIRE_DEFINITION_TYPE')];
    const questionnaireCode = query[DATA_QUERY_TABLE_COLUMNS.asPropertyString(DATA_QUERY_TABLE_COLUMNS.QUESTIONNAIRE_CODE)];
    const questionnaireId = query[DATA_QUERY_TABLE_COLUMNS.asPropertyString('QUESTIONNAIRE_ID')];
    const questionnaireUrl = `/app/subject/${subjectId}/questionnaire-type/${questionnaireDefinitionType}/${questionnaireCode}/view/${questionnaireId}`;
    history.push(questionnaireUrl);    
  };

  return (
    <Tab.Pane loading={isLoading}>
      <Grid>
        <Grid.Column textAlign={"right"}>
          <Button onClick={() => setPage(page - 1)} disabled={page === 0}>
            {t("GLOBAL_BUTTON_PREVIOUS")}
          </Button>
          <Button onClick={() => setPage(page + 1)} disabled={nextDisabled}>
            {t("GLOBAL_BUTTON_NEXT")}
          </Button>
          Total: {queryCount}
        </Grid.Column>
      </Grid>
      <DataQueryTable queries={queries} columns={columns} actionsToShow={actionsToShow} on={on} />
    </Tab.Pane>
  )

};

export default withTranslation()(DataQueryListTab);
