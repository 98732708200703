import React, { useEffect, useState, useContext } from "react";
import {withTranslation} from "react-i18next";
import {Form, Grid, Icon, Input, Loader, Message, Popup, Table} from "semantic-ui-react";
import Page from "../../components/page/Page";
import NotificationService from "../../services/NotificationService";
import UserContext from "../../context/UserContext";
import Pagination from "semantic-ui-react/dist/commonjs/addons/Pagination";
import DateRangeControls from "../../components/DateRangeControls";
import moment from "moment/moment";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";
import DOMPurify from "dompurify";

function NotificationListPage({t}){
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [auditLogEntries, setAuditLogEntries] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().add(1,"days").format("YYYY-MM-DD"));
    const [searchTerm,setSearchTerm] = useState("");
    const [hasSufficientPrivileges, setHasSufficientPrivileges] = useState(false);

    const user = useContext(UserContext);
    const isSuperAdmin = user.profile.superAdmin;

    useEffect(() => {
        initialise();
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNo,startDate,endDate]);

    const initialise = async () => {
        const hasReceiveAlertsPermission = await PermissionsService.hasPermissionInAnyGroup(GroupPermission.RECEIVE_ALERTS);
        if (hasReceiveAlertsPermission || isSuperAdmin) {
            setHasSufficientPrivileges(true);
        }
    };

    const load = async () => {
        try {
            setLoading(true);
            const pagedData = await NotificationService.getEmailNotificationActivityAudit(pageNo-1,moment(startDate),moment(endDate),searchTerm);
            setTotalPages(pagedData.totalPages);
            setAuditLogEntries(pagedData.content);
        } catch (error) {
            console.log('[NotificationListPage] error while loading data: ', error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const handlePaginationChange = (e, { activePage }) => {
        setPageNo(activePage)
    }

    const submitSearch = () => {
        load();
    }

    const handleDateChange = (startDate, endDate) => {
       setStartDate(startDate);
       setEndDate(endDate);
    };

    const createTable = () => {

        const headers = [
            {title: ["NOTIFICATION_LIST_DATE", "Log Date"], config: {width: 2}},
            {title: ["NOTIFICATION_LIST_SUBJECT", "Subject Code"], config: {width: 1}},
            {title: ["NOTIFICATION_LIST_TRIGGER", "Trigger ID\\Name"], config: {width: 1}},
            {title: ["NOTIFICATION_LIST_REASON", "Reason"], config: {width: 2}},
            {title: ["NOTIFICATION_LIST_STATUS", "Status"], config: {width: 1}},
            {title: ["NOTIFICATION_LIST_VIEWMSG", "View Message"], config: {width: 1}}
        ];

        const headerRow = (
            <Table.Header>
                <Table.Row>
                    {headers.map((header,index) => (
                        <Table.HeaderCell key={index} {...header?.config}>
                            {t(header.title)}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
        );

        const configListRows = auditLogEntries?.map((notificationItem) => {
            const purifyConfig = { ALLOWED_TAGS: ['p', 'div', 'table', 'tr', 'td', 'span']};
            let cleanHTML = DOMPurify.sanitize(notificationItem.payload, purifyConfig);
            return (
                <Table.Row
                    key={notificationItem.id}>
                    <Table.Cell >
                        { new Date(notificationItem.logDate).toUTCString()}
                    </Table.Cell>
                    <Table.Cell >
                        {notificationItem.subjectCode}
                    </Table.Cell>
                    <Table.Cell >
                        {notificationItem.triggerName}
                    </Table.Cell>
                    <Table.Cell >
                        {notificationItem.reason}
                    </Table.Cell>
                    <Table.Cell >
                        {notificationItem.status}
                    </Table.Cell>
                    <Table.Cell >
                        <Popup trigger={  <Icon name='envelope' color='orange'></Icon>}>
                            <Popup.Header>{t("GENERIC_TERM_EMAIL", "Email")}</Popup.Header>
                            <Popup.Content>
                                <div dangerouslySetInnerHTML={{__html: cleanHTML}} />
                            </Popup.Content>
                        </Popup>
                    </Table.Cell>
                </Table.Row>
            )}
        );

        return (
            <>
                {headerRow}
                <Table.Body>
                    {configListRows}
                </Table.Body>
            </>
        );
    }

    return (
        <Page
            name="Notification Activity"
            header={t("NOTIFICATION_ACTIVITY", "Notification Activity")}>
            {!loading && error && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={"Error loading notifications, if it persist contact support"}
                />
            )}
            {!loading && !hasSufficientPrivileges && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={t("NOTIFICATIONS_VIEW_MISSING_RECEIVE_ALERTS_PERMISSION", "You do not have permission to view notifications.")}
                />
            )}
            {(!loading && hasSufficientPrivileges) && (
                <>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Form onSubmit={submitSearch}>
                                <Form.Field>
                                <label>{t("NOTIFICATION_LIST_SEARCH_SUBJECTCODE", "Search Subject Code:")}</label>
                                        <Input value={searchTerm} onChange={(val) => setSearchTerm(val.target.value)} action={t("GENERIC_TERM_SEARCH", "Search")}/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={5}>
                                <label>{t("NOTIFICATION_LIST_DATE_RANGE", "Date Range:")}</label>
                                <DateRangeControls
                                    onChange={handleDateChange}
                                    start={moment(startDate)}
                                    end={moment(endDate)}
                                />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Pagination
                                activePage={pageNo}
                                onPageChange={handlePaginationChange}
                                totalPages={totalPages}
                                disabled={typeof auditLogEntries === 'undefined' || auditLogEntries.length === 0}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Table fixed id={"adminNotificationList"}>
                    {createTable()}
                </Table>
                </>
            )}
            {loading && (
                <Loader active={loading} />
            )}
        </Page>
    );

}

export default withTranslation()(NotificationListPage);