import React, { useState, useEffect, useContext } from "react";
import { Button, Grid, Image, Message, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import SubjectService from "../../SubjectService";
import { useLocation } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { getEventDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import ConfigContext from "../../context/ConfigContext";
import TrialContext from "../../context/TrialContext";
import Questionnaire from "../../questionnaires/Questionnaire";
import InternationalisationService from "../../InternationalisationService";

const ConsentModalInsert = (props) => {
  const { t, eventDefinitions, onConsented } = props;

  const config = useContext(ConfigContext);
  const {trial} = useContext(TrialContext);

  const location = useLocation();

  const [consentQuestionnaire, setConsentQuestionnaire] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const consentQuestionnaireCode = config.ui.selfOnboarding
      ? config.ui.selfOnboarding.consentQuestionnaire
      : "study-consent";

    const foundConsentQuestionnaire = eventDefinitions.find(
      (def) => def.code === consentQuestionnaireCode
    );

    setConsentQuestionnaire(foundConsentQuestionnaire);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConsent = async (answers) => {
    // If a subject is consenting it means a new subject is
    // signing up. Get rid of old sign in data.
    await AuthService.clearAuth();

    setErrorMessage("");

    let passedId = null;
    let encodedSubjectData = null;

    let urlParams = location.search.substr(1);
    let searchParams = new URLSearchParams(urlParams);

    if (searchParams.has("subjectId")) {
      passedId = searchParams.get("subjectId");
    }

    if (searchParams.has("subjectData")) {
      encodedSubjectData = searchParams.get("subjectData");
    }

    let postData = JSON.stringify({});

    let decodedSubjectData = encodedSubjectData
      ? atob(encodedSubjectData)
      : postData;

    let subjectJSON = {
      subjectData: JSON.parse(decodedSubjectData),
    };

    const consentQuestionnaireCode = config.ui.selfOnboarding
      ? config.ui.selfOnboarding.consentQuestionnaire
      : "study-consent";

    if (config.ui.selfOnboarding.defaultGroupCodes) {
          subjectJSON.groupCodes = config.ui.selfOnboarding.defaultGroupCodes;
    }

    let answerMap = {};
    Object.entries(answers).forEach((entry) => {
      answerMap[consentQuestionnaireCode + "_" + entry[0]] = entry[1];
    });

    subjectJSON.answerMap = answerMap;
    subjectJSON.consentQuestionnaireCode = consentQuestionnaireCode;

    // TODO: remove passthrough
    if (
      trial.selfOnboardingPassthruEnabled ||
      (location.search && location.search.includes("passthru"))
    ) {
      const subjectId = passedId ? passedId : "PASSTHRU";
      await SubjectService.selfOnboardSubject(subjectId, subjectJSON).then(
        (data) => {
          AuthService.tokenSuccessCallback(data, () => {});
          AuthService.setAccountType("subject");

          const hasStudyConsentDefined = config.ui.studyConsentQuestionnaire;
          const hasScreeningQuestionnairesDefined =
            config.ui.selfOnboarding.screeningQuestionnaires &&
            config.ui.selfOnboarding.screeningQuestionnaires.length > 0;

          if (hasScreeningQuestionnairesDefined) {
            window.location.href = "/app/subject/screening";
          } else if (hasStudyConsentDefined) {
            window.location.href = "/app/subject/study-consent";
          } else {
            window.location.href = "/app/home";
          }
        }
      );
    } else {
      // generate random externalId
      const subjectId = passedId
        ? passedId
        : Math.random().toString(20).substr(2, 8);

      try {
        const subjectData = await SubjectService.selfOnboardSubject(
          subjectId,
          subjectJSON
        );

        await onConsented(subjectData);
      } catch (_error) {
        setErrorMessage(t("ACCOUNT_CREATE_FAILED_UNKNOWN"));
      }
    }
  };

  return (
    <>
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <Image
              style={{ height: "25px" }}
              src={
                "./images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal.Content scrolling style={styles.content}>
        {consentQuestionnaire !== null && (
          <div
            style={
              ({ ...styles.questionnaire },
              {
                textAlign: InternationalisationService.isRTL()
                  ? "right"
                  : "left",
              })
            }
          >
            <Questionnaire
              definition={consentQuestionnaire}
              onSubmit={onConsent}
              subjectId={-1}
            />
          </div>
        )}
        {errorMessage.length > 0 && <Message negative>{errorMessage}</Message>}
      </Modal.Content>
      <Grid centered padded>
        <Grid.Row>
          <Button
            className="borderless"
            basic
            color={"orange"}
            size="small"
            as={"a"}
            href="https://www.aparito.com/privacy-policy/"
            target="_blank"
          >
            {t("PRIVACY_POLICY")}
          </Button>
        </Grid.Row>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    eventDefinitions: getEventDefinitions(state),
  };
};

const styles = {
  content: {
    borderTop: "1px solid #f2f1f1",
    borderBottom: "1px solid #f2f1f1",
  },
  questionnaire: {
    padding: "12px 12px",
  },
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(ConsentModalInsert);
