import React from 'react'
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors'
import ConfigWrapper from './simpleValueEditors/ConfigWrapper'

function MonitoredVideoConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
    </ConfigWrapper>
  )
}

export default MonitoredVideoConfigEditor