import FingerprintJS from "@fingerprintjs/fingerprintjs";
import getDeviceDetails from "device-details";
import InternationalisationService from "../InternationalisationService";
import RequestHelper from "../RequestHelper";
import {serverAddress} from "./config/EnvConfig";

export default class BrowserService {
  static getBrowserDetails() {
    const rawDetails = getDeviceDetails();
    const details = {
      browserName: rawDetails?.browser?.name,
      browserVersion: rawDetails?.browser?.version,
      platformName: rawDetails?.os?.name,
      platformVersion: rawDetails?.os?.version,
    };
    return details;
  }

  static async getBrowserId() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    const browserId = result.visitorId;
    return browserId;
  }

  static updateSubjectBrowserSession(requestBody) {
    return RequestHelper.send(
      serverAddress + "/browsersessions/subject",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      null,
      requestBody
    );
  }

  static deleteSubjectBrowserSession(requestBody) {
    return RequestHelper.send(
      serverAddress + "/browsersessions/subject/delete",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      null,
      requestBody
    );
  }
}
