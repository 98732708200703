import RequestHelper from "../RequestHelper";
import {serverAddress} from "./config/EnvConfig";

export default class VideoCallService {
  static getAccessToken(identity, roomName) {
    return RequestHelper.send(
      serverAddress + "/videocall/accesstoken/" + identity + "/" + roomName,
      {},
      "GET")
  }
}