import React from 'react';
import DateConfigEditor from './DateConfigEditor';
import DateTimeConfigEditor from './DateTimeConfigEditor';
import DecimalConfigEditor from './DecimalConfigEditor';
import DiaryConfigEditor from './DiaryConfigEditor';
import FixedValueConfigEditor from './FixedValueConfigEditor';
import HeadingConfigEditor from './HeadingConfigEditor';
import ImageConfigEditor from './ImageConfigEditor';
import IntegerConfigEditor from './IntegerConfigEditor';
import LinkConfigEditor from './LinkConfigEditor';
import MarkupConfigEditor from './MarkupConfigEditor';
import MonitoredVideoConfigEditor from './MonitoredVideoConfigEditor';
import MonthYearConfigEditor from './MonthYearConfigEditor';
import ParagraphConfigEditor from './ParagraphConfigEditor';
import RealtimeSpelConfigEditor from './RealtimeSpelConfigEditor';
import RenderImageConfigEditor from './RenderImageConfigEditor';
import RenderVideoConfigEditor from './RenderVideoConfigEditor';
import SignatureConfigEditor from './SignatureConfigEditor';
import SingleFixedValueConfigEditor from './SingleFixedValueConfigEditor';
import SliderConfigEditor from './SliderConfigEditor';
import TextConfigEditor from './TextConfigEditor';
import TextareaConfigEditor from './TextareaConfigEditor';
import TimeConfigEditor from './TimeConfigEditor';
import VideoConfigEditor from './VideoConfigEditor';
import FileConfigEditor from './FileConfigEditor';
import AuthoriseConfigEditor from './AuthoriseConfigEditor';

const ConfigEditorSwitch = (props) => {
  const { questionType } = props;
  switch (questionType) {
    case 'DATE':
      return <DateConfigEditor {...props} />;
    case 'DATETIME':
      return <DateTimeConfigEditor {...props} />;
    case 'DECIMAL':
      return <DecimalConfigEditor {...props} />;
    case 'DIARY':
      return <DiaryConfigEditor {...props} />;
    case 'FIXED_VALUE':
      return <FixedValueConfigEditor {...props} />;
    case 'HEADING':
      return <HeadingConfigEditor {...props} />;
    case 'IMAGE':
      return <ImageConfigEditor {...props} />;
    case 'INTEGER':
      return <IntegerConfigEditor {...props} />;
    case 'LINK':
      return <LinkConfigEditor {...props} />;
    case 'MARKUP':
      return <MarkupConfigEditor {...props} />;
    case 'MONITORED_VIDEO':
      return <MonitoredVideoConfigEditor {...props} />;
    case 'MONTHYEAR':
      return <MonthYearConfigEditor {...props} />;
    case 'PARAGRAPH':
      return <ParagraphConfigEditor {...props} />;
    case 'REALTIME_SPEL':
      return <RealtimeSpelConfigEditor {...props} />;
    case 'RENDER_IMAGE':
      return <RenderImageConfigEditor {...props} />;
    case 'RENDER_VIDEO':
      return <RenderVideoConfigEditor {...props} />;
    case 'SIGNATURE':
      return <SignatureConfigEditor {...props} />;
    case 'SINGLE_FIXED_VALUE':
      return <SingleFixedValueConfigEditor {...props} />;
    case 'SLIDER':
      return <SliderConfigEditor {...props} />;
    case 'TEXT':
      return <TextConfigEditor {...props} />;
    case 'TEXTAREA':
      return <TextareaConfigEditor {...props} />;
    case 'TIME':
      return <TimeConfigEditor {...props} />;
    case 'VIDEO':
      return <VideoConfigEditor {...props} />;
    case 'FILE':
      return <FileConfigEditor {...props} />;
    case 'AUTHORISE':
      return <AuthoriseConfigEditor {...props} />;
    default:
      return <p>{"Editor not found"}</p>;
  }
};

export default ConfigEditorSwitch;
