import React from 'react'
import SelectValueEditor from './simpleValueEditors/SelectValueEditor'
import BooleanValueEditor from './simpleValueEditors/BooleanValueEditor';
import DefaultConfigEditors from './compoundValueEditors/DefaultConfigEditors';
import ConfigWrapper from './simpleValueEditors/ConfigWrapper';

function DiaryConfigEditor({ config, onChange }) {
  return (
    <ConfigWrapper value={config} onChange={onChange} >
      <DefaultConfigEditors />
      <SelectValueEditor pathKey="timeInputType" label={"Time Input Type"} arrayOfValues={['NONE', 'TIME', 'DURATION_NUMERIC', 'DURATION_TEXT']} />
      <SelectValueEditor pathKey="timeColumnPosition" label={"Time Column Position"} arrayOfValues={['LAST']} />
      <SelectValueEditor pathKey="addButtonMode" label={"Add Button Mode"} arrayOfValues={['HEADER_CLICK', 'HEADER_CLICK_WITH_ICON']} />
      <BooleanValueEditor pathKey="timeInputUseLabel" label={"should Time Input Use Label"} />
    </ConfigWrapper>
  )
}

export default DiaryConfigEditor;