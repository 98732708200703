import React, {useState} from 'react';
import {Button, Card} from "semantic-ui-react";
import DefinitionOnlyQuestionnaireDisplay from "../../questionnaires/DefinitionOnlyQuestionnaireDisplay";
import {useTranslation} from "react-i18next";

const CardDisplay = ({definitions, selectedDefinition, setSelectedDefinition, isExpanded, setIsExpanded}) => {
  const {t} = useTranslation()


  return <Card style={{overflowX: 'hidden', width: isExpanded ? "800px" : "400px", maxWidth: "60vw"}}>
    {!selectedDefinition &&
    <>
      <div style={{display:'flex', alignItems: 'baseline'}}>
        <h3 style={{paddingLeft: '1em', paddingTop: '8px', display:'flex', flexShrink: 1}}>{t("HELP_SECTION_TITLE", "Help")}</h3>
        {!isExpanded && <Button style={{backgroundColor: 'white', color: 'orange'}} onClick={() => setIsExpanded(true)}
                 icon={'arrows alternate horizontal'}/>}
        {isExpanded && <Button style={{backgroundColor: 'white', color: 'orange'}} onClick={() => setIsExpanded(false)}
                 icon={'long arrow alternate right'}/>}
      </div>
      <Button.Group vertical>
        {definitions?.map(definition => <Button style={{backgroundColor: 'white', color: 'orange', textAlign: 'left'}} onClick={()=>setSelectedDefinition(definition)} content={definition.label} />)}
      </Button.Group>
    </>}
    {selectedDefinition && <div >
      <Button style={{backgroundColor: 'white', color: 'orange'}} onClick={()=>setSelectedDefinition(null)} icon={'arrow left'} />
      {!isExpanded && <Button style={{backgroundColor: 'white', color: 'orange'}} onClick={() => setIsExpanded(true)}
                              icon={'arrows alternate horizontal'}/>}
      {isExpanded && <Button style={{backgroundColor: 'white', color: 'orange'}} onClick={() => setIsExpanded(false)}
                             icon={'long arrow alternate right'}/>}
      <div style={{overflowY: 'scroll', maxHeight: '66vh',padding: '16px', paddingTop: '0px', marginRight: '-8px', }}>
        <DefinitionOnlyQuestionnaireDisplay definition={selectedDefinition} />
      </div>
    </div>}
  </Card>;
}

const HelpSectionDisplay = ({definitions}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDefinition, setSelectedDefinition] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
    {isOpen && <CardDisplay
        definitions={definitions}
        selectedDefinition={selectedDefinition}
        setSelectedDefinition={setSelectedDefinition}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
    />}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
      <Button primary circular size={'huge'} style={{boxShadow: '0px 0px 18px 0px rgba(0,0,0,0.2)'}} onClick={()=>setIsOpen(!isOpen)} icon={'help'} />
    </div>
  </div> ;
}

export default HelpSectionDisplay;