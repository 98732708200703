import React, { useState } from "react";
import { Route } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import Profile from "../pages/profile/Profile";
import DashboardForceChangePasswordRoute from "./DashboardForceChangePasswordRoute";
import StaffOnlyRoutes from "./StaffOnlyRoutes";
import QuestionnaireContext from "../context/QuestionnaireContext";
import ProListPage from "../pages/subjectaccount/questionnaireLists/ProListPage";
import ContentListPage from "../pages/subjectaccount/questionnaireLists/ContentListPage";
import EventListPage from "../pages/subjectaccount/questionnaireLists/EventListPage";
import DataListPage from "../pages/subjectaccount/questionnaireLists/DataListPage";
import QuestionnairePage from "../pages/subjectaccount/QuestionnairePage";
import ModuleListPage from "../pages/subjectaccount/questionnaireLists/ModuleListPage";
import SettingsPage from "../pages/settings/SettingsPage";
import SubjectStudyConsentPage from "../pages/account-activation/SubjectStudyConsentPage";
import LicensesPage from "../pages/dependencies/LicensesPage";

const DashboardRoutes = (props) => {
  const [questionnaireReturnTo, setQuestionnaireReturnTo] = useState(null);
  return (
    <>
      <DashboardForceChangePasswordRoute>
        <Route path={"/app/home"} component={HomePage} />
        <Route path={"/app/profile"} component={Profile} />
        <Route exact path={"/app/licenses"} component={LicensesPage} />
        <Route exact path={"/app/settings"} component={SettingsPage} />
        <Route
          exact
          path={"/app/subject/study-consent"}
          component={SubjectStudyConsentPage}
        />
        <QuestionnaireContext.Provider
          value={{
            returnTo: questionnaireReturnTo,
            setReturnTo: setQuestionnaireReturnTo,
          }}
        >
          <Route exact path={"/app/subject/pro"} component={ProListPage} />
          <Route
            exact
            path={"/app/subject/content"}
            component={ContentListPage}
          />
          <Route exact path={"/app/subject/event"} component={EventListPage} />
          <Route exact path={"/app/subject/data"} component={DataListPage} />
          <Route
            exact
            path={"/app/subject/module/:moduleCode"}
            component={ModuleListPage}
          />
          <Route
            exact
            path={"/app/subject/questionnaire/:questionnaireId"}
            component={QuestionnairePage}
          />
        </QuestionnaireContext.Provider>

        <StaffOnlyRoutes />


      </DashboardForceChangePasswordRoute>
    </>
  );
};

export default DashboardRoutes;
