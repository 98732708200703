import React from 'react';
import {Grid, Modal, Loader, Dimmer} from 'semantic-ui-react';


const DownloadModal = ({visible, t, completed, error, onClose, title}) => {

    const modalTitle = title ? title : 'Download Dialog';

    return (<Modal
            open={visible}
            onClose={onClose}
            closeIcon={true}
            closeOnDimmerClick={false}
            closeOnDocumentClick={false}
        >
            <Modal.Header>{modalTitle}</Modal.Header>
            <Modal.Content>
                {!completed && (

                    <Dimmer active>
                        <Loader indeterminate>{t('DOWNLOAD_MODAL_PLEASE_WAIT', 'Please wait while your file has been downloaded....')}</Loader>
                    </Dimmer>


                )}
                {completed && <Grid style={{width: '100%'}}>

                    <Grid.Row>
                        <Grid.Column width={16} >

                            {completed && error && (
                                <>
                                <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>
                                    {t('DOWNLOAD_MODAL_ERROR', 'Error during download')}
                                </p>
                                <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>
                                     {error}
                                </p>
                                </>
                            )}
                            {completed && !error && (
                                <>
                                    <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>
                                        {t('DOWNLOAD_MODAL_SUCCESS', 'File downloaded - you can now close this dialog')}
                                    </p>
                                </>
                            )}
                        </Grid.Column>
                    </Grid.Row>

                </Grid>}
            </Modal.Content>
        </Modal>
    );
}

export default DownloadModal;
