import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Image, Loader } from "semantic-ui-react";
import SubjectService from "../../../SubjectService";

export const KEY = "QR_CODE_DISPLAY";

function QRCodeDisplay({ user, t }) {
  const [isLoading, setIsLoading] = useState(true);

  const [hasCode, setHasCode] = useState(false);
  const [device, setDevice] = useState();
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState();
  const [onboardingCode, setOnboardingCode] = useState();

  const validDevicePollingInterval = useRef();

  const [isCodeVisible, setIsCodeVisible] = useState();
  const qrCodeVisibleTimeout = useRef();
  const qrCodeUsagePollingInterval = useRef();

  const populateDevice = async () => {
    setIsLoading(true);
    let foundDevice = undefined;
    try {
      const devices = await SubjectService.getSubjectDevices(user.profile.Id);
      foundDevice = devices.filter((device) => device.state === "CREATED").pop();
      if (foundDevice != null) {
        if (device?.id !== foundDevice.id) {
          setDevice(foundDevice);
        }
      } else {
        setDevice(null);
      }
    } catch {
      console.error("[QRCodeDisplay] Error filtering devices");
    } finally {
      setIsLoading(false);
    }
    return foundDevice;
  };

  const populateQrCodeImage = async () => {
    setIsLoading(true);

    try {
      if (device != null) {
        clearInterval(validDevicePollingInterval.current);
        const image = await SubjectService.getQrCode(device.id);
        await setQrCodeImageUrl(URL.createObjectURL(image));
        setHasCode(true);
      } else {
        setHasCode(false);
        setQrCodeImageUrl(false);
      }
    } catch {
      console.error("[QRCodeDisplay] Error getting QR Code image");
    } finally {
      setIsLoading(false);
    }
  };

  const populateOnboardingCode = async () => {
    setIsLoading(true);

    try {
      if (device != null) {
        clearInterval(validDevicePollingInterval.current);
        const code = await SubjectService.getOnboardingCode(device.id);
        setOnboardingCode(code);
      }
    } catch {
      console.error("[QRCodeDisplay] Error getting Onboarding Code");
    } finally {
      setIsLoading(false);
    }
  };

  const init = async () => {
    populateDevice();
    validDevicePollingInterval.current = setInterval(populateDevice, 30 * 1000);
  };

  useEffect(() => {
    init();

    return () => {
      clearInterval(validDevicePollingInterval.current);
      clearTimeout(qrCodeVisibleTimeout.current);
      clearInterval(qrCodeUsagePollingInterval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    populateQrCodeImage();
    populateOnboardingCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  const handleGenerateNewDevice = async () => {
    try {
      const existingDevice = await populateDevice();
      if (existingDevice == null) {
        const newDevice = await SubjectService.subjectCreateDevice(user.profile.Id);
        setDevice(newDevice);
      }
    } catch {
      console.error("[QRCodeDisplay] Error generating new device");
    }
  };

  const handleShowCode = async () => {
    setIsCodeVisible(true);
    qrCodeVisibleTimeout.current = setTimeout(handleCloseQrCode, 60 * 1000);
    qrCodeUsagePollingInterval.current = setInterval(handleQrUsageCheck, 2.5 * 1000);
  };

  const handleCloseQrCode = async () => {
    setIsCodeVisible(false);
    clearTimeout(qrCodeVisibleTimeout.current);
    clearInterval(qrCodeUsagePollingInterval.current);
  }

  const handleQrUsageCheck = async () => {
    const isValidCode = await SubjectService.getIsValidQrCode(device.id);
    if (!isValidCode) {
      // It has been used, deleted etc!
      setDevice();
      setQrCodeImageUrl();
      setHasCode(false);
      setIsCodeVisible(false);
      clearTimeout(qrCodeVisibleTimeout.current);
      clearInterval(qrCodeUsagePollingInterval.current);
      init();
    }
  }

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
        fontWeight: "bold",
        fontSize: "1.5rem"
      }}
    >
      {isLoading && <Loader active inline={"centered"} />}

      {!isLoading && hasCode && !isCodeVisible && (
        <Button onClick={handleShowCode} primary fluid>
          {("QR_CODE_DISPLAY_EXISTING", "Show QR Code")}
        </Button>
      )}

      {!isLoading && hasCode && isCodeVisible && (
        <Image src={qrCodeImageUrl} />
      )}

      {!isLoading && hasCode && isCodeVisible && (
        <div>{onboardingCode}</div>
      )}

      {!isLoading && !hasCode && (
        <Button onClick={handleGenerateNewDevice} primary fluid>
          {("QR_CODE_DISPLAY_NEW", "Get New QR Code")}
        </Button>
      )}
    </Card>
  );
}

export default QRCodeDisplay;
