import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Loader, Modal, Table, Message } from "semantic-ui-react";
import TriggerService from "../../services/admin/TriggerService";
import AdminTriggerEditComponent from "./AdminTriggerEditComponent";
import _ from "lodash";


function AdminTriggerViewModal({ t, isOpen, setIsOpen, triggerId, onUpdate }) {
  const [triggerData, setTriggerData] = useState(null);
  const [error, setError] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const isEditing = !!triggerId;

  const getTriggerData = async () => {
    const triggerData = await TriggerService.getTriggerData(triggerId);
    setTriggerData(triggerData);
  };

  useEffect(() => {
    if (triggerId) {
      getTriggerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerId]);


  const onChangeTrigger = (_e, data) => {
    let value = data.type === "checkbox" ? data.checked : data.value;
    const path = 'trigger.'+data.name;
    setTriggerData((staleTrigger) => {
      var newTrigger = _.cloneDeep(staleTrigger);
      _.set(newTrigger, path, value); 
      return newTrigger;
    });
  };

  const submitTrigger = async (feedback) => {
    let response = [];
    if (isEditing) {
      response = await TriggerService.editTrigger(
        triggerData.trigger,
        feedback
      );
    } 
    if (!Array.isArray(response)) {
      setError(true);
      return;
    }
    if (response.length > 0) {
      setValidationErrors(response);
      return;
    }
    onUpdate();
    setIsOpen(false);
    };

  const onSoftDelete = async (feedback) => {
    const newIsActive = !trigger.isActive;
    await TriggerService.updateTriggerIsActive(
      trigger.id,
      newIsActive,
      feedback
    );
    onUpdate();
    setIsOpen(false);
  };


  const { trigger, triggerOccurrence } = triggerData || {};
  const hasTriggerOccured =
    Array.isArray(triggerOccurrence) && triggerOccurrence.length > 0;

  const createTable = (triggerOccurrence) => {
    if (!triggerOccurrence) {
      return;
    }

    const headers = [
      {
        title: ["ADMIN_TRIGGER_OCCURRENCE_SUBJECT", "Subject"],
      },
      {
        title: ["ADMIN_TRIGGER_OCCURENCE_DATE", "Date of Latest Trigger"],
      },
      {
        title: ["ADMIN_TRIGGER_OCCURENCE_NUMBER", "Times fired"],
      },
    ];

    const headerRow = (
      <Table.Header>
        {headers.map((header) => (
          <Table.HeaderCell>{t(header.title)}</Table.HeaderCell>
        ))}
      </Table.Header>
    );
    const triggerRows = triggerOccurrence.map((tO) => (
      <Table.Row>
        <Table.Cell>{tO.subject.subjectCode} ({tO.subject.Id})</Table.Cell>
        <Table.Cell>{tO.triggerOccurrence?.lastModified}</Table.Cell>
        <Table.Cell>{tO.triggerOccurrence?.occurrences}</Table.Cell>
      </Table.Row>
    ));
    return (
      <>
        {headerRow}
        {triggerRows}
      </>
    );
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} size='large'>
      <Modal.Content >
        <Modal.Description>
        {error && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={"Error, if it persist contact support"}
        />
      )}
          {!triggerData && <Loader />}
          {triggerData && (
            <AdminTriggerEditComponent 
            isInViewModal={true}
            trigger={trigger}  
            onChange={onChangeTrigger} 
            onSubmit={submitTrigger}   
            onSoftDelete={onSoftDelete} 
            validationErrors={validationErrors}
            />
          )}
          {hasTriggerOccured && <Table>{createTable(triggerOccurrence)}</Table>}
          {!hasTriggerOccured && (
            <p>
              {t("TRIGGER_OCCURRENCE_EMPTY", "This trigger has not fired.")}
            </p>
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default withTranslation()(AdminTriggerViewModal);
