import { getDescendantProperty, parseExpression } from "../../utility/objectUtils";
import DateTimeService from "../DateTimeService";
export const getUiTabFromConfig = (moduleCode) => {
    return Window.configuration.ui.tabs.map(tab => {
        if (typeof tab === "string") {
            const name = tab.startsWith("module/") ? tab : "module/" + tab
            const code = tab
            return {
                code,
                name,
                roles: [],
                columnsCompleted: []
            }
        } else {
            return {
                ...tab,
                code: tab.name.startsWith("module/") ? tab.name.replace("module/", "") : tab.name,
                columnsCompleted: tab.columnsCompleted ? tab.columnsCompleted.reverse() : []
            }
        }
    }).find((tab) => {
        return tab.code === moduleCode || tab.name === "module/" + moduleCode;
    });
}

export const moduleHasColumnsForCompletedConfigured = (uiTabConfig) => {
    if (uiTabConfig?.columnsCompleted && uiTabConfig?.columnsCompleted?.length > 0) {
        return true
    } else {
        return false;
    }
}

export const moduleColumnKey = (column) => {
    return moduleColumnFieldOrExpression(column)
        .replaceAll(' ', '')
        .replaceAll('${', '')
        .replaceAll('}', '')
}

export const moduleColumnFieldOrExpression = (column) => {
    if (column.field) {
        return column.field
    } else if (column.expression) {
        return column.expression
    } else {
        throw new Error('column.field or column.expression must be defined for module column')
    }
}

export const parseColumnExpression = (column, context) => {
    if (column.field) {
        return getDescendantProperty(context.q, column.field)
    } else if (column.expression) {
        return parseExpression(column.expression, context)
    } else {
        throw new Error('column.field or column.expression must be defined for module column')
    }
}

export const formatColumn = (column, rawValue) => {
    if (!column.format || !rawValue || rawValue === null || rawValue === 'null') {
        return rawValue;
    }
    try {
        switch (column.format) {
            case 'datetime':
                return DateTimeService.build.asDisplayDateTimeWithTz(
                    rawValue
                )
            case 'localdatetime':
                return DateTimeService.build.asDisplayDateTime(
                    rawValue
                )
            case 'date':
                return DateTimeService.build.asDisplayDate(
                    rawValue
                )
            case 'monthyear':
                return DateTimeService.build.asDisplayMonthYear(
                    rawValue
                )
            default:
                console.log(`[module columns] unsupported column format ${column.format}`, column)
                return rawValue
        }
    } catch (e) {
        // nosemgrep
        console.log(`[module columns] Error parsing ${rawValue} for column`, column)
    }
    return rawValue;


}
