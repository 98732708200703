import React, { memo } from 'react';
import AnnotationList from './AnnotationList';
import AnnotationAddIcon from './AnnotationAddIcon';

const AnnotationControls = ({
  viewingProgress,
  controlState,
  annotationOptions,
  annotations,
  onUpdateAnnotations
}) => {

  // edit -> on list
  // delete  -> on list PROMPT

  // jump to start of marker  -> on list PROMPT -> v2


  const handleSave = (annotation) => {
    let updatedAnnotations = [...annotations];
    if (annotations.find(a => a.id === annotation.id) != null) {
      // Edit -> replace
      updatedAnnotations = annotations
        .map(a => {
          if (a.id === annotation.id) {
            return annotation
          }
          return a;
        });
    } else {
      // New - add
      updatedAnnotations.push(annotation);
    }
    if (onUpdateAnnotations) {
      onUpdateAnnotations(updatedAnnotations);
    }
  }

  return (
    <>
      <AnnotationList
        controlState={controlState}
        annotationOptions={annotationOptions}
        annotations={annotations}
      />
      <AnnotationAddIcon
        viewingProgress={viewingProgress}
        controlState={controlState}
        annotationOptions={annotationOptions}
        annotations={annotations}
        onSave={handleSave}
      />
    </>
  );
};

export default memo(AnnotationControls);
