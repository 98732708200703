import React, {memo, useEffect, useRef, useState} from 'react';
import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {DISPLAY_TIME_FORMAT} from "../constants/DISPLAY_TIME_FORMAT";
import {ANNOTATION_TYPE} from "../VideoPlayer";
import useKey from "../../../../hooks/useKey";
import {withTranslation} from "react-i18next";

const ProgressSlider = ({
    t,
    durationMilliseconds,
    currentTimeMilliseconds,
    onProgressChange,
    annotations,
    setAnnotations,
    controlState
}) => {
    const leftMargin = 20;


    const [selectedInit, setSelectedInit] = useState(false);
    const [selectedEnd, setSelectedEnd] = useState(false);

    const annotationIndicatorSize = '12px';
    const selectedAnnotationIndicatorSize = '15px';
    const annotationIndicatorSizeIn = '12px';
    const sliderRef = useRef(null); // Ref to the slider's container for size and position calculations

    const [hoveredAnnotation, setHoveredAnnotation] = useState(null);
    const [hoverTime, setHoverTime] = useState(null); // State to store the calculated hover time

    const [selectedAnnotation, setSelectedAnnotation] = useState(null);


    useEffect(() => {
        if(selectedAnnotation){
            const isInstant = selectedAnnotation.category.type === ANNOTATION_TYPE.INSTANT.value;
            const ct = Math.floor(currentTimeMilliseconds);
            if(isInstant){
                if(selectedInit && selectedAnnotation.timeStart !== ct){
                    setSelectedAnnotation(prevAnnotation => ({
                        ...prevAnnotation, // Spread the previous annotation to maintain other properties
                        timeStart: ct, // Update timeStart with the new value
                    }));
                    setAnnotations(prevAnnotations => prevAnnotations.map(annotation => {
                        if (annotation.id === selectedAnnotation.id) {
                            return {
                                ...annotation, // Spread the previous annotation to maintain other properties
                                timeStart: ct, // Update timeStart with the new value
                            };
                        }
                        return annotation;
                    }));
                }
            }else{
                if(selectedInit && selectedAnnotation.timeStart !== ct && currentTimeMilliseconds < (selectedAnnotation.timeEnd - 100) ){
                    setSelectedAnnotation(prevAnnotation => ({
                        ...prevAnnotation, // Spread the previous annotation to maintain other properties
                        timeStart: ct, // Update timeStart with the new value
                    }));
                    setAnnotations(prevAnnotations => prevAnnotations.map(annotation => {
                        if (annotation.id === selectedAnnotation.id) {
                            return {
                                ...annotation, // Spread the previous annotation to maintain other properties
                                timeStart: ct, // Update timeStart with the new value
                            };
                        }
                        return annotation;
                    }));
                }
                else if(selectedEnd && selectedAnnotation.timeEnd !== ct && currentTimeMilliseconds > (selectedAnnotation.timeStart + 100)){
                    setSelectedAnnotation(prevAnnotation => ({
                        ...prevAnnotation, // Spread the previous annotation to maintain other properties
                        timeEnd: ct, // Update timeStart with the new value
                    }));
                    setAnnotations(prevAnnotations => prevAnnotations.map(annotation => {
                        if (annotation.id === selectedAnnotation.id) {
                            return {
                                ...annotation, // Spread the previous annotation to maintain other properties
                                timeEnd: ct, // Update timeStart with the new value
                            };
                        }
                        return annotation;
                    }));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInit,selectedEnd,selectedAnnotation,currentTimeMilliseconds]);

    useKey("ArrowUp",
        (event) => {
            if(selectedAnnotation){
                if(selectedAnnotation.level >= 10 )
                    return;
                selectedAnnotation.level +=1;
                setSelectedAnnotation(selectedAnnotation)
                event.preventDefault();
            }
        }
    );

    useKey("ArrowDown",
        (event) => {
            if(selectedAnnotation){
                if(selectedAnnotation.level <= 1 )
                    return;
                selectedAnnotation.level -=1;
                setSelectedAnnotation(selectedAnnotation)
                event.preventDefault();
            }
        }
    );

    useKey("Escape",
        (event) => {
            clearSelection();
        }
    );

    useKey("KeyC",
        (event) => {
            if (event.ctrlKey || event.metaKey) {
                try{
                    navigator.clipboard.writeText(DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(currentTimeMilliseconds));
                }catch (e) {
                    console.warn("Could not copy time to clipboard.")
                }

            }
        }
    );


    useKey("KeyA",
        (event) => {
            if (event.ctrlKey || event.metaKey) {
                try{
                    navigator.clipboard.writeText(JSON.stringify(annotations,null," "));
                }catch (e) {
                    console.warn("Could not copy time to clipboard.")
                }

            }
        }
    );

    const clearSelection = () => {
        setSelectedAnnotation(null);
        setSelectedInit(false);
        setSelectedEnd(false);
    }
    const showAnnotationView = (annotation) => {
        if(!annotation)
            return null;

        let bottomPosition =`calc((${annotation.level - 1}) * ${selectedAnnotationIndicatorSize} + 50px)`;

        return (
            <div
                style={{
                    position: 'absolute',
                    left: annotation.category.type === ANNOTATION_TYPE.INSTANT.value ?
                        calculatePosition(annotation.timeStart):
                        calculatePosition(annotation.timeStart + (annotation.timeEnd - annotation.timeStart)/2),
                    bottom: bottomPosition,

                    transform: 'translateX(-50%)',
                    backgroundColor: '#FFF',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    zIndex: 1000,
                }}
            >
                <span style={{ textAlign: 'center' }}>{`${t('ANNOTATIONS_CATEGORY',"Category")}: ${annotation.category.code}`}</span>
                { annotation.category.type === ANNOTATION_TYPE.INSTANT.value &&
                    (<span style={{ textAlign: 'center' }}>{`${t('ANNOTATIONS_TIME',"Time")}: ${DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeStart)}`}</span>)
                }
                { annotation.category.type === ANNOTATION_TYPE.INTERVAL.value &&
                    (
                        <>
                            <span style={{ textAlign: 'center' }}>{`${t('ANNOTATIONS_INIT_TIME',"Init Time")}: ${DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeStart)}`}</span>
                            <span style={{ textAlign: 'center' }}>{`${t('ANNOTATIONS_END_TIME',"End Time")}: ${DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeEnd)}`}</span>
                            <span style={{ textAlign: 'center' }}>{`${t('ANNOTATIONS_DURATION',"Duration")}: ${DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeEnd - annotation.timeStart)}`}</span>
                        </>
                    )
                }
                { annotation.name && (<span style={{ textAlign: 'center' }}>{`Name: ${annotation.name}`}</span>)}
                { annotation.notes !== undefined && (
                    <span style={{ textAlign: 'center' }}>{`${annotation.notes}`}</span>)
                }
            </div>
        )
    }

    const calculateLeftPosition = (time) => `${(time / durationMilliseconds) * 100}%`;
    const calculatePosition = (time) =>  `${(time / durationMilliseconds) * 100}%`;
    const calculateRightPosition = (timeEnd) => timeEnd?`${((durationMilliseconds - timeEnd) / durationMilliseconds) * 100}%`:null;

    const handleMouseMove = (event) => {
        const { left, width } = sliderRef.current.getBoundingClientRect();
        const mouseX = event.clientX - left - leftMargin; // Mouse position within the slider
        const w = width - leftMargin * 2;
        const time = Math.floor((mouseX / w) * durationMilliseconds); // Calculate time based on mouse position
        setHoverTime(Math.min(Math.max(time, 0), durationMilliseconds)); // Clamp time between 0 and videoDuration

    };
    const handleMouseLeave = () => {
        setHoverTime(null); // Reset hover time when the mouse leaves the slider
    };

    const annotationClicked = (annotation,time, isInitTime, isEndTime) => {
        const selected = selectedAnnotation?(annotation.id === selectedAnnotation.id):false
        if (selected){
                if(isInitTime){
                    setSelectedInit(!selectedInit);
                    setSelectedEnd(false);
                    onProgressChange(time)
                }
                else if(isEndTime){
                    setSelectedEnd(!selectedEnd);
                    setSelectedInit(false);
                    onProgressChange(time)
                }else{
                    setSelectedAnnotation(null)
                    setSelectedInit(false);
                    setSelectedEnd(false);
                }

        }else{
            setSelectedAnnotation(annotation)
            setSelectedInit(false);
            setSelectedEnd(false);
            if(time >=0 )
                onProgressChange(time)
        }

    }

  return (
      <div style={{ position: 'relative', padding: '0px' }} >
          <div
              ref={sliderRef}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
          >
              <RCSlider
                  onChange={onProgressChange}
                  min={0}
                  max={durationMilliseconds}
                  step={1}
                  value={currentTimeMilliseconds}
                  styles={{
                      track: {
                          backgroundColor: '#f8991d',
                          height: '5px',
                          cursor: 'pointer'
                      },
                      handle: {
                          backgroundColor: '#fff',
                          border: '2px solid #fff',
                          outline: '0px solid #fff',
                          margin: '-4px',
                          boxShadow:  '0 0 0 0px #fff',
                          opacity: 1
                      },
                      rail: {
                          backgroundColor: '#adadad',
                          height: '5px',
                          cursor: 'pointer'
                      }
                  }
                  }
                  dotStyle={{ backgroundColor: '#fff', border: '0' }}
                  activeDotStyle={{
                      backgroundColor: '#fff',
                      border: '0px',
                  }}
                  style={{
                      marginLeft: leftMargin,
                      marginTop: 2,
                      maxWidth: 'calc(100% - 40px)',
                      backgroundColor: null,
                  }}
              />

          </div>

          {hoverTime !== null && (
              <div
                  style={{
                      position: 'absolute',
                      left: `${(hoverTime / durationMilliseconds) * 100}%`,
                      top: '45px', // Adjust as needed
                      transform: 'translateX(-50%)',
                      backgroundColor: '#FFF',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                      whiteSpace: 'nowrap',
                  }}
              >
              {DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(hoverTime)}
              </div>
          )}
        <div style={{ marginLeft: leftMargin/2+3, position: 'absolute', top: '-20px', width: 'calc(100% - 20px - 6px)', height: '20px' }}>
            {annotations.map((annotation, index) => {

                const selected = selectedAnnotation?(annotation.id === selectedAnnotation.id):false
                const isInstant = annotation.category.type === ANNOTATION_TYPE.INSTANT.value;

                const annotationColor = annotation.category.color ? annotation.category.color : 'green'
                const annotationColorAlpha = convertColorToRGBA(annotationColor,0.5)


                const hoveringAnnotation = annotation === hoveredAnnotation

                //const aparitoColor = "#F8991D44";
                return (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            justifyContent: isInstant?'center':'space-between', // Center horizontally
                            alignItems: 'center', // Center horizontally
                            position: 'absolute',
                            left: calculateLeftPosition(annotation.timeStart),
                            right: calculateRightPosition(annotation.timeEnd),
                            width: isInstant ? selected? selectedAnnotationIndicatorSize: annotationIndicatorSize : null,
                            height: selected? selectedAnnotationIndicatorSize: annotationIndicatorSize,
                            borderRadius: isInstant ? '50%' : '2px',
                            backgroundColor: annotationColorAlpha,
                            transform: `translateY(-${(annotation.level - 1) * selectedAnnotationIndicatorSize}px)`,
                            bottom: `calc((${annotation.level - 1}) * ${selectedAnnotationIndicatorSize})`
                        }}
                        onMouseEnter={() => setHoveredAnnotation(annotation)}
                        onMouseLeave={() => setHoveredAnnotation(null)}
                        onClick={(event) => {
                            annotationClicked(annotation,-1,false,false)}
                    }
                    >
                        {(annotation.category.type === ANNOTATION_TYPE.INSTANT.value) && (hoveringAnnotation || selected) && (
                            <div style={
                                    {
                                        width: annotationIndicatorSizeIn,
                                        height: annotationIndicatorSizeIn,
                                        borderRadius: '50%',
                                        backgroundColor: (hoveringAnnotation || selected) ? annotationColor : annotationColorAlpha,
                                        border: (selected && selectedInit) ? '3px solid #F8991D' : 'none',
                                        zIndex: 100
                                    }
                                }
                                 onClick={ (event) => {
                                     annotationClicked(annotation,annotation.timeStart,true,false)
                                     event.stopPropagation();
                                 }}
                            />)
                        }
                        {(annotation.category.type === ANNOTATION_TYPE.INTERVAL.value) && (hoveringAnnotation || selected) && (
                            <>
                                <div style={
                                    {
                                        width: (selected && selectedInit) ? selectedAnnotationIndicatorSize : annotationIndicatorSizeIn,
                                        height: (selected && selectedInit) ? selectedAnnotationIndicatorSize : annotationIndicatorSizeIn,
                                        borderRadius: (selected && selectedInit) ? '50%': '20%',
                                        backgroundColor: (hoveringAnnotation || selected) ? annotationColor : annotationColorAlpha,
                                        border: (selected && selectedInit) ? '3px solid #F8991D' : 'none',
                                        zIndex: 100
                                    }
                                }
                                     onClick={(event) => {
                                         annotationClicked(annotation,annotation.timeStart,true,false)
                                         event.stopPropagation()
                                     }
                                    }
                                />
                                <div style={
                                    {
                                        width: (selected && selectedEnd) ? selectedAnnotationIndicatorSize : annotationIndicatorSizeIn,
                                        height: (selected && selectedEnd) ? selectedAnnotationIndicatorSize : annotationIndicatorSizeIn,
                                        borderRadius: (selected && selectedEnd) ? '50%': '20%',
                                        backgroundColor: (hoveringAnnotation || selected) ? annotationColor : annotationColorAlpha,
                                        border: (selected && selectedEnd) ? '3px solid #F8991D' : 'none',
                                        zIndex: 100
                                    }
                                }
                                     onClick={(event) => {
                                         annotationClicked(annotation, annotation.timeEnd, false, true)
                                         event.stopPropagation();
                                     }
                                     }
                                />
                            </>
                                )
                        }
                    </div>
                )
            }
            )}
        </div>
          {showAnnotationView(hoveredAnnotation)}
          {showAnnotationView(selectedAnnotation)}
    </div>
  );
};

function convertColorToRGBA(colorName,alpha) {
    const colors = {
        red: '255,0,0',
        blue: '0,0,255',
        green: '0,128,0',
        purple: '128,0,128',
        orange: '255,165,0',
        yellow: '255,255,0',
        darkblue: '0,0,139',
        darkred: '139,0,0',
        lightblue: '173, 216, 230'
        // Add more named colors and their RGB values as needed
    };

    const rgbValue = colors[colorName.toLowerCase()];
    if (!rgbValue) {
        console.warn('Color not found. Defaulting to black:' + colorName);
        return 'rgba(0,0,0,0.5)'; // Default color if not found
    }

    return `rgba(${rgbValue},${alpha})`;
}


export default memo(withTranslation()(ProgressSlider));
