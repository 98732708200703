import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {createPortal} from "react-dom";
import RegionSelectorControls from "./RegionSelectorControls";
import useCalculatedFPS from "./hooks/useCalculatedFPS";
import DateTimeService from "../../../services/DateTimeService";
import {saveAs} from "file-saver";
import useKey from '../../../hooks/useKey';
import KeyboardHotkeyMappings from "../../../utility/KeyboardHotkeyMappings";
import AttachmentService, {MEDIA_STATE} from "../../../services/AttachmentService"
import {withTranslation} from "react-i18next";
import AppStateService from "../../../services/AppStateService";
import {DataChannel} from "atom5-data-analysis/src/utils/DataChannel";
import Box from "./regionselector/Box";


const RegionSelector = ({t, video, portalTarget, videoSizeState, question,changeAnswerMapValue, getAnswerMapValue, videoData}) => {
  const canvas = useRef(null);
  const boxRef = useRef(null);
  const selectedBoxRef = useRef(null);
  const [boxToolActive, setBoxToolActive] = useState(true);
  const [boxSelectActive, setBoxSelectActive] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleBox = useRef(null);
  const handleSelectBox = useRef(null);
  const videoNativeSize = [video?.videoWidth, video?.videoHeight];
  const videoRect = video?.getBoundingClientRect() || new DOMRect();
  const [editorMode] = useState('BLUR_CORRECTION_DATA');  //
  const [filtered, setFiltered] = useState(false);

  const questionCode = question?.code;
  const attachmentsConfig = question?.config?.regionSelectorAttachmentsConfig;
  const configForEditorMode = attachmentsConfig?.filter(conf => conf?.attachmentType === editorMode);
  const [entries, setEntries] = useState([])
  const [fps, setFPS] = useState(null);
  const [boxId, setBoxId] = useState(0);
  const [initialContent,setInitialContent] = useState(true);
  const [regionError,setRegionError] = useState(null);
  const [mousePoint, setMousePoint] = useState({x:0,y:0})
  const [mouseDown, setMouseDown] = useState({x:0,y:0})
  //May be a wrapped answer due to answer format required for submission of files, unwraps answer value if needed.
  let attachmentReference = configForEditorMode?unWrapAnswer(getAnswerMapValue(configForEditorMode[0]?.fileUploader)):"";
  const [frameData, setFrameData] = useState(null);

  function parseCSV(csvString) {
    const lines = csvString.trim().split('\n');
    const entries = [];

    for (let i = 1; i < lines.length; i++) {
      const data = lines[i].split(',');
      const entry = {
        id: data[0].trim(),
        frame: parseInt(data[1].trim()),
        coords: {
          x1: parseInt(data[2].trim()),
          y1: parseInt(data[3].trim()),
          x2: parseInt(data[4].trim()),
          y2: parseInt(data[5].trim())
        },
        boxId: parseInt(data[6].trim()),
        frameType: data[7].trim()
      };
      entries.push(entry);
    }
    return entries;
  }

  function unWrapAnswer(answerValue) {
    let attachmentReference = answerValue ;
    const isWrappedFileuploadAnswer = typeof attachmentReference === "object" && attachmentReference !== null;
    return isWrappedFileuploadAnswer ? attachmentReference.answer : attachmentReference;
  }
  const loadContent = async () => {
    const questionnaireCtx = AppStateService.getLocalStateForNamespace("CURRENT_QUESTIONNAIRE");
    const subjectId = questionnaireCtx.subjectId
    const attachmentResponse = await AttachmentService.getAttachment(
        subjectId,
        attachmentReference,
        false
    );
    if(attachmentResponse && AttachmentService.getMediaStateFromString(attachmentResponse.state) === MEDIA_STATE.COMPLETED){
      return new Promise((resolve, reject) => {
        AttachmentService.getAttachmentInline(
            subjectId,
            attachmentReference,
            false,
            (progress) => {
              //console.log(progress.percentage);
            },
            (handDataUrl, blob) => {
              // Read blob as text
              const reader = new FileReader();
              reader.onload = function (event) {
                const csvData = event.target.result;
                resolve(parseCSV(csvData));
              };
              reader.onerror = function (event) {
                console.error("File could not be read! Code " + event.target.error.code);
                reject(event.target.error);
              };
              reader.readAsText(blob);
            },
            (err) => {
              console.error('Error getting cvs data attachment', err)
              reject(err);
            },
            attachmentResponse.variantReference,
        )
      })
    }
    console.log(attachmentResponse);
  };

  // This is a bit of a hack
  // cycle1 - isExpandedView set to true
  // cycle2 - this component and video rerender, this value changes
  // cycle3 - this component rerenders getting new video size
  const [localVideoSizeState, setLocalVideoSizeState] = useState(videoSizeState);
  useEffect(()=>{
    if(videoSizeState !== localVideoSizeState) {
      setLocalVideoSizeState(videoSizeState);
    }
  }, [localVideoSizeState, videoSizeState])

  useEffect(()=>{
    KeyboardHotkeyMappings.unAssignKeys();
    return () => {
      KeyboardHotkeyMappings.assignKeys();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // calculate video padding
  // assumption is made here that if horizontalLeadScaling is true there will be vertical padding
  // and the opposite if horizontalLeadScaling is false
  const isHorizontalLeadScaling = videoNativeSize[0] > videoNativeSize[1];
  const scaleFactor = isHorizontalLeadScaling ?
      videoRect.width / videoNativeSize[0] :
      videoRect.height / videoNativeSize[1];
  const halfXDeadzone = isHorizontalLeadScaling ?
      0 :
      (videoRect.width - (videoNativeSize[0] * scaleFactor)) / 2 ;
  const halfYDeadzone = isHorizontalLeadScaling ?
      (videoRect.height - (videoNativeSize[1] * scaleFactor)) / 2 :
      0 ;


  const getSelectedBox = useCallback((frameNumber, x, y) => {
    x = (x - halfXDeadzone) / scaleFactor
    y = (y - halfYDeadzone) / scaleFactor

    const entries = frameData.getDataPos(frameNumber);
    if(entries.length === 0){
      return null;
    } else {
      let selected = null;
      entries.forEach((entry) => {
        if(x > entry.coords.x1 && x < entry.coords.x2  && y > entry.coords.y1 && y < entry.coords.y2) {
          selected = entry;
        }
      });
      return selected;
    }
  },[frameData, halfXDeadzone, halfYDeadzone, scaleFactor])


  const handleMouseDown = (e) => {

    if(e.clientY < 0 || e.clientY > (videoRect.y + videoRect.height))
      return;

    const x = e.clientX - videoRect.x
    const y = e.clientY - videoRect.y
    setMouseDown({ x: x, y: y});

    if(boxSelectActive){
      const _selectedEntry = getSelectedBox(currentFrame,x,y)
      if(_selectedEntry) {
        setSelectedEntry(_selectedEntry);
        setSelectedRows([_selectedEntry.id]);
        setBoxId(_selectedEntry.boxId)

        selectedBoxRef.current._setBoxRect({
          visible: true,
          boxId: _selectedEntry.boxId,
          x: _selectedEntry.coords.x1 * scaleFactor + halfXDeadzone,
          y: _selectedEntry.coords.y1 * scaleFactor + halfYDeadzone,
          width: (_selectedEntry.coords.x2 - _selectedEntry.coords.x1) * scaleFactor,
          height: (_selectedEntry.coords.y2 - _selectedEntry.coords.y1) * scaleFactor
        })
      }else {
        setSelectedEntry(null);
        setSelectedRows([]);
        selectedBoxRef.current.hide();
      }
    }
  }

  const handleToggleBoxTool = () => {
    setBoxSelectActive(false);
    if(selectedBoxRef.current !== null)
      selectedBoxRef.current.hide();
    setBoxToolActive(!boxToolActive);
  }

  const handleToggleBoxSelect = () => {
    setBoxToolActive(false);
    setBoxSelectActive(!boxSelectActive);
  }

  /// When boxSelectActive is changed and sis set to false clear selectedEntry and selectedRows
  useEffect(()=>{
    if(!boxSelectActive){
      setSelectedEntry(null);
      setSelectedRows([]);

    }
    if(selectedBoxRef.current !== null)
      selectedBoxRef.current.hide();
  },[boxSelectActive]);

  /// When boxTollActive is set to false clear selectedEntry
  useEffect(() => {
    if(!boxToolActive){
      setSelectedEntry(null);
    }
  }, [boxToolActive]);


  // handle temporal information
  const calculatedFPSData = useCalculatedFPS(video);

  const currentFrame = useMemo(()=>{
    if(
        typeof calculatedFPSData.currentTime !== "number" ||
        typeof fps !== "number"
    ) {
      return null;
    }
    return Math.round(calculatedFPSData.currentTime * fps);
  }, [calculatedFPSData, fps]);

  // handle drawing on the canvas
  useEffect(()=>{
    const ctx = canvas.current.getContext("2d");
    ctx.clearRect(0,0, 10000, 10000)
    ctx.lineWidth = 1
    ctx.font = "20px Arial"; // Set font size and family
    ctx.fillStyle = "#fff2"
    ctx.strokeStyle = "#0004"

    if(frameData == null)
      return;
    const currentFrameEntries = frameData.getDataPos(currentFrame);
    if(currentFrameEntries === 0)
      return;
    currentFrameEntries.forEach((entry)=> {
      /// If entry is selected then dont draw
      if(selectedEntry?.id === entry.id){
        return;
      }

      const x = entry.coords.x1 * scaleFactor + halfXDeadzone;
      const y = entry.coords.y1 * scaleFactor + halfYDeadzone;
      const width = (entry.coords.x2 - entry.coords.x1) * scaleFactor;
      const height = (entry.coords.y2 - entry.coords.y1) * scaleFactor;

      ctx.fillStyle = "#fff2";
      ctx.fillRect(x, y, width, height);

      ctx.strokeStyle = "#0004";
      ctx.strokeRect(x, y, width, height);

      ctx.fillStyle = "#FFF"; // Set text color
      ctx.fillText(entry.boxId, x + 2, y + 20);
      ctx.fillStyle = "#000"; // Set text color
      ctx.fillText(entry.boxId, x + 3, y + 20); // Add some padding for better visibility
    })
  }, [entries, frameData, currentFrame, halfXDeadzone, halfYDeadzone, scaleFactor,selectedEntry, boxSelectActive])

  useEffect(()=>{
    if (fps == null || fps === 0){
      setFPS(calculatedFPSData.fps);
    }
  },[fps,calculatedFPSData])

  useEffect(()=>{
    if (fps !== null && fps !== 0){
      setFrameData(new DataChannel("FrameData",fps,100));
    }
  },[fps])


  useEffect(() => {
    prepareDataForFileUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[entries])

  useEffect(() => {
    loadContent().then((loadedEntries) => {
      addFrameDataEntries(loadedEntries)
      setEntries(loadedEntries);
      setInitialContent(false)
    }).catch(error => {
      console.log("Error fetching previous region data.")
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Controls
  async function nextFrame() {
    if (fps === null) return;
    const calculatedTime = calculatedFPSData.currentTime + (1 / fps);

    video.currentTime = Math.ceil(calculatedTime * 1000000) / 1000000;//round up number to 6 decimal places
    await video.pause();
  }

  async function previousFrame() {
    if (fps === null) return;
    const calculatedTime = calculatedFPSData.currentTime - (1 / fps)
    video.currentTime = Math.ceil(calculatedTime * 1000000) / 1000000;//round up number to 6 decimal places
    await video.pause();
  }

  async function handleGoToFrame(frameNumber) {
    const calculatedTime = (frameNumber/ fps)
    video.currentTime = Math.ceil(calculatedTime * 1000000) / 1000000;//round up number to 6 decimal places
    await video.pause();
  }


  async function handleFilteredToggle() {
    if(!filtered){
      setEntries(getEntriesFromFrameData().filter(entry => entry?.boxId === boxId));
    }else{
      setEntries(getEntriesFromFrameData())
    }
    setFiltered(!filtered);
  }

  const addFrameDataEntry = (frameNumber,entry) => {

    let cframeData = frameData.getDataPos(frameNumber);
    frameData.setWritePos(frameNumber);
    if(cframeData === 0){
      frameData.setDataPos(
          [entry],
          frameNumber)
    }else{
      frameData.setDataPos(
          [
            ...cframeData,
            entry, // default box id can be set on selector
          ],
          frameNumber)
    }
  }

  const updateDataFrameEntry = (frameNumber,updatedEntry) => {
    let cframeData = frameData.getDataPos(frameNumber);
    frameData.setDataPos(cframeData.map(item => item.id === updatedEntry.id ? updatedEntry : item),frameNumber);
    setEntries(entries.map(item => item.id === updatedEntry.id ? updatedEntry : item ));
  }


  const addFrameDataEntries = (entries) => {
   entries.map( (entry) =>
       addFrameDataEntry(entry.frame,entry)
   );
  }

  function logFrame(type){
    if (fps === null) return;

    if(!boxToolActive) return;
    const coords = boxRef.current.coords();
    addFrameDataEntry(currentFrame, { frame: currentFrame, coords, id: crypto.randomUUID(), boxId: boxId, type: type })
    setEntries(getEntriesFromFrameData());
  }

  async function logInit() {
    if (fps === null) return;
    const coords = boxRef.current.coords();
    addFrameDataEntry(currentFrame, { frame: currentFrame, coords, id: crypto.randomUUID(), boxId: boxId, type: "init" })
    setEntries(getEntriesFromFrameData());
  }

  // Function to find the last entry with boxId and type
  function findLastEntry(boxId, type, frameNumber) {

    for(let i= frameNumber;i >= 0; i--){
      const frameEntries = frameData.getDataPos(i);
      if(frameEntries === 0)
        continue;
      const filteredEntries = frameEntries.filter(entry => entry.boxId === boxId && entry.type === type);
      if(filteredEntries.length > 0){
        return filteredEntries[filteredEntries.length - 1]
      }
    }
  }


// Function to interpolate between two values
  function interpolate(start, end, fraction) {
    return start + (end - start) * fraction;
  }

// Function to create interpolated entries
  function createInterpolatedEntries(initEntry, endEntry) {

    if (!initEntry || !endEntry) {
      console.error('Both init and end entries are required.');
      return [];
    }

    const { frame: startFrame, coords: startCoords } = initEntry;
    const { frame: endFrame, coords: endCoords } = endEntry;

    const interpolatedEntries = [];
    for (let frame = startFrame + 1; frame < endFrame; frame++) {
      const fraction = (frame - startFrame) / (endFrame - startFrame);
      const interpolatedCoords = {
        x1: Math.round(interpolate(startCoords.x1, endCoords.x1, fraction)),
        y1: Math.round(interpolate(startCoords.y1, endCoords.y1, fraction)),
        x2: Math.round(interpolate(startCoords.x2, endCoords.x2, fraction)),
        y2: Math.round(interpolate(startCoords.y2, endCoords.y2, fraction)),
      };
      interpolatedEntries.push({
        frame,
        coords: interpolatedCoords,
        id: crypto.randomUUID(),
        boxId: initEntry.boxId,
        type: 'calculated'
      });
    }

    return interpolatedEntries;
  }


  async function logEnd() {
    if (fps === null) return;

    // Serch init of current entry
    const initEntry = findLastEntry(boxId,"init", currentFrame)
    const coords = boxRef.current.coords();
    const endEntry = { frame: currentFrame, coords, id: crypto.randomUUID(), boxId: boxId, type: "end" }

    const newEntries = createInterpolatedEntries(initEntry,endEntry);

    addFrameDataEntries(newEntries);
    addFrameDataEntry(currentFrame, endEntry);
    setEntries(getEntriesFromFrameData());
  }

  async function handleInterpolateFrames(initEntry,endEntry){
    const newEntries = createInterpolatedEntries(initEntry,endEntry);
    addFrameDataEntries(newEntries);

    setEntries(getEntriesFromFrameData());
  }

  function buildBlobData(){
    const titles = "id,frame,top_left_x,top_left_y,bottom_right_x,bottom_right_y,boxId,frameType\n"
    const csvEntries = entries.map(e=>`${e.id},${e.frame},${e.coords.x1},${e.coords.y1},${e.coords.x2},${e.coords.y2},${e.boxId},${e.frameType}\n`)
    return new Blob([titles, ...csvEntries], {
      type: "text/csv;charset=utf-8;",
    })
  }

  function prepareDataForFileUpload () {
    const csvBlobData = buildBlobData();

    if (attachmentsConfig) {
      if (changeAnswerMapValue) {
        const configForEditorMode = attachmentsConfig.filter(conf => conf?.attachmentType === editorMode);
        if (configForEditorMode.length === 1) {
          try {
            AttachmentService.injectAttachmentForUpload(t,
                changeAnswerMapValue,
                configForEditorMode[0]?.fileUploader,
                csvBlobData,
                configForEditorMode[0]?.mimeType,
                'csv',
                attachmentReference,
                videoData.attachmentVariantRef,
                initialContent ? 'ORIGINAL' : 'CSV',
                !initialContent)
          }catch(error){
            setRegionError(error)
          }
        } else {
          console.error("Attachment configuration of: " + questionCode + " is expecting one element of regionSelectorAttachmentsConfig.attachmentType: " + editorMode + " but got " + configForEditorMode.size);
        }
      } else {
        console.error("changeAnswerMapValue function is not defined, is the region selection tool being used from readonly questionnaire view? No data will be prepared for upload.");
      }
    } else {
      console.warn("No attachment configuration defined for Region Selector, will not attempt to upload generated data from Region Selector tool on submission");
    }
  }
  async function download(){
    const csvBlobData = buildBlobData();
    const dateTimeWithMillis = DateTimeService.now.asString();
    const filename = `video-region-selector-${questionCode}-${dateTimeWithMillis}.csv`;
    saveAs(csvBlobData, filename);
  }
  async function remove(entryId) {
    for(let i =frameData.initPos; i <= frameData.endPos; i++){
      let fd = frameData.getDataPos(i)
      if(fd !== 0) {
        const index = fd.findIndex(e=>e.id === entryId);
        if ( index >= 0){
          fd.splice(index,1)
          //frameData.setDataPos(fd,i);
        }
      }
    }

    setEntries(prev => {
      const index = prev.findIndex(e=>e.id === entryId)
      let newEntries = [...prev];
      newEntries.splice(index, 1)
      return newEntries;
    })
    prepareDataForFileUpload();
  }

  async function handleUpdateSelectRect(boxRect){
    console.log("Update " +  JSON.stringify(boxRect))
    if(selectedEntry){
      const coords = selectedBoxRef.current.coords();
      updateDataFrameEntry(selectedEntry.frame, { ...selectedEntry, coords: coords})
      setEntries(getEntriesFromFrameData());
    }
  }

  ///
  async function handleUpdateBoxId(id, newBoxId, entry){

    let fd = frameData.getDataPos(entry.frame)
    if(fd !== 0) {
      const index = fd.findIndex(e=>e.id === id);
      if ( index >= 0){
        frameData.setDataPos(fd.map((entry) =>
            entry.id === id ? { ...entry, boxId: newBoxId } : entry
        ), entry.frame);
      }else {
        console.log("Error, could not find entry");
      }
    }

    setEntries(getEntriesFromFrameData());

    if(selectedEntry && id === selectedEntry.id){
      setSelectedEntry((prev) => ({
        ...prev,
        boxId: newBoxId
      }));
      selectedBoxRef.current._setBoxRect({
        visible: true,
        boxId: newBoxId,
        x: selectedEntry.coords.x1 * scaleFactor + halfXDeadzone,
        y: selectedEntry.coords.y1 * scaleFactor + halfYDeadzone,
        width: (selectedEntry.coords.x2 - selectedEntry.coords.x1) * scaleFactor,
        height: (selectedEntry.coords.y2 - selectedEntry.coords.y1) * scaleFactor
      })
    }
  }

  const handleBoxInteraction = (e) => {
    setMousePoint({ x: e.clientX, y: e.clientY});
  }

  const getEntriesFromFrameData = () =>{
    let dataArray = []

    for (let p = frameData.initPos; p <= frameData.endPos; p += 1) {
      let fd = frameData.getDataPos(p);
      if(fd instanceof Array)
        dataArray = [...dataArray, ...fd]
    }
    return dataArray;
  }

  function playPause() {
    if (video?.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

  // Note that the instructions should be shown in the translations in RegionSelectorControls
  useKey("KeyQ", () => previousFrame());
  useKey("KeyW", () => nextFrame());
  useKey("KeyE", () => logFrame("keyframe"));
  useKey("KeyT", () => logFrame("track"));
  useKey("KeyI", () => logInit());
  useKey("KeyO", () => logEnd());
  useKey("Space", () => playPause());
  useKey("KeyB", () => handleToggleBoxTool());
  useKey("KeyS", () => handleToggleBoxSelect());


  useKey("Digit1", () => setBoxId(1));
  useKey("Digit2", () => setBoxId(2));
  useKey("Digit3", () => setBoxId(3));
  useKey("Digit4", () => setBoxId(4));
  useKey("Digit5", () => setBoxId(5));
  useKey("Digit6", () => setBoxId(6));
  useKey("Digit7", () => setBoxId(7));
  useKey("Digit8", () => setBoxId(8));
  useKey("Digit9", () => setBoxId(9));
  useKey("Digit0", () => setBoxId(0));

  // This is portaled into the parent video component
  const portaledInputs = <RegionSelectorControls
      video={video}
      entries={entries}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      fps={fps}
      setFPS={setFPS}
      boxId={boxId}
      setBoxId={setBoxId}
      calculatedFPSData={calculatedFPSData}
      currentFrame={currentFrame}
      handlePrevFrame={previousFrame}
      handleNextFrame={nextFrame}
      handleLogSingle={ () => {logFrame("keyframe")}}
      handleLogTrack={() => {logFrame("track")}}
      handleLogInit={logInit}
      handleLogEnd={logEnd}
      handleDownload={download}
      handleRemove={remove}
      handleUpdateBoxId={handleUpdateBoxId}
      handleInterpolateFrames={handleInterpolateFrames}
      handleGoToFrame={handleGoToFrame}
      handleFilteredToggle={handleFilteredToggle}
      handleToggleBoxTool={handleToggleBoxTool}
      boxToolActive={boxToolActive}
      boxSelectActive={boxSelectActive}
      handleToggleBoxSelect={handleToggleBoxSelect}
      filtered={filtered}
      error={regionError}
  />

  return (
    <div
        className={"frame-area-selector"}
        onMouseMove={handleBoxInteraction}
        onMouseDown={handleMouseDown}
        style={{
          position:"absolute",
          left: 0,
          top: 0,
          width: videoRect.width,
          height: videoRect.height,
          zIndex: 999,
        }}
    >
      {portalTarget.current && createPortal(portaledInputs, portalTarget.current)}
      <canvas
          ref={canvas}
          width={`${videoRect.width}px`}
          height={`${videoRect.height}px`}
          style={{position: 'absolute', left: 0, top: 0, zIndex:999}} />
      { boxToolActive  && (        <Box
          ref={boxRef}
          boxSelectActive={boxSelectActive}
          handle={handleBox}
          halfYDeadzone={halfYDeadzone}
          halfXDeadzone={halfXDeadzone}
          scaleFactor={scaleFactor}
          videoRect={videoRect}
          mousePoint={mousePoint}
          mouseDown={mouseDown}
      />)}
      { boxSelectActive && (        <Box
          ref={selectedBoxRef}
          boxSelectActive={boxSelectActive}
          handle={handleSelectBox}
          halfYDeadzone={halfYDeadzone}
          halfXDeadzone={halfXDeadzone}
          scaleFactor={scaleFactor}
          videoRect={videoRect}
          mousePoint={mousePoint}
          mouseDown={mouseDown}
          updateRect={handleUpdateSelectRect}
      />)}
    </div>
  )
}

export default withTranslation()(RegionSelector);
