import RequestHelper from "../../RequestHelper";
import {serverAddress} from "../config/EnvConfig";

const WorkflowService = {
  addNewQuestionnaireWorkflowDefinition: (jsonValue) => {
    const requestBody = { json: jsonValue.json ? jsonValue.json : JSON.parse(jsonValue.text) };
    return RequestHelper.send(
      serverAddress + "/questionnaire-workflow/new",
      {'Accept':'text/plain'},
      "POST",
      null,
      requestBody,
    );
  },
  updateQuestionnaireWorkflowDefinition: (jsonValue) => {
    const requestBody = { json: jsonValue.json ? jsonValue.json : JSON.parse(jsonValue.text) } ;
    return RequestHelper.send(
        serverAddress + "/questionnaire-workflow/update",
        {'Accept':'text/plain'},
        "POST",
        null,
        requestBody,
    );
  },
  fetchAllWorkflowDefinitions: () => {
    return RequestHelper.send(
        serverAddress + "/questionnaire-workflow/list",
        {},
        "GET",
        {}
    );
  },
  fetchWorkflowDefinitionTasks: (workflowCode) => {
    return RequestHelper.send(
        serverAddress + "/questionnaire-workflow/" + workflowCode + "/task/list",
        {},
        "GET",
        {}
    );
  },
  fetchWorkflowBehaviorTypes: () => {
    return RequestHelper.send(
        serverAddress + "/questionnaire-workflow/behaviour-type/list",
        {},
        "GET",
        {}
    );
  },
  getQuestionnaireWorkflowDefinitionJsonExport: (id,includeDBIds) => {
    const queryParams = {};
    queryParams.id = id;
    queryParams.includeDBIds = includeDBIds;
    return RequestHelper.send(
        serverAddress + "/questionnaire-workflow/export",
        {},
        "GET",
        queryParams
    );
  },
}

export default WorkflowService;
