import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Checkbox, Form, FormGroup, Modal, TextArea} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import LanguageSelectionGeneric from "../../languageselection/LanguageSelectionGeneric";

const TranslationEditModal = ({translationObject, isOpen, onClose, onConfirmCallback}) => {
  const {t} = useTranslation();

  const isConfigTranslation = translationObject.code.slice(-6) === "config"

  const jsonifiedValue = useMemo(()=>{
    if(!isConfigTranslation){
      return translationObject.translation
    }

    try{
      return JSON.stringify(JSON.parse(translationObject.translation), null, 2);
    } catch (e) {
      return translationObject.translation;
    }
  }, [translationObject.translation,isConfigTranslation])
  const [translation, setTranslation] = useState(jsonifiedValue)
  const [exclusions,setExclusions] = useState([]);
  const [toAllLanguages, setToAllLanguages] = React.useState(isConfigTranslation)

  useEffect(()=>{
    setTranslation(jsonifiedValue)
  }, [jsonifiedValue])

  const handleChange = (e, v) => {
    setTranslation(v.value)
  }
  const handleToAllLangsClick = (e, v) => {
    setToAllLanguages( (prevState) => {
      return !prevState
    } );
  }
  const handleLanguageSelection = (selectedLanguages) => {
    setExclusions(selectedLanguages);
  }

  const handleSave = useCallback(() => {
    const savePropagation = {
      toAllLanguages: toAllLanguages,
      exclusions: exclusions
    }
    onConfirmCallback({...translationObject, translation, savePropagation});
    onClose();
  }, [onClose, onConfirmCallback, translation, translationObject,exclusions,toAllLanguages])

  const handleCancel = useCallback(() => {
    setTranslation(translationObject.translation);
    onClose();
  }, [onClose, translationObject.translation])


  return (
      <Modal
          open={isOpen}
          onClose={onClose}
      >
        <Modal.Content>
          <h4 style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            marginBottom: '0.5rem'
          }}>{translationObject.code}
          </h4>
          <p style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}>{translationObject.language}
          </p>
          <Form>
            <Form.Field inline>
              <TextArea fluid="true" rows={16} value={translation} onChange={handleChange}/>
            </Form.Field>
            {isConfigTranslation && (
              <FormGroup >
                <Form.Field inline>
                  <Checkbox checked={toAllLanguages} onChange={handleToAllLangsClick} label={t("TRANSLATION_EDIT_LANG_ALL","Save to all languages")} />
                </Form.Field>
                <Form.Field inline>
                  <label aria-disabled={!toAllLanguages}>{t("TRANSLATION_EDIT_LANG_EXCLUSION","Except these languages:")}</label>
                  <LanguageSelectionGeneric
                      inline
                      disabled={!toAllLanguages}
                      language={exclusions}
                      callback={handleLanguageSelection}
                      useMobileLanguages={true}
                      useWebLanguages={true}
                      multiselect={true}
                      withCode={true}
                  />
                </Form.Field>
              </FormGroup>)}
            <Form.Field inline>
              <Button primary onClick={handleSave}>{t("TRANSLATION_EDIT_SAVE", "Save")}</Button>
              <Button basic primary onClick={handleCancel}>{t("TRANSLATION_EDIT_CANCEL", "Cancel")}</Button>
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
  )
}

export default TranslationEditModal;